import { getToken, setToken, removeToken } from '@/utils/auth'
// 注意此时还没有vue实例
import api from '@/http'
const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: {
      authInfo: {}
    },
    thirdPartInfo: []
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setToken (state, payload) {
      state.token = payload
    },
    setUserInfo (state, payload) {
      state.userInfo = { authInfo: {}, ...payload }
    },
    setThirdPartInfo (state, info) {
      state.thirdPartInfo = [...info]
    }
  },
  actions: {
    // user login
    login ({ commit }, userInfo) {
      const { username, password, isSubAccount = false } = userInfo
      return new Promise((resolve, reject) => {
        api.user.login({
          loginName: username.trim(),
          password,
          appId: 'e8b0d33e687d45638ded3a46cadac995',
          isSubAccount
        }).then(resp => {
          commit('setToken', resp.token)
          setToken(resp.token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // user logout
    logout ({ commit }) {
      return new Promise((resolve, reject) => {
        try {
          removeToken() // must remove  token  first
          commit('resetState')
          sessionStorage.clear()
          resolve()
        } catch (error) {
          reject(error)
        }
      })
    },

    // remove token
    resetToken ({ commit }) {
      return new Promise(resolve => {
        removeToken() // must remove  token  first
        commit('resetState')
        resolve()
      })
    },

    getUserInfo ({ commit }) {
      return api.user.userInfo().then(res => {
        localStorage.nvseditloginusername = res.nickName
        commit('setUserInfo', res)
      })
    },

    // 绑定第三方账号
    bindThird ({ commit, state }, {thirdPartType, code}) {
      return api.user.user_bind({
        thirdPartType,
        code: code
      }).then(r => {
        if (!r) {
          console.error('管理账号失败', r)
          return false
        }
        commit('setThirdPartInfo', r.thirdPartInfo)
        return true
      })
    },

    // 解绑第三方账号
    unbindThird ({ commit }, {thirdPartType, thirdPartUid}) {
      return api.user.user_unbind({ thirdPartType, thirdPartUid }).then(r => {
        console.log('解绑返回', r)
        commit('setThirdPartInfo', r.thirdPartInfo)
      })
    },

    // 查询绑定的第三方账号列表
    getThirdPartInfo ({commit}) {
      return api.user.user_get_third_part().then(r => {
        console.log('已绑定的列表', r)
        commit('setThirdPartInfo', r.thirdPartInfo)
      })
    }
  }
}
