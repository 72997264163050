export default {
  // 打开录音录制
  'openAudioRecord': 'openAudioRecord',
  // 完成录音录制 this.$bus.$on(this.$EventBusKey.finishAudioRecord, (audioInfo) => {})
  /**
   * audioInfo = {
   *   blob: Blob, // 生成的音频文件
       inPoint: 0, // timeline 入口地址
       mimeType: 'audio/webm',
       duration: 0, // 持续时长，微秒
       fileName: ''
   * }
   */
  // 录音完成后，会发送这个事件，对应的轨道组件和素材panel组件接收，做上轨和上传
  'finishAudioRecord': 'finishAudioRecord'
}
