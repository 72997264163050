const builtIns = [
  {
    id: 'fade',
    coverUrl: "/static/videotransition/builtin/Fade.png",
    name: "Fade",
    displayName: "Fade",
    displayNamezhCN: "淡入淡出",
  }, {
    id: 'turning',
    coverUrl: "/static/videotransition/builtin/Turning.png",
    name: "Turning",
    displayName: "Turning",
    displayNamezhCN: "翻转",
  }, {
    id: 'swap',
    coverUrl: "/static/videotransition/builtin/Swap.png",
    name: "Swap",
    displayName: "Swap",
    displayNamezhCN: "层叠",
  }, {
    id: 'stretch_in',
    coverUrl: "/static/videotransition/builtin/Stretch_In.png",
    name: "Stretch In",
    displayName: "Stretch In",
    displayNamezhCN: "伸展进入",
  }, {
    id: 'page_curl',
    coverUrl: "/static/videotransition/builtin/Page_Curl.png",
    name: "Page Curl",
    displayName: "Page Curl",
    displayNamezhCN: "卷页",
  }, {
    id: 'lens_flare',
    coverUrl: "/static/videotransition/builtin/Lens_Flare.png",
    name: "Lens Flare",
    displayName: "Lens Flare",
    displayNamezhCN: "镜头炫光",
  }, {
    id: 'star',
    coverUrl: "/static/videotransition/builtin/Star.png",
    name: "Star",
    displayName: "Star",
    displayNamezhCN: "星形",
  }, {
    id: 'dip_to_black',
    coverUrl: "/static/videotransition/builtin/Dip_To_Black.png",
    name: "Dip To Black",
    displayName: "Dip To Black",
    displayNamezhCN: "闪黑",
  }, {
    id: 'dip_to_white',
    coverUrl: "/static/videotransition/builtin/Dip_To_White.png",
    name: "Dip To White",
    displayName: "Dip To White",
    displayNamezhCN: "闪白",
  }, {
    id: 'push_to_right',
    coverUrl: "/static/videotransition/builtin/Push_To_Right.png",
    name: "Push To Right",
    displayName: "Push To Right",
    displayNamezhCN: "右推拉",
  }, {
    id: 'push_to_top',
    coverUrl: "/static/videotransition/builtin/Push_To_Top.png",
    name: "Push To Top",
    displayName: "Push To Top",
    displayNamezhCN: "上推拉",
  }, {
    id: 'upper_left_into',
    coverUrl: "/static/videotransition/builtin/Upper_Left_Into.png",
    name: "Upper Left Into",
    displayName: "Upper Left Into",
    displayNamezhCN: "斜推",
  }
]

export default builtIns