import { get, post, delet } from '@/utils/request'
import NvNetwork from '@/api/network/NvNetwork'

export default {

  live_compile: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_live_compile,
      data,
      headers: {
        headers: {
          'Content-Type': 'text/plain'
        },
        params: { ...props }
      }
    }),

  live_job_info: ({ ...props }) =>
    get({
      url: NvNetwork.data().webApi_live_job_info,
      headers: {
        params: { ...props }
      }
    }),

  live_job_delete: ({ ...props }) =>
    get({
      url: NvNetwork.data().webApi_live_job_delete,
      headers: {
        params: { ...props }
      }
    }),

  live_getLiveResourceByOriginUrl: ({ ...props }) =>
    get({
      url: NvNetwork.data().webApi_live_getLiveResourceByOriginUrl,
      headers: {
        params: { ...props }
      }
    }),

  live_publish: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_live_publish,
      data,
      headers: {
        params: { ...props }
      }
    }),

  live_stopLiveResource: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_live_stopLiveResource,
      data,
      headers: {
        params: { ...props }
      }
    }),

  live_refreshAuthKey: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_live_refreshAuthKey,
      data,
      headers: {
        params: { ...props }
      }
    }),

  live_audio_to_text_start: (data) =>
    post({
      url: NvNetwork.data().webApi_live_audio_to_text_start,
      data
    }),

  live_audio_to_text_end: (data) =>
    post({
      url: NvNetwork.data().webApi_live_audio_to_text_end,
      data
    })
}
