export default {
  namespaced: true,
  state: {
    selectedTime: -999,
    isVideoTransSelected: false,
    isVideoScaleSelected: false,
    isVideoOpacitySelected: false,
    isVideoRotationSelected: false,
    isVideoMaskSelected: false,
    isEffectSelected: false,
    isAudioVolumeSelected: false
  },
  mutations: {
    updateSelectedTime (state, payload) {
      state.selectedTime = payload
    },
    toggleVideoTransSelected(state, payload) {
      state.isVideoTransSelected = payload
    },
    toggleVideoScaleSelected(state, payload) {
      state.isVideoScaleSelected = payload
    },
    toggleVideoOpacitySelected(state, payload) {
      state.isVideoOpacitySelected = payload
    },
    toggleVideoRotationSelected(state, payload) {
      state.isVideoRotationSelected = payload
    },
    toggleVideoMaskSelected(state, payload) {
      state.isVideoMaskSelected = payload
    },
    resetVideoKeyFrameSelected(state) {
      state.isVideoScaleSelected = false
      state.isVideoTransSelected = false
      state.isVideoOpacitySelected = false
      state.isVideoRotationSelected = false
      state.isVideoMaskSelected = false
      state.selectedTime = -999
    },
    toggleEffectSelected(state, payload) {
      state.isEffectSelected = payload
    },
    resetToggleEffectSelected(state) {
      state.isEffectSelected = false
      state.selectedTime = -999
    },
    toggleAudioVolumeSelected(state, payload) {
      state.isAudioVolumeSelected = payload
    },
    resetAudioKeyFrameSelected(state) {
      state.isAudioVolumeSelected = false
      state.selectedTime = -999
    },
  }
}
