export default {
  currentClip: null,
  currentTrack: null,
  currentClips: [],
  currentPanel: null,
  currentTheme: null,
  blankClip: null,
  AISelectedClip: null,
  currentPanelName: '',
  currentPanelType: '',
  aiStatus: 0, // 语音识别状态 0 准备，1 进行中，2 成功，3 失败
  isTrimModel: false, // 是否正在进行素材裁剪
  currentPreviewId: null, // 正在预览的素材
}
