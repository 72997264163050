var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isEquipmentSupport ||
    !_vm.isBrowserSupport ||
    !_vm.isBrowserVersionSupport ||
    !_vm.isBrowserParamsSupport ||
    !_vm.isBrowserModeSupport
    ? _c("browser", {
        attrs: {
          isEquipmentSupport: _vm.isEquipmentSupport,
          isBrowserSupport: _vm.isBrowserSupport,
          isBrowserVersionSupport: _vm.isBrowserVersionSupport,
          isBrowserParamsSupport: _vm.isBrowserParamsSupport,
          updateParamsIndex: _vm.updateParamsIndex,
          isBrowserModeSupport: _vm.isBrowserModeSupport
        }
      })
    : _c("router-view")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }