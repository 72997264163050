import axios from 'axios'

export default {
  namespaced: true,
  state: {
    thumbnailsBaseUrl: '',
    thumbStep: 1000000,
    thumbType: 'jpg',
    thumbTxtJsonObj: '',
    thumbMatrixUrl: '',
    resWidth: 0,
    resHeight: 0,
    // 时间线开始时间
    startTimeFromM3u8: -1,
    ingestTostartFromM3u8: -1,
    // 用户选择的开始时间
    startTime: -1,
    // 收录开始时间
    ingestTime: -1,
    isM3u8Updating: true,
    clipList: [],
    captionList: [],
    atotUrl: '',
    incrementAtot: null,
    nextIncrementAtotUrl: '', // 语音转文字增量下一个url（暂停继续用）
    diffAtot: {},
    lastIndexWhenNotHit: -1,
    videoSpeechEnable: true, // true：老规则（websocket），false: 新规则（轮询）
    audioToTextPollTimerId: 0, // 轮询计时器 和socket连接断开保持同步
    firstEXTINFDuration: -1,
    projectData: null,
    selectedTab: 'audioToText'
  },
  mutations: {
    updateThumbnailsBaseUrl (state, payload) {
      state.thumbnailsBaseUrl = payload
    },
    updateThumbStep (state, payload) {
      state.thumbStep = payload * 1000
    },
    updateThumbType (state, payload) {
      state.thumbType = payload
    },
    updateThumbTxtJsonObj (state, payload) {
      state.thumbTxtJsonObj = payload
    },
    updateThumbTxtJsonObjByKey (state, payload) {
      const { key, value } = payload
      state.thumbTxtJsonObj[key].url = value
    },
    updateThumbMatrixUrl (state, payload) {
      const url = payload.replace('.m3u8', '.jpg')
      state.thumbMatrixUrl = url
    },
    updateFirstEXTINFDuration (state, payload) {
      state.firstEXTINFDuration = payload
    },
    updateAudioToTextPollTimerId (state, payload) {
      state.audioToTextPollTimerId = payload
    },
    updateNextIncrementAtotUrl (state, payload) {
      state.nextIncrementAtotUrl = payload
    },
    updateVideoSpeechEnable (state, payload) {
      state.videoSpeechEnable = payload
    },
    updateLastIndexWhenNotHit (state, payload) {
      state.lastIndexWhenNotHit = payload
    },
    updateDiffAtot (state, payload) {
      state.diffAtot = payload
    },
    deleteDiffAtotById (state, payload) {
      delete state.diffAtot[payload]
    },
    updateDiffAtotById (state, payload) {
      const { id } = payload
      state.diffAtot[id] = payload
    },
    // 语转文增量
    addNewAtot (state, payload) {
      state.incrementAtot = payload
    },
    updateAtotUrl (state, payload) {
      state.atotUrl = payload
    },
    toggleM3u8Updating (state, payload) {
      state.isM3u8Updating = !state.isM3u8Updating
      if (!state.isM3u8Updating) {
        clearTimeout(state.audioToTextPollTimerId)
        console.info('m3u8已停止更新')
      }
    },
    updateIngestTostartFromM3u8 (state, payload) {
      state.ingestTostartFromM3u8 = payload
    },
    updateStartTimeFromM3u8 (state, payload) {
      state.startTimeFromM3u8 = payload
    },
    updateStartTime (state, payload) {
      state.startTime = payload
    },
    updateIngestTime (state, payload) {
      state.ingestTime = payload
    },
    updateClipHW (state, payload) {
      const { resWidth, resHeight } = payload
      state.resWidth = resWidth
      state.resHeight = resHeight
    },
    updateClipBeforePublish (state) {
      state.clipList.map(item => {
        if (item.selected) {
          Vue.set(item, 'published', true)
          item.selected = false
        }
      })
    },
    updateClipWithCheckAll (state, payload) {
      state.clipList.forEach(clip => {
        if (!clip.published && clip.status === 'finish') {
          clip.selected = payload
        }
      })
    },
    updateClipList (state, payload) {
      state.clipList = payload
    },
    updateClipItem (state, payload) {
      const item = state.clipList.find(item => payload.uuid === item.uuid)
      Object.assign(item, payload)
    },
    addClipItem (state, payload) {
      state.clipList.push(payload)
    },
    deleteClipItem (state, payload) {
      state.clipList.splice(payload, 1)
    },
    updateCaptionList (state, payload) {
      state.captionList = payload
    },
    updateProjectData (state, payload) {
      if (!payload.resolvingPower) {
        payload.resolvingPower = 720
      }
      state.projectData = payload
    },
    updateSelectedTab (state, payload) {
      state.selectedTab = payload
    },
  },
  actions: {
    getTxtJsonObj ({ commit }, payload) {
      commit('updateThumbMatrixUrl', payload)
      const url = payload.replace('.m3u8', '.txt')
      return new Promise((resolve, reject) => {
        axios.get(url)
          .then(resp => {
            const json = JSON.parse('{' + resp.data + '}')
            const result = {}
            for (let ele in json) {
              result[ele] = {
                range: json[ele],
                url: ''
              }
            }
            commit('updateThumbTxtJsonObj', result)
            resolve()
          })
          .catch(error => {
            resolve(error)
          })
      })
    },
    getThumbByPosition ({ commit, state }, payload) {
      return new Promise(async (resolve, reject) => {
        if (state.thumbTxtJsonObj) {
          let { range, url } = state.thumbTxtJsonObj[payload]
          if (!url) {
            const res = await axios.get(state.thumbMatrixUrl, {
              responseType: 'blob',
              headers: {
                'Range': `bytes=${range}`
              }
            })
            url = URL.createObjectURL(res.data)
            commit('updateThumbTxtJsonObjByKey', { key: payload, value: url })
          }
          resolve(url)
        } else {
          resolve(state.thumbnailsBaseUrl + '-' + payload + `.${state.thumbType}`)
        }
      })
    }
  }
}
