<template>
  <browser
    v-if="!isEquipmentSupport || !isBrowserSupport || !isBrowserVersionSupport || !isBrowserParamsSupport || !isBrowserModeSupport"
    :isEquipmentSupport="isEquipmentSupport"
    :isBrowserSupport="isBrowserSupport"
    :isBrowserVersionSupport="isBrowserVersionSupport"
    :isBrowserParamsSupport="isBrowserParamsSupport"
    :updateParamsIndex="updateParamsIndex"
    :isBrowserModeSupport="isBrowserModeSupport"
  ></browser>
  <router-view v-else></router-view>
</template>

<script>
import Browser from './components/home/Browser'
import browserMixin from './browserMixin'
export default {
  name: 'Root',
  mixins: [browserMixin],
  components: {
    Browser
  },
  created() {
    if (process.env.NODE_ENV === 'development' ) {
      localStorage.removeItem('useAppVersion')
    }
    const storageVersion = localStorage.getItem('useAppVersion')
    const getBigVersion = (s1) => s1.split("_").splice(0, 2).join("_")
    if (storageVersion && getBigVersion(storageVersion) !== getBigVersion(this.$appVersion)) {
      window.location.href= '/' + localStorage.getItem('useAppVersion')
    } else {
      localStorage.setItem('useAppVersion', this.$appVersion)
    }
  }
}
</script>
