import { get, post, delet } from '@/utils/request'
import NvNetwork from '@/api/network/NvNetwork'

export default {
  
  audio_align: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_audio_align,
      data,
      headers: {
        params: { ...props }
      }
    }),
  
  audio_result: ({ ...props }) =>
    get({
      url: NvNetwork.data().webApi_audio_result,
      headers: {
        params: { ...props }
      }
    }),
}
