import { deepCopy } from '@/api/TrackCalculatorUtils'
export default {
  setCurrentClip ({ commit }, clip) {
    commit('setCurrentClip', deepCopy(clip))
  },
  setCurrentTrack ({ commit }, track) {
    commit('setCurrentTrack', deepCopy(track))
  },
  setCurrentClips ({ commit }, clips) {
    commit('setCurrentClips', deepCopy(clips))
  },
  setCurrentPanel ({ commit }, panelType) {
    commit('setCurrentPanel', deepCopy(panelType))
  },
  setCurrentTheme ({ commit }, theme) {
    commit('setCurrentTheme', deepCopy(theme))
  },
  setBlankClip ({ commit }, blankClip) {
    commit('setBlankClip', deepCopy(blankClip))
  },
  changeAISelect ({ commit }, { checked, clip }) {
    commit('changeAISelect', checked ? deepCopy(clip) : null)
  },
  changeCurrentPanelName ({ commit }, panelName) {
    commit('changeCurrentPanelName', panelName)
  },
  setTrimModelStatus ({ commit }, val) {
    commit('setTrimModelStatus', val)
  },
  setPanelType ({ commit }, type) {
    commit('setPanelType', type)
  },
  changeAIStatus ({ commit }, status) {
    commit('changeAIStatus', status)
    if (status !== 1) {
      commit('changeAISelect', null)
    }
  }
}
