import { buildTimestampfromString } from '@/components/liveApp/utils'
const getters = {
  token: state => state.user.token,
  userInfo: state => state.user.userInfo,

  selectedKeyFrameTime: state => state.keyFrame.selectedTime,
  isVideoTransSelected: state => state.keyFrame.isVideoTransSelected,
  isVideoScaleSelected: state => state.keyFrame.isVideoScaleSelected,
  isVideoRotationSelected: state => state.keyFrame.isVideoRotationSelected,
  isEffectSelected: state => state.keyFrame.isEffectSelected,
  ingestTostart: state => {
    const { ingestTime, startTime } = state.live
    const tsIngestTIme = buildTimestampfromString(ingestTime) || 0
    const tsStartTIme = buildTimestampfromString(startTime) || 0
    return tsStartTIme - tsIngestTIme
  },
  ingestTostartFromM3u8: state => state.live.ingestTostartFromM3u8
}
export default getters
