import { get, post, delet } from '@/utils/request'
import NvNetwork from '@/api/network/NvNetwork'

export default {
  
  recognition_create: (data, { ...props }) =>
    post({
      url: NvNetwork.data().webApi_recognition_create,
      data,
      headers: {
        params: { ...props }
      }
    }),
  
  recognition_info: ({ ...props }) =>
    get({
      url: NvNetwork.data().webApi_recognition_info,
      headers: {
        params: { ...props }
      }
    }),
}
