/**
 * 一些常用&特殊字符串   标识
 */
export default {
  //中英文
  'zhLang': 'zh',
  'enLang': 'en',

  //媒体类型
  'videoTypes': ['mp4', 'mkv', 'mp4', 'ts', 'mts','m2ts', 'avi', 'flv', 'mov', 'm2p', 'mxf', 'mpg', 'rm', 'rmvb', 'wmv','vob','3gp', 'gif', 'mxf', 'm4v', 'webm'],
  'audioTypes': ['mp3', 'wav', 'aac', 'ac3', 'm4a', 'mxf', 'flac', 'opus'],
  'imageTypes': ['png', 'jpg', 'jpeg', 'jpe', 'bmp', 'tga'],

  //高斯模糊
  'GaussianBlur': 'Gaussian Blur',
  //马赛克
  'Mosaic': 'Mosaic',
  //遮罩
  'Mask': 'Mask',
  // 特效【调节】
  "Regulation": "Regulation",

  //特效-模糊
  'effectBlur': 123,
  //特效-马赛克
  'effectMosaic': 124,
  //特效-遮罩
  'effectShade': 125,
  // 特效-调节
  'effectRegulation': 126,

  'defaultCaptionDisplayName': 'default text',
  'defaultCaptionDisplayNamezhCN': '默认文本',

  // 片段改变事件类型
  'clipTrimInChangeType': 1,
  'clipTrimOutChangeType': 2,
  'clipZoomChangeType': 3,
}
