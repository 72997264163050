import { get, post, delet } from '@/utils/request'
import NvNetwork from '@/api/network/NvNetwork'

export default {
  
  get_template: ({ ...props }) =>
    get({
      url: 'https://vsapi.meishesdk.com/api/my/template/listTemplatesForEditor',
      headers: {
        params: { ...props }
      }
    }),
  
  get_classify: ({ ...props }) =>
    get({
      url: 'https://vsapi.meishesdk.com/api/my/template/listCategories',
      headers: {
        params: { ...props }
      }
    }),
}
