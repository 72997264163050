import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
import zh from './config/zh'
import en from './config/en'

const i18n = new VueI18n({
  locale: getLocale(),
  messages: {
    zh,
    en,
  }
})

Vue.prototype.$getLocale = getLocale
export function getLocale () {
  var lang = navigator.language
  // console.log('Locale Language：', lang)
  // console.log('Storage language', localStorage.language)
  if (localStorage.language === undefined) {
    lang = lang.substr(0, 2)
    if (lang === 'zh') {
      return 'zh'
    } else {
      return 'en'
    }
  } else {
    return localStorage.language
  }
}

export default i18n
