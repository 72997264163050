import EventBus from '../EventBus'
import EventBusKeys from '../api/EventBusKey'
import Global from '../api/Global'
import Utils from '../api/Utils'
import { prepareAssetIndexDB } from '../api/AssetsUtils'

// 初始化wasm
function initPlayerWasm (enableMultithreadDecoding = false, enableDropFrameMode = false) {
  return new Promise(async (resolve, reject) => {
    const loader = WASMLoader({
      showError (err) {
        reject(new Error('wasm 加载失败' + err))
      },
      loadingFinished: resolve
    })
    const wasm = await Utils.getWasmModuleName()
    loader.loadEmscriptenModule(wasm, enableMultithreadDecoding, enableDropFrameMode)
  })
}
function initIndexDB () {
  return new Promise((resolve, reject) => {
    prepareAssetIndexDB(resolve, reject)
  })
}
// 确保wasm加载完成
function ensureMeisheModule () {
  const poll = (resolve, reject) => {
    if (Module.Meishe !== undefined && Module.Meishe.streamingContext !== undefined) {
      resolve()
    } else {
      setTimeout(() => poll(resolve, reject), 400)
    }
  }
  return new Promise(poll)
}
// 处理FS, 创建目录
function createFSDir () {
  const dirs = FS.readdir('/')
  const folders = [
    Global.getTemplateFolder(),
    Global.getFSProjectFolder(),
    Global.getFSVideoFxFolder(),
    Global.getFSAnimatedStickerFolder(),
    Global.getFSCaptionStyleFolder(),
    Global.getFSThemeFolder(),
    Global.getFSTransitionFolder(),
    Global.getFSM3u8Folder(),
    Global.getFSFontFolder(),
    Global.getFSCompoundFolder(),
    Global.getFSRecord(),
    Global.getModelFolder(),
    Global.getResourcesFolder()
  ]
  folders.map(item => {
    if (!dirs.includes(item)) {
      FS.mkdir(item)
    }
  })
}
// SDK 鉴权
function verifySdkLicenseFile (type) {
  return new Promise((resolve, reject) => {
    const streamingContext = nvsGetStreamingContextInstance()
    if (Utils.needLowerMemory()) {
      streamingContext.setMaxVideoFileReaderCount(8)
    }
    if (type === 'live') {
      streamingContext.setMaxVideoFileReaderCount(4)
    }
    if (type !== 'live' && !Utils.needLowerMemory()) {
      streamingContext.setMaxImageReaderCount(16)
    }
    streamingContext.onWebRequestAuthFinish = success => {
      if (!success) {
        if (process.env.NODE_ENV !== 'development' && this.$Utils.isAuth()) {
          reject(new Error('SDK 鉴权失败'))
        } else {
          console.error('SDK 鉴权失败')
        }
      }
      resolve(streamingContext)
    }
    streamingContext.verifySdkLicenseFile(Utils.getAuthUrl(), type)
  })
}
// 添加音频上下文
export function resumeAudio () {
  function slot () {
    nvsResumeAudioContextWithPromise().then(() => {
      document.body.removeEventListener('mousedown', slot)
      window.removeEventListener('keydown', slot)
    }).catch(e => {
      console.error(e)
    })
  }

  document.body.addEventListener('mousedown', slot)
  window.addEventListener('keydown', slot)
}

function initStreamContextEvent () {
  const streamingContext = nvsGetStreamingContextInstance()

  // 播放停止的回调
  streamingContext.onPlaybackStopped = timeline => {
    EventBus.$emit(EventBusKeys.onPlaybackStopped, timeline)
  }
  // 播放中回调
  streamingContext.onPlaybackTimelinePosition = (timeline, position) => {
    EventBus.$emit(EventBusKeys.onPlaybackTimelinePosition, timeline, position)
  }
  // 播放到末尾
  streamingContext.onPlaybackEOF = (timeline) => {
    EventBus.$emit(EventBusKeys.onPlaybackEOF, timeline)
  }
  streamingContext.onAudioVUMeter = (timeline, leftVUValue, rightVUValue, timeStamp) => {
    EventBus.$emit(EventBusKeys.onAudioVUMeter, timeline, leftVUValue, rightVUValue, timeStamp)
  }
  // 生成图片的回调
  streamingContext.onImageGrabbedArrived = (imageData, time) => {
    EventBus.$emit(EventBusKeys.onImageGrabbedArrived, imageData, time)
  }
  // 播放状态发生改变的回调
  streamingContext.onWebRequestWaitStatusChange = (isVideo, waiting) => {
    EventBus.$emit(EventBusKeys.onWebRequestWaitStatusChange, isVideo, waiting)
  }
  // 资源安装完成的回调
  streamingContext.getAssetPackageManager().onFinishAssetPackageInstallation = (
    assetPackageId,
    assetPackageFilePath,
    assetPackageType,
    error
  ) => {
    EventBus.$emit(
      EventBusKeys.onFinishAssetPackageInstallation + assetPackageFilePath,
      assetPackageId,
      assetPackageFilePath,
      assetPackageType,
      error
    )
  }
  streamingContext.onImageGrabbedArrived = (imageData, time) => {
    EventBus.$emit(EventBusKeys.grabImage, imageData, time)
  }
}
export function initSDK (type, enableMultithreadDecoding = false, enableDropFrameMode = false) {
  if (Module.Meishe) {
    createFSDir()
    return initIndexDB()
  }
  return initPlayerWasm(enableMultithreadDecoding, enableDropFrameMode).then(() => {
    return ensureMeisheModule()
  }).then(() => {
    createFSDir()
    initStreamContextEvent()
    return initIndexDB()
  }).then(() => {
    resumeAudio()
    return verifySdkLicenseFile(type)
  })
}
