export default {
    namespaced: true,
    state: {
        panelLock: null,
        replaceClipMedia:null,
        replaceClipModalVisible:false,
    },
    mutations: {
        updatePanelLock(state, payload) {
            state.panelLock = payload 
        },
        updateReplaceClipMedia(state, payload) {
            state.replaceClipMedia = payload 
        },
        updateReplaceClipModalVisible(state, payload) {
            state.replaceClipModalVisible = payload 
        }
    }
  }
  