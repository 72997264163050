<template>
  <!--右键菜单-->
  <div id="globalRightMenuDom" class="right-menu" :style="styleObj">
    <ul>
      <li v-for="item in rightMenuList" :key="item.id">
        <div class="item" :class="{ disable: item.text.isDisabled }" @click="handler(item)">
          <span class="text">
            {{ item.text.content ? item.text.content : item.text }}
          </span>
          <span class="hotkey">{{ item.text.hotkey }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "right-menu",
  props: {
    rightMenuStatus: String,
    rightMenuTop: Number,
    rightMenuLeft: Number,
    rightMenuList: Array,
  },
  computed: {
    styleObj() {
      const length = this.rightMenuList.length
      // 每一项的高度是35px
      const top = this.rightMenuTop - length * 30 + 'px'
      return {
        display: this.rightMenuStatus,
        top,
        left: this.rightMenuLeft + 15 + 'px',
      }
    }
  },
  methods: {
    handler(item) {
      if (!item.text.isDisabled) {
        item.handler(item.parameter)
      }
    }
  },
};
</script>

<style scoped lang="scss">
// 右键菜单样式
.right-menu {
  background: #1E1E1E;
  border: 1px solid #393939;
  border-radius: 4px;
  position: fixed;
  left: 0;
  top: 0;
  width: 170px;
  height: auto;
  display: none;
  z-index: 9999;
  box-sizing: border-box;
  padding: 10px 0;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 12px;

    li {
      padding: 0;
      &:not(:last-child) {
        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(7) {
          &::after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background: #272727;
            margin: 10px 0;
          }
        }
      }
      .item {
        display: flex;
        justify-content: space-between;
        color: #fff;
        padding: 8px 15px;
        box-sizing: border-box;
        cursor: pointer;
        text-align: justify;
        &:not(.disable):hover {
          background: rgb(33, 38, 49);
        }
        &.disable {
          color: #E8F3FA;
          cursor: default;
        }
      }
    }
  }
}
</style>
