// monitor Api
export default {
  // 清理视口 () => {}
  'monitorClearSelect': 'monitorClearSelect',
  // 视口类型变化
  // 切换序列监听器 this.$bus.$emit(this.$EventBusKey.monitorTypeUpdate, this.$Global.MONITOR_TYPE_0)
  // 切换片段监听器 this.$bus.$emit(this.$EventBusKey.monitorTypeUpdate, this.$Global.MONITOR_TYPE_1, timeline)
  // 切换h5监听器 this.$bus.$emit(this.$EventBusKey.monitorTypeUpdate, this.$Global.MONITOR_TYPE_2, h5_url)
  'monitorTypeUpdate': 'monitorTypeUpdate',
  // 视口初始化
  'monitorBuild': 'monitorBuild',
  // 物料增加，刷新时间轴 (position) => {}
  'monitorTimelineAppend': 'monitorTimelineAppend',
  // 选中 clip，统一根据 type 处理 (clip) => {}
  'monitorSelectClip': 'monitorSelectClip',

  // 更新选中 captionClip (captionClip) => {}
  'monitorSelectCaption': 'monitorSelectCaption',
  // 主动定位 captionClip (captionClip, translateTo) => {}
  // translateTo =
  // 'left-top' | 'top' | 'right-top'
  // 'left' | 'center' | 'right'
  // 'left-bottom' | 'bottom' | 'right-bottom'
  'monitorTranslateCaption': 'monitorTranslateCaption',

  'monitorTranslateSubtitleCaption': 'monitorTranslateSubtitleCaption',

  // 选中 组合字幕 compoundCaption (compoundCaptionClip) => {}
  'monitorSelectCompoundCaption': 'monitorSelectCompoundCaption',
  // 主动定位 compoundCaption (compoundCaptionClip, translateTo) => {}
  // translateTo =
  // 'left-top' | 'top' | 'right-top'
  // 'left' | 'center' | 'right'
  // 'left-bottom' | 'bottom' | 'right-bottom'
  'monitorTranslateCompoundCaption': 'monitorTranslateCompoundCaption',

  // 调整普通字幕位置，根据number表单控件调整 captionClip (captionClip, finalPosition:{x:number,y:number}) => {}
  'monitorOffsetTranslateCaption': 'monitorOffsetTranslateCaption',

  // 调整富文本字幕（贴纸）大小
  'monitorScaleCaption': 'monitorScaleCaption',

  // 调整复合字幕位置, 根据number表单控件调整 compoundCaption (compoundCaption, finalPosition:{x:number,y:number}) => {}
  'monitorOffsetTranslateCompoundCaption': 'monitorOffsetTranslateCompoundCaption',

  // 调整贴纸位置, 根据number表单控件调整 compoundCaption (compoundCaption, finalPosition:{x:number,y:number}) => {}
  'monitorOffsetTranslateAnimatedSticker': 'monitorOffsetTranslateAnimatedSticker',
  // 更新选中 animatedStickerClip (animatedStickerClip) => {}
  'monitorSelectAnimatedSticker': 'monitorSelectAnimatedSticker',
  // 四下旋转 AnimatedSticker (animatedStickerClip, rotateKey) => {}
  // 水平 垂直 向右90 向左90
  // rotateKey = 'horizontal' | 'vertical' | 'to-right-90' | 'to-left-90'
  'monitorRotateAnimatedSticker': 'monitorRotateAnimatedSticker',
  // 主动定位 animatedStickerClip (animatedStickerClip, translateTo) => {}
  // translateTo =
  // 'left-top' | 'top' | 'right-top'
  // 'left' | 'center' | 'right'
  // 'left-bottom' | 'bottom' | 'right-bottom'
  'monitorTranslateAnimatedSticker': 'monitorTranslateAnimatedSticker',

  // 更新选中 videoClip
  'monitorSelectVideo': 'monitorSelectVideo',

  // 获取 Transform2D videoFx x,y 位置
  // (videoFx, callback) => {}
  // callback = (point) => {} pint.oX, point.oY
  // point: {...}
  // oX: 中心点x oY: 中心点y x: 左上角点x y: 左上角点y
  // width: 矩阵宽度 height: 矩阵高度
  'monitorGetTransform2DVideoFxPoint': 'monitorGetTransform2DVideoFxPoint',
  // 更新选中 Transform2D videoFx (videoFx) => {}
  'monitorSelectTransform2DVideoFx': 'monitorSelectTransform2DVideoFx',
  // 四下旋转 Transform2D videoFx (videoFx, rotateKey) => {}
  // 水平 垂直 向右90 向左90
  // rotateKey = 'horizontal' | 'vertical' | 'to-right-90' | 'to-left-90'
  'monitorRotateVideoFx': 'monitorRotateVideoFx',
  // 定位 Transform2D videoFx (videoFx, x, y) => {} // x, y liveWindow坐标
  'monitorTranslateVideoFx': 'monitorTranslateVideoFx',
  // 缩放 Transform2D videoFx (videoFx, scale) => {}
  'monitorScaleVideoFx': 'monitorScaleVideoFx',
  // 裁剪模式
  'monitorScanVideoFx': 'monitorScanVideoFx',

  // 计算裁剪遮罩选中框konva位置
  'getCropPoint': 'getCropPoint',

  // 更新选中 effect (videoFx) => {}
  'monitorSelectEffectVideoFx': 'monitorSelectEffectVideoFx',
  // 获取effect videoFx 初始region (callback) => {}
  // callback = (region) => {
  //  videoFx.params.push(region)
  // }
  'monitorGetDefaultEffectVideoFxRegion': 'monitorGetDefaultEffectVideoFxRegion',
  // 主动定位 effect videoFxClip (videoFxClip, translateTo) => {}
  // translateTo =
  // 'left-top' | 'top' | 'right-top'
  // 'left' | 'center' | 'right'
  // 'left-bottom' | 'bottom' | 'right-bottom'
  'monitorTranslateEffectVideoFxRegion': 'monitorTranslateEffectVideoFxRegion',

  'monitorChangeShape': 'monitorChangeShape'
}
