<template>
  <div id="b-root">
    <!--设备不支持提示!this.isEquipmentSupport-->
    <div id="b-equipment" v-show="!this.isEquipmentSupport">
      <img src="/static/browser/icon_browser_system.png">
      <div class="equipment-title">{{this.$t('browser.equipmentTitle')}}</div>
      <div class="equipment-sub-title">
        {{this.$t('browser.equipmentSubTitle')+this.$t('browser.Windows')+'、'+this.$t('browser.Mac')+'、'+this.$t('browser.Linux')}}
      </div>
    </div>

    <!--浏览器不支持提示!this.isBrowserSupport-->
    <div id="b-n-browser" v-show="!this.isBrowserSupport">
      <img src="/static/browser/icon_browser_browser.png">
      <div class="n-browser-title">{{this.$t('browser.browserTitle')}}</div>
      <div class="n-browser-desc">
        <div class="n-browser-sub-show" v-for="(item,index) in recommendData" :key="index" v-show="item.isShow">
          <img v-bind:src="item.icon">
          <div class="recommend-to" @click="goRecommendUrl(index)">{{item.message}}</div>
        </div>
      </div>
    </div>


    <!--浏览器支持提示this.isBrowserSupport-->
    <div id="b-y-browser" v-show="this.isBrowserSupport">
      <!--版本不支持!this.isBrowserVersionSupport-->
      <div class="y-browser-version" v-show="!this.isBrowserVersionSupport">
        <img src="/static/browser/icon_browser_update.png">
        <div class="update-title">
          {{this.$t('browser.updateTitle')+isBrowserVersionSubTitle+$t('browser.browser')+(is360?
          $t('browser._360Cut'):'')}}
        </div>
        <img style="margin-top: 31px" v-bind:src="this.isBrowserVersionIcon">
        <div class="update-to"
             @click="toUpdate(isBrowserVersionUpdateUrl)">
          {{this.isBrowserVersionTitle}}
        </div>
      </div>

      <!--版本支持,参数不支持 this.isBrowserVersionSupport !this.isBrowserParamsSupport-->
      <div class="browser-params" v-show="this.isBrowserVersionSupport && !this.isBrowserParamsSupport">
        <div class="params-re-chrome">
          <div class="re-params-title">{{this.$t('browser.recommendChrome')}}</div>
          <img style="margin-top: 31px" src="/static/browser/icon_google.png">
          <div class="re-params-to" @click="toUpdate('http://www.google.cn/chrome/browser/desktop/index.html')">
            {{this.$t('browser.Chrome')}}
          </div>
          <div class="re-params-no">{{$t('browser.recommendNo')}}</div>
        </div>
        <div class="y-browser-params">
          <div class="params-title">{{this.$t('browser.paramsTitle')}}</div>
          <img class="params-icon" v-bind:src="isBrowserParamsUrl">
          <div class="params-sub-title">
            {{(this.isSupportSharedArrayBuffer()?'':(this.$t('browser.noSupport'))+'SharedArrayBuffer')+''+(this.isSupportWebassembly()?'':(this.$t('browser.noSupport'))+'Webassembly')}}
          </div>
        </div>
      </div>

      <!--  浏览器当前打开模式不支持 【 Firefox中的隐私模式 】    -->
      <div class="y-browser-version"  v-show="!this.isBrowserModeSupport">
        <img src="/static/browser/icon_browser_update.png">
        <div class="update-title">
          {{ isBrowserVersionSubTitle +$t('browser.browser') + $t('browser.updateModeTitle') }}
        </div>
      </div>
    </div>


  </div>
</template>

<script>
  export default {
    name: 'Browser',
    props :{
      isEquipmentSupport: {
        type: Boolean,
        default: false
      },
      isBrowserSupport: {
        type: Boolean,
        default: false
      },
      isBrowserVersionSupport: {
        type: Boolean,
        default: false
      },
      isBrowserParamsSupport: {
        type: Boolean,
        default: false
      },
      isBrowserModeSupport: {
        type: Boolean,
        default: false
      },
      updateParamsIndex: {
        type: Number,
        default: 0
      },
    },
    created () {
      this.setUpdateParams(this.updateParamsIndex)
    },
    data: function () {
      return {
        is360: false,
        paramsBrowser: [
          {
            id: 0,
            message: this.$t('browser.Chrome'),
            subMessage: this.$t('browser.ChromeSub'),
            icon: '/static/browser/icon_google.png',
            updateUrl: 'http://www.google.cn/chrome/browser/desktop/index.html',
            paramsUrl: '/static/browser/360-browser-params.png'
          },
          {
            id: 1,
            message: this.$t('browser._360'),
            icon: '/static/browser/icon_360_se.png',
            subMessage: this.$t('browser._360Sub'),
            updateUrl: 'https://browser.360.cn/ee/',
            paramsUrl: '/static/browser/360-browser-params.png'
          },
          {
            id: 2,
            message: this.$t('browser.Firefox'),
            icon: '/static/browser/icon_firefox.png',
            subMessage: this.$t('browser.FirefoxSub'),
            updateUrl: 'http://www.firefox.com.cn/',
            paramsUrl: '/static/browser/firefox-browser-params.png'
          },
          {
            id: 3,
            message: this.$t('browser.Opera'),
            icon: '/static/browser/icon_opera.png',
            subMessage: this.$t('browser.OperaSub'),
            updateUrl: 'https://www.oupeng.com/operapc',
            paramsUrl: '/static/browser/opera-browser-params.png'
          },
          {
            id: 4,
            message: this.$t('browser.Edge'),
            icon: '/static/browser/icon_edge.png',
            subMessage: this.$t('browser.EdgeSub'),
            updateUrl: 'https://www.microsoft.com/en-us/windows/microsoft-edge',
            paramsUrl: '/static/browser/360-browser-params.png'
          },
          // {
          //   id: 5,
          //   message: this.$t('browser.Safari'),
          //   icon: '/static/browser/icon_safari.png',
          //   subMessage: this.$t('browser.SafariSub'),
          //   updateUrl: 'https://www.apple.com/safari/',
          //   paramsUrl: ''
          // }
        ],
        recommendData: [
          {
            id: 0,
            message: this.$t('browser.Chrome'),
            subMessage: this.$t('browser.ChromeSub'),
            icon: '/static/browser/icon_google.png',
            isShow: true,
            updateUrl: 'http://www.google.cn/chrome/browser/desktop/index.html'
          },
          {
            id: 1,
            message: this.$t('browser.Edge'),
            subMessage: this.$t('browser.EdgeSub'),
            icon: '/static/browser/icon_edge.png',
            isShow: true,
            updateUrl: 'https://www.microsoft.com/en-us/windows/microsoft-edge',
          },
          {
            id: 2,
            message: this.$t('browser._360'),
            icon: '/static/browser/icon_360_se.png',
            subMessage: this.$t('browser._360Sub'),
            isShow: (this.$Utils.isZHLan()),
            updateUrl: 'https://browser.360.cn/ee/'
          },
          {
            id: 3,
            message: this.$t('browser.Opera'),
            icon: '/static/browser/icon_opera.png',
            subMessage: this.$t('browser.OperaSub'),
            isShow: true,
            updateUrl: 'https://www.oupeng.com/operapc'
          },
          // {
          //   id: 4,
          //   message: this.$t('browser.Safari'),
          //   icon: '/static/browser/icon_safari.png',
          //   subMessage: this.$t('browser.SafariSub'),
          //   isShow: true,
          //   updateUrl: 'https://www.apple.com/safari/'
          // }
        ]
      }
    },
    methods: {
      isSupportSharedArrayBuffer () {
        return typeof SharedArrayBuffer !== 'undefined'
      },
      isSupportWebassembly () {
        if (this.isIE() || this.isIE11()) {
          return false
        }
        return typeof WebAssembly !== 'undefined'
      },
      _mime: function (option, value) {
        var mimeTypes = navigator.mimeTypes
        for (var mt in mimeTypes) {
          if (mimeTypes[mt][option] === value) {
            return true
          }
        }
        return false
      },
      goRecommendUrl: function (index) {
        window.location.href = this.recommendData[index].updateUrl
      },
      toUpdate: function (url) {
        window.location.href = url
      },
      setUpdateParams: function (index) {
        this.isBrowserVersionIcon = this.paramsBrowser[index].icon
        this.isBrowserVersionTitle = this.paramsBrowser[index].message
        this.isBrowserVersionSubTitle = this.paramsBrowser[index].subMessage
        this.isBrowserVersionUpdateUrl = this.paramsBrowser[index].updateUrl
        this.isBrowserParamsUrl = this.paramsBrowser[index].paramsUrl
      },
      isIE() {
        var userAgent = navigator.userAgent //取得浏览器的userAgent字符串
        return userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1
      },
      isIE11() {
        var userAgent = navigator.userAgent //取得浏览器的userAgent字符串
        return userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1
      }
    }
  }
</script>

<style lang="scss">
  html, body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }

  #b-root {
    width: 100%;
    height: 100%;
    background: white;
  }

  #b-equipment {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .equipment-title {
      margin-top: 65px;
      font-size: 16px;
      color: $base-color;
    }

    .equipment-sub-title {
      font-size: 14px;
      margin-top: 20px;
      color: $base-color;
    }
  }

  #b-n-browser {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .n-browser-title {
      margin-top: 58px;
      font-size: 25px;
      color: rgba(51, 51, 51, 1);
    }

    .n-browser-desc {
      display: flex;
      flex-direction: row;
      margin-top: 20px;


      .n-browser-sub-title {
        font-size: 20px;
        margin-top: 20px;
        margin-right: 20px;
        color: rgba(51, 51, 51, 1);
      }

      .n-browser-sub-show {
        display: flex;
        margin-right: 30px;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .recommend-to {
          color: rgba(67, 136, 255, 1);
          margin-top: 12px;
          font-size: 15px;
          cursor: pointer;
          text-align: center;
          text-decoration: underline;
        }
      }
    }
  }

  #b-y-browser {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .y-browser-version {
      align-items: center;
      display: flex;
      flex-direction: column;

      .update-title {
        margin-top: 60px;
        font-size: 25px;
        color: rgba(51, 51, 51, 1);
        margin-left: 15px

      }

      .update-to {
        color: rgba(67, 136, 255, 1);
        margin-top: 15px;
        font-size: 18px;
        cursor: pointer;
        text-decoration: underline;
      }


      .update-sub-title {
        color: $base-color;
        margin-top: 19px;
        font-size: 25px;
      }

    }

    .browser-params {
      width: 100%;
      height: 100%;
      padding-top: 30px;
      overflow-y: auto;


      .params-re-chrome {
        display: flex;
        flex-direction: column;
        align-items: center;

        .re-params-title {
          margin-top: 60px;
          font-size: 25px;
          color: rgba(51, 51, 51, 1);
          margin-left: 15px

        }

        .re-params-to {
          color: rgba(67, 136, 255, 1);
          margin-top: 15px;
          font-size: 18px;
          cursor: pointer;
          text-decoration: underline;
        }

        .re-params-no {
          color: $base-color;
          margin-bottom: 10px;
          margin-top: 60px;
          font-size: 25px;
        }

      }

      .y-browser-params {
        align-items: center;
        display: flex;
        flex-direction: column;

        .params-title {
          font-size: 25px;
          color: rgba(51, 51, 51, 1);
          margin-left: 15px
        }

        .params-icon {
          width: 80%;
          margin-top: 50px
        }

        .params-sub-title {
          align-content: end;
          display: flex;
          color: $base-color;
          bottom: 0px;
          font-size: 12px;
          margin-bottom: 15px;
          position: absolute;
        }
      }
    }


  }
</style>
