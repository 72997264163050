import Cookies from 'js-cookie'
const TokenKey = 'Authorization'

export function getToken () {
  return localStorage[TokenKey]
}

export function setToken (token) {
  localStorage[TokenKey] = token
  Cookies.set('token', token)
  Cookies.set('token', token, {domain: '.meishesdk.com'})
}

export function removeToken() {
  localStorage.removeItem(TokenKey)
}
