import StringValue from '@/api/StringValue'
import vm from '@/main'
import JSZip from 'jszip'
import { v5 as uuidv5 } from 'uuid'
import { deleteIndexDBByIndexAndName, saveAndInstallPackage } from '@/api/AssetsUtils'
import Global from '@/api/Global'
import TimelineUtils from '@/api/TimelineUtils'
import store from '@/store'

const KEYFRAME_AUDIO = ["Left Gain", "Right Gain"];

/**
 * 根据值获取键
 * @param {*} obj 要遍历的对象
 * @param {*} value 要匹配的值
 */
export function getObjectKeyByValue (obj, value) {
  return Object.keys(obj).find(k => {
    const objValue = obj[k]
    if (Array.isArray(objValue)) {
      return obj[k].includes(value)
    } else {
      return obj[k] === value
    }
  })
}

export function debounce (func, wait, immediate) {
  var timeout, result

  var debounced = function () {
    var context = this
    var args = arguments
    if (timeout) clearTimeout(timeout)
    var later = function () {
      timeout = null
      if (!immediate) result = func.apply(context, args)
    }
    var callNow = immediate && !timeout
    timeout = setTimeout(later, wait)
    if (callNow) result = func.apply(this, args)
    return result
  }
  debounced.cancel = function () {
    clearTimeout(timeout)
    timeout = null
  }
  return debounced
}

export function getMediaTypeByName (fileName) {
  const { videoTypes, audioTypes, imageTypes } = StringValue
  const suffix = fileName.split('.').pop().toLocaleLowerCase()
  if (videoTypes.includes(suffix)) {
    return 'video'
  } else if (audioTypes.includes(suffix)) {
    return 'audio'
  } else if (imageTypes.includes(suffix)) {
    return 'image'
  } else {
    return 'video'
  }
}

export function allWithProgress (requests, callback) {
  let index = 0
  requests.forEach(item => {
    item.then(() => {
      callback(index++)
    })
  })
  return Promise.all(requests)
}

export function getQueryStringByName (name) {
  const result = location.search.match(new RegExp("[\?\&]" + name + "=([^\&]+)", "i"))
  if (result == null || result.length < 1) {
    return ""
  }
  return result[1]
}

/**
 * 达不到帧精确，使用该函数近似
 * @param {*} frameTime
 * @param {*} currentTime
 */
export function isFrameTimeEqual (frameTime, currentTime = TimelineUtils.getCurrentTimelinePosition()) {
  const time = Number(frameTime)
  if (time + 1 >= currentTime && time - 1 <= currentTime) {
    return true
  } else {
    return false
  }
}

export function getSelectedClip () {
  const tracks = TimelineUtils.timelineData.tracks
  for (let i = 0; i < tracks.length; i++) {
    const clips = tracks[i].clips
    for (let j = 0; j < clips.length; j++) {
      const clip = clips[j]
      if (clip.selected) {
        return clip
      }
    }
  }
  return null
}

export function getKeyFramesByClip (clip = {}) {
  if (
    clip.type === 'timelineVideoFx' &&
    [vm.$Global.effectBlur, vm.$Global.effectMosaic, vm.$Global.effectShade].includes(clip.clipSubType)
  ) {
    return clip.keyFrames
  } else {
    let ks = []
    if (clip.videoFxs) {
      const videoFx = clip.videoFxs.filter(videoFx => videoFx.type === 'property' || videoFx.desc === 'Mosaic')
      if (videoFx && videoFx.length > 0) {
        videoFx.forEach(item => {
          ks = ks.concat(item.keyFrames)
        })
      }
    }
    if (Array.isArray(clip.audioFxs) && clip.audioFxs.length > 0) {
      const audioFx = clip.audioFxs.find(audioFx => audioFx && audioFx.type === 'volume')
      if (audioFx) {
        ks = ks.concat(audioFx.keyFrames)
      }
    }
    return ks
  }
}

/**
 * 只有视频关键帧需要refresh，模糊特技不需要
 * @param {*} clip
 */
export function refreshKeyFrameWhenMouseUp (clip) {
  const frames = getKeyFramesByClip(clip)
  if (frames.length > 0) {
    let videoFx
    if(clip.videoFxs){
      videoFx = clip.videoFxs.find(videoFx => videoFx.type === 'property')
      videoFx.raw.removeAllKeyframe('Scale X')
      videoFx.raw.removeAllKeyframe('Scale Y')
      videoFx.raw.removeAllKeyframe('Trans X')
      videoFx.raw.removeAllKeyframe('Trans Y')
      videoFx.raw.removeAllKeyframe('Rotation')
      videoFx.raw.removeAllKeyframe('Opacity')
    }
   
    const audioFx = clip.audioFxs.find(videoFx => videoFx.type === 'volume')
    if (audioFx) {
      KEYFRAME_AUDIO.forEach(ele => {
        audioFx.raw.removeAllKeyframe(ele)
      })
    }
    frames.forEach(frame => {
      if (KEYFRAME_AUDIO.includes(frame.key) && audioFx) {
        audioFx.raw.setFloatValAtTime(frame.key, frame.value, Number(frame.time))
      } else {
        videoFx && videoFx.raw.setFloatValAtTime(frame.key, frame.value, Number(frame.time))
      }

    })
  }
}

export function refreshVideoPropertyFxWhenMouseUp (clip) {
  if (!clip.raw || typeof clip.raw.getPropertyVideoFx !== 'function') return
  const propertyVideoFx = clip.raw.getPropertyVideoFx()
  if (!propertyVideoFx) return
  const fx = clip.videoFxs.find(fx => fx.type == 'property')
  if (!fx) return
  // 因为之前有可能removeAllFx，所以需要打开
  clip.raw.enablePropertyVideoFx(true)
  const clipDuration = clip.outPoint - clip.inPoint

  if (fx.animationType === 'inAnimation') {
    let hasOutAnimation = false
    let packageEffectOut = 0
    let package2EffectIn = 0
    let package2EffectOut = 0
    let package2Id = fx.isPostOutAnimation ? 'Post Package2 Id' : 'Package2 Id'

    // 获取信息
    for (let i = 0; i < fx.params.length; i++) {
      let param = fx.params[i]
      if (param.key === 'Package Effect Out') packageEffectOut = param.value
      else if (param.key === 'Package2 Effect In') package2EffectIn = param.value
      else if (param.key === 'Package2 Effect Out') package2EffectOut = param.value
      else if (param.key === package2Id) {
        if (param.value) hasOutAnimation = true
      }
    }

    // 限幅，然后更新信息
    packageEffectOut = Math.min(packageEffectOut, clipDuration)
    if (hasOutAnimation) {
      let outAnimationDuration = package2EffectOut - package2EffectIn
      package2EffectOut = clipDuration
      package2EffectIn = package2EffectOut - outAnimationDuration
      package2EffectIn = Math.max(package2EffectIn, packageEffectOut)
      propertyVideoFx.setFloatVal('Package2 Effect In', package2EffectIn)
      propertyVideoFx.setFloatVal('Package2 Effect Out', package2EffectOut)
    }
    for (let i = 0; i < fx.params.length; i++) {
      let param = fx.params[i]
      if (param.key === 'Package Effect Out') param.value = packageEffectOut
      else if (param.key === 'Package2 Effect In') {
        if (hasOutAnimation) param.value = package2EffectIn
      } else if (param.key === 'Package2 Effect Out') {
        if (hasOutAnimation) param.value = package2EffectOut
      }
    }
    propertyVideoFx.setFloatVal('Package Effect Out', packageEffectOut)
  } if (fx.animationType === 'loopAnimation') {
    // 限幅，然后更新信息
    for (let i = 0; i < fx.params.length; i++) {
      let param = fx.params[i]
      if (param.key === 'Package Effect Out') {
        const packageEffectOut = Math.min(param.value, clipDuration)
        param.value = packageEffectOut
        propertyVideoFx.setFloatVal('Package Effect Out', packageEffectOut)
      }
    }
  } else {
    let hasOutAnimation = false
    let package2EffectIn = 0
    let package2EffectOut = 0
    let package2Id = fx.isPostOutAnimation ? 'Post Package2 Id' : 'Package2 Id'

    // 获取信息
    for (let i = 0; i < fx.params.length; i++) {
      let param = fx.params[i]
      if (param.key === 'Package2 Effect In') package2EffectIn = param.value
      else if (param.key === 'Package2 Effect Out') package2EffectOut = param.value
      else if (param.key === package2Id) {
        if (param.value) hasOutAnimation = true
      }
    }

    // 限幅，然后更新信息
    if (hasOutAnimation) {
      let outAnimationDuration = package2EffectOut - package2EffectIn
      package2EffectOut = clipDuration
      package2EffectIn = package2EffectOut - outAnimationDuration
      package2EffectIn = Math.max(package2EffectIn, 0)
      for (let i = 0; i < fx.params.length; i++) {
        let param = fx.params[i]
        if (param.key === 'Package2 Effect In') param.value = package2EffectIn
        else if (param.key === 'Package2 Effect Out') param.value = package2EffectOut
      }
      propertyVideoFx.setFloatVal('Package2 Effect In', package2EffectIn)
      propertyVideoFx.setFloatVal('Package2 Effect Out', package2EffectOut)
    }
  }
}

export function refreshAssetAnimationWhenMouseUp (clip) {
  const animationId = clip.animationId
  const inAnimationId = clip.inAnimationId
  const outAnimationId = clip.outAnimationId
  const clipDuration = clip.outPoint - clip.inPoint
  if (inAnimationId) {
    const inAnimationDuration = Number(clip.inAnimationDuration)
    let outAnimationDuration = 0
    if (outAnimationId) {
      outAnimationDuration = Number(clip.outAnimationDuration)
    }
    if (inAnimationDuration >= clipDuration) {
      // clip.inAnimationDuration = clipDuration - 100000
      clip.inAnimationDuration = clipDuration
      // 将出动画长度置为0.1s
      if (outAnimationId) {
        // clip.outAnimationDuration = 100000
        clip.outAnimationDuration = 0
      }
    } else if (outAnimationId && (inAnimationDuration + outAnimationDuration >= clipDuration)) {
      clip.outAnimationDuration = clipDuration - clip.inAnimationDuration
    } else if (outAnimationId && (inAnimationDuration + outAnimationDuration < clipDuration)) {
      // 不需要处理
    }
  }
  if (animationId) {
    clip.animationPeroid = Math.min(clipDuration, clip.animationPeroid)
    if (clip.type === 'sticker') {
      clip.raw.applyAnimatedStickerPeriodAnimation(animationId)
      clip.raw.setAnimatedStickerAnimationPeriod(clip.animationPeroid / 1000)
    } else if (clip.type === 'caption') {
      clip.raw.applyModularCaptionAnimation(animationId)
      clip.raw.setModularCaptionAnimationPeroid(clip.animationPeroid / 1000)
    }
  }
  if (inAnimationId) {
    clip.inAnimationDuration = Math.min(clipDuration, clip.inAnimationDuration)
    if (clip.type === 'sticker') {
      clip.raw.applyAnimatedStickerInAnimation(inAnimationId)
      // 出动画duration会默认设为500，此处重置为0
      clip.raw.setAnimatedStickerOutAnimationDuration(0)
      clip.raw.setAnimatedStickerInAnimationDuration(clip.inAnimationDuration / 1000)
    } else if (clip.type === 'caption') {
      clip.raw.applyModularCaptionInAnimation(inAnimationId)
      // 出动画duration会默认设为500，此处重置为0
      clip.raw.setModularCaptionOutAnimationDuration(0)
      clip.raw.setModularCaptionInAnimationDuration(clip.inAnimationDuration / 1000)
    }
  }
  if (outAnimationId) {
    clip.outAnimationDuration = Math.min(clipDuration, clip.outAnimationDuration)
    if (clip.type === 'sticker') {
      clip.raw.applyAnimatedStickerOutAnimation(outAnimationId)
      clip.raw.setAnimatedStickerOutAnimationDuration(clip.outAnimationDuration / 1000)
    } else if (clip.type === 'caption') {
      clip.raw.applyModularCaptionOutAnimation(outAnimationId)
      clip.raw.setModularCaptionOutAnimationDuration(clip.outAnimationDuration / 1000)
    }
  }
  // console.log(clip.raw.getModularCaptionInAnimationDuration())
  // console.log(clip.raw.getModularCaptionAnimationPeroid())
  // console.log(clip.raw.getModularCaptionOutAnimationDuration())
}

export function getRegionValueFromSdkValue (vecRegionInfo) {
  if (vecRegionInfo && vecRegionInfo.size() > 0) {
    const regionInfo = vecRegionInfo.get(0)
    if (regionInfo.type === 'polygon') {
      if (regionInfo.points.size() < 4) {
        console.warn('region info points is not enough!')
        return
      }
      const x1Val = regionInfo.points.get(0).x
      const y1Val = regionInfo.points.get(0).y
      const x2Val = regionInfo.points.get(1).x
      const y2Val = regionInfo.points.get(1).y
      const x3Val = regionInfo.points.get(2).x
      const y3Val = regionInfo.points.get(2).y
      const x4Val = regionInfo.points.get(3).x
      const y4Val = regionInfo.points.get(3).y
      return {
        type: regionInfo.type,
        x1: x1Val,
        y1: y1Val,
        x2: x2Val,
        y2: y2Val,
        x3: x3Val,
        y3: y3Val,
        x4: x4Val,
        y4: y4Val
      }
    } else if (regionInfo.type === 'ellipse') {
      const centerXVal = regionInfo.center.x
      const centerYVal = regionInfo.center.y
      const aVal = regionInfo.a
      const bVal = regionInfo.b
      const angleVal = regionInfo.theta
      return {
        type: regionInfo.type,
        centerX: centerXVal,
        centerY: centerYVal,
        a: aVal,
        b: bVal,
        angle: angleVal
      }
    }
  }
}
/**
 * 转换普通region（x1，x2，x3，x4...）为ellipseRegion格式
 * @param {*} regionValue
 * @returns
 */
export function convertRegionValueToEllipseRegion (regionValue) {
  let centerXVal = regionValue.x1 + (regionValue.x4 - regionValue.x1) / 2
  let centerYVal = regionValue.y1 - (regionValue.y4 - regionValue.y3) / 2
  let aVal = (regionValue.x4 - regionValue.x1) / 2
  let bVal = (regionValue.y4 - regionValue.y3) / 2
  let angleVal = 0

  let center = new NvsPointF(centerXVal, centerYVal)
  return { centerX: centerXVal, centerY: centerYVal, a: aVal, b: bVal, angle: angleVal, centerXVal, centerYVal, aVal, bVal, angleVal, center }
}

/**
 * rgba转十六进制
 * @param {*} color
 */
export function hexify (color) {
  if (color.startsWith('rgba') || color.startsWith('rgb')) {
    var values = color
      .replace(/rgb[a]?\(/, '')
      .replace(/\)/, '')
      .replace(/[\s+]/g, '')
      .split(',')
    var a = parseFloat(values[3] || 1),
      r = Math.floor(a * parseInt(values[0]) + (1 - a) * 255),
      g = Math.floor(a * parseInt(values[1]) + (1 - a) * 255),
      b = Math.floor(a * parseInt(values[2]) + (1 - a) * 255)
    return "#" +
      ("0" + r.toString(16)).slice(-2) +
      ("0" + g.toString(16)).slice(-2) +
      ("0" + b.toString(16)).slice(-2)
  } else {
    if (color.startsWith('#') && color.length === 4) {
      return '#' + color[1].repeat(2) + color[2].repeat(2) + color[3].repeat(2)
    }
    return color
  }
}

/**
 * 判断服务端数据是否是富文本类型字幕
 * @param {*} materialData
 */
export function isMaterialRichTextType (materialData) {
  const packageUrl = materialData.packageUrl || materialData.packageRelativePath
  const type = packageUrl.split('.').pop()
  return type === 'animatedsticker'
}

/**
 * 判断category是否是滚屏字幕
 * @param {*} item
 */
export function isMaterialScrollCaptionType (item) {
  if (item.systemFlag === 1 && item.materialType === 3 && item.categoryId === 3) {
    return true
  } else {
    return false
  }
}

/**
 * 判断category是否是唱词字幕
 * @param {*} item
 */
export function isMaterialLyricsType (item) {
  if (item && item.systemFlag && item.systemFlag === 1 && item.materialType === 3 && item.categoryId === 2) {
    return true
  } else {
    return false
  }
}

/**
 * 判断category是否是组合字幕
 * @param {*} item
 */
export function isMaterialCompoundCaptionType (item) {
  if (item.systemFlag === 0 && item.materialType === 3 && item.categoryId === 8) {
    return true
  } else {
    return false
  }
}

/**
 * 判断是否是模块化字幕
 * @param {*} item
 */
export function isMaterialModularCaptionType (suffix) {
  if (['captionrenderer', 'captioncontext', 'captioninanimation', 'captionoutanimation', 'captionanimation'].includes(suffix)) {
    return true
  } else {
    return false
  }
}

/**
 * 判断category是否是自由文字
 * @param {*} item
 */
export function isMaterialFreeTextType (item) {
  if (item.systemFlag === 1 && item.materialType === 3 && item.categoryId === 4) {
    return true
  } else {
    return false
  }
}

/**
 * 以promise的方式读取文件
 * @param {*} file
 */
export function readFilePromisify (file, type = 'arrayBuffer') {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader()
    reader.onload = function () {
      resolve(reader.result)
    }
    reader.onerror = reject
    if (type === 'arrayBuffer') {
      reader.readAsArrayBuffer(file)
    } else if (type === 'dataUrl') {
      reader.readAsDataURL(file)
    }
  })
}

/**
 * 将网络路径转换成blob
 * @param {*} url
 */
function getImageBlobByHttpUrl (url) {
  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest()
    xhr.open("get", url, true)
    xhr.responseType = "blob"
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response)
      } else {
        reject()
      }
    }
    xhr.send()
  })
}

/**
 * 将本地图片路径转换成blob
 * @param {*} url
 */
function getImageBlobByLocalUrl (url) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    img.onload = () => {
      canvas.width = img.width
      canvas.height = img.height
      ctx.drawImage(img, 0, 0, img.width, img.height)
      canvas.toBlob(e => {
        resolve(e)
      })
    }
    img.src = url
    img.onerror = reject
  })
}

export function getImageInfoByLocalUrl (url) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      resolve(img)
    }
    img.src = url
    img.onerror = reject
  })
}

/**
 * 将dataUrl转换成u8Array
 * @param {*} url
 */
export function getU8ArrayFromDataUrl (url) {
  const arr = url.split(',')
  const bstr = window.atob(arr[1])
  let bLength = bstr.length
  const u8arr = new Uint8Array(bLength)
  while (bLength--) {
    u8arr[bLength] = bstr.charCodeAt(bLength)
  }
  return u8arr
}

/**
 * 将图片路径转换成u8Array
 * @param {*} url
 */
export async function getU8arrayByUrl (url) {
  const blob = await getImageBlobByLocalUrl(url)
  const res = await readFilePromisify(blob)
  return new Uint8Array(res)
}

/**
 * 将新生成的数据保存到片段信息中
 * @param {*} clip
 * @param {*} u8Array
 * @param {*} skipUpload
 */
export function handleRichTextImage (clip, u8Array, skipUpload) {
  return new Promise(async (resolve, reject) => {
    if (skipUpload) {
      const oldUuid = clip.uuid
      clip.uuid = TimelineUtils.uuid()
      const packageName = clip.uuid + '.png'
      try {
        FS.unlink('/resources/' + oldUuid + 'png')
        deleteIndexDBByIndexAndName(oldUuid, 'resources')
        FS.writeFile('/resources/' + packageName, u8Array)
      } catch (error) { }
      clip.richCustomStickerImagePath = 'resources/' + packageName
      resolve()
    } else {
      vm.NvNetwork.uploadAliOss({
        keyUUID: vm.$Utils.generateUUID(),
        file: new Blob([u8Array]),
        extension: 'png',
        uploadModule: Enum.uploadModule.image,
        callback: async data => {
          if (data.uploadFinish) {
            clip.richCustomStickerImageUrl = 'https://' + data.Location
            // 重新生成uuid，并删除FS里旧的png
            const oldUuid = clip.uuid
            clip.uuid = TimelineUtils.uuid()
            const packageName = clip.uuid + '.png'
            try {
              FS.unlink('/resources/' + oldUuid + '.png')
              deleteIndexDBByIndexAndName(oldUuid, 'resources')
              FS.writeFile('/resources/' + packageName, u8Array)
            } catch (error) { }
            clip.richCustomStickerImagePath = 'resources/' + packageName
            resolve()
          } else {
            reject()
          }
        }
      })
    }
  })
}

export function transformRedirect (route) {
  let result = route.path
  const arr = Object.keys(route.query)
  if (arr.length > 0) {
    result += '?'
    arr.forEach((key, index) => {
      if (index === 0) {
        result += key + '=' + route.query[key]
      } else {
        result += '&' + key + '=' + route.query[key]
      }
    })
  }
  return encodeURIComponent(result)
}

export function getChildElementByClassName (clip, className) {
  return Array.from(document.querySelectorAll(className)).find(node => {
    const nodeArr = node.id.split(',')
    if (nodeArr.length === 2) {
      const targetId = nodeArr[1]
      return clip.id == targetId
    } else {
      return false
    }
  })
}

export function doStack (value) {
  const oldVal = vm.textStartValue
  const newVal = JSON.stringify(value, replacer)
  if (oldVal !== newVal) {
    const command = new Global.EditCommand(oldVal, newVal)
    vm.stack.execute(command)
    vm.textStartValue = newVal
  }
}

function replacer (key, value) {
  if (key === 'raw') {
    return 'undefined'
  }
  return value
}

/**
 * 处理裁剪mosaic范围问题
 */
export function calcCropMosaicOneSideRatio (videoClip) {
  const videoSize = TimelineUtils.timelineData.videoSize.split(':')
  const videoInfo = TimelineUtils.nvStreamingContext().getAVFileInfo(videoClip.m3u8Path, 0).videoStreamInfo
  if (videoInfo.rotation == 1/* 90度 */ || videoInfo.rotation == 3 /* 270度 */) {
    [videoInfo.width, videoInfo.height] = [videoInfo.height, videoInfo.width]
  }
  let videoOneSideRatio = {
    width: 1,
    height: 1
  }

  if (videoSize[0] * 1 / videoSize[1] * 1 > videoInfo.width / videoInfo.height) {
    // 宽比高大
    videoOneSideRatio.width = videoInfo.width / (videoInfo.height / videoSize[1]) / videoSize[0]
  } else {
    // 高比宽大
    videoOneSideRatio.height = videoInfo.height / (videoInfo.width / videoSize[0]) / videoSize[1]
  }
  return videoOneSideRatio
}

export function delay (t) {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, t)
  })
}

// 复制到剪贴板
export function copy2Clipboard (str) {
  return navigator.permissions.query({ name: 'clipboard-write' }).then(res => {
    if (res.state === 'granted') {
      return navigator.clipboard.writeText(str)
    } else return Promise.reject(new Error())
  }).then(() => {
    return str
  }).catch(() => {
    // 使用execCommand 复制。MDN上，该方法已废弃
    const input = document.createElement('input')
    document.body.appendChild(input)
    input.value = str
    input.select()
    if (document.execCommand('copy')) {
      console.log('execCommand 复制成功')
    } else {
      console.log('execCommand 复制失败')
      return Promise.reject(new Error('execCommand 复制失败'))
    }
    document.body.removeChild(input)
  }).finally(() => {
    return str
  })
}

// 获取剪贴板内容
export function getClipboard () {
  return navigator.permissions.query({ name: 'clipboard-read' }).then(res => {
    if (res.state === 'granted') {
      return navigator.clipboard.readText()
    } else {
      return Promise.reject(new Error('未获取剪贴板权限'))
    }
  })
}
export async function exportRichTextTemplate (clipInfo, imageContent) {
  const { richHtmlDescription: htmlString, styleDesc: originUuid } = clipInfo
  const zip = new JSZip()
  const uuid = uuidv5(htmlString + clipInfo.translationX + clipInfo.translationY + clipInfo.scaleX + clipInfo.scaleY, uuidv5.URL)
  const folderName = 'richText_' + uuid
  const folder = zip.folder(folderName)
  // const xmlContent = await FS.readFile(projectXmlFilePath, { encoding: 'utf8' })
  folder.file('richTextDesc.html', htmlString)
  folder.file('richTextImage.png', imageContent)
  folder.file('info.json', JSON.stringify({
    uuid,
    originUuid,
    text: clipInfo.text,
    translationX: clipInfo.translationX,
    translationY: clipInfo.translationY,
    scaleX: clipInfo.scaleX,
    scaleY: clipInfo.scaleY
  }))
  const zipContent = await zip.generateAsync({ type: "blob" })
  return { content: zipContent, folder: folderName }
}

export function sleep (time) {
  return new Promise(resolve => setTimeout(resolve, time))
}

export function getKey (clip) {
  return `${clip.id}_${clip.trimIn}_${clip.trimOut}_${!!clip.separated}_${!!clip.canReplace}`
}

/**
 * 帧精确
 * @param {*} clip
 * @returns
 */
export function alignFrame (clip, notSetSDK) {
  const tracks = TimelineUtils.timelineData.tracks
  clip.inPoint = vm.$Utils.getFrameTime(clip.inPoint)
  clip.outPoint = vm.$Utils.getFrameTime(clip.outPoint)
  if (clip.curveSpeedString) {
    clip.outPoint = vm.$Utils.getFloorFrameTime(clip.outPoint)
  }

  if (notSetSDK) return

  for (const track of tracks) {
    for (const item of track.clips) {
      if (item.uuid === clip.uuid) {
        track.raw.changeInPoint(clip.raw.getIndex(), clip.inPoint)
        track.raw.changeOutPoint(clip.raw.getIndex(), clip.outPoint)
        clip.trimIn = clip.raw.getTrimIn()
        clip.trimOut = clip.raw.getTrimOut()
        break
      }
    }
  }
  clip.speed = (clip.trimOut - clip.trimIn) / (clip.outPoint - clip.inPoint)
}

export function buildFormatedThumbnailNumber (num) {
  // debugger
  const digit = String(num).length
  if (digit < 7) {
    return Array(7 - digit).fill(0).join('') + num
  } else {
    return num
  }
}

export function isMessageDuplicate (options = {}) {
  const { type = 'error', message, duration = 3000, showClose = true } = options
  const messageTextArr = Array.from(document.getElementsByClassName('el-message')).map(item => {
    return item.innerText
  })
  if (!messageTextArr.includes(message)) {
    vm.$message({
      type,
      message,
      duration,
      showClose
    })
  }
}

export function isLoadingDuplicate (options = {}) {
  const { message } = options
  const messageTextArr = Array.from(document.getElementsByClassName('el-loading-text')).map(item => {
    return item.innerText
  })
  if (!messageTextArr.includes(message)) {
    return false
  } else {
    return true
  }
}

export function buildTemplateFootageId (index) {
  return (Array(3).join(0) + index).slice(-3)
}

export async function loadAndInstallFont (key) {
  if (key === 'Noto Color Emoji') return
  let asset = store.state.material.fonts.find(f => {
    if (f.stringValue === key) {
      return true
    }
    // 兼容旧的xml
    // HYQiHei-55S [HYQiHei-HES]
    const temp = f.stringValue.split(' [')
    if (Array.isArray(temp) && temp[0] === key) {
      return true
    }
    return false
  })
  if (!asset) {
    console.warn('缺少字体', key);
    return
  }
  const familyNameFromStringValue = asset.stringValue
  const familyName = await saveAndInstallPackage(asset)
  if (familyNameFromStringValue.split(' [')[0] !== familyName) {
    store.commit('material/updateFontNameObj', {
      key: familyNameFromStringValue,
      value: familyName
    })
  }
  store.commit('material/changeFontStatus', asset.stringValue)
  return asset.stringValue
}

export function checkFontValid (caption) {
  if (caption.fontFamily === 'Noto Color Emoji') return true
  let asset = store.state.material.fonts.find(f => {
    if (f.stringValue === caption.fontFamily) {
      return true
    }
    // HYQiHei-55S [HYQiHei-HES]
    const temp = f.stringValue.split(' [')
    if (Array.isArray(temp) && temp[0] === caption.fontFamily) {
      // 使用这个字体名称
      caption.fontFamily = f.stringValue
      return true
    }
    return false
  })
  if (!asset) {
    return false
  }
  return true
}