import { VideoFx, AudioFx, FxParam, CompoundCaptionClip, TemplateClip, VideoClip, VideoTrack, AudioTrack, AudioClip } from '@/api/ProjectData'
import Utils from '@/api/Utils'
import EventBus from '@/EventBus'
import EventBusKey from '@/api/EventBusKey'
import Global from '@/api/Global'
import vm from '@/main'
import { doStack } from '@/utils'
import { Message } from 'element-ui'
import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    popover: {
      visible: false,
      position: {
        x: 0,
        y: 0
      }
    },
    mouseDownItem: '',
    mouseUpItem: '',
    list: [],
    videoSize: '',
    projectInfo: null,
    videoTrack: null, // 机位视频轨道
    audioTrack: null, // 音频轨道
    timeline: null,
    liveWindow: null,
    topUserVideoTrack: null, // 上层上传的视频轨道
    bottomUserVideoTrack: null, // 下层上传的视频轨道
    topImageTrack: null, // 上层图片轨道
    bottomImageTrack: null, // 下层图片轨道
    rect: {
      style: {
        x: 0, y: 0, width: 0, height: 0
      },
      class: '',
      visible: false
    }
  },
  mutations: {
    setVideoSize(state, videoSize) {
      state.videoSize = videoSize;
    },
    setRectVisible (state, isVisible) {
      state.rect.visible = !!isVisible
    },
    setRectClass (state, className) {
      state.rect.class = className
    },
    setRectStyle (state, style) {
      state.rect.style = {
        ...state.rect.style,
        ...style
      }
      const parent = document.querySelector('.canvas-parent')
      if (parent) {
        const {width: parentWidth, height: parentHeight} = parent.getBoundingClientRect()
        const {width, height, left, top} = state.rect.style
        const classSet = new Set(state.rect.class.split(' '))
        if (parseInt(left) < 2) {
          classSet.add('rect-left')
        } else classSet.delete('rect-left')
        if (parseInt(top) < 2) classSet.add('rect-top')
        else classSet.delete('rect-top')
        if (parseInt(top) + parseInt(height) > parentHeight - 2) {
          classSet.add('rect-bottom')
        } else classSet.delete('rect-bottom')
        if (parseInt(left) + parseInt(width) > parentWidth - 2) {
          classSet.add('rect-right')
        } else classSet.delete('rect-right')
        state.rect.class = [...classSet].join(' ')
      }
    },
    seekTimeline (state, nowTime) {
      if (!state.timeline) {
        console.error('timeline is null')
        return
      }
      nowTime = isNaN(nowTime) ? nvsGetStreamingContextInstance().getTimelineCurrentPosition(state.timeline) : nowTime
      nowTime = Math.min(nowTime, state.timeline.getDuration() - 1)
      // EventBus.$emit(EventBusKey.play, nowTime, this.mTimeline)
      nvsGetStreamingContextInstance().seekTimeline(
        state.timeline,
        nowTime,
        NvsVideoPreviewSizeModeEnum.LiveWindowSize,
        0
      )
    },
    playBackTimeline (state, t) {
      if (state.timeline && nvsGetStreamingContextInstance()) {
        let nowTime
        if (typeof t === 'number' && !isNaN(t)) {
          nowTime = t
        } else {
          nowTime = nvsGetStreamingContextInstance().getTimelineCurrentPosition(state.timeline)
        }
        // this.disablePlaybackPos = false
        nvsGetStreamingContextInstance().playbackTimeline(state.timeline, nowTime, -1, NvsVideoPreviewSizeModeEnum.LiveWindowSize, true, 0)
        state.rect.visible = false
      }
    },
    // 换行，取消换行
    itemEnter (state, payload) {
      const [standIndex, eleIndex] = payload.split('_')
      console.log('换行：',state.list[standIndex*1],eleIndex*1 !== state.list[standIndex].length - 1)
      if (state.list[standIndex*1] && eleIndex*1 !== state.list[standIndex].length - 1) {
        state.list[standIndex][eleIndex].enter = !state.list[standIndex][eleIndex].enter
        doStack(state.list)
      }
    },
    updatePopoverVisible (state, payload) {
      state.popover.visible = payload
      state.rect.visible = false
    },
    updatePopoverPosition (state, payload) {
      state.popover.position = payload
    },
    setProjectInfo (state, payload) {
      state.projectInfo = payload
    },
    updateList (state, payload) {
      state.list = payload
    },
    // 初始化  时间线添加内容
    updateListWithRaw (state, payload) {
      let { textVideos, timeline, liveWindow } = payload || state
      textVideos = textVideos || state.list
      state.timeline = timeline
      state.liveWindow = liveWindow
      clearAllVideoTrack(timeline)
      state.topUserVideoTrack = new VideoTrack(0)
      state.bottomUserVideoTrack = new VideoTrack(0)
      state.topImageTrack = new VideoTrack(0)
      state.bottomImageTrack = new VideoTrack(0)
      state.videoTrack = new VideoTrack(0)
      state.audioTrack = new AudioTrack(0)
      state.bottomImageTrack.raw = timeline.appendVideoTrack()
      state.bottomUserVideoTrack.raw = timeline.appendVideoTrack()
      state.videoTrack.raw = timeline.appendVideoTrack()
      state.audioTrack.raw = timeline.appendAudioTrack()
      state.topImageTrack.raw = timeline.appendVideoTrack()
      state.topUserVideoTrack.raw = timeline.appendVideoTrack()

      console.time('build timeline - (updateListWithRaw)')
      textVideos.forEach(stand => {
        stand.forEach(({ video, audio, musicLyricsClip, compoundCaption, template }) => {
          // 添加视频clip时考虑对齐的偏移
          addClipInTrack(state.videoTrack.raw, video)
          addAudioClipInTrack(state, state.audioTrack.raw, audio) 
          if (musicLyricsClip && musicLyricsClip.isMusicLyrics) {
            musicLyricsClip.raw = timeline.addCaption(
              musicLyricsClip.text.replace(/[\ |\~|\`|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\-|\_|\+|\=|\||\\|\[|\]|\{|\}|\;|\:|\"|\'|\,|\<|\.|\>|\/|\?/\，/\。/\；/\：/\“/\”/\》/\《/\|/\{/\}/\、/\!/\~/\`]/g,""),
              musicLyricsClip.inPoint,
              musicLyricsClip.outPoint - musicLyricsClip.inPoint,
              musicLyricsClip.styleDesc || Global.getDefaultSubtitleStyle(),
              false
            )
            musicLyricsClip.styleDesc = musicLyricsClip.styleDesc || Global.getDefaultSubtitleStyle()
            if (!isEmpty(musicLyricsClip.font)) {
              musicLyricsClip.raw.setFontFamily(musicLyricsClip.font)
            }
            if (!isEmpty(musicLyricsClip.fontSize)) {
              musicLyricsClip.raw.setFontSize(musicLyricsClip.fontSize)
            }
            if (!isEmpty(musicLyricsClip.color)) {
              const nvsColor = Utils.RGBAToNvsColor(musicLyricsClip.color)
              musicLyricsClip.raw.setTextColor(nvsColor)
            }
            if (!isEmpty(musicLyricsClip.translationX) && !isEmpty(musicLyricsClip.translationY)) {
              const x = musicLyricsClip.translationX
              const y = musicLyricsClip.translationY
              const offsetPointF = new NvsPointF(x, y)
              musicLyricsClip.raw.setCaptionTranslation(offsetPointF)
            }
            if (!isEmpty(musicLyricsClip.outlineColor)) {
              let color = Utils.RGBAToNvsColor(musicLyricsClip.outlineColor)
              musicLyricsClip.outline = true
              musicLyricsClip.raw.setDrawOutline(true)
              musicLyricsClip.raw.setOutlineColor(color)
            }
            if (!isEmpty(musicLyricsClip.outlineWidth)) {
              musicLyricsClip.raw.setOutlineWidth(musicLyricsClip.outlineWidth)
            }
          }
          // 添加组合字幕
          if (compoundCaption) {
            addCompoundCaption(timeline, compoundCaption)
          }
          // 模板内容处理
          if (template) {
            applyTemplate(template, {
              topUserVideoTrack: state.topUserVideoTrack,
              topImageTrack: state.topImageTrack,
              bottomUserVideoTrack: state.bottomUserVideoTrack,
              bottomImageTrack: state.bottomImageTrack,
              timeline
            })
          }
        })
        // 处理转场问题
        for (let i = 0; i < state.videoTrack.raw.getClipCount() - 1; i++) {
          state.videoTrack.raw.setBuiltinTransition(i, '')
          state.audioTrack.raw.setBuiltinTransition(i, '')
        }
        stand.slice(-1)[0].enter = false //处理出现尾部时码问题
      })
      state.list = textVideos
      EventBus.$emit(EventBusKey.updateDuration)
      console.timeEnd('build timeline - (updateListWithRaw)')
    },
    changeMusicLyricsDesc (state, styleDesc) {
      console.log(styleDesc)
      state.list.forEach(item => {
        item.forEach(({ musicLyricsClip }) => {
          if (musicLyricsClip) {
            if (!!styleDesc) {
              if (!musicLyricsClip.raw) {
                musicLyricsClip.raw = state.timeline.addCaption(
                  musicLyricsClip.text.replace(/[\ |\~|\`|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\-|\_|\+|\=|\||\\|\[|\]|\{|\}|\;|\:|\"|\'|\,|\<|\.|\>|\/|\?/\，/\。/\；/\：/\“/\”/\》/\《/\|/\{/\}/\、/\!/\~/\`]/g,""),
                  musicLyricsClip.inPoint,
                  musicLyricsClip.outPoint - musicLyricsClip.inPoint,
                  musicLyricsClip.styleDesc || Global.getDefaultSubtitleStyle(),
                  false
                )
              }
              musicLyricsClip.raw.applyCaptionStyle(styleDesc, true)
              musicLyricsClip.styleDesc = styleDesc
              musicLyricsClip.isMusicLyrics = true
            } else {
              state.timeline.removeCaption(musicLyricsClip.raw)
              musicLyricsClip.raw = null
              delete musicLyricsClip.styleDesc
              musicLyricsClip.isMusicLyrics = false
              delete musicLyricsClip.font
              delete musicLyricsClip.fontSize
              musicLyricsClip.color = 'rgba(255, 255, 255, 1)'
              delete musicLyricsClip.translationX
              delete musicLyricsClip.translationY
              musicLyricsClip.outline = false
              musicLyricsClip.outlineColor = 'rgba(0, 0, 0, 0)'
              delete musicLyricsClip.outlineWidth
            }
          }
        })
      })
      // doStack(state.list)
    },
    // 唱词字幕设置
    changeMusicLyricsStyle (state, { font, fontSize, fontColor, outlineColor, translationX, translationY, outlineWidth }) {
      // console.log(font, fontSize, fontColor, outlineColor, translationX, translationY, outlineWidth)
      state.list.forEach(item => {
        item.forEach(({ musicLyricsClip }) => {
          if (musicLyricsClip && musicLyricsClip.raw) {
            if (!isEmpty(font)) {
              musicLyricsClip.font = font
              musicLyricsClip.raw.setFontFamily(font)
            }
            if (!isEmpty(fontSize)) {
              musicLyricsClip.fontSize = fontSize
              musicLyricsClip.raw.setFontSize(fontSize)
            }
            if (!isEmpty(fontColor)) {
              musicLyricsClip.color = fontColor
              const nvsColor = Utils.RGBAToNvsColor(fontColor)
              musicLyricsClip.raw.setTextColor(nvsColor)
            }
            if (!isEmpty(translationX)) {
              musicLyricsClip.translationX = translationX
            }
            if (!isEmpty(translationY)) {
              musicLyricsClip.translationY = translationY
            }
            if (!isEmpty(translationX) || !isEmpty(translationY)) {
              const x = !isEmpty(translationX) ? translationX : musicLyricsClip.translationX
              const y = !isEmpty(translationY) ? translationY : musicLyricsClip.translationY
              const offsetPointF = new NvsPointF(x, y)
              musicLyricsClip.raw.setCaptionTranslation(offsetPointF)
            }
            if (!isEmpty(outlineColor)) {
              let color = Utils.RGBAToNvsColor(outlineColor)
              musicLyricsClip.outline = true
              musicLyricsClip.outlineColor = outlineColor
              musicLyricsClip.raw.setDrawOutline(true)
              musicLyricsClip.raw.setOutlineColor(color)
            }
            // else {
            //   let color = Utils.RGBAToNvsColor('rgba(0, 0, 0, 0)')
            //   musicLyricsClip.outline = false
            //   musicLyricsClip.outlineColor = 'rgba(0, 0, 0, 0)'
            //   musicLyricsClip.raw.setOutlineColor(color)
            // }
            if (!isEmpty(outlineWidth)) {
              musicLyricsClip.outlineWidth = outlineWidth
              musicLyricsClip.raw.setOutlineWidth(outlineWidth)
            }
          }
        })
      })
      // doStack(state.list)
    },
    // 修改缩放
    changeScale (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        let s = true
        console.time('修改缩放')
        camera.forEach(({ video }) => {
          s && console.time('addFxTrans2D')
          addFxTrans2D(video, {
            [Enum.FX_PARAM.SCALE_X]: {value, type: 'float'},
            [Enum.FX_PARAM.SCALE_Y]: {value, type: 'float'}
          }, s)
          s && console.timeEnd('addFxTrans2D')
          s = false
        })
        console.timeEnd('修改缩放')
      }
    },
    changePosX (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ video }) => {
          addFxTrans2D(video, {[Enum.FX_PARAM.TRANS_X]: {value, type: 'float'}})
        })
      }
    },
    changePosY (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ video }) => {
          addFxTrans2D(video, {[Enum.FX_PARAM.TRANS_Y]: {value, type: 'float'}})
        })
      }
    },
    changeOpacity (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ video }) => {
          addFxTrans2D(video, {[Enum.FX_PARAM.OPACITY]: {value, type: 'float'}})
        })
      }
    },
    // 修改速度
    changeSpeed (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ video }) => {
          video.raw.changeSpeed(value, true)
          video.speed = value
          video.inPoint = video.raw.getInPoint()
          video.outPoint = video.raw.getOutPoint()
        })
      }
    },
    // 背景模糊
    changeBgMode (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ video }) => {
          video.raw.enablePropertyVideoFx(true)
          const propertyVideoFx = video.raw.getPropertyVideoFx()
          if (propertyVideoFx) {
            propertyVideoFx.setMenuVal('Background Mode', value ? 'Blur' : 'Color Solid')
            propertyVideoFx.setFloatVal('Background Blur Radius', value ? 64 : 0)
          }
          video.bgBlur = value
        })
      }
    },
    // 旋转
    changeRotation (state, { cameraIndex, key, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ video }) => {
          addFxTrans2D(video, {[key]: {type: 'float', value}})
        })
      }
    },
    // 修改音量
    changeVolume (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ audio }) => {
          audio.raw.setVolumeGain(value, value)
          audio.volume = value
        })
      }
    },
    // 音频渐入
    changeAudioFadeInTime (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        const { audio } = camera[0]
        audio.fadeInDuration = value
        audio.raw.setFadeInDuration(value)
      }
    },
    // 音频渐出
    changeAudioFadeOutTime (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        const { audio } = camera[camera.length - 1]
        audio.fadeOutDuration = value
        audio.raw.setFadeOutDuration(value)
      }
    },
    // 音频降噪开关状态存入clip中
    changeAudioDenoise (state, { cameraIndex, value }) {
      console.log('---音频降噪开关---：', value)
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ audio }) => {
          audio.denoiseSwitch = value
        })
      }
    },
    // 音频降噪 250HZ
    changeAudioBandGain10 (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ audio }) => {
          addAudioFx(audio, {[Enum.FX_AUDIO_PARAM.HERTZ_250]: {value, type: 'float'}})
        })
      }
    },
    // 音频降噪 500HZ
    changeAudioBandGain13 (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ audio }) => {
          addAudioFx(audio, {[Enum.FX_AUDIO_PARAM.HERTZ_500]: {value, type: 'float'}})
        })
      }
    },
    // 音频降噪 1000HZ
    changeAudioBandGain16 (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ audio }) => {
          addAudioFx(audio, {[Enum.FX_AUDIO_PARAM.HERTZ_1000]: {value, type: 'float'}})
        })
      }
    },
    // 音频降噪 2000HZ
    changeAudioBandGain19 (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ audio }) => {
          addAudioFx(audio, {[Enum.FX_AUDIO_PARAM.HERTZ_2000]: {value, type: 'float'}})
        })
      }
    },
    // 音频降噪 4000HZ
    changeAudioBandGain22 (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ audio }) => {
          addAudioFx(audio, {[Enum.FX_AUDIO_PARAM.HERTZ_4000]: {value, type: 'float'}})
        })
      }
    },
    // 音频降噪 其他参数
    changeAudioBandGainOther (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ audio }) => {
          addAudioFx(audio, {
            [Enum.FX_AUDIO_PARAM.HERTZ_31]: {value, type: 'float'},
            [Enum.FX_AUDIO_PARAM.HERTZ_40]: {value, type: 'float'},
            [Enum.FX_AUDIO_PARAM.HERTZ_50]: {value, type: 'float'},
            [Enum.FX_AUDIO_PARAM.HERTZ_63]: {value, type: 'float'},
            [Enum.FX_AUDIO_PARAM.HERTZ_80]: {value, type: 'float'},
            [Enum.FX_AUDIO_PARAM.HERTZ_100]: {value, type: 'float'},
            [Enum.FX_AUDIO_PARAM.HERTZ_125]: {value, type: 'float'},
            [Enum.FX_AUDIO_PARAM.HERTZ_160]: {value, type: 'float'},
            [Enum.FX_AUDIO_PARAM.HERTZ_200]: {value, type: 'float'},
            [Enum.FX_AUDIO_PARAM.HERTZ_315]: {value, type: 'float'},
            [Enum.FX_AUDIO_PARAM.HERTZ_400]: {value, type: 'float'},
            [Enum.FX_AUDIO_PARAM.HERTZ_630]: {value, type: 'float'},
            [Enum.FX_AUDIO_PARAM.HERTZ_800]: {value, type: 'float'},
            [Enum.FX_AUDIO_PARAM.HERTZ_1250]: {value, type: 'float'},
            [Enum.FX_AUDIO_PARAM.HERTZ_1600]: {value, type: 'float'},
            [Enum.FX_AUDIO_PARAM.HERTZ_2500]: {value, type: 'float'},
            [Enum.FX_AUDIO_PARAM.HERTZ_3200]: {value, type: 'float'},
            [Enum.FX_AUDIO_PARAM.HERTZ_5000]: {value, type: 'float'},
            [Enum.FX_AUDIO_PARAM.HERTZ_6300]: {value, type: 'float'},
            [Enum.FX_AUDIO_PARAM.HERTZ_8000]: {value, type: 'float'},
            [Enum.FX_AUDIO_PARAM.HERTZ_10000]: {value, type: 'float'},
            [Enum.FX_AUDIO_PARAM.HERTZ_12500]: {value, type: 'float'},
            [Enum.FX_AUDIO_PARAM.HERTZ_16000]: {value, type: 'float'},
            [Enum.FX_AUDIO_PARAM.HERTZ_20000]: {value, type: 'float'},
            [Enum.FX_AUDIO_PARAM.HERTZ_25000]: {value, type: 'float'}
          })
        })
      }
    },
    // 智能校色开关状态存入clip中
    setColourCorrectionSwitch (state, { cameraIndex, value }) {
      console.log('---智能校色开关---：', value)
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ video }) => {
          video.colourCorrectionSwitch = value
        })
      }
    },
    // 曝光
    changeExposure (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ video }) => {
          const opt = {
            desc: Enum.FX_DESC.BASICIMAGEADJUST,
            params: [
              {key: Enum.FX_PARAM.EXPOSURE, value, type: 'float'}
            ]
          }
          setFxParam(video, opt)
        })
      }
    },
    // 高光
    changeHighlight (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ video }) => {
          const opt = {
            desc: Enum.FX_DESC.BASICIMAGEADJUST,
            params: [
              {key: Enum.FX_PARAM.HIGHLIGHT, value, type: 'float'}
            ]
          }
          setFxParam(video, opt)
        })
      }
    },
    // 阴影
    changeShadow (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ video }) => {
          const opt = {
            desc: Enum.FX_DESC.BASICIMAGEADJUST,
            params: [
              {key: Enum.FX_PARAM.SHADOW, value, type: 'float'}
            ]
          }
          setFxParam(video, opt)
        })
      }
    },
    // 黑点
    changeBlackpoint (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ video }) => {
          const opt = {
            desc: Enum.FX_DESC.BASICIMAGEADJUST,
            params: [
              {key: Enum.FX_PARAM.BLACKPOINT, value, type: 'float'}
            ]
          }
          setFxParam(video, opt)
        })
      }
    },
    // 自然饱和度
    changeVibrance (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ video }) => {
          const opt = {
            desc: Enum.FX_DESC.BASICIMAGEADJUST,
            params: [
              {key: Enum.FX_PARAM.VIBRANCE, value, type: 'float'}
            ]
          }
          setFxParam(video, opt)
        })
      }
    },
    // 色调
    changeTint (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ video }) => {
          const opt = {
            desc: Enum.FX_DESC.TINT,
            params: [
              {key: Enum.FX_PARAM.TINT, value, type: 'float'}
            ]
          }
          setFxParam(video, opt)
        })
      }
    },
    // 色温
    changeTemperature (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ video }) => {
          const opt = {
            desc: Enum.FX_DESC.TINT,
            params: [
              {key: Enum.FX_PARAM.TEMPERATURE, value, type: 'float'}
            ]
          }
          setFxParam(video, opt)
        })
      }
    },
    // 清晰度
    changeDefinition (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ video }) => {
          const opt = {
            desc: Enum.FX_DESC.DEFINITION,
            params: [
              {key: Enum.FX_PARAM.INTENSITY, value, type: 'float'}
            ]
          }
          setFxParam(video, opt)
        })
      }
    },
    // 噪点去除
    changeDenoise (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ video }) => {
          const opt = {
            desc: Enum.FX_DESC.DENOISE,
            params: [
              {key: Enum.FX_PARAM.INTENSITY, value, type: 'float'}
            ]
          }
          setFxParam(video, opt)
        })
      }
    },
    // 亮度
    changeBrightness (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ video }) => {
          const opt = {
            desc: Enum.FX_DESC.BASICIMAGEADJUST,
            params: [
              {key: Enum.FX_PARAM.BRIGHTNESS, value, type: 'float'}
            ]
          }
          setFxParam(video, opt)
        })
      }
    },
    // 对比度
    changeContrast (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ video }) => {
          const opt = {
            desc: Enum.FX_DESC.BASICIMAGEADJUST,
            params: [
              {key: Enum.FX_PARAM.CONTRAST, value, type: 'float'}
            ]
          }
          setFxParam(video, opt)
        })
      }
    },
    // 饱和度
    changeSaturation (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ video }) => {
          const opt = {
            desc: Enum.FX_DESC.BASICIMAGEADJUST,
            params: [
              {key: Enum.FX_PARAM.SATURATION, value, type: 'float'}
            ]
          }
          setFxParam(video, opt)
        })
      }
    },
    // 暗角
    changeDegree (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ video }) => {
          const opt = {
            desc: Enum.FX_DESC.VIGNETTE,
            params: [
              {key: Enum.FX_PARAM.DEGREE, value, type: 'float'}
            ]
          }
          setFxParam(video, opt)
        })
      }
    },
    // 锐度
    changeAmount (state, { cameraIndex, value }) {
      const camera = state.list[cameraIndex]
      if (Array.isArray(camera)) {
        camera.forEach(({ video }) => {
          const opt = {
            desc: Enum.FX_DESC.SHARPEN,
            params: [
              {key: Enum.FX_PARAM.AMOUNT, value, type: 'float'}
            ]
          }
          setFxParam(video, opt)
        })
      }
    },
    addTRANSFORM2D (state, video) {
      console.log(state, video)
      const fx = new VideoFx(Global.TIMELINEVIDEOFXTYPEBUILDIN, video.videoFxs.length, Enum.FX_DESC.TRANSFORM_2D)
      fx.raw = video.raw.appendBuiltinFx(fx.desc)
      fx.params = [
        new FxParam('float', Enum.FX_PARAM.SCALE_X, 1),
        new FxParam('float', Enum.FX_PARAM.SCALE_Y, 1),
        new FxParam('float', Enum.FX_PARAM.TRANS_X, 0),
        new FxParam('float', Enum.FX_PARAM.TRANS_Y, 0),
        new FxParam('float', Enum.FX_PARAM.OPACITY, 1)
      ]
      video.videoFxs = [fx]
    },
    addColorProperty (state, video) {
      console.log(state, video)
      const fx = new VideoFx(Global.TIMELINEVIDEOFXTYPEBUILDIN, video.videoFxs.length, Enum.FX_DESC.COLOR_PROPERTY)
      fx.raw = video.raw.appendBuiltinFx(fx.desc)
      fx.params = [
        new FxParam('float', Enum.FX_PARAM.BRIGHTNESS, 1),
        new FxParam('float', Enum.FX_PARAM.CONTRAST, 1),
        new FxParam('float', Enum.FX_PARAM.SATURATION, 1)
      ]
      video.videoFxs = [fx]
    },
    addVignette (state, video) {
      console.log(state, video)
      const fx = new VideoFx(Global.TIMELINEVIDEOFXTYPEBUILDIN, video.videoFxs.length, Enum.FX_DESC.VIGNETTE)
      fx.raw = video.raw.appendBuiltinFx(fx.desc)
      fx.params = [
        new FxParam('float', Enum.FX_PARAM.DEGREE, 0)
      ]
      video.videoFxs = [fx]
    },
    addSharpen (state, video) {
      console.log(state, video)
      const fx = new VideoFx(Global.TIMELINEVIDEOFXTYPEBUILDIN, video.videoFxs.length, Enum.FX_DESC.SHARPEN)
      fx.raw = video.raw.appendBuiltinFx(fx.desc)
      fx.params = [
        new FxParam('float', Enum.FX_PARAM.AMOUNT, 0)
      ]
      video.videoFxs = [fx]
    },
    // 修改唱词
    changeText (state, { standIndex, eleIndex, text }) {
      const { musicLyricsClip } = state.list[standIndex][eleIndex]
      musicLyricsClip.raw.setText(text.replace(/[\ |\~|\`|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\-|\_|\+|\=|\||\\|\[|\]|\{|\}|\;|\:|\"|\'|\,|\<|\.|\>|\/|\?/\，/\。/\；/\：/\“/\”/\》/\《/\|/\{/\}/\、/\!/\~/\`]/g,""))
      musicLyricsClip.text = text
      doStack(state.list)
    },
    // 合并唱词
    mergeText (state, { selections }) {
      console.log(selections)
      const {standIndex, eleIndex} = selections[0]
      const startVideoClip = state.list[standIndex][eleIndex]
      const endVideoClip = state.list[selections[1].standIndex][selections[1].eleIndex]
      const { video: {inPoint, trimIn} } = startVideoClip
      const { video: {m3u8Path, outPoint, trimOut} } = endVideoClip
      const startMusicLyricsClip = startVideoClip.musicLyricsClip
      const endMusicLyricsClip = endVideoClip.musicLyricsClip
      console.log(inPoint, outPoint, trimOut)
      state.videoTrack.raw.removeRange(inPoint, outPoint, true)
      state.timeline.removeCaption(endMusicLyricsClip.raw)
      state.list[selections[1].standIndex].splice(selections[1].eleIndex, 1)
      const videoClipRaw = state.videoTrack.raw.addClip2(m3u8Path, inPoint, trimIn, trimOut)
      startVideoClip.video.raw = videoClipRaw
      startVideoClip.video.outPoint = outPoint
      startVideoClip.video.trimOut = trimOut
      const text = startMusicLyricsClip.text + endMusicLyricsClip.text
      startMusicLyricsClip.raw.setText(text)
      startMusicLyricsClip.text = text
      startMusicLyricsClip.raw.changeOutPoint(outPoint)
      startMusicLyricsClip.outPoint = outPoint
      startMusicLyricsClip.trimOut = trimOut
      doStack(state.list)
    },
    // 拆分唱词
    splitText (state, { standIndex, eleIndex, selectionStart, text }) {
      console.log(selectionStart, text)
      const timePer = (selectionStart / text.length).toFixed(2)
      console.log(timePer)
      const firstText = text.substr(0, selectionStart)
      const lastText = text.substr(selectionStart, text.length)
      const { musicLyricsClip, video, enter, isTemplate, stand, uuid } = state.list[standIndex][eleIndex]
      const { m3u8Path, inPoint, outPoint, trimIn, trimOut } = video
      const timeCenter = Math.round(inPoint + (outPoint - inPoint)*timePer)
      const trimCenter = Math.round(trimIn + (trimOut - trimIn)*timePer)
      state.videoTrack.raw.removeRange(inPoint, outPoint, true)
      const videoClipRaw1 = state.videoTrack.raw.addClip2(m3u8Path, inPoint, trimIn, trimCenter)
      const videoClipRaw2 = state.videoTrack.raw.addClip2(m3u8Path, timeCenter, trimCenter, trimOut)
      const newTextVideo = {
        compoundCaption: null,
        enter: enter,
        isTemplate: isTemplate,
        musicLyricsClip: deepCopy(musicLyricsClip),
        stand: stand,
        template: null,
        uuid: uuid,
        video: deepCopy(video)
      }
      // 改变上层数据
      video.raw = videoClipRaw1
      video.outPoint = timeCenter
      video.trimOut = trimCenter
      musicLyricsClip.outPoint = timeCenter
      musicLyricsClip.text = firstText
      state.timeline.removeCaption(musicLyricsClip.raw)
      addSubTittleCaption(state, musicLyricsClip)
      
      newTextVideo.video.raw = videoClipRaw2
      newTextVideo.video.inPoint = timeCenter
      newTextVideo.video.outPoint = outPoint
      newTextVideo.video.trimIn = trimCenter
      newTextVideo.video.trimOut = trimOut
      newTextVideo.musicLyricsClip.inPoint = timeCenter
      newTextVideo.musicLyricsClip.text = lastText
      addSubTittleCaption(state, newTextVideo.musicLyricsClip)
      console.log(newTextVideo)
      state.list[standIndex].splice(eleIndex+1, 0, newTextVideo)
      
      doStack(state.list)
    },
    // 添加组合字幕
    addCaptions (state, { selections, caption }) {
      console.log('添加组合字幕', selections)
      const start = selections[0]
      const { standIndex, eleIndex } = start
      const startItem = state.list[standIndex][eleIndex]
      const end = selections[selections.length - 1]
      const { standIndex: ei, eleIndex: ek } = end
      const endItem = state.list[ei][ek]
      if (startItem.compoundCaption) {
        state.timeline.removeCompoundCaption(startItem.compoundCaption.raw)
      }
      if (!caption.id) {
        startItem.compoundCaption = null
        return
      }
      const compoundCaption = new CompoundCaptionClip(0, caption.id)
      compoundCaption.inPoint = startItem.video.inPoint
      compoundCaption.outPoint = endItem.video.outPoint
      // startItem.compoundCaption = compoundCaption
      Vue.set(startItem, 'compoundCaption', compoundCaption)
      const { inPoint, outPoint, styleDesc } = startItem.compoundCaption
      startItem.compoundCaption.raw = state.timeline.addCompoundCaption(inPoint, outPoint - inPoint, styleDesc)
      const count = startItem.compoundCaption.raw.getCaptionCount()
      startItem.compoundCaption.text = []
      for (let i = 0; i < count; i++) {
        const text = startItem.compoundCaption.raw.getText(i)
        const font = startItem.compoundCaption.raw.getFontFamily(i)
        const color = startItem.compoundCaption.raw.getTextColor(i)
        startItem.compoundCaption.text.push({
          text, font, color: color ? Utils.NvsColorToRGBA(color) : ''
        })
      }
    },
    // 针对片头片尾加组合字幕
    addGroupCaptions  (state, { index, caption }) {
      console.log('添加片头片尾组合字幕', index)
      if (index === 0) {
        // 片头设置
      } else {
        // 片尾设置
      }
      const startItem = state.list[index][0]
      if (startItem.compoundCaption) {
        state.timeline.removeCompoundCaption(startItem.compoundCaption.raw)
      }
      if (!caption.id) {
        startItem.compoundCaption = null
        return
      }
      const compoundCaption = new CompoundCaptionClip(0, caption.id)
      compoundCaption.inPoint = startItem.video.inPoint
      compoundCaption.outPoint = startItem.video.outPoint
      startItem.compoundCaption = compoundCaption
      const { inPoint, outPoint, styleDesc } = startItem.compoundCaption
      startItem.compoundCaption.raw = state.timeline.addCompoundCaption(inPoint, outPoint - inPoint, styleDesc)
      const count = startItem.compoundCaption.raw.getCaptionCount()
      startItem.compoundCaption.text = []
      for (let i = 0; i < count; i++) {
        const text = startItem.compoundCaption.raw.getText(i)
        const font = startItem.compoundCaption.raw.getFontFamily(i)
        const color = startItem.compoundCaption.raw.getTextColor(i)
        startItem.compoundCaption.text.push({
          text, font, color: color ? Utils.NvsColorToRGBA(color) : ''
        })
      }
    },
    // 修改组合字幕
    changeCaption (state, { index, translationX, translationY, color, font, text, selection }) {
      const { standIndex, eleIndex } = selection
      const textVideo = state.list[standIndex][eleIndex]
      if (textVideo.compoundCaption) {
        if (!isEmpty(translationX)) {
          textVideo.compoundCaption.translationX = translationX
        }
        if (!isEmpty(translationY)) {
          textVideo.compoundCaption.translationY = translationY
        }
        if (!isEmpty(translationX) || !isEmpty(translationY)) {
          const x = !isEmpty(translationX) ? translationX : textVideo.compoundCaption.translationX
          const y = !isEmpty(translationY) ? translationY : textVideo.compoundCaption.translationY
          const offsetPointF = new NvsPointF(x, y)
          textVideo.compoundCaption.raw.setCaptionTranslation(offsetPointF)
        }
        if (!isEmpty(color)) {
          textVideo.compoundCaption.text[index].color = color
          const nvsColor = Utils.RGBAToNvsColor(color)
          textVideo.compoundCaption.raw.setTextColor(index, nvsColor)
        }
        if (!isEmpty(font)) {
          textVideo.compoundCaption.text[index].font = font
          textVideo.compoundCaption.raw.setFontFamily(index, font)
        }
        if (!isEmpty(text)) {
          textVideo.compoundCaption.text[index].text = text
          textVideo.compoundCaption.raw.setText(index, text)
        }
      }
    },
    // 添加模板
    addTemplate (state, { selections, template, caption }) {
      console.log('%c<!-------添加模板开始------->', 'color: blue,background: black')
      const start = selections[0]
      const { standIndex, eleIndex } = start
      const startItem = state.list[standIndex][eleIndex]// 对应的字幕类
      const end = selections[selections.length - 1]
      const { standIndex: ei, eleIndex: ek } = end
      const endItem = state.list[ei][ek]
      if (startItem.template) {
        removeTemplate(state, startItem.template, selections)
        startItem.template = null
      }
      const _template = new TemplateClip(0, template.id)
      _template.inPoint = startItem.video.inPoint
      _template.outPoint = endItem.video.outPoint
      _template.range = selections
      startItem.template = Object.assign({}, _template)
      // 纯视频模板不需要组合字幕
      if (template.id !== 333) {
        if (startItem.template.caption) {
          state.timeline.removeCompoundCaption(startItem.template.caption.raw)
        }
        const compoundCaption = new CompoundCaptionClip(0, caption.id)
        compoundCaption.inPoint = startItem.video.inPoint
        compoundCaption.outPoint = endItem.video.outPoint
        startItem.template.caption = compoundCaption
        const {inPoint, outPoint, styleDesc} = startItem.template.caption
        startItem.template.caption.raw = state.timeline.addCompoundCaption(inPoint, outPoint - inPoint, styleDesc)
        const count = startItem.template.caption.raw.getCaptionCount()
        startItem.template.caption.text = []
        for (let i = 0; i < count; i++) {
          const text = startItem.template.caption.raw.getText(i)
          const font = startItem.template.caption.raw.getFontFamily(i)
          const color = startItem.template.caption.raw.getTextColor(i)
          startItem.template.caption.text.push({
            text, font, color: color ? Utils.NvsColorToRGBA(color) : ''
          })
        }
      }
      isTemplate(state, selections)
      console.log('%c<!-------添加模板结束------->', 'color: blue,background: black')
    },
    deleteTemplate (state, { selections }) {
      console.log('%c<!-------删除模板开始------->', 'color: red')
      const start = selections[0]
      const { standIndex, eleIndex } = start
      const startItem = state.list[standIndex][eleIndex]// 对应的字幕类
      if (!startItem.template) return
      removeTemplate(state, startItem.template, selections)
      startItem.template = null
      EventBus.$emit(EventBusKey.seekTimeline)
      console.log('%c<!-------删除模板结束------->', 'color: red')
    },
    // 扣像特效
    changeSpillRemoval (state, { rgba, hex, selections }) {
      selections.map(item => {
        let { standIndex, eleIndex } = item
        let clipItem = state.list[standIndex][eleIndex]
        let fx = getFx(clipItem.video)
        let param = fx.params.find(
          (param) => param.type === 'color' && param.key === 'Key Color'
        )
        if (param) param.value = hex
        else fx.params.push(new FxParam('color', 'Key Color', hex))
        fx.raw.setColorVal('Key Color', Utils.RGBAToNvsColor(rgba))
      })
      EventBus.$emit(EventBusKey.seekTimeline)
    },
    changeYiSe (state, { val, selections }) {
      selections.map(item => {
        let { standIndex, eleIndex } = item
        let clipItem = state.list[standIndex][eleIndex]
        let fx = getFx(clipItem.video)
        let param = fx.params.find(
          (param) => param.type === 'float' && param.key === 'Spill Removal Intensity'
        )
        param.value = val
        fx.raw.setFloatVal('Spill Removal Intensity', val)
      })
      EventBus.$emit(EventBusKey.seekTimeline)
    },
    resetMK (state, { selections }) {
      selections.map(item => {
        let { standIndex, eleIndex } = item
        let clipItem = state.list[standIndex][eleIndex]
        let fx = getFx(clipItem.video)
        // 抠像
        let param = fx.params.find(param => param.type === 'color' && param.key === 'Key Color')
        if (param) param.value = '#00000000'
        else fx.params.push(new FxParam('color', 'Key Color', '#00000000'))
        fx.raw.setColorVal('Key Color', new NvsColor(0, 0, 0, 0))
        // ??
        let param1 = fx.params.find(param => param.key === 'Softeness Amendment')
        if (param1) param1.value = 0.1
        fx.raw.setFloatVal('Softeness Amendment', 0.1)
        // 异色
        let param2 = fx.params.find(param => param.type === 'float' && param.key === 'Spill Removal Intensity')
        if (param2) param2.value = 0
        fx.raw.setFloatVal('Spill Removal Intensity', 0)
      })
      EventBus.$emit(EventBusKey.seekTimeline)
    },
    // 修改上传文件
    changeTemplateUpload (state, {videoInfo, m3u8Path, param, selections}) {
      console.log('%c====添加上传文件开始====', 'color: blue')
      console.log('background: blue', '上传文件信息', videoInfo)
      const start = selections[0]
      const {standIndex, eleIndex} = start
      const startItem = state.list[standIndex][eleIndex]// 对应的字幕类
      const end = selections[selections.length - 1]
      const {standIndex: ei, eleIndex: ek} = end
      const endItem = state.list[ei][ek]
      let videoClip = startItem.template.video
      let duration = 0
      // 获取媒体格式类型  1-视频   2-音频  3-图片
      const fileType = Utils.getMediaFormatType(videoInfo.url)
      if (fileType === 3) {
        duration = endItem.video.outPoint - startItem.video.inPoint
      } else {
        duration = Math.min(videoInfo.duration * 1000, endItem.video.outPoint - startItem.video.inPoint)
      }
      const option = {
        index: 2,
        inPoint: startItem.video.inPoint,
        outPoint: endItem.video.outPoint,
        trimIn: 0,
        trimOut: duration,
        ...videoInfo,
        path: videoInfo.url
      }
      console.log('inPoint', option.inPoint, 'outPoint', option.outPoint, 'duration', duration)
      // 加到textVideo里
      if (videoClip) {
        const index = videoClip.raw.getIndex()
        if (startItem.template.id === 222) {
          state.bottomUserVideoTrack.raw.removeClip(index)
        } else if (startItem.template.id === 333 || startItem.template.id === 444) {
          console.log(1212121121)
          state.topUserVideoTrack.raw.removeClip(index)
        }
        videoClip = null
      }
      const v = new VideoClip()
      for (const key in v) {
        if (Object.hasOwnProperty.call(v, key)) {
          v[key] = option[key] === undefined ? v[key] : option[key]
        }
      }
      v.videoType = fileType
      v.offset = option.offset
      v.orgDuration = duration
      v.uploadInfo = videoInfo
      v.m3u8Path = m3u8Path
      videoClip = v
      // index轨道位置，1：topUserVideoTrack，-1：bottomUserVideoTrack
      if (param.index === 1) {
        const videoClipRaw = state.topUserVideoTrack.raw.addClip2(m3u8Path, startItem.video.inPoint, 0, duration)
        if (videoClipRaw.getVideoType() === 1) {
          videoClipRaw.setImageMotionAnimationEnabled(false)
          videoClipRaw.setImageMotionMode(0)
        }
        videoClip.raw = videoClipRaw
      } else if (param.index === -1) {
        const videoClipRaw = state.bottomUserVideoTrack.raw.addClip2(m3u8Path, startItem.video.inPoint, 0, duration)
        if (videoClipRaw.getVideoType() === 1) {
          videoClipRaw.setImageMotionAnimationEnabled(false)
          videoClipRaw.setImageMotionMode(0)
        }
        videoClip.raw = videoClipRaw
      }
      // 静音
      videoClip.raw.setVolumeGain(0, 0)
      videoClip.volume = 0
      // 视频缩放
      let fx = getFxTrans2D(videoClip)
      fx.raw.setFloatVal(Enum.FX_PARAM.SCALE_X, param.scaleX)
      fx.raw.setFloatVal(Enum.FX_PARAM.SCALE_Y, param.scaleY)
      fx.raw.setFloatVal(Enum.FX_PARAM.TRANS_X, param.transX)
      fx.raw.setFloatVal(Enum.FX_PARAM.TRANS_Y, param.transY)
      fx.params.forEach(params => {
        if (params.key === Enum.FX_PARAM.SCALE_X || params.key === Enum.FX_PARAM.SCALE_Y) {
          params.value = param.scaleX
        }
      })
      fx.params.forEach(params => {
        if (params.key === Enum.FX_PARAM.TRANS_X) {
          params.value = param.transX
        }
      })
      fx.params.forEach(params => {
        if (params.key === Enum.FX_PARAM.TRANS_Y) {
          params.value = param.transY
        }
      })
      startItem.template.video = videoClip
      EventBus.$emit(EventBusKey.seekTimeline)
      console.log('上传视频后template信息：', startItem)
    },
    // 修改素材音量
    changeUploadVideoVolume (state, {val, selection}) {
      const {standIndex, eleIndex} = selection
      const startItem = state.list[standIndex][eleIndex]
      const videoClip = startItem.template.video
      videoClip.raw.setVolumeGain(val, val)
      videoClip.volume = val
      console.log('%c====添加上传文件结束====', 'color: blue')
    },
    // 修改模板内的组合字幕
    changeTemplateCaption (state, { index, translationX, translationY, scaleX, scaleY, color, font, text, selections }) {
      const textVideo = state.list[selections[0].standIndex][selections[0].eleIndex]
      if (textVideo.template.caption) {
        if (!isEmpty(translationX)) {
          textVideo.template.caption.translationX = translationX
        }
        if (!isEmpty(translationY)) {
          textVideo.template.caption.translationY = translationY
        }
        if (!isEmpty(translationX) || !isEmpty(translationY)) {
          const x = !isEmpty(translationX) ? translationX : textVideo.template.caption.translationX
          const y = !isEmpty(translationY) ? translationY : textVideo.template.caption.translationY
          const offsetPointF = new NvsPointF(x, y)
          textVideo.template.caption.raw.setCaptionTranslation(offsetPointF)
        }
        if (!isEmpty(scaleX)) {
          textVideo.template.caption.scaleX = scaleX
          textVideo.template.caption.raw.setScaleX(scaleX)
        }
        if (!isEmpty(scaleY)) {
          textVideo.template.caption.scaleY = scaleY
          textVideo.template.caption.raw.setScaleY(scaleY)
        }
        if (!isEmpty(color)) {
          textVideo.template.caption.text[index].color = color
          const nvsColor = Utils.RGBAToNvsColor(color)
          textVideo.template.caption.raw.setTextColor(index, nvsColor)
        }
        if (!isEmpty(font)) {
          textVideo.template.caption.text[index].font = font
          textVideo.template.caption.raw.setFontFamily(index, font)
        }
        if (!isEmpty(text)) {
          textVideo.template.caption.text[index].text = text
          textVideo.template.caption.raw.setText(index, text)
        }
      }
    },
    // 修改模板内的背景图
    changeTemplateBgImage (state, { imageInfo, m3u8Path, index, selections }) {
      console.log('%c<!-------添加模板背景图开始------->', 'color: #bf8918')
      console.log('selections', selections)
      const start = selections[0]
      const {standIndex, eleIndex} = start
      const startItem = state.list[standIndex][eleIndex]// 对应的字幕类
      const end = selections[selections.length - 1]
      const {standIndex: ei, eleIndex: ek} = end
      const endItem = state.list[ei][ek]
      const fileType = Utils.getMediaFormatType(imageInfo.url)
      const duration = endItem.video.outPoint - startItem.video.inPoint
      const option = {
        index: 1, // 随便填的
        inPoint: startItem.video.inPoint,
        outPoint: endItem.video.outPoint,
        trimIn: 0,
        trimOut: duration,
        ...imageInfo,
        path: imageInfo.url
      }
      if (startItem.template.backgroundImage) {
        // 偶发性背景图添加不上bug，导致raw为null
        if (startItem.template.backgroundImage.raw) {
          const index = startItem.template.backgroundImage.raw.getIndex()
          console.log(index, '22222')
          if (startItem.template.id === 222 || startItem.template.id === 555) {
            state.bottomImageTrack.raw.removeClip(index)
          } else if (startItem.template.id === 444) {
            state.topImageTrack.raw.removeClip(index)
          }
        }
        startItem.template.backgroundImage = null
      }
      const imgClip = new VideoClip()
      for (const key in imgClip) {
        if (Object.hasOwnProperty.call(imgClip, key)) {
          imgClip[key] = option[key] === undefined ? imgClip[key] : option[key]
        }
      }
      imgClip.videoType = fileType
      imgClip.offset = option.offset
      imgClip.orgDuration = duration
      imgClip.m3u8Path = m3u8Path
      startItem.template.backgroundImage = imgClip
      if (index === 1) {
        const imgClipRaw = state.topImageTrack.raw.addClip2(m3u8Path, startItem.video.inPoint, 0, duration)
        imgClipRaw.setImageMotionAnimationEnabled(false)
        startItem.template.backgroundImage.raw = imgClipRaw
      } else if (index === -1) {
        const imgClipRaw = state.bottomImageTrack.raw.addClip2(m3u8Path, startItem.video.inPoint, 0, duration)
        imgClipRaw.setImageMotionAnimationEnabled(false)
        startItem.template.backgroundImage.raw = imgClipRaw
      }
      EventBus.$emit(EventBusKey.seekTimeline)
      console.log('上传背景图后template信息：', startItem)
      console.log('%c<!-------添加模板背景图结束------->', 'color: #bf8918')
    },
    // 修改机位
    changeCamera (state, { camera, selections }) {
      const { standIndex: si, eleIndex: sk } = selections[0]
      const { standIndex: ei, eleIndex: ek } = selections[selections.length - 1]
      const list = state.list[si].slice(sk, ek + 1).map(item => {
        changeVideoCamera(state.videoTrack, item, camera)
        return item
      })
      const before = state.list.slice(0, si)
      const beforeCur = state.list[si].slice(0, sk)
      const afterCur = state.list[si].slice(ek + 1)
      const after = state.list.slice(ei + 1, state.list.length)
      state.list = []
      state.list.push(...before)
      if (beforeCur.length) {
        state.list.push(beforeCur)
      }
      state.list.push(list)
      if (afterCur.length) {
        state.list.push(afterCur)
      }
      state.list.push(...after)
      doStack(state.list)
      state.popover.visible = false
      EventBus.$emit(EventBusKey.changePanel)
    },
    clearTimeline (state) {
      const mTimeline = state.timeline
      clearAllVideoTracks(mTimeline)
      clearAllAudioTracks(mTimeline)
      clearVideoFxs(mTimeline.getFirstTimelineVideoFx(), mTimeline)
      clearCaptions(mTimeline.getFirstCaption(), mTimeline)
      clearCompoundCaptions(mTimeline.getFirstCompoundCaption(), mTimeline)
      // await clearStickers(mTimeline.getFirstAnimatedSticker())
    },
    // 分段
    subsection (state, selections) {
      const { standIndex: si, eleIndex: sk } = selections[0]
      const { eleIndex: ek } = selections[selections.length - 1]
      console.time('subsection!!')
      const list = [
        ...state.list.slice(0, si),
        state.list[si].slice(0, sk),
        state.list[si].slice(sk, ek + 1),
        state.list[si].slice(ek + 1),
        ...state.list.slice(si + 1)
      ].filter(item => item.length)
      state.list = list
      console.timeEnd('subsection!!')
      doStack(state.list)
    },
    // 添加过片头片尾
    addUploadClip (state, {videoInfo, m3u8Path, uploadType}) {
      console.log('%c<!-------添加过片头片尾------->', 'color: #bf8918')
      let duration = videoInfo.duration * 1000
      // 获取媒体格式类型  1-视频   2-音频  3-图片
      const fileType = Utils.getMediaFormatType(videoInfo.url)
      if (fileType === 3) {
        duration = 5000000
      }
      let inPoint = 0
      let outPoint = 0
      if (uploadType === 'head') {
        inPoint = 0
        outPoint = duration
      } else {
        let endPoint = state.list[state.list.length - 1][state.list[state.list.length - 1].length - 1].video.outPoint
        inPoint = endPoint
        outPoint = endPoint + duration
      }
      const option = {
        isSpecialVideo: true,
        index: 1,
        inPoint: inPoint,
        outPoint: outPoint,
        trimIn: 0,
        trimOut: duration,
        ...videoInfo,
        path: videoInfo.url
      }
      console.log('inPoint', option.inPoint, 'outPoint', option.outPoint, 'duration', duration)
      
      const v = new VideoClip()
      for (const key in v) {
        if (Object.hasOwnProperty.call(v, key)) {
          v[key] = option[key] === undefined ? v[key] : option[key]
        }
      }
      v.videoType = fileType
      v.offset = option.offset
      v.orgDuration = duration
      v.uploadInfo = videoInfo
      v.m3u8Path = m3u8Path
      if (String(v.thumbnails)) {
        v.thumbnailBaseUrl = v.thumbnails[0].url.replace(/-\d+.jpg/, '')
      } else if (v.thumbnailInfo) {
        v.thumbnailInfo.urlPrefix && (v.thumbnailHost = v.thumbnailInfo.urlPrefix)
        v.thumbnailInfo.interval && (v.thumbnailStep = v.thumbnailInfo.interval * 1000)
        v.thumbnailInfo.extension && (v.thumbnailType = v.thumbnailInfo.extension)
      }
      console.log('new clip',v)
      // 底层处理 插入clip
      let videoClipRaw
      if (uploadType === 'head') {
        videoClipRaw = state.videoTrack.raw.insertClip(m3u8Path, inPoint)
        state.videoTrack.raw.setBuiltinTransition(0, '')
      } else {
        videoClipRaw = state.videoTrack.raw.appendClip(m3u8Path)
        state.videoTrack.raw.setBuiltinTransition(state.videoTrack.raw.getClipCount() - 2, '')
      }
      
      if (videoClipRaw.getVideoType() === 1) {
        videoClipRaw.setImageMotionAnimationEnabled(false)
        videoClipRaw.setImageMotionMode(0)
      }
      v.raw = videoClipRaw
      
      const audio = new AudioClip()
      for (const key in audio) {
        if (Object.hasOwnProperty.call(audio, key)) {
          audio[key] = option[key] === undefined ? audio[key] : option[key]
        }
      }
      audio.videoType = fileType
      audio.offset = option.offset
      audio.orgDuration = duration
      audio.uploadInfo = videoInfo
      audio.m3u8Path = m3u8Path
      console.log('new audio clip',audio)
      console.log('state',state)
      // 底层处理 插入clip
      let audioClipRaw
      if (uploadType === 'head') {
        audioClipRaw = state.audioTrack.raw.insertClip(m3u8Path, inPoint)
        state.audioTrack.raw.setBuiltinTransition(0, '')
      } else {
        audioClipRaw = state.audioTrack.raw.appendClip(m3u8Path)
        state.audioTrack.raw.setBuiltinTransition(state.audioTrack.raw.getClipCount() - 2, '')
      }
      audio.raw = audioClipRaw
      audio.denoiseSwitch = !!state.projectInfo.needDenoise
      applyAudioEQFx (audio, audio.denoiseSwitch)
  
      // 填加上层数据
      let res = []
      res.push({
        musicLyricsClip: null,
        video: v,
        audio: audio,
        uuid: Utils.generateUUID(),
        compoundCaption: null,
        template: null,
        stand: uploadType,
        isTemplate: false,
        checked: true, // 当前机位/分段是否被选中。与合成有关
        enter: false // 换行
      })
      if (uploadType === 'head') {
        let pIndex = 0
        let list = []
        const diff = duration // 字幕需要移动的距离
        console.time('整体数据移动')
        for (let i = 0; i < state.list.length; i++) {
          const stands = state.list[i]
          list[i] = list[i] || []
          for (;pIndex < stands.length; pIndex++) {
            const item = stands[pIndex]
            const video = {
              ...item.video,
              inPoint: item.video.inPoint + diff,
              outPoint: item.video.outPoint + diff
            }
            const audio = {
              ...item.audio,
              inPoint: item.audio.inPoint + diff,
              outPoint: item.audio.outPoint + diff
            }
            let musicLyricsClip
            if (item.musicLyricsClip) {
              musicLyricsClip = {
                ...item.musicLyricsClip,
                inPoint: item.musicLyricsClip.inPoint + diff,
                outPoint: item.musicLyricsClip.outPoint + diff
              }
              if (item.musicLyricsClip.raw) {
                item.musicLyricsClip.raw.movePosition(diff)
              }
            }
            let compoundCaption
            if (item.compoundCaption) {
              compoundCaption = {
                ...item.compoundCaption,
                inPoint: item.compoundCaption.inPoint + diff,
                outPoint: item.compoundCaption.outPoint + diff
              }
              item.compoundCaption.raw.movePosition(diff)
            }
            if (item.template) {
              item.template.inPoint += diff
              item.template.outPoint += diff
              console.log('template_inpoint', item.template.inPoint)
              console.log('template_inpoint', item.template.outPoint)
              // 底图
              if (item.template.backgroundImage) {
                let sourceClipIndex = item.template.backgroundImage.raw.getIndex()
                console.log('img-index', sourceClipIndex)
                let targetTimelinePos = item.template.inPoint
                if (item.template.id === 222 || item.template.id === 555) {
                  state.bottomImageTrack.raw.moveClipByPosition(sourceClipIndex, targetTimelinePos, false, false)
                } else if (item.template.id === 444) {
                  state.topImageTrack.raw.moveClipByPosition(sourceClipIndex, targetTimelinePos, false, false)
                }
                item.template.backgroundImage.inPoint += diff
                item.template.backgroundImage.outPoint += diff
              }
              // 上传视频
              if (item.template.video) {
                let sourceClipIndex = item.template.video.raw.getIndex()
                console.log('video-index', sourceClipIndex)
                let targetTimelinePos = item.template.inPoint
                if (item.template.id === 222) {
                  state.bottomUserVideoTrack.raw.moveClipByPosition(sourceClipIndex, targetTimelinePos, false, false)
                } else if (item.template.id === 333 || item.template.id === 444) {
                  state.topUserVideoTrack.raw.moveClipByPosition(sourceClipIndex, targetTimelinePos, false, false)
                }
                item.template.video.inPoint += diff
                item.template.video.outPoint += diff
              }
              // 组合字幕
              if (item.template.caption) {
                item.template.caption.raw.movePosition(diff)
                item.template.caption.inPoint += diff
                item.template.caption.outPoint += diff
              }
            }
            list[i].push({...item, video, audio, musicLyricsClip, compoundCaption})
          }
          pIndex = 0
        }
        list = list.filter(item => item.length)
        list.unshift(res)
        state.list = list
        console.timeEnd('delete action')
        console.log('list', list)
        doStack(state.list)
        EventBus.$emit(EventBusKey.showClipHead, false)
      } else {
        state.list.push(res)
        console.log('list', state.list)
        doStack(state.list)
        EventBus.$emit(EventBusKey.showClipFoot, false)
      }
      EventBus.$emit(EventBusKey.updateDuration)
      EventBus.$emit(EventBusKey.seekTimeline)
    },
    // 选中
    setSelected (state, {index, isSelected}) {
      state.list[index][0].checked = isSelected
    },
    // 对当前看到的clip 设置特效区域
    setCurrentFxRegion (state, {cameraIndex, rect}) {
      const { VIGNETTE, SHARPEN, BASICIMAGEADJUST, TINT, DEFINITION } = Enum.FX_DESC
      const now = nvsGetStreamingContextInstance().getTimelineCurrentPosition(state.timeline)
      const video = getCurrentVideo(now, state.list[cameraIndex])
      const fxNames = [VIGNETTE, SHARPEN, BASICIMAGEADJUST, TINT, DEFINITION]
      fxNames.forEach(desc => {
        let fx = video.videoFxs.find(fx => fx.desc === desc)
        if (!fx) {
          const fxRaw = video.raw.appendBuiltinFx(desc)
          fx = new VideoFx(Global.TIMELINEVIDEOFXTYPEBUILDIN, video.videoFxs.length, desc)
          fx.raw = fxRaw
          video.videoFxs.push(fx)
        }
        fx.raw.setRegional(true)
        const region = new NvsVectorFloat()
        region.push_back(rect.x1)
        region.push_back(rect.y1)
        region.push_back(rect.x2)
        region.push_back(rect.y2)
        region.push_back(rect.x3)
        region.push_back(rect.y3)
        region.push_back(rect.x4)
        region.push_back(rect.y4)
        fx.raw.setRegion(region)
        const param = fx.params.find(p => p.key === 'region')
        if (param) {
          param.value = rect
        } else {
          fx.params.push(new FxParam('object', 'region', rect))
        }
      })
    },
    // 对当前选中机位设置区域特效
    setFxRegion (state, {cameraIndex, rect}) {
      const { VIGNETTE, SHARPEN, BASICIMAGEADJUST, TINT, DEFINITION } = Enum.FX_DESC
      const fxNames = [VIGNETTE, SHARPEN, BASICIMAGEADJUST, TINT, DEFINITION]
      state.list[cameraIndex].forEach(({video}) => {
        fxNames.forEach(desc => {
          let fx = video.videoFxs.find(fx => fx.desc === desc)
          if (!fx) {
            const fxRaw = video.raw.appendBuiltinFx(desc)
            fx = new VideoFx(Global.TIMELINEVIDEOFXTYPEBUILDIN, video.videoFxs.length, desc)
            fx.raw = fxRaw
            video.videoFxs.push(fx)
          }
          const region = new NvsVectorFloat()
          region.push_back(rect.x1)
          region.push_back(rect.y1)
          region.push_back(rect.x2)
          region.push_back(rect.y2)
          region.push_back(rect.x3)
          region.push_back(rect.y3)
          region.push_back(rect.x4)
          region.push_back(rect.y4)
          fx.raw.setRegion(region)
          const param = fx.params.find(p => p.key === 'region')
          if (param) {
            param.value = rect
          } else {
            fx.params.push(new FxParam('object', 'region', rect))
          }
        })
      })
    },
    // 设置反向
    setInverseRegion (state, {cameraIndex, isInverseRegion}) {
      const { VIGNETTE, SHARPEN, BASICIMAGEADJUST, TINT, DEFINITION } = Enum.FX_DESC
      const fxNames = [VIGNETTE, SHARPEN, BASICIMAGEADJUST, TINT, DEFINITION]
      state.list[cameraIndex].forEach(textVideo => {
        const videoFxs = textVideo.video.videoFxs
        fxNames.forEach(desc => {
          let fx = videoFxs.find(fx => fx.desc === desc)
          if (!fx) {
            const fxRaw = textVideo.video.raw.appendBuiltinFx(desc)
            fx = new VideoFx(Global.TIMELINEVIDEOFXTYPEBUILDIN, textVideo.video.videoFxs.length, desc)
            fx.raw = fxRaw
            textVideo.video.videoFxs.push(fx)
          }
          fx.raw.setInverseRegion(isInverseRegion)
          fx.isInverseRegion = isInverseRegion
        })
      })
    },
    // 设置是否为区域特效
    setIsRegion (state, {cameraIndex, isRegional}) {
      const { VIGNETTE, SHARPEN, BASICIMAGEADJUST, TINT, DEFINITION } = Enum.FX_DESC
      const fxNames = [VIGNETTE, SHARPEN, BASICIMAGEADJUST, TINT, DEFINITION]
      state.list[cameraIndex].forEach(textVideo => {
        const videoFxs = textVideo.video.videoFxs
        fxNames.forEach(desc => {
          let fx = videoFxs.find(fx => fx.desc === desc)
          if (!fx) {
            const fxRaw = textVideo.video.raw.appendBuiltinFx(desc)
            fx = new VideoFx(Global.TIMELINEVIDEOFXTYPEBUILDIN, textVideo.video.videoFxs.length, desc)
            fx.raw = fxRaw
            textVideo.video.videoFxs.push(fx)
          }
          fx.raw.setRegional(isRegional)
          fx.isRegional = isRegional
          let regionParam = fx.params.find(p => p.type === 'object' && p.key === 'region')
          if (!regionParam) {
            const rect = {
              x1: -0.5,
              y1: -0.5,
              x2: -0.5,
              y2: 0.5,
              x3: 0.5,
              y3: 0.5,
              x4: 0.5,
              y4: -0.5
            }
            const region = new NvsVectorFloat()
            region.push_back(rect.x1)
            region.push_back(rect.y1)
            region.push_back(rect.x2)
            region.push_back(rect.y2)
            region.push_back(rect.x3)
            region.push_back(rect.y3)
            region.push_back(rect.x4)
            region.push_back(rect.y4)
            fx.raw.setRegion(region)
            fx.params.push(new FxParam('object', 'region', rect))
          } else {
            const region = new NvsVectorFloat()
            region.push_back(regionParam.value.x1)
            region.push_back(regionParam.value.y1)
            region.push_back(regionParam.value.x2)
            region.push_back(regionParam.value.y2)
            region.push_back(regionParam.value.x3)
            region.push_back(regionParam.value.y3)
            region.push_back(regionParam.value.x4)
            region.push_back(regionParam.value.y4)
            fx.raw.setRegion(region)
          }
        })
      })
    }
  },
  actions: {
    deleteSelectedClip ({state, commit}, selected) {
      console.log('%c<---------删除选中-------> ', 'color: red')
      console.time('delete action')
      const { standIndex: si, eleIndex: sk } = selected[0]
      const { standIndex: ei, eleIndex: ek } = selected[selected.length - 1]
      const { video: { inPoint } } = state.list[si][sk] // 选中的第一个clip的inPoint
      const { video: { outPoint } } = state.list[ei][ek] // 选中的最后一个clip 的outPoint
      let hasTemplate = false
      selected.forEach(({standIndex, eleIndex}) => {
        const clip = state.list[standIndex][eleIndex]
        if (!!clip.isTemplate || clip.template) hasTemplate = true
        removeTextVideoItem(state, clip)
      })
      EventBus.$emit(EventBusKey.updateDuration)
      if (hasTemplate) {
        Message({
          message: vm.$t('textVideo.msg.hint2'),
          type: 'warning'
        })
        return false
      }
      let list = [
        ...state.list.slice(0, si),
        state.list[si].slice(0, sk)
      ] // 当前选中 之前的部分都不需要修改
      if (list.length > 1) {
        if (list.slice(-1).pop().length === 0) {
          const index = list[list.length - 2].slice(-1).pop().video.raw.getIndex()
          state.videoTrack.raw.setBuiltinTransition(index, '')
        } else {
          const index = list.slice(-1).pop().slice(-1).pop().video.raw.getIndex()
          state.videoTrack.raw.setBuiltinTransition(index, '')
        }
      }
      
      // 从被删除位开始，后面的视频修改point, 字幕都往前移动
      let pIndex = ek + 1
      const diff = inPoint - outPoint // 字幕需要移动的距离
      for (let i = ei; i < state.list.length; i++) {
        const stands = state.list[i]
        list[i] = list[i] || []
        for (;pIndex < stands.length; pIndex++) {
          const item = stands[pIndex]
          const video = {
            ...item.video,
            inPoint: item.video.inPoint + diff,
            outPoint: item.video.outPoint + diff
          }
          const audio = {
            ...item.audio,
            inPoint: item.audio.inPoint + diff,
            outPoint: item.audio.outPoint + diff
          }
          let musicLyricsClip
          if (item.musicLyricsClip) {
            musicLyricsClip = {
              ...item.musicLyricsClip,
              inPoint: item.musicLyricsClip.inPoint + diff,
              outPoint: item.musicLyricsClip.outPoint + diff
            }
            if (item.musicLyricsClip.raw) {
              item.musicLyricsClip.raw.movePosition(diff)
            }
          }
          let compoundCaption
          if (item.compoundCaption) {
            compoundCaption = {
              ...item.compoundCaption,
              inPoint: item.compoundCaption.inPoint + diff,
              outPoint: item.compoundCaption.outPoint + diff
            }
            item.compoundCaption.raw.movePosition(diff)
          }
          if (item.template) {
            item.template.inPoint += diff
            item.template.outPoint += diff
            console.log('template_inpoint', item.template.inPoint)
            console.log('template_inpoint', item.template.outPoint)
            // 底图
            if (item.template.backgroundImage) {
              let sourceClipIndex = item.template.backgroundImage.raw.getIndex()
              console.log('img-index', sourceClipIndex)
              let targetTimelinePos = item.template.inPoint
              if (item.template.id === 222 || item.template.id === 555) {
                state.bottomImageTrack.raw.moveClipByPosition(sourceClipIndex, targetTimelinePos, false, false)
              } else if (item.template.id === 444) {
                state.topImageTrack.raw.moveClipByPosition(sourceClipIndex, targetTimelinePos, false, false)
              }
              item.template.backgroundImage.inPoint += diff
              item.template.backgroundImage.outPoint += diff
            }
            // 上传视频
            if (item.template.video) {
              let sourceClipIndex = item.template.video.raw.getIndex()
              console.log('video-index', sourceClipIndex)
              let targetTimelinePos = item.template.inPoint
              if (item.template.id === 222) {
                state.bottomUserVideoTrack.raw.moveClipByPosition(sourceClipIndex, targetTimelinePos, false, false)
              } else if (item.template.id === 333 || item.template.id === 444) {
                state.topUserVideoTrack.raw.moveClipByPosition(sourceClipIndex, targetTimelinePos, false, false)
              }
              item.template.video.inPoint += diff
              item.template.video.outPoint += diff
            }
            // 组合字幕
            if (item.template.caption) {
              item.template.caption.raw.movePosition(diff)
              item.template.caption.inPoint += diff
              item.template.caption.outPoint += diff
            }
          }
          list[i].push({...item, video, audio, musicLyricsClip, compoundCaption})
        }
        pIndex = 0
      }
      list = list.filter(item => item.length)
      // 删除片头片尾时
      EventBus.$emit(EventBusKey.showClipHead, list[0][0].stand === 'head' ? false : true)
      EventBus.$emit(EventBusKey.showClipFoot, list[list.length - 1][0].stand === 'foot' ? false : true)
      console.timeEnd('delete action')
      console.log('list', list)
      commit('updateList', list)
      doStack(state.list)
      EventBus.$emit(EventBusKey.seekTimeline)
    }
  }
}
function getCurrentVideo (time, list) {
  for (let i = 0; i < list.length; i++) {
    const { video } = list[i]
    if (video.inPoint <= time && video.outPoint > time) {
      return video
    }
  }
  return list[0].video
}
function getFx (clip) {
  if (!clip) return null
  let videoFx = clip.videoFxs.find(
    ({ type, desc }) =>
      type === Global.TIMELINEVIDEOFXTYPEBUILDIN &&
      desc === 'Master Keyer'
  )
  if (videoFx) {
    if (!videoFx.params.find((f) => f.key === 'Spill Removal')) { videoFx.params.push(new FxParam('bool', 'Spill Removal', true)) }
    if (!videoFx.params.find((f) => f.key === 'Softeness Amendment')) { videoFx.params.push(new FxParam('float', 'Softeness Amendment', 0.1)) }
    if (!videoFx.params.find((f) => f.key === 'Spill Removal Intensity')) {
      videoFx.params.push(
        new FxParam('float', 'Spill Removal Intensity', 0)
      )
    }
    return videoFx
  }
  let fxRaw = clip.raw.appendBuiltinFx('Master Keyer')
  fxRaw.setBooleanVal('Spill Removal', true)
  fxRaw.setFloatVal('Softeness Amendment', 0.1)
  fxRaw.setFloatVal('Spill Removal Intensity', 0)
  videoFx = new VideoFx(
    Global.TIMELINEVIDEOFXTYPEBUILDIN,
    clip.videoFxs.length,
    'Master Keyer'
  )
  videoFx.raw = fxRaw
  videoFx.params.push(new FxParam('bool', 'Spill Removal', true))
  videoFx.params.push(new FxParam('float', 'Softeness Amendment', 0.1))
  videoFx.params.push(new FxParam('float', 'Spill Removal Intensity', 0))
  clip.videoFxs.push(videoFx)
  return videoFx
}
function getFxTrans2D (clip) {
  if (!clip) return null
  let videoFx = clip.videoFxs.find(
    ({ type, desc }) =>
      type === Global.TIMELINEVIDEOFXTYPEBUILDIN &&
      desc === 'Transform 2D'
  )
  if (videoFx) {
    if (!videoFx.params.find((f) => f.key === 'Scale X')) { videoFx.params.push(new FxParam('float', 'Scale X', 1)) }
    if (!videoFx.params.find((f) => f.key === 'Scale Y')) { videoFx.params.push(new FxParam('float', 'Scale Y', 1)) }
    if (!videoFx.params.find((f) => f.key === 'Trans X')) { videoFx.params.push(new FxParam('float', 'Trans X', 0)) }
    if (!videoFx.params.find((f) => f.key === 'Trans Y')) { videoFx.params.push(new FxParam('float', 'Trans Y', 0)) }
    if (!videoFx.params.find((f) => f.key === 'Opacity')) { videoFx.params.push(new FxParam('float', 'Opacity', 1)) }
    return videoFx
  }
  let fxRaw = clip.raw.appendBuiltinFx('Transform 2D')
  fxRaw.setFloatVal('Scale X', 1)
  fxRaw.setFloatVal('Scale Y', 1)
  fxRaw.setFloatVal('Trans X', 0)
  fxRaw.setFloatVal('Trans Y', 0)
  fxRaw.setFloatVal('Opacity', 1)
  videoFx = new VideoFx(
    Global.TIMELINEVIDEOFXTYPEBUILDIN,
    clip.videoFxs.length,
    'Transform 2D'
  )
  videoFx.raw = fxRaw
  videoFx.params.push(new FxParam('float', 'Scale X', 1))
  videoFx.params.push(new FxParam('float', 'Scale Y', 1))
  videoFx.params.push(new FxParam('float', 'Trans X', 0))
  videoFx.params.push(new FxParam('float', 'Trans Y', 0))
  videoFx.params.push(new FxParam('float', 'Opacity', 1))
  clip.videoFxs.push(videoFx)
  return videoFx
}

function removeTemplate (state, oldTemplate, selections) {
  // 删背景图
  if (oldTemplate.backgroundImage) {
    const index = oldTemplate.backgroundImage.raw.getIndex()
    if (oldTemplate.id === 222 || oldTemplate.id === 555) {
      state.bottomImageTrack.raw.removeClip(index)
    } else if (oldTemplate.id === 444) {
      state.topImageTrack.raw.removeClip(index)
    }
    oldTemplate.backgroundImage = null
  }
  // 删上传文件
  if (oldTemplate.video) {
    const index = oldTemplate.video.raw.getIndex()
    if (oldTemplate.id === 222) {
      state.bottomUserVideoTrack.raw.removeClip(index)
    } else if (oldTemplate.id === 333 || oldTemplate.id === 444) {
      state.topUserVideoTrack.raw.removeClip(index)
    }
    oldTemplate.video = null
  }
  // 删组合字幕
  if (oldTemplate.caption) {
    state.timeline.removeCompoundCaption(oldTemplate.caption.raw)
    oldTemplate.caption = null
  }
  // 删特效,遍历clip删除抠像特效 !同时将isTemplate置成false
  selections.map(item => {
    let { standIndex, eleIndex } = item
    let clip = state.list[standIndex][eleIndex]
    clip.isTemplate = false
    let fx = clip.video.videoFxs.find(({ type, desc }) =>
      type === Global.TIMELINEVIDEOFXTYPEBUILDIN &&
    desc === 'Master Keyer')
    if (fx) clip.video.raw.removeFx(fx.raw.getIndex())
    let videoFxs = clip.video.videoFxs
    if (!videoFxs) return
    videoFxs.splice(videoFxs.indexOf(videoFxs.find(
      ({ type, desc }) =>
        type === Global.TIMELINEVIDEOFXTYPEBUILDIN &&
        desc === 'Master Keyer'
    )), 1)
  })
}
// 设置选中区已添加模板
function isTemplate (state, selections) {
  selections.map(item => {
    let { standIndex, eleIndex } = item
    let clip = state.list[standIndex][eleIndex]
    clip.isTemplate = true
  })
}
function isEmpty (v) {
  return v === undefined || v === null
}
function changeVideoCamera (videoTrack, textVideo, camera) {
  console.log('<!--更新上层数据-->', "color: green")
  // 删除旧的视频
  const index = textVideo.video.raw.getIndex()
  videoTrack.raw.removeClip(index, true)
  // 更新上层数据
  const {video} = textVideo
  textVideo.stand = camera.id
  for (const key in video) {
    if (Object.hasOwnProperty.call(video, key) && Object.hasOwnProperty.call(camera, key)) {
      video[key] = camera[key]
    }
  }
  // 添加新视频
  const { m3u8Path, inPoint, trimIn, trimOut, offset } = textVideo.video
  textVideo.video.raw = videoTrack.raw.addClip2(m3u8Path, inPoint, trimIn - offset, trimOut - offset)
  // 重新添加特效
  applyVideoFx(textVideo.video)
}
function applyVideoFx (video) {
  video.videoFxs.forEach(fx => {
    fx.raw = video.raw.appendBuiltinFx(fx.desc)
    if (fx.isInverseRegion) {
      fx.raw.setInverseRegion(fx.isInverseRegion)
    }
    if (fx.isRegional) fx.raw.setRegional(fx.isRegional)
    if (Array.isArray(fx.params)) {
      fx.params.forEach(param => {
        if (param.type === 'float') {
          fx.raw.setFloatVal(param.key, param.value)
        } else if (param.type === 'bool') {
          fx.raw.setBooleanVal(param.key, param.value)
        } else if (param.type === 'color') {
          fx.raw.setColorVal(param.key, Utils.HexToNvsColor(param.value))
        } else if (param.type === 'object') {
          const region = new NvsVectorFloat()
          region.push_back(param.value.x1)
          region.push_back(param.value.y1)
          region.push_back(param.value.x2)
          region.push_back(param.value.y2)
          region.push_back(param.value.x3)
          region.push_back(param.value.y3)
          region.push_back(param.value.x4)
          region.push_back(param.value.y4)
          fx.raw.setRegion(region)
        }
      })
    } else {
      for (const key in fx.params) {
        const {type, value} = fx.params[key]
        if (type === 'float') {
          fx.raw.setFloatVal(key, value)
        } else if (type === 'bool') {
          fx.raw.setBooleanVal(key, value)
        } else if (type === 'color') {
          fx.raw.setColorVal(key, Utils.HexToNvsColor(value))
        } else if (type === 'object') {
          const region = new NvsVectorFloat()
          region.push_back(value.x1)
          region.push_back(value.y1)
          region.push_back(value.x2)
          region.push_back(value.y2)
          region.push_back(value.x3)
          region.push_back(value.y3)
          region.push_back(value.x4)
          region.push_back(value.y4)
          fx.raw.setRegion(region)
        }
      }
    }
  })
}
// 添加音频降噪特效
function applyAudioEQFx (audio, isDenoise) {
  audio.audioFxs.forEach(fx => {
    fx.raw = audio.raw.appendFx(fx.desc)
    if (Array.isArray(fx.params)) {
      fx.params = new Object
    }
    for (let i = 1; i <= 30; i++) {
      let value = isDenoise ? -20.0 : 0
      if (isDenoise && i === 10) {
        value = -14.7
      }else if (isDenoise && i === 13) {
        value = -14.3
      }else if (isDenoise && i === 16) {
        value = -11.8
      }else if (isDenoise && i === 19) {
        value = -10.5
      }else if (isDenoise && i === 22) {
        value = -17.7
      }
      if (fx.params.hasOwnProperty(i + " Band Gain")) {
        fx.params[i + " Band Gain"] = {key: i + " Band Gain", value: fx.params[i + " Band Gain"].value, type: 'float'}
        fx.raw.setFloatVal(i + " Band Gain", fx.params[i + " Band Gain"].value)
      } else {
        fx.params[i + " Band Gain"] = {key: i + " Band Gain", value: value, type: 'float'}
        fx.raw.setFloatVal(i + " Band Gain", value)
      }
    }
  })
}
function applyTemplate (template, {topUserVideoTrack, topImageTrack, bottomUserVideoTrack, bottomImageTrack, timeline}) {
  const {caption, video, backgroundImage, id} = template
  if (id === 222) { // 开窗模板
    addClipInTrack(bottomUserVideoTrack.raw, video)
    addClipInTrack(bottomImageTrack.raw, backgroundImage)
    addCompoundCaption(timeline, caption)
  } else if (id === 333) { // 纯视频
    addClipInTrack(topUserVideoTrack.raw, video)
  } else if (id === 444) { // 解说模板
    addClipInTrack(topUserVideoTrack.raw, video)
    addClipInTrack(topImageTrack.raw, backgroundImage)
    addCompoundCaption(timeline, caption)
  } else if (id === 555) { // 标志模板
    addClipInTrack(bottomImageTrack.raw, backgroundImage)
    addCompoundCaption(timeline, caption)
  }
}

function addClipInTrack (videoTrackRaw, video) {
  if (!(video && video.m3u8Path)) return
  // console.log(video.m3u8Path,video.inPoint,Math.max(video.trimIn - (video.offset || 0), 0),video.trimOut - (video.offset || 0))
  video.raw = videoTrackRaw.addClip2(
    video.m3u8Path,
    video.inPoint,
    Math.max(video.trimIn - (video.offset || 0), 0),
    video.trimOut - (video.offset || 0)
  )
  if (!video.raw) {
    console.error('clip 添加失败', video)
    return
  }
  if (video.videoType === 3) {
    video.raw.setImageMotionAnimationEnabled(false)
    video.raw.setImageMotionMode(0)
  }
  video.raw.setVolumeGain(0, 0)
  video.volume = 0
  applyVideoFx(video)
}

function addAudioClipInTrack (state, audioTrackRaw, audio) {
  if (!(audio && audio.m3u8Path)) return
  audio.raw = audioTrackRaw.addClip2(
    audio.m3u8Path,
    audio.inPoint,
    Math.max(audio.trimIn - (audio.offset || 0), 0),
    audio.trimOut - (audio.offset || 0)
  )
  if (!audio.raw) {
    console.error('clip 添加失败', audio)
    return
  }
  if (audio.volume !== 1) {
    audio.raw.setVolumeGain(audio.volume, audio.volume)
  }
  // 降噪判断
  audio.denoiseSwitch = audio.hasOwnProperty("denoiseSwitch") ? audio.denoiseSwitch : !!state.projectInfo.needDenoise
  applyAudioEQFx (audio, audio.denoiseSwitch)
}

function addCompoundCaption (timeline, compoundCaption) {
  if (!compoundCaption) return
  const { inPoint, outPoint, styleDesc } = compoundCaption
  compoundCaption.raw = timeline.addCompoundCaption(inPoint, outPoint - inPoint, styleDesc)
  compoundCaption.raw.setScaleX(compoundCaption.scaleX)
  compoundCaption.raw.setScaleY(compoundCaption.scaleY)
  compoundCaption.raw.setRotationZ(compoundCaption.rotation)
  const offsetPointF = new NvsPointF(compoundCaption.translationX, compoundCaption.translationY)
  compoundCaption.raw.setCaptionTranslation(offsetPointF)
  compoundCaption.text.map((item, index) => {
    compoundCaption.raw.setText(index, item.text)
    if (item.font) {
      compoundCaption.raw.setFontFamily(index, item.font)
    }
    if (item.color) {
      compoundCaption.raw.setTextColor(index, Utils.RGBAToNvsColor(item.color))
    }
  })
}

function clearAllVideoTracks (mTimeline) {
  return new Promise((resolve, reject) => {
    clearAllVideoTrack(mTimeline).then(() => {
      // console.log('清理所有轨道')
      resolve()
    }).catch(e => {
      reject(new Error('轨道清理错误', e))
    })
  })
}

function clearAllVideoTrack (mTimeline) {
  return new Promise((resolve, reject) => {
    while (mTimeline.videoTrackCount() !== 0) {
      mTimeline.removeVideoTrack(0)
    }
    resolve()
  })
}

function clearAllAudioTracks (mTimeline) {
  return new Promise((resolve, reject) => {
    clearAllAudioTrack(mTimeline).then(() => {
      // console.log('清理所有轨道')
      resolve()
    }).catch(e => {
      reject(new Error('轨道清理错误', e))
    })
  })
}

function clearAllAudioTrack (mTimeline) {
  return new Promise((resolve, reject) => {
    while (mTimeline.audioTrackCount() !== 0) {
      mTimeline.removeAudioTrack(0)
    }
    resolve()
  })
}

function clearVideoFxs (videoFx, mTimeline) {
  function clearVideoFx (videoFx, deleteAllCallBack) {
    if (videoFx) {
      clearVideoFx(mTimeline.removeTimelineVideoFx(videoFx), deleteAllCallBack)
    } else {
      if (deleteAllCallBack && typeof deleteAllCallBack === 'function') {
        mTimeline.deleteWatermark()
        deleteAllCallBack()
      }
    }
  }
  return new Promise((resolve, reject) => {
    clearVideoFx(videoFx, () => { resolve() })
  })
}

function clearCaptions (caption, mTimeline) {
  function clear (caption, deleteAllCallBack) {
    if (caption) {
      clear(mTimeline.removeCaption(caption), deleteAllCallBack)
    } else {
      if (deleteAllCallBack && typeof deleteAllCallBack === 'function') {
        deleteAllCallBack()
      }
    }
  }
  return new Promise((resolve, reject) => {
    clear(caption, () => { resolve() })
  })
}

function clearCompoundCaptions (caption, mTimeline) {
  function clearCompoundCaption (caption, deleteAllCallBack) {
    if (caption) {
      clearCompoundCaption(mTimeline.removeCompoundCaption(caption), deleteAllCallBack)
    } else {
      if (deleteAllCallBack && typeof deleteAllCallBack === 'function') {
        deleteAllCallBack()
      }
    }
  }
  return new Promise((resolve, reject) => {
    clearCompoundCaption(caption, () => { resolve() })
  })
}

// 删除一段文字视频
function removeTextVideoItem (state, item) {
  if (item.musicLyricsClip && item.musicLyricsClip.raw) {
    state.timeline.removeCaption(item.musicLyricsClip.raw)
  }
  if (item.compoundCaption) {
    state.timeline.removeCompoundCaption(item.compoundCaption.raw)
  }
  if (item.video && item.video.raw) {
    state.videoTrack.raw.removeClip(item.video.raw.getIndex(), false)
  }
  if (item.audio && item.audio.raw) {
    state.audioTrack.raw.removeClip(item.audio.raw.getIndex(), false)
  }
}

// 添加唱词字幕
function addSubTittleCaption (state, musicLyricsClip) {
  if (musicLyricsClip) {
    musicLyricsClip.raw = state.timeline.addCaption(musicLyricsClip.text, musicLyricsClip.inPoint, musicLyricsClip.outPoint - musicLyricsClip.inPoint, musicLyricsClip.styleDesc || Global.getDefaultSubtitleStyle(), false)
    if (!isEmpty(musicLyricsClip.font)) {
      musicLyricsClip.raw.setFontFamily(musicLyricsClip.font)
    }
    if (!isEmpty(musicLyricsClip.fontSize)) {
      musicLyricsClip.raw.setFontSize(musicLyricsClip.fontSize)
    }
    if (!isEmpty(musicLyricsClip.color)) {
      const nvsColor = Utils.RGBAToNvsColor(musicLyricsClip.color)
      musicLyricsClip.raw.setTextColor(nvsColor)
    }
    if (!isEmpty(musicLyricsClip.translationX) || !isEmpty(musicLyricsClip.translationY)) {
      const x = musicLyricsClip.translationX
      const y = musicLyricsClip.translationY
      const offsetPointF = new NvsPointF(x, y)
      musicLyricsClip.raw.setCaptionTranslation(offsetPointF)
    }
    if (!isEmpty(musicLyricsClip.outlineColor)) {
      let color = Utils.RGBAToNvsColor(musicLyricsClip.outlineColor)
      musicLyricsClip.outline = true
      musicLyricsClip.raw.setDrawOutline(true)
      musicLyricsClip.raw.setOutlineColor(color)
    } else {
      let color = Utils.RGBAToNvsColor('rgba(0, 0, 0, 0)')
      musicLyricsClip.outline = false
      musicLyricsClip.outlineColor = 'rgba(0, 0, 0, 0)'
      musicLyricsClip.raw.setOutlineColor(color)
    }
    if (!isEmpty(musicLyricsClip.outlineWidth)) {
      musicLyricsClip.raw.setOutlineWidth(musicLyricsClip.outlineWidth)
    }
  }
}
/**
 * 添加transform 2D特效。 数据结构改成对象，从90ms 提升到8ms (第二次为0.001ms)
 * @param {*} clip video
 * @param {Object} options 需要修改的字段 {[key]: {type, value}, ....}
 */
function addFxTrans2D (clip, options, s) {
  let fx
  if (clip.videoFxs.length) {
    fx = clip.videoFxs.find(
      ({ type, desc }) =>
        type === Global.TIMELINEVIDEOFXTYPEBUILDIN &&
        desc === Enum.FX_DESC.TRANSFORM_2D
    )
  }
  
  s && console.time('if!!!')
  if (!fx) {
    // 大约需要8ms
    fx = new VideoFx(Global.TIMELINEVIDEOFXTYPEBUILDIN, clip.videoFxs.length, Enum.FX_DESC.TRANSFORM_2D)
    // if (Array.isArray(fx.params)) {
    //   fx.params = {}
    // }
    fx.raw = clip.raw.appendBuiltinFx(Enum.FX_DESC.TRANSFORM_2D)
    clip.videoFxs.unshift(fx)
  }
  if (!Array.isArray(fx.params)) {
    fx.params = []
  }
  s && console.timeEnd('if!!!')
  for (const key in options) {
    const {type, value} = options[key]
    if (type === 'float') {
      fx.raw.setFloatVal(key, value)
    } else if (type === 'bool') {
      fx.raw.setBooleanVal(key, value)
    } else if (type === 'color') {
      fx.raw.setColorVal(key, Utils.HexToNvsColor(value))
    }
    const index = fx.params.findIndex(fx => fx.key === key)
    if (index > -1) {
      fx.params[index].value = value
    } else {
      fx.params.push(new FxParam(type, key, value))
    }
  }
  return fx
}

// 添加音频降噪特效
function addAudioFx (clip, options) {
  let fx
  if (clip.audioFxs.length) {
    fx = clip.audioFxs.find(
      ({ type, desc }) =>
        type === Global.TIMELINEVIDEOFXTYPEBUILDIN &&
        desc === Enum.FX_AUDIO_DESC.AUDIO_EQ
    )
  }
  if (!fx) {
    fx = new AudioFx(Global.TIMELINEVIDEOFXTYPEBUILDIN, clip.audioFxs.length, Enum.FX_AUDIO_DESC.AUDIO_EQ)
    if (Array.isArray(fx.params)) {
      fx.params = {}
    }
    fx.raw = clip.raw.appendFx(Enum.FX_AUDIO_DESC.AUDIO_EQ)
    clip.audioFxs.push(fx)
  }
  for (const key in options) {
    const {type, value} = options[key]
    if (type === 'float') {
      fx.raw.setFloatVal(key, value)
    }
    if (fx.params.hasOwnProperty(key)) {
      fx.params[key].value = value
    } else {
      fx.params[key] = new FxParam(type, key, value)
    }
  }
  return fx
}
// 设置Color Property特效
function getFxColorProperty (clip, options) {
  // console.log(clip, options)
  let fx
  if (clip.videoFxs.length) {
    fx = clip.videoFxs.find(
      ({ type, desc }) =>
        type === Global.TIMELINEVIDEOFXTYPEBUILDIN &&
        desc === Enum.FX_DESC.COLOR_PROPERTY
    )
  }
  if (!fx) {
    fx = new VideoFx(Global.TIMELINEVIDEOFXTYPEBUILDIN, clip.videoFxs.length, Enum.FX_DESC.COLOR_PROPERTY)
    // if (Array.isArray(fx.params)) {
    //   fx.params = {}
    // }
    fx.raw = clip.raw.appendBuiltinFx(Enum.FX_DESC.COLOR_PROPERTY)
    clip.videoFxs.push(fx)
  }
  for (const key in options) {
    const {type, value} = options[key]
    if (type === 'float') {
      fx.raw.setFloatVal(key, value)
    }
    const index = fx.params.findIndex(fx => fx.key === key)
    if (index > -1) {
      fx.params[index].value = value
    } else {
      fx.params.push(new FxParam(type, key, value))
    }
  }
  return fx
}
function setFxParam (clip, options = {}) {
  const fxDesc = options.desc
  let fx
  if (clip.videoFxs.length) {
    fx = clip.videoFxs.find(
      ({ type, desc }) =>
        type === Global.TIMELINEVIDEOFXTYPEBUILDIN &&
        desc === fxDesc
    )
  }
  if (!fx) {
    fx = new VideoFx(Global.TIMELINEVIDEOFXTYPEBUILDIN, clip.videoFxs.length, fxDesc)
    fx.raw = clip.raw.appendBuiltinFx(fxDesc)
    clip.videoFxs.push(fx)
  }
  options.params.forEach(({key, type, value}) => {
    if (type === 'float') {
      fx.raw.setFloatVal(key, value)
    }
    const index = fx.params.findIndex(p => p.key === key)
    if (index > -1) {
      fx.params[index] = new FxParam(type, key, value)
    } else {
      fx.params.push(new FxParam(type, key, value))
    }
  })
  return fx
}
