import store from '@/store'

export function buildTimestampfromString (time) {
  if (typeof time !== 'string') return
  const timeArr = time.split(':')
  if (timeArr.length === 4) {
    const hour = timeArr[0]
    const min = timeArr[1]
    const sec = timeArr[2]
    const frame = timeArr[3]
    // 微秒
    return (Number(hour * 3600) + Number(min * 60) + Number(sec) + frame / 25) * 1000000
  } if (timeArr.length === 3) {
    const hour = timeArr[0]
    const min = timeArr[1]
    const sec = timeArr[2]
    // 微秒
    return (Number(hour * 3600) + Number(min * 60) + Number(sec)) * 1000000
  } else {
    return 0
  }
}

export function buildStringFromTimestamp (value, withMilli = false) {
  let time = value / 1000000
  if (time < 1) {
    time = 0
  }
  let sec = parseInt(time)
  let min
  let hour = parseInt(sec / 3600)
  if (hour > 0) {
    sec %= 3600
    min = parseInt(sec / 60)
    if (min > 0) {
      sec %= 60
    }
  } else {
    min = parseInt(sec / 60)
    if (min > 0) {
      sec %= 60
    }
  }
  if (withMilli) {
    let milli = value % 1000000 / 1000
    if (milli < 1) {
      milli = '000'
    } else if (milli < 10) {
      milli = '00' + milli
    } else if (milli < 100) {
      milli = '0' + milli
    }
    return unitFormat(hour) + ':' + unitFormat(min) + ':' + unitFormat(sec.toFixed(0)) + ',' + milli
  } else {
    return unitFormat(hour) + ':' + unitFormat(min) + ':' + unitFormat(sec.toFixed(0))
  }
}

function unitFormat (number) {
  let numberString = ''
  if (number < 10) {
    numberString += '0'
  }
  return numberString + number.toString()
}

export function buildTimestampFromDate (value) {
  const hh = new Date(value).getHours()
  const mm = new Date(value).getMinutes()
  const ss = new Date(value).getSeconds()
  // 微秒
  return (hh * 3600 + mm * 60 + ss * 1) * 1000000
}

export function buildDatefromTimestamp (ts) {
  // 微秒时间戳
  const m = buildStringFromTimestamp(ts)
  if (m) {
    // 00:00:01
    const hh = m.substring(0, 2)
    const mm = m.substring(3, 5)
    const ss = m.substring(6, 8)
    // 插件需要。秒
    return new Date(1970, 1, 1, Number(hh), Number(mm), Number(ss))
  } else {
    return ''
  }
}

export function buildCompareFormat (value) {
  return value / 1000000 | 0
}

export function rebuildM3u8 (data) {
  const { ingestTostart } = store.getters
  const m3u8Arr = data.split('\n')
  let accu = 0
  let firstEXTINF = -1
  let lastEXTINF = -1
  for (let index = 0; index < m3u8Arr.length; index++) {
    const ele = m3u8Arr[index]
    if (/#EXTINF:/.test(ele)) {
      const resultArr = ele.match(/#EXTINF:(.*),/)
      if (resultArr.length === 2) {
        const temp = resultArr[1] * 1000000
        if (accu + temp > ingestTostart) {
          // 如果startTimeFromM3u8没有赋值，并且lastIndexWhenNotHit也没有被赋值，更新
          if (store.state.live.startTimeFromM3u8 === -1 && store.state.live.lastIndexWhenNotHit === -1) {
            store.commit('live/updateStartTimeFromM3u8', buildStringFromTimestamp(accu + buildTimestampfromString(store.state.live.ingestTime)))
            store.commit('live/updateIngestTostartFromM3u8', accu)
          }
          // 截取当前index的上一个，大于ingestTostart的情况，都需要更新
          lastEXTINF = index - 1
          break
        } else {
          if (firstEXTINF === -1) {
            firstEXTINF = index
          }
          accu += temp
        }
      }
    }
  }
  // 时移长度大于m3u8总时长，不展示，只展示最后一个切片，此时lastEXTINF还没有被赋值
  if (lastEXTINF === -1 || (lastEXTINF !== -1 && store.state.live.lastIndexWhenNotHit !== -1)) {
    const arrLength = m3u8Arr.length
    let lastIndex
    if (store.state.live.lastIndexWhenNotHit === -1) {
      lastIndex = arrLength - 4
      store.commit('live/updateLastIndexWhenNotHit', lastIndex)
      // 根据lastIndex构建时间线开始的m3u8切片
      const ele = m3u8Arr[lastIndex]
      if (/#EXTINF:/.test(ele)) {
        const resultArr = ele.match(/#EXTINF:(.*),/)
        if (resultArr.length === 2) {
          const temp = resultArr[1] * 1000000
          accu -= temp
        }
      }
      store.commit('live/updateStartTimeFromM3u8', buildStringFromTimestamp(accu + buildTimestampfromString(store.state.live.ingestTime)))
      store.commit('live/updateIngestTostartFromM3u8', accu)
    } else {
      lastIndex = store.state.live.lastIndexWhenNotHit
      for (let index = lastIndex; index < m3u8Arr.length; index++) {
        const ele = m3u8Arr[lastIndex]
        if (/#EXTINF:/.test(ele)) {
          const resultArr = ele.match(/#EXTINF:(.*),/)
          if (resultArr.length === 2) {
            const temp = resultArr[1] * 1000000
            accu -= temp
          }
        }
      }
    }
    lastEXTINF = lastIndex - 1
  }
  const res = data.match(/EXTINF:(.*),/)
  store.commit('live/updateFirstEXTINFDuration', res[1] * 1000000 | 0)

  // 从第一个EXTINF开始算，到满足条件的EXTINF为止都删除
  // [1,2,3,4].splice(1[a], 2[b]) ===> [2,3,4] ===> a的意思是第一个要的值得index，b的意思是从a开始数b个
  m3u8Arr.splice(firstEXTINF, (lastEXTINF + 1) - firstEXTINF)
  const result = m3u8Arr.join('\n').replace(/#EXT-X-MEISHE-DURATION:(\d+)/, ($0, $1, $2) => {
    return '#EXT-X-MEISHE-DURATION:' + ($1 * 1000 - accu) / 1000
  })
  return result
}
