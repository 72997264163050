import axios from 'axios'

export default {
  namespaced: true,
  state: {
    data: {
      info: null,
      clips: []
    }
  },
  mutations: {
    setSplitData (state, payload) {
      state.data = payload
    },
    setSplitClips (state, payload) {
      state.data.clips = payload
    }
  },
  actions: {
    getSplitData({ commit }) {
      return new Promise((resolve, reject) => {
        const searchStr = location.search.slice(1)
        let url = sessionStorage.nvssplitvideourl
        if (/splitResourceId=\d+/.test(searchStr)) {
          url = sessionStorage.nvsoriginalsplitvideourl
        }
        axios.get(url)
          .then(resp => {
            commit('setSplitData', resp.data)
            resolve(resp.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
}