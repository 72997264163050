export default {
  setCurrentClip (state, clip) {
    state.currentClip = clip
    state.currentclips = []
    state.blankClip = null
  },
  setCurrentTrack (state, track) {
    state.currentTrack = track
  },
  setCurrentClips (state, clips) {
    state.currentClips = Array.isArray(clips) ? clips : []
    state.currentClip = null
    state.blankClip = null
  },
  setCurrentPanel (state, panelType) {
    state.currentPanel = panelType
  },
  setCurrentTheme (state, theme) {
    state.currentTheme = theme
  },
  setBlankClip (state, blankClip) {
    state.BlankClip = blankClip
    state.currentClip = null
    state.currentClips = []
  },
  changeAISelect (state, clip) {
    state.AISelectedClip = clip
  },
  changeCurrentPanelName (state, panelName) {
    state.currentPanelName = panelName
    state.AISelectedClip = null
  },
  setTrimModelStatus (state, val) {
    state.isTrimModel = val
  },
  setPanelType (state, type) {
    state.currentPanelType = type
  },
  changeAIStatus (state, status) {
    state.aiStatus = status * 1
  },
  setCurrentPreviewId (state, id) {
    state.currentPreviewId = id
  }
}
