import { debounce } from '@/utils'

export default {
  namespaced: true,
  state: {
    timeline: undefined,
    streamingContext: undefined,
    liveWindow: undefined,
    ratio: 16 / 9,
    ratioKey: '16:9',
    ratioMap: [{
      key: '16:9',
      ratio: 16 / 9
    }, {
      key: '9:16',
      ratio: 9 / 16
    }, {
      key: '9:21',
      ratio: 9 / 21
    }, {
      key: '21:9',
      ratio: 21 / 9
    }, {
      key: '18:9',
      ratio: 18 / 9
    }, {
      key: '9:18',
      ratio: 9 / 18
    }, {
      key: '3:4',
      ratio: 3 / 4
    }, {
      key: '4:3',
      ratio: 4 / 3
    }, {
      key: '1:1',
      ratio: 1 / 1
    }],
    frame: 25,
    storeCtxEvts: null,
    monitorType: 'MONITOR_TYPE_0',
    safeFrameSize: 80
  },
  getters: {
    safeFrameSize: state => state.safeFrameSize
  },
  mutations: {
    setMonitorType (state, type) {
      state.monitorType = type
    },
    setStreamingContext (state, ctx) {
      state.streamingContext = ctx
    },
    setTimeline (state, timeline) {
      state.timeline = timeline
    },
    setLiveWindow (state, liveWindow) {
      state.liveWindow = liveWindow
    },
    seekDraw (state) {
      state.streamingContext.seekTimeline(state.timeline, state.streamingContext.getTimelineCurrentPosition(state.timeline), NvsVideoPreviewSizeModeEnum.LiveWindowSize, 0)
    },
    changeRatio (state, value) {
      const ratioTemp = state.ratioMap.find(i => i.key === value)
      if (!ratioTemp) return
      state.ratio = ratioTemp.ratio
      state.ratioKey = ratioTemp.key
    },
    setSafeFrameSize (state, size) {
      state.safeFrameSize = size
    }
  },
  actions: {
    monitorStopReady ({state}) {
      return state.streamingContext.streamingEngineReadyForTimelineModification()
    }
  }
}
