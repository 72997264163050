import { get, post, delet } from '@/utils/request'
import NvNetwork from '@/api/network/NvNetwork'

export default {
  
  material_list_private: ({ ...props }) =>
    get({
      url: NvNetwork.data().webApi_material_listPrivate,
      headers: {
        params: { ...props }
      }
    }),
  
  material_list_authed: ({ ...props }) =>
    get({
      url: NvNetwork.data().webApi_material_listAuthed,
      headers: {
        params: { ...props }
      }
    }),
  
  material_list_public: ({ ...props }) =>
    get({
      url: NvNetwork.data().webApi_material_listAll,
      headers: {
        params: { ...props }
      }
    }),
  
  material_listTypeAndCategory: ({ ...props }) =>
    get({
      url: NvNetwork.data().webApi_material_listTypeAndCategory,
      headers: {
        params: { ...props }
      }
    }),

}
