const ChromeBrowserMinCompleteVersionSupported = '75.0.3770.142'
const SafariMinSupportedVersion = '15.2'

// root.vue 中 拖拽\框架逻辑
export default {
  data() {
    return {
      isEquipmentSupport: false,
      isBrowserSupport: false,
      isBrowserVersionSupport: false,
      isBrowserParamsSupport: true,
      isBrowserModeSupport: true, // 表示当前浏览打开模式是否支持 【 Firefox 隐私模式不支持 】
      updateParamsIndex: 0,
      version: 'an unknown version',
      browser: 'An unknown browser',
      OS: 'an unknown OS',
      dataMobileOS: ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'],
      dataOS: [
        {
          string: navigator.platform,
          subString: 'Win',
          identity: 'Windows'
        },
        {
          string: navigator.platform,
          subString: 'Mac',
          identity: 'Mac'
        },
        {
          string: navigator.userAgent,
          subString: 'iPhone',
          identity: 'iPhone/iPod'
        },
        {
          string: navigator.platform,
          subString: 'Linux',
          identity: 'Linux'
        }
      ],
      dataBrowser: [
        {
          string: navigator.userAgent,
          subString: 'Chrome',
          identity: 'Chrome'
        },
        {
          string: navigator.userAgent,
          subString: 'OmniWeb',
          versionSearch: 'OmniWeb/',
          identity: 'OmniWeb'
        },
        {
          string: navigator.vendor,
          subString: 'Apple',
          identity: 'Safari',
          versionSearch: 'Version'
        },
        {
          prop: window.opera,
          identity: 'Opera'
        },
        {
          string: navigator.vendor,
          subString: 'iCab',
          identity: 'iCab'
        },
        {
          string: navigator.vendor,
          subString: 'KDE',
          identity: 'Konqueror'
        },
        {
          string: navigator.userAgent,
          subString: 'Firefox',
          identity: 'Firefox'
        },
        {
          string: navigator.vendor,
          subString: 'Camino',
          identity: 'Camino'
        },
        {
          // for newer Netscapes (6+)
          string: navigator.userAgent,
          subString: 'Netscape',
          identity: 'Netscape'
        },
        {
          string: navigator.userAgent,
          subString: 'MSIE',
          identity: 'Explorer',
          versionSearch: 'MSIE'
        },
        {
          string: navigator.userAgent,
          subString: 'Gecko',
          identity: 'Mozilla',
          versionSearch: 'rv'
        },
        {
          // for older Netscapes (4-)
          string: navigator.userAgent,
          subString: 'Mozilla',
          identity: 'Netscape',
          versionSearch: 'Mozilla'
        }
      ]
    }
  },
  created() {
    this.detectBrowser()
    this.isEquipmentSupport = this.detectPCOS()

    console.log('你正在使用的是: ' + this.browser + ' ' + this.version + ' on ' + this.OS + '!')
    console.log('isSupportSharedArrayBuffer:', this.isSupportSharedArrayBuffer() ? 'yes' : 'no')
    console.log('isSupportWebassembly:', this.isSupportWebassembly() ? 'yes' : 'no')
    console.log('isChrome:', this.isChrome() ? 'yes' : 'no')
    console.log('isFirefox:', this.isFirefox() ? 'yes' : 'no')
    console.log('isEdge:', this.isEdge() ? 'yes' : 'no')
    console.log('isIE:', this.isIE() ? 'yes' : 'no')
    console.log('isIE11:', this.isIE11() ? 'yes' : 'no')
    console.log('isOpera:', this.isOpera() ? 'yes' : 'no')
    console.log('isSafari:', this.isSafari() ? 'yes' : 'no')

    //其他浏览器
    if (!this.isElseBrowser()) {
      this.isBrowserSupport = false
    } else {
      this.isBrowserSupport = true
      //opera浏览器
      if (this.isOpera()) {
        this.updateParamsIndex = 3
        if (this.browser === 'Chrome' && this.isVersionHeigherThanDefault()) {
          this.isBrowserVersionSupport = true
          this.isBrowserParamsSupport = this.isSupportSharedArrayBuffer() && this.isSupportWebassembly()
          console.log('isBrowserParamsSupport:', this.isBrowserParamsSupport)
        } else {
          //升级到最新版本
          this.isBrowserVersionSupport = false
        }
        //火狐浏览器
      } else if (this.isFirefox()) {
        if (this.browser === 'Chrome') {
          this.updateParamsIndex = 2
          if (this.isVersionHeigherThanDefault()) {
            this.isBrowserVersionSupport = true
            this.isBrowserParamsSupport = this.isSupportSharedArrayBuffer() && this.isSupportWebassembly()
          } else {
            //升级到最新版本
            this.isBrowserVersionSupport = false
          }
        } else {
          this.updateParamsIndex = 2
          if (this.version >= 57) {
            //这里，正式版Firefox 72，即便网站设置了相应的header，并且通过about:config设置了相应的选项，仍然无法正常使用
            if (this.version >= 72) {
              this.isBrowserSupport = false
            } else {
              this.isBrowserVersionSupport = true
              this.isBrowserParamsSupport = this.isSupportSharedArrayBuffer() && this.isSupportWebassembly()
            }
          } else {
            this.isBrowserVersionSupport = false
          }
          if (this.version >= 80) {
            this.isBrowserSupport = true
            this.isBrowserVersionSupport = true
            this.isBrowserParamsSupport = this.isSupportSharedArrayBuffer() && this.isSupportWebassembly()
          }
        }
        // 检查Firefox 是否打开隐私模式
        //TODO 暂时先这样【Firefox隐私模式不支持indexDB】，没找到更好的检查方法
        const checkDBName = "check-browser-open-mode-test"
        const db = indexedDB.open(checkDBName);
        db.onerror = () => {
          this.isBrowserModeSupport = false
        };
        indexedDB.deleteDatabase(checkDBName)

        //edge浏览器
      } else if (this.isEdge() || this.isIE() || this.isIE11()) {
        this.updateParamsIndex = 4
        if (this.browser === 'Chrome' && this.isVersionHeigherThanDefault(79)) {
          this.isBrowserVersionSupport = true
          this.isBrowserParamsSupport = this.isSupportSharedArrayBuffer() && this.isSupportWebassembly()
        } else {
          //升级到最新版本
          this.isBrowserVersionSupport = false
        }
      } else if (this.isSafari()) {
        // safari
        this.isBrowserSupport = false
        // this.updateParamsIndex = 5
        // if(this.isVersionHeigherThanDefault(SafariMinSupportedVersion)) {
        //   this.isBrowserVersionSupport = true
        //   this.isBrowserParamsSupport = this.isSupportSharedArrayBuffer() && this.isSupportWebassembly()
        // } else {
        //   this.isBrowserVersionSupport = false
        // }
      } else {
        //Chrome和360
        if (window.clientInformation && window.clientInformation.languages && window.clientInformation.languages.length >= 2) {
          this.updateParamsIndex = 0
          if (this.browser === 'Chrome' && this.isVersionHeigherThanDefault()) {
            this.isBrowserVersionSupport = true
            this.isBrowserParamsSupport = this.isSupportSharedArrayBuffer() && this.isSupportWebassembly()
          } else {
            //升级到最新版本
            this.isBrowserVersionSupport = false
          }
        } else {
          this.is360 = true
          this.updateParamsIndex = 1
          if (this.browser === 'Chrome' && this.isVersionHeigherThanDefault()) {
            this.isBrowserVersionSupport = true
            this.isBrowserParamsSupport = this.isSupportSharedArrayBuffer() && this.isSupportWebassembly()
          } else {
            //升级到最新版本
            this.isBrowserVersionSupport = false
          }
        }
      }
    }

    console.log('设备是否支持：', this.isEquipmentSupport)
    console.log('浏览器是否支持：', this.isBrowserSupport)
    console.log('浏览器版本是否支持：', this.isBrowserVersionSupport)
    console.log('浏览器参数是否支持：', this.isBrowserParamsSupport)
  },
  methods: {
    isVersionHeigherThanDefault (defaultVersion = ChromeBrowserMinCompleteVersionSupported) {
      const defaultDigitArr = defaultVersion.split('.')
      const currentDigitArr = String(this.version).split('.')
      if (defaultDigitArr.length && currentDigitArr.length) {
        if (Number(currentDigitArr[0]) > Number(defaultDigitArr[0])) {
          return true
        } else {
          if (this.version.localeCompare(defaultVersion) >= 0) {
            return true
          } else {
            return false
          }
        }
      } else {
        return false
      }
    },
    isSupportSharedArrayBuffer() {
      return typeof SharedArrayBuffer !== 'undefined'
    },
    isSupportWebassembly() {
      if (this.isIE() || this.isIE11()) {
        return false
      }
      return typeof WebAssembly !== 'undefined'
    },
    // 判断当前浏览器是否可用
    isElseBrowser() {
      let ua = navigator.userAgent.toLocaleLowerCase()
      let browserType = null
      let itBrowserNoSupport = true
      if (ua.match(/ubrowser/) != null) {
        browserType = 'UC'
        itBrowserNoSupport = false
      } else if (ua.match(/bidubrowser/) != null) {
        browserType = '百度'
        itBrowserNoSupport = false
      } else if (ua.match(/metasr/) != null) {
        browserType = '搜狗'
        itBrowserNoSupport = false
      } else if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
        browserType = 'QQ'
        itBrowserNoSupport = false
      } else if (ua.match(/maxthon/) != null) {
        browserType = '遨游'
        itBrowserNoSupport = false
      } else if (ua.match(/chrome/) != null) {
        browserType = 'chrome'
        itBrowserNoSupport = true
      }
      console.log('is current Brower :', browserType)
      return itBrowserNoSupport
    },
    detectPCOS: function () {
      let userAgentInfo = navigator.userAgent
      let flag = true
      for (let v = 0; v < this.dataMobileOS.length; v++) {
        if (userAgentInfo.indexOf(this.dataMobileOS[v]) > 0) {
          this.OS = this.dataMobileOS[v]
          flag = false
          break
        }
      }
      if (flag) {
        this.OS = this.searchString(this.dataOS) || 'an unknown OS'
      }
      return flag
    },
    detectBrowser: function () {
      this.browser = this.searchString(this.dataBrowser) || 'An unknown browser'
      this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || 'an unknown version'
    },
    searchVersion: function (dataString) {
      var index = dataString.indexOf(this.versionSearchString)
      if (index == -1) return
      if (this.versionSearchString === 'Chrome') {
        let versionStringToEnd = dataString.substring(index + this.versionSearchString.length + 1)
        let versionStringOnly = versionStringToEnd.split(' ')[0]
        console.log('Chrome browser complete version is ', versionStringOnly)
        return versionStringOnly
      } else {
        return dataString.substring(index + this.versionSearchString.length + 1)
      }
    },
    searchString: function (data) {
      for (var i = 0; i < data.length; i++) {
        var dataString = data[i].string
        var dataProp = data[i].prop
        this.versionSearchString = data[i].versionSearch || data[i].identity
        if (dataString) {
          if (dataString.indexOf(data[i].subString) != -1) {
            return data[i].identity
          }
        } else if (dataProp) {
          return data[i].identity
        }
      }
    },

    isChrome() {
      return !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
    },
    isFirefox() {
      return typeof InstallTrigger !== 'undefined'
    },
    isEdge() {
      var isIE = /*@cc_on!@*/ false || !!document.documentMode
      return !isIE && !!window.StyleMedia
    },
    isOpera() {
      return (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0
    },
    isIE() {
      var userAgent = navigator.userAgent //取得浏览器的userAgent字符串
      return userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1
    },
    isIE11() {
      var userAgent = navigator.userAgent //取得浏览器的userAgent字符串
      return userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1
    },
    isSafari() {
      return /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
    }
  }
}
