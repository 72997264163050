import axios from 'axios'
// 下载素材
function downloadData (url, responseType, callback) {
  const option = responseType ? { responseType } : {}
  if (callback && typeof callback === 'function') option.onDownloadProgress = callback
  return new Promise((resolve, reject) => {
    axios.get(url, option).then(res => {
      resolve(res.data)
    }).catch(reject)
  })
}

/* 模板 */
/**
 * 获取模板列表
 * @param {string} lang 获取素材语言 zh_CN/en
 */
function getTemplate ({ pageNum, pageSize, lang, keyword }) {
  const params = {
    type: 19,
    pageNum,
    pageSize,
    lang: lang || 'zh_CN',
    keyword
  }
  return axios.get('https://vsapi.meishesdk.com/api/my/template/listTemplatesForEditor', { params })
}
function getTags () {
  return axios.get('https://vsapi.meishesdk.com/api/editor/template/listGeneralTags')
}
export function getClassify (lang) {
  const params = {
    type: 19,
    lang: lang || 'zh_CN'
  }
  return axios.get('https://vsapi.meishesdk.com/api/my/template/listCategories', { params })
}
export default {
  // 视频相关
  downloadData,
  // 模板
  getTemplate,
  getClassify,
  getTags
}
