import router from './router'
import store from './store'
import { getToken, setToken } from '@/utils/auth' // get token from cookie
import Vue from 'vue'
import axios from 'axios'

// no redirect whitelist
const whiteList = [
  '/Login',
  '/Register',
  '/GetBackPassword',
  window.$nvsConfig.backPath
]

const loginUrl = '/Login'

router.beforeEach(async (to, from, next) => {
  try {
    if (process.env.NODE_ENV !== 'development') {
      const response = await axios.get(`/${process.env.APP_VERSION}/static/versionfile.json?_=` + Math.random())
      if (200 === response.status && process.env.VERSION !== response.data.version) {
        const message = "系统版本有更新，点击确认加载最新，或按【CTRL + F5】！"
        Vue.prototype.$alert(message, '系统提示', {
          confirmButtonText: '确定',
          callback () {
            window.location.reload(true)
          }
        })
        return
      }
    }
  } catch (err) {
    console.error(err);
  } finally {
    // determine whether the user has logged in
    const hasToken = getToken()
    if (hasToken) {
      if (
        store.getters.userInfo.userId
      ) {
        next()
      } else {
        try {
          await store.dispatch('user/getUserInfo')
          if (whiteList.includes(to.path)) {
            next({
              name: 'ProjectList'
            })
          } else {
            next()
          }
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken')
          next({
            name: 'Login',
            replace: true
          })
        }
      }
    } else {
      /* has no token */
  
      const { token, projectName, resolution } = to.query
      // projectName/resolution 创建新工程使用
      if (token) setToken(token)
      if (projectName) sessionStorage.nvsprojectname = projectName
      if (resolution) sessionStorage.nvsResolution = resolution
  
      if (whiteList.indexOf(to.path) !== -1) {
        // in the free login whitelist, go directly
        next()
      } else {
        sessionStorage.clear()
        // other pages that do not have permission to access are redirected to the login page.
        if (token && window.$nvsConfig && window.$nvsConfig.backPath) {
          next({ path: window.$nvsConfig.backPath })
        } else {
          next({name: 'Login'})
        }
      }
    }
  }
})

router.afterEach(() => {
})

router.onError(error => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed) {
    console.error('缓存未清理，需要重新加载...')
    router.replace(targetPath)
  }
})
