import i18n from '@/i18n'

const validLengthRange = (rule, value, callback, displayName, minLength, maxLength) => {
  if ((minLength > -1) && (value.length < minLength)) {
    callback(new Error(`${displayName}${i18n.t('valid.minLength')}${minLength}`))
  } else if ((maxLength > -1) && (value.length > maxLength)) {
    callback(new Error(`${displayName}${i18n.t('valid.maxLength')}${maxLength}`))
  } else {
    callback()
  }
}

const validLength = (rule, value, callback, displayName, length) => {
  if (value.length !== length) {
    callback(new Error(`${displayName}${i18n.t('valid.lengthIs')}${length}`))
  } else {
    callback()
  }
}

const validPasswordPattern = (rule, value, callback) => {
  // 必须同时包含数字和字母
  // const reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])[A-Za-z0-9]+$/
  const reg = /^[a-zA-Z0-9]+$/
  if (!reg.test(value)) {
    callback(new Error(`${i18n.t('valid.notRightPasswordPattern')}`))
  } else {
    callback()
  }
}

const validEmailPattern = (rule, value, callback) => {
  const reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
  if (!reg.test(value)) {
    callback(new Error(`${i18n.t('valid.notRightEmailPattern')}`))
  } else {
    callback()
  }
}

const validPhonePattern = (rule, value, callback) => {
  // const reg = /^1[3456789]\d{9}$/
  const allNumber = value.match(/\d{1}/g)
  if (i18n.locale === 'zh' && allNumber.length !== 11) {
    callback(new Error(`${i18n.t('valid.notRightPhonePattern')}`))
  } else {
    callback()
  }
}

export default {
  /**
   * 校验字符串： 非空
   * @param displayName 错误提示展示的字段名
   */
  notNull: (displayName, message) => {
    return { required: true, message: message || `${displayName}${i18n.t('valid.notNull')}` }
  },

  /**
   * 校验字符串： 非空 且 长度区间
   * @param displayName 错误提示展示的字段名
   * @param minLength   最小长度（-1表示不限制）
   * @param maxLength   最大长度（-1表示不限制）
   */
  lengthRange: (displayName, minLength, maxLength) => {
    return {
      validator: (rule, value, callback) => validLengthRange(rule, value, callback, displayName, minLength, maxLength)
    }
  },

  isPasswordPattern: () => {
    return {
      validator: (rule, value, callback) => validPasswordPattern(rule, value, callback)
    }
  },

  /**
   * 校验字符串： 非空 且 长度等于
   * @param displayName 错误提示展示的字段名
   * @param length   长度值
   */
  length: (displayName, length) => {
    return {
      validator: (rule, value, callback) => validLength(rule, value, callback, displayName, length)
    }
  },

  isEmailPattern: () => {
    return {
      validator: (rule, value, callback) => validEmailPattern(rule, value, callback)
    }
  },

  isPhonePattern: () => {
    return {
      validator: (rule, value, callback) => validPhonePattern(rule, value, callback)
    }
  },

  isImageRatioAndSizeVerified: async (file, expectedSize, expectedRatio) => {
    const isLtSize = file.size / 1024 / 1024 < expectedSize
    const isRatio = await new Promise(function (resolve, reject) {
      const [width, height] = expectedRatio.split(':')
      const _URL = window.URL || window.webkitURL
      const img = new Image()
      img.onload = function () {
        const valid = (img.width / img.height) === (width / height)
        resolve(valid)
      }
      img.src = _URL.createObjectURL(file)
    })
    return {
      isLtSize,
      isRatio
    }
  },

  isVideoRatioAndSizeVerified: async (file, expectedSize, expectedRatio) => {
    const isLtSize = file.size / 1024 / 1024 < expectedSize
    const isRatio = await new Promise(function (resolve, reject) {
      const [expectedWidth, expectedHeight] = expectedRatio.split(':')
      const _URL = window.URL || window.webkitURL
      const videoElement = document.createElement('video')
      // 当指定的音频/视频的元数据已加载时，会发生 loadedmetadata 事件。 元数据包括：时长、尺寸（仅视频）以及文本轨道。
      videoElement.addEventListener('loadedmetadata', function () {
        const width = videoElement.videoWidth
        const height = videoElement.videoHeight
        // const duration = videoElement.duration // 视频时长
        // if (Math.floor(duration) >= 30) return operatTip('error', '上传视频时长不能超过 30S！')
        const valid = (width / height) === (expectedWidth / expectedHeight)
        resolve(valid)
      })
      videoElement.src = _URL.createObjectURL(file)
    })
    return {
      isLtSize,
      isRatio
    }
  }
}
