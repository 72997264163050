// 用于模板功能
import EventBus from '@/EventBus'
import EventBusKey from '@/api/EventBusKey'
import Utils, { escapeXmlValue } from '@/api/Utils'
import NvNetwork from '../api/network/NvNetwork'
import TimelineToXml from '@/utils/TimelineToXml'
import GL from '@/api/Global'
import { checkAndInstallAssetFromIndexDB, saveAssetToIndexDB } from '@/api/AssetsUtils'
import apis from '@/api/apis'
import Enum from '@/utils/enum'
import { resumeAudio } from '@/utils/NvBase'

var mStreamingContext
var mainTimeline = null
let playbackStopCallback

var logId = 0
var logBeginId = 0
var logMaxNum = 5000
var db
var objectStoreName = 'logs'

function openIndexDB () {
  var request = window.indexedDB.open('consoleLogData', 3)
  request.onerror = function (e) {
    console.error('open index db error!')
  }
  request.onsuccess = function (e) {
    db = e.target.result
    console.log('index db version:' + db.version)
    if (db.objectStoreNames.contains(objectStoreName)) {
      var transaction = db.transaction(objectStoreName, 'readwrite')
      var store = transaction.objectStore(objectStoreName)
      store.clear()
    }
  }
  request.onupgradeneeded = function (e) {
    var ret = e.target.result
    if (!ret.objectStoreNames.contains(objectStoreName)) {
      ret.createObjectStore(objectStoreName, {keyPath: 'id'})
    } else {
      var transaction = db.transaction(objectStoreName, 'readwrite')
      var store = transaction.objectStore(objectStoreName)
      store.clear()
    }
    console.log('DB version changed to 3')
  }
}

function saveLogToIndexDB (...args) {
  var transaction = db.transaction(objectStoreName, 'readwrite')
  var store = transaction.objectStore(objectStoreName)
  if (logId > logMaxNum) {
    for (var i = logBeginId; i <= logId - logMaxNum; i++) {
      store.delete(i)
      logBeginId++
    }
  }
  var data = {
    'id': logId++,
    'data': args
  }
  store.put(data)
}

function initSDK () {
  return new Promise(async (resolve, reject) => {
    var wasmLoader = WASMLoader({
      showError (errorText) {
        reject(new Error('wasm 加载失败' + errorText))
      },
      loadingFinished () {
        wasmLoaded(resolve, reject)
        openIndexDB()
      }
    })
    let wasm = await Utils.getWasmModuleName()
    wasmLoader.loadEmscriptenModule(wasm, false, true)
  })
}

async function wasmLoaded (resolve, reject) {
  await ensureModule()
  resumeAudio()
  FS.mkdir(GL.getFSM3u8Folder())
  FS.mkdir(GL.getFSFontFolder())
  FS.mkdir(GL.getTemplateFolder())
  const streamingContext = nvsGetStreamingContextInstance()
  if (!Utils.needLowerMemory()) {
    streamingContext.setMaxImageReaderCount(16)
  }
  streamingContext.onWebRequestAuthFinish = (success) => {
    if (!success) {
      reject(new Error('SDK鉴权失败'))
    }
    resolve()
  }
  streamingContext.verifySdkLicenseFile(Utils.getAuthUrl())
}
function ensureModule () {
  const poll = (resolve, reject) => {
    if (Module.Meishe !== undefined && Module.Meishe.streamingContext !== undefined) {
      resolve()
    } else {
      setTimeout(_ => poll(resolve, reject), 400)
    }
  }
  return new Promise(poll)
}
function getTemplateRootDir (templateId) {
  const packageManager = mStreamingContext.getAssetPackageManager()
  return `${packageManager.getAssetPackageRootDir()}/template/${templateId}`
}
// var cur = 0
function initContext () {
  mStreamingContext = nvsGetStreamingContextInstance()
  resumeAudio()
  mStreamingContext.onWebRequestWaitStatusChange = (isVideo, waiting) => {
    EventBus.$emit(EventBusKey.onWebRequestWaitStatusChange, waiting)
  }

  mStreamingContext.onStreamingEngineStateChanged = (state) => {

  }

  mStreamingContext.onPlaybackTimelinePosition = (timeline, position) => {
    // let t = parseInt(position / 1000000)
    // if (t !== cur) {
    //   cur = t
    //   console.log(t, parseInt(timeline.getDuration() / 1000000))
    // }
    EventBus.$emit(EventBusKey.play, position, timeline)
  }

  mStreamingContext.onPlaybackStopped = (timeline) => {
    if (playbackStopCallback && typeof playbackStopCallback === 'function') playbackStopCallback()
    EventBus.$emit(EventBusKey.onPlaybackStopped, timeline)
  }

  mStreamingContext.onPlaybackEOF = (timeline) => {
    // console.log('onPlaybackEOF')
  }

  mStreamingContext.getAssetPackageManager().onFinishAssetPackageInstallation = (assetPackageId, assetPackageFilePath, assetPackageType, error) => {
    EventBus.$emit(EventBusKey.onFinishAssetPackageInstallation + assetPackageFilePath, assetPackageId, assetPackageFilePath, assetPackageType, error)
  }
}
function createTimeline (width, height) {
  let resolution = new NvsVideoResolution(width, height)
  return mStreamingContext.createTimeline(resolution, new NvsRational(25, 1), new NvsAudioResolution(44100, 2))
}

function connectLiveWindow (timeline, canvasId) {
  const mLiveWindow = mStreamingContext.createLiveWindow(canvasId)
  mLiveWindow.setFillMode(NvsLiveWindowFillModeEnum.PreserveAspectFit)
  mStreamingContext.connectTimelineWithLiveWindow(timeline, mLiveWindow)
  return mLiveWindow
}
function removeLiveWindow (w) {
  if (!mStreamingContext) {
    console.error('StreamingContext is null')
    return
  }
  mStreamingContext.removeLiveWindow(w)
}
function context () {
  return mStreamingContext
}
function seekTimeline (timeline, nowTime) {
  if (!timeline) {
    console.error('mTimeline is null')
    return
  }
  nowTime = isNaN(nowTime) ? mStreamingContext.getTimelineCurrentPosition(timeline) : nowTime
  mStreamingContext.seekTimeline(timeline, nowTime, NvsVideoPreviewSizeModeEnum.LiveWindowSize, 0)
}
function play (timeline, t) {
  if (timeline && mStreamingContext) {
    let nowTime = t === undefined ? mStreamingContext.getTimelineCurrentPosition(timeline) : t
    if (nowTime === timeline.getDuration()) {
      nowTime = 0
    }
    let videoRes = timeline.getVideoRes()
    const ratio = 1080 / 3
    let imageSize = Math.min(videoRes.imageHeight, videoRes.imageWidth)
    let proxy = new NvsRational(1, 1)
    if (imageSize > ratio) {
      proxy.num = ratio
      proxy.den = imageSize
    }
    // mStreamingContext.playbackTimeline(timeline, nowTime, -1, NvsVideoPreviewSizeModeEnum.LiveWindowSize, true, 0)
    mStreamingContext.playbackTimelineWithProxyScale(timeline, nowTime, -1, proxy, true, 0)
  }
}
function stop () {
  if (!mStreamingContext) {
    console.error('streamingContext is null')
    return
  }
  return new Promise((resolve, reject) => {
    mStreamingContext.stop()
    playbackStopCallback = resolve
    setTimeout(reject, 5000)
  })
}
function playStatus (timeline) {
  return mStreamingContext.getStreamingEngineState()
}
function getAVFileInfo (m3u8Path) {
  if (mStreamingContext) {
    return mStreamingContext.getAVFileInfo(m3u8Path, 0)
  }
  return null
}
function setMainTimeline (tl) {
  mainTimeline = tl
}
function getCoverUrl (timeline = mainTimeline, time = 0, uploadOptions) {
  timeline = timeline || mainTimeline
  time = time || 0
  return new Promise(async (resolve, reject) => {
    const file = await getCoverData(timeline, time)
    const uuid = Utils.generateUUID()
    // 上传封面
    let uploadModule, projectId
    if (uploadOptions) {
      uploadModule = uploadOptions.uploadModule
      projectId = uploadOptions.projectId
    }
    NvNetwork.uploadAliOss({
      projectId,
      extension: 'jpg',
      uploadModule: uploadModule || Enum.uploadModule.image,
      keyUUID: uuid,
      file,
      callback: data => {
        if (data.uploadFinish) {
          let videoUrl = data.Location
          // 偶发 上传封面后, img标签获取图片失败
          setTimeout(() => {
            resolve('https://' + videoUrl)
          }, 500)
        }
      },
      errorCb: err => {
        console.error('封面上传失败', err)
        reject(err)
      }
    })
  })
}
function getCoverData (timeline = mainTimeline, time = 0) {
  return new Promise((resolve, reject) => {
    timeline = timeline || mainTimeline
    time = time || 0
    if (!timeline || !mStreamingContext) {
      console.error('timeline or StreamingContext is null')
      reject(new Error('timeline or StreamingContext is null'))
    }
    let timer = setTimeout(() => {
      console.error('获取封面超时')
      resolve('')
    }, 30000)
    mStreamingContext.onImageGrabbedArrived = (imageData, time) => {
      if (timer) clearTimeout(timer)
      mStreamingContext.onImageGrabbedArrived = null
      var array = new Uint8Array(imageData)
      resolve(new File([array], 'cover.jpg'))
    }
    mStreamingContext.grabImageFromTimeline(timeline, time, new NvsRational(1, 1), 0)
  })
}
function upload (options) {
  return new Promise((resolve, reject) => {
    NvNetwork.uploadAliOss({
      extension: options.extension,
      uploadModule: options.uploadModule,
      keyUUID: options.keyUUID || Utils.generateUUID(),
      file: options.file,
      projectId: options.projectId,
      callback: data => {
        if (data.uploadFinish) {
          const videoUrl = data.Location
          resolve('https://' + videoUrl)
        }
      },
      errorCb: reject
    })
  })
}
function writeXml (path, noCaptions = false) {
  path = path || `${localStorage.nvsprojectid}.xml`
  TimelineToXml(mainTimeline, path, noCaptions)
}
function installTemplate (tempUrl, tempId) {
  const url = tempUrl
  let packageName = `${tempId}.template`
  return new Promise((resolve, reject) => {
    checkAndInstallAssetFromIndexDB(packageName).then(() => {
      resolve()
    }).catch(async () => {
      try {
        const data = await apis.downloadData(url, 'arraybuffer')
        console.log(data, '模板数据')
        await saveAssetToIndexDB(packageName, new Uint8Array(data))
        await checkAndInstallAssetFromIndexDB(packageName)
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  })
}
function installInteractProject (url, uuid) {
  const filePath = '/' + uuid + '.project'
  return new Promise(async (resolve, reject) => {
    try {
      const data = await apis.downloadData(url, 'arraybuffer')
      FS.writeFile(filePath, new Uint8Array(data))
      EventBus.$on(EventBusKey.onFinishAssetPackageInstallation + filePath, function slot(assetPackageId, assetPackageFilePath, assetPackageType, error) {
        console.log(filePath, '安装完成回调')
        FS.unlink(assetPackageFilePath, 0)
        EventBus.$off(EventBusKey.onFinishAssetPackageInstallation + filePath, slot)
        if (error === 0) resolve(url)
        else reject(new Error(`资源安装失败${url} 错误码: ${error}`))
      })
      nvsGetStreamingContextInstance().getAssetPackageManager().installAssetPackage(filePath, '', Enum.assetPackageType['project'])
    } catch (error) {
      reject(error)
    }
  })
}
function getCurrentTimelinePosition (timeline) {
  if (timeline && mStreamingContext) {
    return mStreamingContext.getTimelineCurrentPosition(timeline)
  }
  return 0
}
function createTemplateTimeline (templateId, clipList) {
  if (!mStreamingContext) {
    console.error('StreamingContext is null')
    return
  }
  const vectorVal = new NvsVectorVal()
  clipList.map(({id, m3u8Path}) => {
    vectorVal.push_back({ id, path: m3u8Path || ':/footage/default_black.png' })
  })
  const timeline = mStreamingContext.createTemplateTimeline(templateId, vectorVal)
  mainTimeline = timeline
  return timeline
}
function getTemplateFootages (templateId, aspectRatio) {
  if (!mStreamingContext) {
    console.error('StreamingContext is null')
    return
  }
  mStreamingContext.getAssetPackageManager().changeTemplateAspectRatio(templateId, aspectRatio)
  return mStreamingContext.getAssetPackageManager().getTemplateFootages(templateId)
}
function fillTemplateCaptions(captions, templateCaptions) {
  for (let i = 0; i < templateCaptions.size(); i++) {
    let item = templateCaptions.get(i)
    if (item.timelineClipCaptions.size() > 0) {
      fillTemplateCaptions(captions, item.timelineClipCaptions)
    } else {
      captions.push(item)
    }
  }
}
function getTemplateCaptions (templateId) {
  if (!mStreamingContext) {
    console.error('StreamingContext is null')
    return
  }
  const templateCaptions = mStreamingContext.getAssetPackageManager().getTemplateCaptions(templateId)
  if (!templateCaptions) {
    console.warn('获取模板字幕失败')
    return []
  }
  const captions = []
  fillTemplateCaptions(captions, templateCaptions)
  return captions
}
function removeTimeline (timeline) {
  if (!mStreamingContext) {
    console.error('StreamingContext is null')
    return
  }
  mStreamingContext.removeTimeline(timeline)
}
function uploadNoReplaceFootage (templateId, noReplaceTempList) {
  return new Promise(resolve => {
    const dir = mStreamingContext.getAssetPackageManager().getTemplatePackageDirPath(templateId)
    const files = new Map()
    noReplaceTempList.forEach(item => {
      const path = `${dir}/${item.name}`
      if(item.type !== NvsFootageTypeEnum.Audio) {
        const trackIndex = item.clipInfos[0].trackIndex
        const clipIndex = item.clipInfos[0].clipIndex
        const track = mainTimeline.getVideoTrackByIndex(trackIndex)
        const clip = track.getClipByIndex(clipIndex)
        const rawFx = clip.getRawFxByIndex(0)
        const alphaPath = rawFx?.getStringVal('Alpha File')
        if(alphaPath) {
          const name = alphaPath.substring(alphaPath.lastIndexOf('/') + 1)
          try {
            files.set(path, {
              alpha: new File([FS.readFile(`${dir}/${name}`)], name)
            })
          } catch (error) {
            console.error(name, '上传不可替换素材, 文件读取失败', path)
          }
        }
      }
      try {
        files.set(path, {
          ...files.get(path),
          origin: new File([FS.readFile(path)], item.name)
        })
      } catch (error) {
        console.error(item.name, '上传不可替换素材, 文件读取失败', path)
      }
    })
    if (files.size) {
      EventBus.$emit(EventBusKey.uploadFiles, files) // 上传不可替换素材
      resolve('loading') // 需要上传, 返回等待
    } else {
      resolve('finish') // 不需要上传, 直接跳云剪辑
    }
    console.log('不可替换素材', files)
  })
}
function writeCompoundXml ({ footages, templateId, sizeLevel, path, captions, duration, aspectRatio }) {
  const xmlStreamWriter = new NvsXmlStreamWriter(path || `compound_${GL.getProjectId()}.xml`)
  if (!xmlStreamWriter.open()) {
    console.error('xmlStreamWriter open failed!')
    return
  }
  xmlStreamWriter.writeStartDocument()
  xmlStreamWriter.writeStartElement('template')
  xmlStreamWriter.writeAttribute('templateId', '' + templateId)
  // xmlStreamWriter.writeAttribute('sizeLevel', '' + sizeLevel)
  xmlStreamWriter.writeAttribute('duration', '' + duration)
  xmlStreamWriter.writeAttribute('aspectRatio', '' + aspectRatio)
  xmlStreamWriter.writeStartElement('footages')
  footages.map(footage => {
    xmlStreamWriter.writeStartElement('footage')
    xmlStreamWriter.writeAttribute('footageId', '' + footage.id)
    xmlStreamWriter.writeAttribute('path', '' + footage.path)
    xmlStreamWriter.writeAttribute('trimIn', '' + footage.trimIn)
    xmlStreamWriter.writeAttribute('volume', '' + (footage.volume === undefined ? 1 : footage.volume))
    // 不再处理zoom参数了，默认固定写1，为了合成器兼容使用
    xmlStreamWriter.writeAttribute('zoom', '1')
    xmlStreamWriter.writeAttribute('duration', '' + footage.duration)
    xmlStreamWriter.writeEndElement()
  })
  xmlStreamWriter.writeEndElement() // footages
  xmlStreamWriter.writeStartElement('captions')
  captions = captions || []
  captions.map(caption => {
    xmlStreamWriter.writeStartElement('caption')
    xmlStreamWriter.writeAttribute('text', '' + escapeXmlValue(caption.text))
    xmlStreamWriter.writeAttribute('id', '' + caption.replaceId)
    xmlStreamWriter.writeAttribute('font', '' + caption.fontFamily)
    xmlStreamWriter.writeEndElement() // caption
  })
  xmlStreamWriter.writeEndElement() // captions
  xmlStreamWriter.writeEndElement() // timeline
  xmlStreamWriter.writeEndDocument()
  xmlStreamWriter.close()
}

export default {
  openIndexDB,
  saveLogToIndexDB,
  initSDK,
  initContext,
  createTimeline,
  connectLiveWindow,
  removeLiveWindow,
  removeTimeline,
  seekTimeline,
  context,
  play,
  stop,
  playStatus,
  getAVFileInfo,
  getCoverUrl,
  setMainTimeline,
  upload,
  writeXml,
  installTemplate,
  installInteractProject,
  createTemplateTimeline,
  getTemplateFootages,
  getCurrentTimelinePosition,
  uploadNoReplaceFootage,
  writeCompoundXml,
  getTemplateCaptions,
  getCoverData,
  getTemplateRootDir
}
