var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "right-menu",
      style: _vm.styleObj,
      attrs: { id: "globalRightMenuDom" }
    },
    [
      _c(
        "ul",
        _vm._l(_vm.rightMenuList, function(item) {
          return _c("li", { key: item.id }, [
            _c(
              "div",
              {
                staticClass: "item",
                class: { disable: item.text.isDisabled },
                on: {
                  click: function($event) {
                    return _vm.handler(item)
                  }
                }
              },
              [
                _c("span", { staticClass: "text" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        item.text.content ? item.text.content : item.text
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "hotkey" }, [
                  _vm._v(_vm._s(item.text.hotkey))
                ])
              ]
            )
          ])
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }