// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import EventBus from './EventBus'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css/normalize.css'
import Global from './api/Global'
import TimelineUtils from './api/TimelineUtils'
import EventBusKey from './api/EventBusKey'
import NvNetwork from './api/network/NvNetwork'
import i18n from './i18n/'
import store from './store'
import Utils from './api/Utils'
import Root from './Root'
import './style/index.scss'
import './plugins/axios'
import '@/icons' // icon
import '@/permission' // permission control
import rules from './utils/rules'
import api from '@/http'
import Undo from '@/utils/Undo'
import rightMenu from "./directives/rightmenu/index";

Vue.prototype.commonRules = rules
Vue.prototype.$Global = Global
Vue.prototype.$EventBusKey = EventBusKey
Vue.prototype.$TimelineUtils = TimelineUtils
Vue.prototype.NvNetwork = NvNetwork
Vue.prototype.$Utils = Utils
Vue.prototype.$api = api
Vue.prototype.$appVersion = process.env.APP_VERSION
if (location.pathname.includes('CoverApp')) {
  Vue.prototype.permList = process.env.PERM_LIST_PICCAPTURE
} else {
  Vue.prototype.permList = process.env.PERM_LIST_DEFAULT
}
Global.PERM_LIST = Vue.prototype.permList

Global.undo = new Undo()
Vue.prototype.stack = new Global.undo.Stack()

Vue.config.productionTip = false
Vue.use(ElementUI, { size: 'small' })
Vue.use(rightMenu)

Object.defineProperties(Vue.prototype, {
  $bus: {
    get: function () {
      return EventBus
    }
  }
})
/* eslint-disable no-new */
export default new Vue({
  el: '#app',
  store,
  router,
  i18n,
  components: { Root },
  template: '<Root/>'
})
