export default {
  // 打开粒子运动轨迹设置 modal (videoFx, packageId) => {}
  // videoFx 粒子特效
  // packageId 粒子包uu-id
  'showParticleGraffitlPanelMonitorModal': 'showParticleGraffitlPanelMonitorModal',
  'showCompound': 'showCompound',
  'wasmLoadingFinish': 'wasmLoadingFinish',
  'wasmLoadingError': 'wasmLoadingError',
  'resourceClipModelClose':'resourceClipModelClose'
}
