import COS from 'cos-js-sdk-v5'
import AWS from 'aws-sdk'
import OSS from 'ali-oss'
import axios from 'axios'
import Utils from '../Utils'
import api from '@/http'
import vm from '@/main'
import { allWithProgress } from '@/utils'

let taskInfos = []

function getTaskInfos () {
  return taskInfos
}

function addTaskInfo (inTaskInfo) {
  taskInfos.push(inTaskInfo)
}

function removeTaskInfo (inTaskInfo) {
  let removeTaskInfo
  if (inTaskInfo.keyUUID !== undefined) {
    removeTaskInfo = taskInfos.find(param => param.keyUUID === inTaskInfo.keyUUID)
  } else if (inTaskInfo.jobId > 0) {
    removeTaskInfo = taskInfos.find(param => param.jobId === inTaskInfo.jobId)
  }
  if (removeTaskInfo) {
    taskInfos.splice(taskInfos.indexOf(removeTaskInfo), 1)
  }
}

/**
 * createdAt    jobId      jobType
 progress     projectId  resourceId
 resourceType status     workerId
 * @param inTaskInfo
 */
function updateTaskInfo (inTaskInfo) {
  let index = -1
  if (inTaskInfo.keyUUID !== undefined) {
    for (let i = 0; i < taskInfos.length; i++) {
      if (taskInfos[i].keyUUID === inTaskInfo.keyUUID) {
        index = i
        break
      }
    }
  } else if (inTaskInfo.jobId > 0) {
    for (let i = 0; i < taskInfos.length; i++) {
      if (taskInfos[i].jobId === inTaskInfo.jobId) {
        index = i
        break
      }
    }
  }
  if (!taskInfos[index]) return
  if (inTaskInfo.createdAt != null) {
    taskInfos[index].createdAt = inTaskInfo.createdAt
  }
  if (inTaskInfo.title != null) {
    taskInfos[index].title = inTaskInfo.title
  }
  if (inTaskInfo.jobId != null) {
    taskInfos[index].jobId = inTaskInfo.jobId
  }
  if (inTaskInfo.jobType != null) {
    taskInfos[index].jobType = inTaskInfo.jobType
  }
  if (inTaskInfo.progress != null) {
    taskInfos[index].progress = inTaskInfo.progress
  }
  if (inTaskInfo.projectId != null) {
    taskInfos[index].projectId = inTaskInfo.projectId
  }
  if (inTaskInfo.resourceId != null) {
    taskInfos[index].resourceId = inTaskInfo.resourceId
  }
  if (inTaskInfo.resourceType != null) {
    taskInfos[index].resourceType = inTaskInfo.resourceType
  }
  if (inTaskInfo.status != null) {
    taskInfos[index].status = inTaskInfo.status
  }
  if (inTaskInfo.workerId != null) {
    taskInfos[index].workerId = inTaskInfo.workerId
  }
  if (inTaskInfo.m3u8Url != null) {
    taskInfos[index].m3u8Url = inTaskInfo.m3u8Url
  }
  if (inTaskInfo.jobId != null) {
    taskInfos[index].m3u8LocalPath = inTaskInfo.m3u8LocalPath
  }
  if (inTaskInfo.keyUUID != null) {
    taskInfos[index].keyUUID = inTaskInfo.keyUUID
  }
  if (inTaskInfo.uploadType != null) {
    taskInfos[index].uploadType = inTaskInfo.uploadType
  }
}

let uploadRecordInfos = []
// {name: FS路径}
function getRecordInfos () {
  return uploadRecordInfos
}

function addRecordInfo (inTaskInfo) {
  uploadRecordInfos.push(inTaskInfo)
}

function removeRecordInfo (inTaskInfo) {
  let removeTaskInfo = uploadRecordInfos.find(param => param.name === inTaskInfo.name)
  if (removeTaskInfo) {
    uploadRecordInfos.splice(uploadRecordInfos.indexOf(removeTaskInfo), 1)
  }
}

let aliOssClient = null
function getAliOssClient () {
  return aliOssClient
}
function setAliOssClient (_aliOssClient) {
  aliOssClient = _aliOssClient
}

export default {
  getTaskInfos,
  addTaskInfo,
  removeTaskInfo,
  updateTaskInfo,
  getRecordInfos,
  addRecordInfo,
  removeRecordInfo,
  getAliOssClient,
  setAliOssClient,
  data () {
    return {
      // NOTE: 上传及打包任务相关
      webApi_sts_info: Utils.getApiHost('sts') + 'sts/info',
      webApi_sts_info2: Utils.getApiHost('sts') + 'upload/sts/info',
      webApi_sts_upload_local: Utils.getApiHost('sts') + 'upload/files',
      // webApi_job_info: Utils.getApiHost('job') + 'job/info',
      webApi_job_info: Utils.getApiHost('job') + 'job/batch/info',
      webApi_job_list: Utils.getApiHost('job') + 'job/list',
      webApi_job_delete: Utils.getApiHost('job') + 'job/delete',
      webApi_job_transcode: Utils.getApiHost('job') + 'job/transcode/createBatchJobs',

      // NOTE: 用户相关
      webApi_user_list: Utils.getApiHost('user') + 'user/list',
      webApi_userInfo: Utils.getApiHost('user') + 'usercenter/user/info',
      webApi_login: Utils.getApiHost('user') + 'usercenter/login',
      webApi_register: Utils.getApiHost('user') + 'usercenter/register',
      webApi_checkPhone: Utils.getApiHost('user') + 'usercenter/verify_code/check',
      webApi_checkEmail: Utils.getApiHost('user') + 'usercenter/user/check/email',
      webApi_checkPassword: Utils.getApiHost('user') + 'usercenter/user/update',
      webApi_forwardToEmail: Utils.getApiHost('user') + 'usercenter/verifyCode/forward/phone/to/email',
      webApi_judgeUserExist: Utils.getApiHost('user') + 'usercenter/user/exist',
      webApi_verify_code_create: Utils.getApiHost('user') + 'usercenter/verify_code/create',
      webApi_verify_code_image_create: Utils.getApiHost('user') + 'usercenter/verify_code_image/create',

      webApi_user_bind: Utils.getApiHost('user') + 'user/thirdPart/bind', // 绑定第三方账号
      webApi_user_unbind: Utils.getApiHost('user') + 'user/thirdPart/unbind', // 解绑第三方账号
      webApi_user_third_part_info: Utils.getApiHost('user') + 'user/thirdPart/info', // 查询绑定的第三方账号

      // NOTE: 资源相关
      webApi_resource_create: Utils.getApiHost('resource') + 'resource/create',
      webApi_resource_update: Utils.getApiHost('resource') + 'resource/update',
      webApi_resource_list: Utils.getApiHost('resource') + 'resource/list',
      webApi_resource_info: Utils.getApiHost('resource') + 'resource/info',
      webApi_resource_tag_info: Utils.getApiHost('resource') + 'resource/tag/info',
      webApi_resource_delete: Utils.getApiHost('resource') + 'resource/delete',
      webApi_resource_batch_delete: Utils.getApiHost('resource') + 'resource/batchDelete',
      webApi_resource_append: Utils.getApiHost('resource') + 'resource/append',
      webApi_resource_reverse: Utils.getApiHost('resource') + 'resource/reverse',

      // NOTE: 视频同步
      webApi_audio_align: Utils.getApiHost('audio') + 'resource/audio/compare',
      webApi_audio_result: Utils.getApiHost('audio') + 'resource/audio/cmpresult',

      // NOTE: 成片相关
      webApi_video_list: Utils.getApiHost('video') + 'video/list',
      webApi_video_delete: Utils.getApiHost('video') + 'video/delete',
      webApi_video_create: Utils.getApiHost('video') + 'video/create',
      webApi_video_share: Utils.getApiHost('video') + 'video/share',
      webApi_video_share_info: Utils.getApiHost('video') + 'video/share/info',

      // NOTE: 素材相关
      webApi_material_list: Utils.getApiHost('material') + 'material/list',
      webApi_material_update: Utils.getApiHost('material') + 'material/update',
      webApi_material_all: Utils.getApiHost('material') + 'materialcenter/material/all',
      webApi_material_categories: Utils.getApiHost('material') + 'material/categories',
      webApi_material_add: Utils.getApiHost('material') + 'material/add',
      webApi_material_private_update: Utils.getApiHost('material') + 'material/private/update',
      webApi_material_private_list: Utils.getApiHost('material') + 'material/private/list',
      webApi_material_listPrivate: Utils.getApiHost('material') + 'materialcenter/material/listPrivate',
      webApi_material_listAuthed: Utils.getApiHost('material') + 'materialcenter/material/listAuthed',
      webApi_material_listAll: Utils.getApiHost('material') + 'materialcenter/material/listAll',
      webApi_material_listTypeAndCategory: Utils.getApiHost('material') + 'material/listTypeAndCategory',
      webApi_material_arrearage: Utils.getApiHost('material') + 'materialcenter/material/arrearageMaterial',
      webApi_material_submitMaterial: Utils.getApiHost('material') + 'materialcenter/material/submitMaterial',

      // NOTE: 工程相关
      webApi_project_list: Utils.getApiHost('project') + 'project/list',
      webApi_project_info: Utils.getApiHost('project') + 'project/info',
      webApi_project_create: Utils.getApiHost('project') + 'project/create',
      webApi_project_create_empty: Utils.getApiHost('project') + 'project/create/empty',
      webApi_project_update: Utils.getApiHost('project') + 'project/update',
      webApi_project_delete: Utils.getApiHost('project') + 'project/delete',
      webApi_project_compile: Utils.getApiHost('project') + 'project/compile',
      webApi_project_history_list: Utils.getApiHost('project') + 'project/history/list',
      webApi_project_history_save: Utils.getApiHost('project') + 'project/history/save',
      webApi_project_clone: Utils.getApiHost('project') + 'project/clone',
      webApi_project_version_list: Utils.getApiHost('project') + 'version/list',

      // 三端互通
      three_terminal_project_create: Utils.getApiHost('project') + 'threeTerminalProject/create',
      three_terminal_project_update: Utils.getApiHost('project') + 'threeTerminalProject/update',

      // NOTE: 直播相关
      webApi_project_reset: Utils.getLiveApiHost() + 'm3u8Transform/resetMeiShe',
      webApi_live_project_compile: Utils.getLiveApiHost() + 'project/compile',
      webApi_live_compile: `${Utils.getLiveApiHost()}project/compile`,
      webApi_live_job_info: `${Utils.getLiveApiHost()}job/info`,
      webApi_live_job_delete: `${Utils.getLiveApiHost()}cancelCompilingJob`,
      webApi_live_getLiveResourceByOriginUrl: Utils.getLiveApiHost() + 'm3u8Transform/getLiveResourceByOriginUrl',
      webApi_live_publish: Utils.getApiHost('yicai') + 'meishe/live/create',
      webApi_live_refreshAuthKey: Utils.getLiveApiHost() + 'm3u8Transform/refreshM3u8UrlAuthKey',
      webApi_live_stopLiveResource: Utils.getLiveApiHost() + 'm3u8Transform/stopLiveResource',
      webApi_live_audio_to_text_start: Utils.getLiveApiHost() + 'm3u8Transform/startConnecting',
      webApi_live_audio_to_text_end: Utils.getLiveApiHost() + 'm3u8Transform/endConnecting',

      // 语言转字幕 讯飞语音转写
      webApi_recognition_create: Utils.getApiHost('recognition') + 'recognition/create',
      webApi_recognition_info: Utils.getApiHost('recognition') + 'recognition/info',

      // 直播剪辑
      webApi_app_auth: 'https://testeditor.meishesdk.com:7443/app/auth', // 测试环境的鉴权
      webApi_page_auth_host: 'https://eapi.meishesdk.com:7443/',
      webApi_license_index: 'https://api.meishesdk.com',
      asset_host: Utils.getAssetHost(),

      // 发布
      // 微博
      weibo_requestAuth: 'oauth2/authorize', // 请求授权
      weibo_getAuth: 'oauth2/access_token', // 获取授权
      weibo_share: '2/statuses/share.json', // 分享
      weibo_getTokenInfo: 'oauth2/get_token_info', // 查看授权信息
      weibo_revokeAuth: 'oauth2/revokeoauth2', // 回收授权
      weibo_userInfo: '2/users/show.json', // 查看已授权用户信息
      weibo_likeCount: '2/interest/like_count.json', // 根据URL批量获取对象的总赞数

      // 智能标签
      tag_all: Utils.getApiHost('tag') + 'tag/all',
      tag_fuzzySearch: Utils.getApiHost('tag') + 'tag/fuzzySearch',
      tag_create: Utils.getApiHost('tag') + 'tag/create'
    }
  },
  uploadAliOss (options = {}) {
    if (process.env.NODE_ENV === 'aws') {
      return this.awsUpload(options)
    } else if (process.env.NODE_ENV === 'local'){
      return this.localUpload(options)
    }
    this.aliyunUpload2(options)
  },

  // 开始分片上传。
  async  aliMultipartUpload (client, file, key, progress, error) {
    try {
      let fileOrBlob = file
      if (typeof file === 'string') {
        fileOrBlob = new Blob([file], {type: 'text/plain;charset=utf-8'})
      }
      // object-name可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
      const result = await client.multipartUpload(key, fileOrBlob, {
        progress,
        // meta是用户自定义的元数据，通过head接口可以获取到Object的meta数据。
        meta: {
          year: 2020,
          people: 'test'
        }
      })
      console.log(result)
    } catch (e) {
      console.error(e)
      // 捕获超时异常。
      if (e.code === 'ConnectionTimeoutError') {
        console.log('TimeoutError')
        // do ConnectionTimeoutError operation
      }
      if (error && typeof error === 'function') {
        console.error('分片上传失败:', e.code)
        error(e)
      }
    }
  },
  async aliyunUpload2 (options = {}) {
    const {
      title,
      projectId,
      keyUUID,
      file,
      isNeedCallback = 0,   // 后端是否支持回调
      extension,
      isDir = 0,
      isMXF = 0,
      uploadModule,
      callback,
      errorCb,
      uuid
    } = options
    try {
      const requestParams = {
        type: Enum.ossType.ali,
        extension,
        isNeedCallback,
        uploadModule,
        isDir,
        ...(isMXF ? { callbackTimes: Array.from(file).length } : {})
      }
      if (uploadModule === Enum.uploadModule.bsProject) {
        requestParams.projectId = projectId
      }
      if ([Enum.uploadModule.template, Enum.uploadModule.multi_project].includes(uploadModule)) {
        requestParams.uuid = uuid
      }
      const response = await api.resource.sts_info2(requestParams)
      // console.log(response)
      try {
        const client = new OSS({
          region: response.region,
          accessKeyId: response.accessKeyId,
          accessKeySecret: response.secretAccessKey,
          stsToken: response.securityToken,
          bucket: response.bucket,
          cname: true,
          endpoint: Utils.getCOSHost()
        })
        this.setAliOssClient(client)
        let fileOrBlob = file
        if (typeof file === 'string') {
          fileOrBlob = new Blob([file], {type: 'text/plain;charset=utf-8'})
        }
        const callbackInfo = response.callbackInfo || {}
        const callbackObj = {
          url: callbackInfo.callbackUrl,
          body: callbackInfo.callbackBody,
          contentType: callbackInfo.callbackBodyType
        }
        if (isMXF) {
          const promiseQueue = []
          const progressList = []
          const fileLength = file.length
          Array.from(file).forEach((fileOne, index) => {
            promiseQueue.push(client.multipartUpload(
              response.relativePath.replace(/(dir\/)\*$/, '$1' + fileOne.name),
              fileOne,
              {
                headers: {
                  'Cache-Control': 'public'
                },
                progress(p) {
                  progressList[index] = p
                  const sum = progressList.reduce((prev, cur) => prev + cur,0)
                  vm.$bus.$emit(vm.$EventBusKey.MediaUpload, JSON.stringify({
                    title,
                    keyUUID,
                    progress: Math.round(sum / fileLength *  100),
                    uploadType: 0
                  }))
                },
                callback: callbackObj
              }
            ))
          })
          const allResults = await allWithProgress(promiseQueue, function (index) {})
          let Location = Utils.getCOSHost() + '/' + allResults[0].name
          Location = Location.replace(/https:\/\/|http:\/\//, '')

          // eslint-disable-next-line standard/no-callback-literal
          callback({ // 全部上传完成调用callback resolve掉
            resourceId: response.objectId,
            Location
          })
        } else if (isDir) {
          const promiseQueue = []
          Array.from(file).forEach((fileOne, index) => {
            promiseQueue.push(client.put(
              response.relativePath.replace(/(dir\/)\*$/, '$1' + fileOne.name),
              fileOne,
              {
                headers: {
                  'Cache-Control': 'public'
                }
              }
            ))
          })
          const allResults = await allWithProgress(promiseQueue, function (index) {
            vm.$bus.$emit(vm.$EventBusKey.MediaUpload, JSON.stringify({
              title,
              keyUUID,
              progress: Math.round(index / (file.length - 1) * 100),
              uploadType: 0
            }))
          })
          // eslint-disable-next-line standard/no-callback-literal
          callback({
            resourceId: response.objectId,
            Location: allResults[0].url.replace(/https:\/\/|http:\/\//, '')
          })
        } else if (isNeedCallback === 0) {
          const result = await client.put(
            response.relativePath,
            fileOrBlob,
            {
              headers: {
                'Cache-Control': 'public'
              }
            }
          )
          // eslint-disable-next-line standard/no-callback-literal
          callback({
            resourceId: response.objectId,
            Location: result.url.replace(/https:\/\/|http:\/\//, ''),
            uploadFinish: true,
            projectId: response.projectId
          })
        } else {
          let finish = false
          const result = await client.multipartUpload(
            response.relativePath,
            fileOrBlob,
            {
              // progress: (p, _checkpoint) => {
              progress: (...params) => {
                const p = params[0]
                let progressData = {}
                progressData.keyUUID = keyUUID
                progressData.progress = Math.round(p * 100)
                progressData.filename = file.name
                progressData.resourceId = response.objectId
                progressData.key = response.relativePath
                progressData.uploadFinish = false
                progressData.Location = Utils.getCOSHost() + '/' + response.relativePath.replace(/(dir\/)\*$/, '$1' + file.name)
                progressData.Location = progressData.Location.replace(/https:\/\/|http:\/\//, '')
                callback(progressData)
              },
              headers: {
                'Cache-Control': 'public'
              },
              callback: callbackObj
            }
          )
          // console.log(result)
          if (result.data.code === 0 && !finish) {
            callback({
              keyUUID,
              progress: 100,
              filename: file.name,
              resourceId: response.objectId,
              key: response.relativePath,
              uploadFinish: true,
              Location: (Utils.getCOSHost() + '/' + response.relativePath.replace(/(dir\/)\*$/, '$1' + file.name)).replace(/https:\/\/|http:\/\//, '')
            })
            finish = true
          }
        }
      } catch (e) {
        console.error(e)
        // 捕获超时异常。
        if (e.code === 'ConnectionTimeoutError') {
          console.log('TimeoutError')
          // do ConnectionTimeoutError operation
        }
        if (errorCb && typeof errorCb === 'function') {
          console.error('分片上传失败:', e.code)
          errorCb(e)
        }
      }
    } catch (message) {
      if (errorCb && typeof errorCb === 'function') errorCb(message)
    }
  },
  async localUpload (options = {}) {
    const {
      title,
      projectId,
      keyUUID,
      file,
      isNeedCallback = 0,
      extension,
      isDir = 0,
      isMXF = 0,
      uploadModule,
      callback,
      errorCb,
      uuid
    } = options
    try {
      const requestParams = {
        type: Enum.ossType.local,
        extension,
        isNeedCallback,
        uploadModule,
        isDir
      }
      if (uploadModule === Enum.uploadModule.bsProject) {
        requestParams.projectId = projectId
      }
      if ([Enum.uploadModule.template, Enum.uploadModule.multi_project].includes(uploadModule)) {
        requestParams.uuid = uuid
      }
      const response = await api.resource.sts_info2(requestParams)
      const formData = new FormData()

      if (file instanceof Blob) {
        formData.append('files', file)
      } else {
        const blob = new Blob([file], { type: 'text/plain' })
        const xmlFile = new File(
          [blob],
          response.relativePath.replace(/^.*\/([^\/]+)$/, '$1')
        )
        formData.append('files', xmlFile)
      }
      formData.append('keys', response.relativePath)

      const uploadResponse =await api.resource.upload_file_local(
        formData,
        {},
        function (progressData){
          if (progressData.lengthComputable) {
            const progress = Math.round(
              (progressData.loaded * 100) / progressData.total
            )
            callback({
              resourceId: response.objectId,
              filename: file.name,
              progress,
              projectId: response.projectId
            })
          }
        }
      )

      const data = {
        keyUUID : keyUUID,
        resourceId: response.objectId,
        Location: uploadResponse.urls[0].replace(
          /https:\/\/|http:\/\//,
          ''
        ),
        projectId: response.projectId,
        uploadFinish:true,
        progress:100
      }
      callback(data)
    } catch (message) {
      if (errorCb && typeof errorCb === 'function') errorCb(message)
    }
  },
  // 腾讯云上传 get sts_info type=1或不传
  txUpload (key, uuid, file, callback, error) {
    const url = this.data().webApi_sts_info
    axios.get(url, {params: {type: 1}}).then(response => {
      // console.log(response)
      if (response.data.code === 0) {
        let cos = new COS({
          SecretId: response.data.data.credentials.tmpSecretId,
          SecretKey: response.data.data.credentials.tmpSecretKey,
          XCosSecurityToken: response.data.data.credentials.sessionToken
        })

        cos.putObject({
          Bucket: 'bsedit-1257467088',
          Region: 'ap-beijing',
          Key: key,
          Body: file,
          onProgress: function (progressData) {
            progressData.keyUUID = uuid
            progressData.uploadFinish = false
            progressData.progress = Math.round((progressData.loaded * 100) / progressData.total)
            progressData.key = key
            progressData.filename = file.name
            // console.log('TX progress', progressData.progress)
            // console.log(JSON.stringify(progressData))
            callback(progressData)
          }
        }, function (err, data) {
          // console.log(err || data)
          if (data) {
            data.uploadFinish = true
            data.key = key
            data.keyUUID = uuid
            data.filename = file.name
            // console.log(JSON.stringify(data))
            callback(data)
          }
          if (err) {
            if (error && typeof error === 'function') error(err)
          }
        })
      }
    }).catch(err => {
      if (error && typeof error === 'function') error(err)
      // console.log(err)
    })
  },
  // 亚马逊上传 get sts_info type=2
  async awsUpload (options = {}) {
    const {
      projectId,
      keyUUID,
      file,
      extension,
      isDir = 0,
      uploadModule,
      callback
    } = options
    const key = Utils.getStorageKeyPath(file.name, extension) + keyUUID + '.' + extension
    const requestParams = {
      type: Enum.ossType.amazon,
      extension: extension,
      isNeedCallback: 0, // 亚马逊上传  没有回调
      uploadModule: uploadModule,
      isDir: isDir,
      projectId: projectId
    }
    const response = await api.resource.sts_info2(requestParams)
    console.log('awsUpload response:', response)
    let s3 = new AWS.S3({
      accessKeyId: response.accessKeyId,
      secretAccessKey: response.secretAccessKey,
      region: 'us-west-1'
    })
    let params = {
      Bucket: 'bsedit',
      Key: key,
      Body: file,
      ACL: 'public-read'
    }
    s3.upload(params, (err, sendData) => {
      console.log(err || sendData)
      if (sendData) {
        sendData.uploadFinish = true
        sendData.key = key
        sendData.keyUUID = keyUUID
        sendData.progress = 100
        sendData.filename = file.name
        const host = Utils.getCOSHost().replace('https://' || 'http://', '') + '/' // cdn host
        sendData.Location = sendData.Location.replace('https://' || 'http://', '').replace(/^[^/]*\//, host) // 替换成cdn host
        console.log(JSON.stringify(sendData))
        callback(sendData)
      }
    }).on('httpUploadProgress', function (data) {
      data.keyUUID = keyUUID
      data.uploadFinish = false
      data.progress = Math.round((data.loaded * 100) / data.total)
      data.key = key
      data.filename = file.name
      data.projectId = response.projectId,
      console.log('AWS progress', data.progress)
      console.log(JSON.stringify(data))
      callback(data)
    })
  },

  downloadM3U8UrlToLocal (url, callback) {
    axios.get(url)
      .then(function (response) {
        let fileName = Utils.getNameFromUrl(url)
        let localFilePath = '/m3u8/' + fileName
        FS.writeFile(localFilePath, response.data)
        callback(localFilePath)
      })
  }
}
