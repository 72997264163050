import { get, post, delet } from '@/utils/request'
import NvNetwork from '@/api/network/NvNetwork'

export default {
  create: (data, { ...props }) =>
    post({
      url: NvNetwork.data().three_terminal_project_create,
      data,
      headers: {
        params: { ...props }
      }
    }),
  update: (data, { ...props }) =>
    post({
      url: NvNetwork.data().three_terminal_project_update,
      data,
      headers: {
        params: { ...props }
      }
    }),
}
