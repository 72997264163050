import Gl from './Global'
export function PrefixInteger (num, length) {
  return (Array(length).join('0') + num).slice(-length)
}
export function formattedForMusicCaption (item) {
  return formattedData(item.inPoint / Gl.CONVERT * Gl.SYSTEM) + '~' + formattedData(item.outPoint / Gl.CONVERT * Gl.SYSTEM)
}
export function formattedData (totalFrame) {
  let text = ''
  let hour = Math.floor(totalFrame / (25 * 60 * 60))
  // console.log('hour ' + hour)
  if (hour >= 1) {
    if (hour >= 100){
      text += PrefixInteger(hour, 3) + ':'
    } else {
      text += PrefixInteger(hour, 2) + ':'
    }
  } else {
    hour = 0
    text += '00:'
  }
  let minute = Math.floor((totalFrame - (hour * (25 * 60 * 60))) / 25 / 60)
  // console.log('minute ' + minute + '  totalFrame: ' + (totalFrame))
  if (minute >= 1) {
    text += PrefixInteger(minute, 2) + ':'
  } else {
    minute = 0
    text += '00:'
  }
  let second = Math.floor((totalFrame - (hour * (25 * 60 * 60)) - (minute * (25 * 60))) / 25)
  // console.log('second ' + second + ' ' + (totalFrame - (hour * (25 * 60 * 60)) - (minute * (25 * 60))) / 25 + ' ' + (totalFrame - (hour * (25 * 60 * 60)) - (minute * (25 * 60))) / 25 / 25)
  if (second >= 1) {
    text += PrefixInteger(second, 2) + ':'
  } else {
    second = 0
    text += '00:'
  }
  let frame = Math.floor((totalFrame - (hour * (25 * 60 * 60)) - (minute * (25 * 60))) % 25)
  // console.log('frame ' + frame)
  if (frame >= 1) {
    text += PrefixInteger(frame, 2)
  } else {
    text += '00'
  }
  // console.log('text: ' + text)
  return text
}

export function convertTimeCode (seconds) {
  // console.log(seconds)
  var sec = parseInt(seconds)
  var frame = Math.round((seconds - sec) * 25)
  var min
  var hour = parseInt(sec / 3600)
  if (hour > 0) {
    sec %= 3600
    min = parseInt(sec / 60)
    if (min > 0) {
      sec %= 60
    }
  } else {
    min = parseInt(sec / 60)
    if (min > 0) {
      sec %= 60
    }
  }
  return buildNumberString(hour) + ':' + buildNumberString(min) +
    ':' + buildNumberString(sec) + ':' + buildNumberString(frame)
}
/**
 * 返回时间到秒（--:--:--）
 * @param {*} seconds 
 * @returns 时间格式到秒
 */
export function convertTimeCode2 (seconds) {
  // console.log(seconds)
  var sec = parseInt(seconds)
  var min
  var hour = parseInt(sec / 3600)
  if (hour > 0) {
    sec %= 3600
    min = parseInt(sec / 60)
    if (min > 0) {
      sec %= 60
    }
  } else {
    min = parseInt(sec / 60)
    if (min > 0) {
      sec %= 60
    }
  }
  return buildNumberString(hour) + ':' + buildNumberString(min) + ':' + buildNumberString(sec)
}
export function buildNumberString (number) {
  var numberString = ''
  if (number < 10) {
    numberString += '0'
  }
  return numberString + number.toString()
}

export function secondToFrame (second) {
  return second * 25
}

export function frameToSecond (frame) {
  return frame / 25
}
/*
获取一定帧数的px长度 duration代表的是微秒
 */
export function getWidthByDuration (duration) {
  duration = duration / Gl.CONVERT
  return Gl.getTLPXEveryTime() * secondToFrame(duration)
}
// 从资源列表拖拽资源时，宽度不随比例尺改变
export function getDefaultWidthByDuration (duration) {
  duration = duration / Gl.CONVERT
  return getDefaultTLPXEveryTime() * secondToFrame(duration)
}
function getDefaultTLPXEveryTime () {
  const a = Gl.TimelineRules[Gl.DEFAULT_RULER]
  return a.rulingSpace / a.tipSpace
}
export function getWidthByDuration2 (duration, pixelPerMicrosecond) {
  duration = duration / Gl.CONVERT
  return pixelPerMicrosecond * secondToFrame(duration)
}
/*
获取一定长度代表的帧数
 */
export function getDurationByWidth (width) {
  return Gl.getTLTimeEveryPX() * width
}
/*
获取一定长度代表的秒
 */
export function getSecondDurationByWidth (width) {
  return frameToSecond(getDurationByWidth(width))
}

export function frameFromFormatData (formateData) {
  var spiltArr = formateData.split(':')
  var seconds = (+spiltArr[0]) * 60 * 60 + (+spiltArr[1]) * 60 + (+spiltArr[2])
  return secondToFrame(seconds) + (+spiltArr[3])
}
