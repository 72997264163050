/**
 * 资源库与LiveWindow交互
 */
export default {
  // 缩放
  'mediaScaleValue': 'mediaScaleValue',
  // 位置X&Y
  'mediaXYPosition': 'mediaXYPosition',
  // 旋转
  'mediaRotationValue': 'mediaRotationValue',
  // 特效的大小，宽高
  'effectPosition': 'effectPosition',
  'initEffectPosition': 'initEffectPosition'
}
