import Utils from '@/api/Utils'
import TimelineUtils from '@/api/TimelineUtils'
import { TimelineData, VideoTrack, AudioTrack, TimelineVideoFxTrack, CaptionTrack, StickerTrack, CompoundCaptionTrack, MusicLyricsTrack, Transition, VideoClip, AudioClip, TimelineVideoFxClip, CaptionClip, StickerClip, CompoundCaptionClip, MusicLyricsClip, VideoFx, AudioFx, FxParam, KeyFrame, WaterMark } from '@/api/ProjectData'
import store from '@/store'
import GL from '@/api/Global'
import TempUtils from './TempUtils'
import builtInTransitionConfig from '@/components/panels/_components/builtInTransitionConfig'
import { getRegionValueFromSdkValue } from '@/utils'
import api from '@/http'

// xml中不记录的特效参照字段
const excludeKeys = ['Filter Intensity', 'Region Data', 'Enable Region', 'Region Coordinate System Type',
  'Region Feather Width', 'Reverse Region', 'Ignore Region Background', 'Description File',
  "Exposure", "Region Storyboard Resource Dir Path", "Vibrance", "Video Mode", "Region Storyboard Description String"
]

// Transform 2D特效里面作为property特效的参数
const propertyIncludeKeys = ['Scale X', 'Scale Y', 'Trans X', 'Trans Y', 'Anchor X', 'Anchor Y', 'Rotation', 'Opacity',
 'Background Mode', 'Background Color', 'Background Image', 'Background Image Pan', 'Background Blur Radius', 'Enable Background Rotation']

function getSpeed (val) {
  let minIndex = -1
  let min = 10
  const speeds = [0.125, 0.5, 1, 2, 4]
  speeds.map((item, index) => {
    const diff = Math.abs(item - val)
    if (diff < min) {
      min = diff
      minIndex = index
    }
  })
  return speeds[minIndex]
}

async function getAllMaterial (timeline) {
  try {
    const allUUID = []

    // 字幕
    let msCaption = timeline.getFirstCaption()
    while (msCaption) {
      const desc = msCaption.getCaptionStylePackageId()
      allUUID.push(desc)
      msCaption = timeline.getNextCaption(msCaption)
    }

    // 组合字幕
    let msCompoundCaption = timeline.getFirstCompoundCaption()
    while (msCompoundCaption) {
      const desc = msCompoundCaption.getCaptionStylePackageId()
      allUUID.push(desc)
      msCompoundCaption = timeline.getNextCompoundCaption(msCompoundCaption)
    }

    // 贴纸
    let msSticker = timeline.getFirstAnimatedSticker()
    while (msSticker) {
      const desc = msSticker.getAnimatedStickerPackageId()
      allUUID.push(desc)
      msSticker = timeline.getNextAnimatedSticker(msSticker)
    }

    // 特效
    let msTimelineVideoFx = timeline.getFirstTimelineVideoFx()
    while (msTimelineVideoFx) {
      const type = msTimelineVideoFx.getTimelineVideoFxType() === NvsVideoFxTypeEnum.Builtin ? 'builtin' : 'package'
      if (type === 'package') {
        const desc = msTimelineVideoFx.getTimelineVideoFxPackageId()
        allUUID.push(desc)
      }
      msTimelineVideoFx = timeline.getNextTimelineVideoFx(msTimelineVideoFx)
    }

    console.log(allUUID)
    const res = await api.material.material_all(allUUID)
    console.log(res)
    return res?.elements?.length ? res.elements : null
  } catch (error) {
    console.error(error)
  }
}

// Transcode timeline to project data
export default async function transcodeTimelineToProjectData (timeline, footages, templateId, materialAllInfo, fromInteractProject) {
  if (timeline === undefined) {
    console.error('timeline is invalid!')
    return
  }

  var assets = materialAllInfo ?? await getAllMaterial(timeline)
  let clipInAnimationList = []
  let pageNum = 1
  let result = await api.materialcenter['material_list_public']({
    type: 2,
    category: 3,
    kind: 1,
    pageNum: pageNum++,
    pageSize: 2000,
    systemFlag: 0
  })
  clipInAnimationList = clipInAnimationList.concat(result.elements)
  while (clipInAnimationList.total > clipInAnimationList.length) {
    let result = await api.materialcenter['material_list_public']({
      type: 2,
      category: 3,
      kind: 1,
      pageNum: pageNum++,
      pageSize: 2000,
      systemFlag: 0
    })
    clipInAnimationList = clipInAnimationList.concat(result.elements)
  }

  const ratioMap = store.state.monitor.ratioMap
  const { imageHeight, imageWidth } = timeline.getVideoRes()
  let ratio
  if (sessionStorage.nvsResolution) {
    ratio = sessionStorage.nvsResolution
  } else {
    ratio = ratioMap.find(item => item.ratio.toFixed(2) === (imageWidth / imageHeight).toFixed(2))
    if (ratio) {
      ratio = ratio.key
    } else ratio = '16:9'
  }
  var projectData = new TimelineData(imageWidth, imageHeight)
  projectData.duration = timeline.getDuration()
  projectData.videoSize = ratio
  if (GL.PERM_LIST.b_template) {
    projectData.templateId = templateId || ''
  } else {
    projectData.themeTemplateId = templateId || ''
  }
  projectData.enableRenderOrderByZValue = timeline.isRenderOrderByZValueEnabled()
  // 水印
  const waterMark = timeline.getWatermarkInfo()
  if (waterMark) {
    projectData.waterMark = new WaterMark(waterMark)
  }
  if (GL.isSupportVideoFx()) {
    console.log('transcodeTimelineVideoFxTracks')
    await transcodeTimelineVideoFxTracks(timeline, projectData.tracks, assets)
  }
  if (GL.isSupportCaption() && GL.isSupportCaptionStyleSetting()) {
    console.log('transcodeCaptionTracks')
    await transcodeCaptionTracks(timeline, projectData.tracks, assets)
    console.log('transcodeCompoundCaptionTracks')
    await transcodeCompoundCaptionTracks(timeline, projectData.tracks, assets)
  }
  if (GL.isSupportAnimatedSticker()) {
    console.log('transcodeStickerTracks')
    await transcodeStickerTracks(timeline, projectData.tracks, assets)
  }

  let musicLyricsTrack
  if (GL.isSupportCaption() && GL.isSupportCaptionStyleSetting()) {
    for (let i = 0; i < projectData.tracks.length; i++) {
      if (projectData.tracks[i].type === GL.MUSICLYRICSTRACK) {
        musicLyricsTrack = projectData.tracks[i]
        projectData.tracks.splice(i, 1)
        break
      }
    }
  }

  projectData.tracks.sort(decompare())
  let trackCount = projectData.tracks.length
  for (let i = 0; i < projectData.tracks.length; i++) {
    projectData.tracks[i].index = i + trackCount
  }

  console.log('transcodeVideoTracks')
  let consider2DTransformAsProperty = true
  if (fromInteractProject) consider2DTransformAsProperty = !fromInteractProject
  await transcodeVideoTracks(timeline, projectData.tracks, assets, footages, consider2DTransformAsProperty, clipInAnimationList)
  if (GL.isSupportMusic() && GL.PERM_LIST.b_template) {
    console.log('transcodeAudioTracks')
    await transcodeAudioTracks(timeline, projectData.tracks, assets, footages)
  }
  if (GL.PERM_LIST.b_template) {
    // 兼容其他端, 至少一轨音频
    let hasAudioTrack = projectData.tracks.find(track => track.type === GL.AUDIOTRACK)
    if (!hasAudioTrack) projectData.tracks.push(new AudioTrack(projectData.tracks.length))

    // 兼容其他端, 一定要有唱词轨
    if (GL.isSupportCaption() && GL.isSupportCaptionStyleSetting()) {
      if (musicLyricsTrack) projectData.tracks.push(musicLyricsTrack)
      else projectData.tracks.push(new MusicLyricsTrack(projectData.tracks.length))
    }
  }
  projectData.tracks.map((track, index) => {
    track.index = index
  })

  // projectData.tracks.sort(compare())
  projectData.tracks.map(track => {
    track.clips.map((clip, index) => {
      clip.index = index
    })
  })
  return projectData
}

function compare () {
  return function (object1, object2) {
    var value1 = object1.index
    var value2 = object2.index
    if (value2 < value1) {
      return 1
    } else if (value2 > value1) {
      return -1
    } else {
      return 0
    }
  }
}

function decompare () {
  return function (object1, object2) {
    var value1 = object1.index
    var value2 = object2.index
    if (value2 < value1) {
      return -1
    } else if (value2 > value1) {
      return 1
    } else {
      return 0
    }
  }
}

async function transcodeVideoTracks (timeline, tracks, assets, footages, consider2DTransformAsProperty, clipInAnimationList) {
  return new Promise(async function (resolve, reject) {
    for (let i = timeline.videoTrackCount() - 1; i >= 0; i--) {
      let msVideoTrack = timeline.getVideoTrackByIndex(i)
      var videoTrack = new VideoTrack(tracks.length)
      videoTrack.volume = msVideoTrack.getVolumeGain().leftVolume
      console.log('transcodeVideoClips')
      await transcodeVideoClips(msVideoTrack, videoTrack.clips, assets, footages, timeline, consider2DTransformAsProperty, clipInAnimationList)
      if (GL.isSupportVideoTransition()) {
        console.log('transcodeTransitions')
        await transcodeTransitions(msVideoTrack, videoTrack.transitions, assets)
      }
      // 过滤没有片段的轨道，这里指模板里面的只有empty.png的空轨
      if (videoTrack.clips.length > 0) {
        tracks.push(videoTrack)
      }
    }
    resolve()
  })
}

async function transcodeAudioTracks (timeline, tracks, assets, footages) {
  return new Promise(async function (resolve, reject) {
    // 存储所有音频轨
    const audioTrackArr = []
    for (let i = 0; i < timeline.audioTrackCount(); i++) {
      let msAudioTrack = timeline.getAudioTrackByIndex(i)
      var audioTrack = new AudioTrack(tracks.length)
      audioTrack.volume = msAudioTrack.getVolumeGain().leftVolume
      tracks.push(audioTrack)
      audioTrackArr.push(audioTrack)
      await transcodeTransitions(msAudioTrack, audioTrack.transitions, assets)
    }
    for (let i = timeline.audioTrackCount() - 1; i >= 0; i--) {
      // 上层正向取，sdk层反向取
      const audioTrack = audioTrackArr.shift()
      let msAudioTrack = timeline.getAudioTrackByIndex(i)
      console.log('transcodeAudioClips')
      await transcodeAudioClips(msAudioTrack, audioTrack.clips, assets, footages, timeline)
      console.log('transcodeTransitions')
    }
    resolve()
  })
}

function transcodeTimelineVideoFxTracks (timeline, tracks, assets) {
  return new Promise(async function (resolve, reject) {
    let timelineVideoFxTracks = []
    let msTimelineVideoFx = timeline.getFirstTimelineVideoFx()
    let regulateGroupName = ''
    while (msTimelineVideoFx !== undefined) {
      // 处理 调整的clip 判断逻辑时 fxGroup 中包含 regulate-
      // 现在时模板跳转云间时 调整clip 会分成三个，需要合并参数设置 multiRaw、multiParams 等参数
      let group = msTimelineVideoFx.getTemplateAttachment(Enum.attachmentType.fxGroup)
      let isRegulate = false, isCombination = false, groupValue = {}
      if (group) {
        try {
          groupValue = JSON.parse(group)
          if (groupValue.regulate) {
            isRegulate = true
          }
          if (groupValue.combination) {
            isCombination = true
          }
        } catch (error) {
          console.log(group)
        }
      }
      let timelineVideoFxTrack
      let needNewTrack = true
      for (let i = 0; i < timelineVideoFxTracks.length; i++) {
        let overlap = false
        for (let j = 0; j < timelineVideoFxTracks[i].clips.length; j++) {
          if (timelineVideoFxTracks[i].index > msTimelineVideoFx.getZValue()) {
            overlap = true
            break
          }
          let clip = timelineVideoFxTracks[i].clips[j]
          if (clip.outPoint <= msTimelineVideoFx.getInPoint()) continue
          if (clip.inPoint >= msTimelineVideoFx.getOutPoint()) continue
          // 如果是调节，且入出点相同，合为一个轨道，否则新建一个轨道
          if (clip.inPoint === msTimelineVideoFx.getInPoint() && clip.outPoint === msTimelineVideoFx.getOutPoint() && isRegulate) continue
          if (clip.inPoint <= msTimelineVideoFx.getInPoint() || clip.outPoint >= msTimelineVideoFx.getOutPoint()) {
            overlap = true
            break
          }
        }
        if (!overlap) {
          timelineVideoFxTrack = timelineVideoFxTracks[i]
          needNewTrack = false
          break
        }
      }
      if (needNewTrack) {
        timelineVideoFxTrack = new TimelineVideoFxTrack(msTimelineVideoFx.getZValue())
        timelineVideoFxTracks.push(timelineVideoFxTrack)
      }
      var type = msTimelineVideoFx.getTimelineVideoFxType() === NvsVideoFxTypeEnum.Builtin ? 'builtin' : 'package'
      var desc
      if (type === 'builtin') {
        desc = msTimelineVideoFx.getBuiltinTimelineVideoFxName()
      } else if (type === 'package') {
        if (!GL.isSupportVideoFxExtension()) {
          msTimelineVideoFx = timeline.getNextTimelineVideoFx(msTimelineVideoFx)
          continue
        }
        desc = msTimelineVideoFx.getTimelineVideoFxPackageId()
      }
      var timelineVideoFxClip = new TimelineVideoFxClip(timelineVideoFxTrack.clips.length, type, desc)
      timelineVideoFxClip.inPoint = msTimelineVideoFx.getInPoint()
      // timelineVideoFxClip.outPoint = Math.min(msTimelineVideoFx.getOutPoint(), timeline.getDuration())
      timelineVideoFxClip.outPoint = msTimelineVideoFx.getOutPoint()
      timelineVideoFxClip.isRegional = msTimelineVideoFx.getRegional()
      timelineVideoFxClip.isIgnoreBackground = msTimelineVideoFx.getIgnoreBackground()
      timelineVideoFxClip.isInverseRegion = msTimelineVideoFx.getInverseRegion()
      timelineVideoFxClip.regionalFeatherWidth = msTimelineVideoFx.getRegionalFeatherWidth()

      if (isCombination) {
        timelineVideoFxClip.combination = true
        timelineVideoFxClip.combinationOrder = Number(groupValue.combinationOrder)
      }

      if (msTimelineVideoFx.getRegional()) {
        const vecRegionInfo = msTimelineVideoFx.getRegionInfos()
        const regionInfo = vecRegionInfo?.get(0)
        if (regionInfo?.type === 'ellipse') {
          timelineVideoFxClip.shape = 'ellipse'
        }
      }
      // 模糊，马赛克，遮罩等内建滤镜
      if (type === 'builtin') {
        if (desc === 'Gaussian Blur') {
          timelineVideoFxClip.clipSubType = GL.effectBlur
          timelineVideoFxClip.displayName = 'Blurry'
          timelineVideoFxClip.displayNamezhCN = '模糊'
        } else if (desc === 'Mosaic') {
          if (timelineVideoFxClip.isIgnoreBackground) {
            // 如果忽略背景颜色，肯定是遮罩
            timelineVideoFxClip.clipSubType = GL.effectShade
            timelineVideoFxClip.displayName = 'Mask'
            timelineVideoFxClip.displayNamezhCN = '遮罩'
          } else {
            timelineVideoFxClip.clipSubType = GL.effectMosaic
            timelineVideoFxClip.displayName = 'Mosaic'
            timelineVideoFxClip.displayNamezhCN = '马赛克'
          }
        }
      } else {
        if (assets) {
          const assetInfo = assets.find(item => item.id === desc)
          if (assetInfo) {
            timelineVideoFxClip.displayName = assetInfo.displayName
            timelineVideoFxClip.displayNamezhCN = assetInfo.displayNameZhCn
          }
        }
        if (nvsGetStreamingContextInstance().getAssetPackageManager().isParticleFX(desc)) {
           timelineVideoFxClip.nvTypeId = Enum.mType.particle
        } else {
          timelineVideoFxClip.nvTypeId = Enum.mType.videofx
        }
      }
      if (isRegulate) {  // 此处使用 displayName 中 包含 regulate 判定为调整clip
        // 将多个 timelineVideoFx  并且 displayName中包含 regulate 合并成一个clip
        if (regulateGroupName !== groupValue.displayName) {
          timelineVideoFxClip.desc = 'Regulate'
          timelineVideoFxClip.displayName = groupValue.displayName
          timelineVideoFxClip.displayNamezhCN = "调节"
          timelineVideoFxClip.nvTypeId = -1
          timelineVideoFxClip.clipSubType = GL.effectRegulation
          timelineVideoFxClip.descArray = [desc]
          timelineVideoFxClip.multiParams = {[desc]: []}
          timelineVideoFxClip.multiRaw = { [desc]: msTimelineVideoFx }
          regulateGroupName = groupValue.displayName
          transcodeFxParamsWithoutFx(msTimelineVideoFx, timelineVideoFxClip.multiParams[desc])
        } else {
          let clip = timelineVideoFxTrack.clips.filter(clip => clip.displayName === regulateGroupName)[0];
          clip.descArray.push(desc)
          clip.multiParams[desc] = []
          transcodeFxParamsWithoutFx(msTimelineVideoFx, clip.multiParams[desc])
          clip.multiRaw[desc] = msTimelineVideoFx
          timelineVideoFxClip = clip
          msTimelineVideoFx = timeline.getNextTimelineVideoFx(msTimelineVideoFx)
          continue
        }
      } else {
        transcodeFxParams(msTimelineVideoFx, timelineVideoFxClip)
      }
      transcodeKeyFrames(msTimelineVideoFx, timelineVideoFxClip.keyFrames)
      console.log(timelineVideoFxClip.keyFrames)
      if (timelineVideoFxClip.keyFrames) {
        const intensityKF = timelineVideoFxClip.keyFrames.filter(item => item.key === Enum.fxKFParamType.intensity)
        if (intensityKF.length === 0) {
          timelineVideoFxClip.intensity = msTimelineVideoFx.getFilterIntensity()
        }
      }
      timelineVideoFxTrack.clips.push(timelineVideoFxClip)

      msTimelineVideoFx = timeline.getNextTimelineVideoFx(msTimelineVideoFx)
    }
    // timelineVideoFxTracks.sort(decompare())
    // let trackCount = tracks.length
    for (let i = 0; i < timelineVideoFxTracks.length; i++) {
      // timelineVideoFxTracks[i].index = i + trackCount
      tracks.push(timelineVideoFxTracks[i])
    }
    resolve()
  })
}

function transcodeCaptionTracks (timeline, tracks, assets) {
  return new Promise(async function (resolve, reject) {
    let captionTracks = []
    let msCaption = timeline.getFirstCaption()
    let isLyrics = false
    while (msCaption) {
      isLyrics = msCaption.isLyrics()
      var captionTrack
      let needNewTrack = true
      for (let i = 0; i < captionTracks.length; i++) {
        let overlap = false
        for (let j = 0; j < captionTracks[i].clips.length; j++) {
          if (captionTracks[i].index < msCaption.getZValue()) {
            overlap = true
            break
          }
          let clip = captionTracks[i].clips[j]
          if (clip.outPoint <= msCaption.getInPoint()) continue
          if (clip.inPoint >= msCaption.getOutPoint()) continue
          if (clip.inPoint <= msCaption.getInPoint() || clip.outPoint >= msCaption.getOutPoint()) {
            overlap = true
            break
          }
        }
        if (!overlap) {
          captionTrack = captionTracks[i]
          needNewTrack = false
          break
        }
      }
      let text = msCaption.getText()
      let desc = msCaption.getCaptionStylePackageId()
      let caption

      if (needNewTrack) {
        if (isLyrics) {
          captionTrack = new MusicLyricsTrack(msCaption.getZValue())
          captionTrack.styleDesc = desc
          captionTrack.font = await Utils.getFontFamily(msCaption.getFontFamily())
          captionTrack.fontSize = msCaption.getFontSize()
          captionTrack.scaleX = msCaption.getScaleX()
          captionTrack.scaleY = msCaption.getScaleY()
          captionTrack.rotation = msCaption.getRotationZ()
          captionTrack.translationX = msCaption.getCaptionTranslation().x
          captionTrack.translationY = msCaption.getCaptionTranslation().y
          captionTrack.outline = msCaption.getDrawOutline()
          captionTrack.outlineWidth = msCaption.getOutlineWidth()
          captionTrack.outlineColor = Utils.NvsColorToRGBA(msCaption.getOutlineColor())
          msCaption.isTextColorChanged() && (captionTrack.color = Utils.NvsColorToRGBA(msCaption.getTextColor()))
          switch (msCaption.getTextAlignment()) {
            case NvsCaptionTextAlignmentEnum.Left:
              captionTrack.align = 'left'
              break;
            case NvsCaptionTextAlignmentEnum.right:
              captionTrack.align = 'right'
              break;
            default:
              captionTrack.align = 'center'
              break;
          }
          captionTracks.push(captionTrack)
        } else {
          captionTrack = new CaptionTrack(msCaption.getZValue())
          captionTracks.push(captionTrack)
        }
      }

      if (isLyrics) {
        caption = new MusicLyricsClip(captionTrack.clips.length)
        caption.text = text
        captionTrack.styleDesc = desc // 如过轨道的第一个clip的text='', 则会出现没有desc, 所以要在后边的clip中设置
      } else {
        if (desc) {
          caption = new CaptionClip(captionTrack.clips.length, text, 'general', 'general', desc)
        } else {
          const contextId = msCaption.getModularCaptionContextPackageId()
          const rendererId = msCaption.getModularCaptionRendererPackageId()
          const animationId = msCaption.getModularCaptionAnimationPackageId()
          const inAnimationId = msCaption.getModularCaptionInAnimationPackageId()
          const outAnimationId = msCaption.getModularCaptionOutAnimationPackageId()
          caption = new CaptionClip(captionTrack.clips.length, text, 'modular', 'general', '')
          contextId && (caption.contextId = contextId)
          rendererId && (caption.rendererId = rendererId)
          if (animationId) {
            caption.animationId = animationId
            const animationPeroid = msCaption.getModularCaptionAnimationPeroid()
            caption.animationPeroid = animationPeroid * 1000
          } else {
            if (inAnimationId) {
              caption.inAnimationId = inAnimationId
              const inAnimationDuration = msCaption.getModularCaptionInAnimationDuration()
              caption.inAnimationDuration = inAnimationDuration * 1000
            }
            if (outAnimationId) {
              caption.outAnimationId = outAnimationId
              const outAnimationDuration = msCaption.getModularCaptionOutAnimationDuration()
              caption.outAnimationDuration = outAnimationDuration * 1000
            }
          }
        }
      }
      caption.inPoint = msCaption.getInPoint()
      // caption.outPoint = Math.min(msCaption.getOutPoint(), timeline.getDuration())
      caption.outPoint = msCaption.getOutPoint()
      transcodeKeyFrames(msCaption, caption.keyFrames)
      console.log(caption.keyFrames)
      if (caption.keyFrames) {
        const scaleXKF = caption.keyFrames.filter(item => item.key === Enum.captionKFParamType.scaleX)
        if (scaleXKF.length === 0) {
          caption.scaleX = msCaption.getScaleX()
          caption.scaleY = msCaption.getScaleY()
        }
        const transXKF = caption.keyFrames.filter(item => item.key === Enum.captionKFParamType.transX)
        if (transXKF.length === 0) {
          caption.translationX = msCaption.getCaptionTranslation().x
        }
        const transYKF = caption.keyFrames.filter(item => item.key === Enum.captionKFParamType.transY)
        if (transYKF.length === 0) {
          caption.translationY = msCaption.getCaptionTranslation().y
        }
        const rotationKF = caption.keyFrames.filter(item => item.key === Enum.captionKFParamType.rotation)
        if (rotationKF.length === 0) {
          caption.rotation = msCaption.getRotationZ()
        }
      }
      caption.font = await Utils.getFontFamily(msCaption.getFontFamily())
      caption.letterSpacing = msCaption.getLetterSpacing()
      caption.letterSpacingType = msCaption.getLetterSpacingType()
      if (caption.letterSpacingType === NvsCaptionLetterSpacingTypeEnum.Percentage) {
        caption.letterSpacingPercentage = msCaption.getLetterSpacing()
      } else {
        caption.letterSpacingAbsolute = msCaption.getLetterSpacing()
      }
      caption.lineSpacing = msCaption.getLineSpacing()
      caption.fontSize = msCaption.getFontSize()
      if (msCaption.isTextColorChanged()) {
        caption.color = Utils.NvsColorToRGBA(msCaption.getTextColor())
      } else {
        caption.color = ''
      }
      caption.bold = msCaption.getBold()
      caption.weight = msCaption.getWeight()
      caption.italic = msCaption.getItalic()
      caption.underline = msCaption.getUnderline()
      caption.shadow = msCaption.getDrawShadow()
      caption.shadowColor = Utils.NvsColorToRGBA(msCaption.getShadowColor())
      caption.shadowOffsetX = Number(msCaption.getShadowOffset().x.toFixed(0))
      caption.shadowOffsetY = Number(msCaption.getShadowOffset().y.toFixed(0))
      caption.shadowFeather = msCaption.getShadowFeather()
      caption.outline = msCaption.getDrawOutline()
      caption.outlineColor = Utils.NvsColorToRGBA(msCaption.getOutlineColor())
      caption.outlineWidth = Number(msCaption.getOutlineWidth().toFixed(1))
      caption.bgRadius = Number(msCaption.getBackgroundRadius().toFixed(0))
      captionTrack.clips.push(caption)

      const bgColor = Utils.NvsColorToRGBA(msCaption.getBackgroundColor())
      caption.bgColor = bgColor === 'rgba(0,0,0,0)' ? '' : bgColor

      const alignItems = ['left', 'center', 'right','top',  'vCenter','bottom']
      const index = msCaption.getVerticalLayout() ? msCaption.getTextAlignment() + 3 : msCaption.getTextAlignment()
      caption.align = alignItems[index]

      const group = msCaption.getTemplateAttachment(Enum.attachmentType.fxGroup)
      if (group) {
        caption.combination = true
        caption.combinationOrder = Number(group)
      }

      msCaption = timeline.getNextCaption(msCaption)
    }
    // captionTracks.sort(decompare())
    // let trackCount = tracks.length
    for (let i = 0; i < captionTracks.length; i++) {
      // captionTracks[i].index = i + trackCount
      tracks.push(captionTracks[i])
    }
    resolve()
  })
}

function transcodeCompoundCaptionTracks (timeline, tracks, assets) {
  return new Promise(async function (resolve, reject) {
    let captionTracks = []
    let msCaption = timeline.getFirstCompoundCaption()
    while (msCaption !== undefined) {
      var captionTrack
      let needNewTrack = true
      for (let i = 0; i < captionTracks.length; i++) {
        let overlap = false
        for (let j = 0; j < captionTracks[i].clips.length; j++) {
          if (captionTracks[i].index < msCaption.getZValue()) {
            overlap = true
            break
          }
          let clip = captionTracks[i].clips[j]
          if (clip.outPoint <= msCaption.getInPoint()) continue
          if (clip.inPoint >= msCaption.getOutPoint()) continue
          if (clip.inPoint <= msCaption.getInPoint() || clip.outPoint >= msCaption.getOutPoint()) {
            overlap = true
            break
          }
        }
        if (!overlap) {
          captionTrack = captionTracks[i]
          needNewTrack = false
          break
        }
      }

      if (needNewTrack) {
        captionTrack = new CompoundCaptionTrack(msCaption.getZValue())
        captionTracks.push(captionTrack)
      }

      var desc = msCaption.getCaptionStylePackageId()
      var compoundCaption = new CompoundCaptionClip(captionTrack.clips.length, desc)
      compoundCaption.inPoint = msCaption.getInPoint()
      // compoundCaption.outPoint = Math.min(msCaption.getOutPoint(), timeline.getDuration())
      compoundCaption.outPoint = msCaption.getOutPoint()
      compoundCaption.scaleX = msCaption.getScaleX()
      compoundCaption.scaleY = msCaption.getScaleY()
      compoundCaption.rotation = msCaption.getRotationZ()
      compoundCaption.translationX = msCaption.getCaptionTranslation().x
      compoundCaption.translationY = msCaption.getCaptionTranslation().y

      if (assets) {
        const assetInfo = assets.find(item => item.id === desc)
        if (assetInfo) {
          compoundCaption.displayName = assetInfo.displayName
          compoundCaption.displayNamezhCN = assetInfo.displayNameZhCn
        }
      }

      for (let i = 0; i < msCaption.getCaptionCount(); i++) {
        let font = await Utils.getFontFamily(msCaption.getFontFamily(i))
        compoundCaption.text.push({
          text: msCaption.getText(i),
          font,
          color: Utils.NvsColorToRGBA(msCaption.getTextColor(i))
        })
      }

      const group = msCaption.getTemplateAttachment(Enum.attachmentType.fxGroup)
      if (group) {
        compoundCaption.combination = true
        compoundCaption.combinationOrder = Number(group)
      }

      captionTrack.clips.push(compoundCaption)
      // await insertAssetToList(desc + '.compoundcaption', '', assets);

      msCaption = timeline.getNextCompoundCaption(msCaption)
    }
    // captionTracks.sort(decompare())
    // let trackCount = tracks.length
    for (let i = 0; i < captionTracks.length; i++) {
      // captionTracks[i].index = i + trackCount
      tracks.push(captionTracks[i])
    }
    resolve()
  })
}

function transcodeStickerTracks (timeline, tracks, assets) {
  return new Promise(async function (resolve, reject) {
    let stickerTracks = []
    let msSticker = timeline.getFirstAnimatedSticker()
    while (msSticker !== undefined) {
      var stickerTrack
      let needNewTrack = true
      for (let i = 0; i < stickerTracks.length; i++) {
        let overlap = false
        for (let j = 0; j < stickerTracks[i].clips.length; j++) {
          if (stickerTracks[i].index < msSticker.getZValue()) {
            overlap = true
            break
          }
          let clip = stickerTracks[i].clips[j]
          if (clip.outPoint <= msSticker.getInPoint()) continue
          if (clip.inPoint >= msSticker.getOutPoint()) continue
          if (clip.inPoint <= msSticker.getInPoint() || clip.outPoint >= msSticker.getOutPoint()) {
            overlap = true
            break
          }
        }
        if (!overlap) {
          stickerTrack = stickerTracks[i]
          needNewTrack = false
          break
        }
      }

      if (needNewTrack) {
        stickerTrack = new StickerTrack(msSticker.getZValue())
        stickerTracks.push(stickerTrack)
      }

      const desc = msSticker.getAnimatedStickerPackageId()
      const sticker = new StickerClip(stickerTrack.clips.length, desc)
      const animationId = msSticker.getAnimatedStickerPeriodAnimationPackageId()
      const inAnimationId = msSticker.getAnimatedStickerInAnimationPackageId()
      const outAnimationId = msSticker.getAnimatedStickerOutAnimationPackageId()
      if (animationId) {
        sticker.animationId = animationId
        const animationPeroid = msSticker.getAnimatedStickerAnimationPeriod()
        sticker.animationPeroid = animationPeroid * 1000
      } else {
        if (inAnimationId) {
          sticker.inAnimationId = inAnimationId
          const inAnimationDuration = msSticker.getAnimatedStickerInAnimationDuration()
          sticker.inAnimationDuration = inAnimationDuration * 1000
        }
        if (outAnimationId) {
          sticker.outAnimationId = outAnimationId
          const outAnimationDuration = msSticker.getAnimatedStickerOutAnimationDuration()
          sticker.outAnimationDuration = outAnimationDuration * 1000
        }
      }
      sticker.inPoint = msSticker.getInPoint()
      // sticker.outPoint = Math.min(msSticker.getOutPoint(), timeline.getDuration())
      sticker.outPoint = msSticker.getOutPoint()
      transcodeKeyFrames(msSticker, sticker.keyFrames)
      console.log(sticker.keyFrames)
      if (sticker.keyFrames) {
        const scaleKF = sticker.keyFrames.filter(item => item.key === Enum.stickerKFParamType.scale)
        if (scaleKF.length === 0) {
          sticker.scale = msSticker.getScale()
        }
        const transXKF = sticker.keyFrames.filter(item => item.key === Enum.stickerKFParamType.transX)
        if (transXKF.length === 0) {
          sticker.translationX = msSticker.getTranslation().x
        }
        const transYKF = sticker.keyFrames.filter(item => item.key === Enum.stickerKFParamType.transY)
        if (transYKF.length === 0) {
          sticker.translationY = msSticker.getTranslation().y
        }
        const rotationKF = sticker.keyFrames.filter(item => item.key === Enum.stickerKFParamType.rotation)
        if (rotationKF.length === 0) {
          sticker.rotation = msSticker.getRotationZ()
        }
      }
      sticker.horizontalFlip = msSticker.getHorizontalFlip()
      sticker.verticalFlip = msSticker.getVerticalFlip()
      if (msSticker.hasAudio()) {
        sticker.volume = msSticker.getVolumeGain().leftVolume
      }
      const customImagePath = msSticker.getStringVal('Ext Image1')
      if (customImagePath) {
        sticker.stickerType = 'custom'
        const file = FS.readFile(customImagePath)
        sticker.path = await TempUtils.upload({
          extension: 'png',
          uploadModule: Enum.uploadModule.image,
          file: new Blob([file])
        })
      }

      if (assets) {
        const assetInfo = assets.find(item => item.id === desc)
        if (assetInfo) {
          sticker.displayName = assetInfo.displayName
          sticker.displayNamezhCN = assetInfo.displayNameZhCn
        }
      }

      const group = msSticker.getTemplateAttachment(Enum.attachmentType.fxGroup)
      if (group) {
        sticker.combination = true
        sticker.combinationOrder = Number(group)
      }

      stickerTrack.clips.push(sticker)
      msSticker = timeline.getNextAnimatedSticker(msSticker)
    }
    // stickerTracks.sort(decompare())
    // let trackCount = tracks.length
    for (let i = 0; i < stickerTracks.length; i++) {
      // stickerTracks[i].index = i + trackCount
      tracks.push(stickerTracks[i])
    }
    resolve()
  })
}

async function transcodeVideoClips (videoTrack, clips, assets, footages, timeline, consider2DTransformAsProperty, clipInAnimationList) {
  return new Promise(async function (resolve, reject) {
    for (let i = 0; i < videoTrack.getClipCount(); i++) {
      let msVideoClip = videoTrack.getClipByIndex(i)
      var type = msVideoClip.getVideoType() === NvsVideoClipTypeEnum.AV ? 1 : 3
      let footage
      if (footages.length > 0) {
        if (footages[0].clipInfos) {
          footage = footages.find(item => {
            if (item.type === NvsFootageTypeEnum.Audio) return false
            return item.clipInfos.find(({clipIndex, trackIndex}) => trackIndex === videoTrack.getIndex() && clipIndex === i)
          })
        } else {
          const m3u8Path = msVideoClip.getFilePath()
          footage = footages.find(item => {
            if (item.type === NvsFootageTypeEnum.Audio) return false
            return item.m3u8Path === m3u8Path
          })
        }
      }
      if (!footage) continue
      console.warn('footage', footage)
      var clip = new VideoClip(i, type, footage.m3u8Path, footage.m3u8Url, footage.path, footage.orgDuration)
      clip.inPoint = msVideoClip.getInPoint()
      // clip.outPoint = Math.min(msVideoClip.getOutPoint(), timeline.getDuration())
      clip.outPoint = msVideoClip.getOutPoint()
      clip.trimIn = msVideoClip.getTrimIn()
      clip.trimOut = msVideoClip.getTrimOut()
      clip.trimIn = Math.round(clip.trimIn)
      clip.trimOut = Math.round(clip.trimOut)
      clip.volume = msVideoClip.getVolumeGain().leftVolume
      clip.speed = msVideoClip.getSpeed()
      clip.fadeInDuration = msVideoClip.getFadeInDuration()
      clip.fadeOutDuration = msVideoClip.getFadeOutDuration()
      clip.extraRotation = msVideoClip.getExtraVideoRotation()
      // clip.reverse = xmlStreamReader.getAttributeValue('reverse').toString() === 'true';
      // clip.noAudio = xmlStreamReader.getAttributeValue('noAudio').toString() === 'true';
      if (footage.resourceId) {
        clip.id = footage.resourceId
      } else {
        clip.id = footage.id
      }

      clip.title = footage.title
      clip.leftChannelUrl = footage.leftChannelUrl || ''
      clip.rightChannelUrl = footage.rightChannelUrl || ''
      if (footage.thumbnailHost) {
        clip.thumbnailHost = footage.thumbnailHost
        clip.thumbnailStep = footage.thumbnailStep
        clip.thumbnailType = footage.thumbnailType
      } else {
        if (footage.thumbnailBaseUrl) {
          clip.thumbnailBaseUrl = footage.thumbnailBaseUrl
          clip.thumbnails = footage.thumbnails
        } else {
          Utils.setVideoClipThumbnails(clip, footage.thumbnails)
        }
      }
      clip.alphaPath = footage.alphaPath || footage.alphaUrl  || ''
      clip.alphaM3u8Path = footage.alphaM3u8Path || ''
      clip.alphaM3u8Url = footage.alphaM3u8Url || ''
      clip.curveSpeedString = msVideoClip.getClipVariableSpeedCurvesString()
      if(clip.curveSpeedString) {
        clip.curveSpeedName = '自定义'
      }
      clip.blendingMode = msVideoClip.getBlendingMode()
      clip.enableClipFreezeFrame = msVideoClip.isClipFreezeFrameEnabled() || false
      clip.freezeFrameTrimPos = msVideoClip.getClipFreezeFrameTrimPosition() || 0

      const group = msVideoClip.getTemplateAttachment(Enum.attachmentType.fxGroup)
      if (group) {
        clip.combination = true
        clip.combinationOrder = Number(group)
      }

      clips.push(clip)
      // var pathList = m3u8Path.split('/');
      // await insertAssetToList(pathList[pathList.length - 1], m3u8Url, assets, clip);
      if (GL.isSupportVideoFx()) {
        await transcodeVideoFxs(msVideoClip, clip.videoFxs, false, consider2DTransformAsProperty, clipInAnimationList)
        await transcodeVideoFxs(msVideoClip, clip.videoFxs, true, consider2DTransformAsProperty)  // 只处理rawFx
      }
      await transcodeAudioFxs(msVideoClip, clip.audioFxs, false)
    }
    resolve()
  })
}

function transcodeAudioClips (audioTrack, clips, assets, footages, timeline) {
  return new Promise(async function (resolve, reject) {
    for (let i = 0; i < audioTrack.getClipCount(); i++) {
      let msAudioClip = audioTrack.getClipByIndex(i)
      let footage
      if (footages.length > 0) {
        if (footages[0].clipInfos) {
          footage = footages.find(item => {
            if (item.type !== NvsFootageTypeEnum.Audio) return false
            return item.clipInfos.find(({clipIndex, trackIndex}) => trackIndex === audioTrack.getIndex() && clipIndex === i)
          })
        } else {
          const m3u8Path = msAudioClip.getFilePath()
          footage = footages.find(item => {
            if (item.type !== NvsFootageTypeEnum.Audio) return false
            return item.m3u8Path === m3u8Path
          })
        }
      }
      var clip = new AudioClip(i, footage.m3u8Path, footage.m3u8Url, footage.path, footage.orgDuration)
      clip.inPoint = msAudioClip.getInPoint()
      // clip.outPoint = Math.min(msAudioClip.getOutPoint(), timeline.getDuration())
      clip.outPoint = msAudioClip.getOutPoint()
      clip.trimIn = msAudioClip.getTrimIn()
      clip.trimOut = msAudioClip.getTrimOut()
      clip.volume = msAudioClip.getVolumeGain().leftVolume
      clip.speed = msAudioClip.getSpeed()
      clip.fadeInDuration = msAudioClip.getFadeInDuration()
      clip.fadeOutDuration = msAudioClip.getFadeOutDuration()
      clip.curveSpeedString = msAudioClip.getClipVariableSpeedCurvesString()
      clip.uuid = TimelineUtils.uuid()
      clip.title = footage.title
      clip.leftChannelUrl = footage.leftChannelUrl
      clip.rightChannelUrl = footage.rightChannelUrl
      if (footage.channelType) clip.channelType = footage.channelType
      if (clip.channelType === 'right') {
        clip.volume = msAudioClip.getVolumeGain().rightVolume
      } else {
        clip.volume = msAudioClip.getVolumeGain().leftVolume
      }

      const group = msAudioClip.getTemplateAttachment(Enum.attachmentType.fxGroup)
      if (group) {
        clip.combination = true
        clip.combinationOrder = Number(group)
      }

      clips.push(clip)
      // var pathList = m3u8Path.split('/');
      // await insertAssetToList(pathList[pathList.length - 1], m3u8Url, assets);

      transcodeAudioFxs(msAudioClip, clip.audioFxs)
    }
    resolve()
  })
}

function transcodeTransitions (videoTrack, transitions, assets) {
  return new Promise(async function (resolve, reject) {
    for (let i = 0; i < videoTrack.getClipCount(); i++) {
      let msTransiton = videoTrack.getTransitionBySourceClipIndex(i)
      if (msTransiton === undefined) continue
      if (msTransiton.getVideoTransitionType() === NvsVideoTransitionTypeEnum.Builtin) {
        var desc = msTransiton.getBuiltinVideoTransitionName()
        var transition = new Transition('builtin', i, desc)
        const assetInfo = builtInTransitionConfig.find(item => item.name === desc)
        if (assetInfo) {
          transition.displayName = assetInfo.displayName
          transition.displayNamezhCN = assetInfo.displayNamezhCN
        }
        transition.duration = msTransiton.getVideoTransitionDuration()
        transitions.push(transition)
      } else {
        var desc = msTransiton.getVideoTransitionPackageId()
        // await insertAssetToList(desc + '.videotransition', '', assets); // only video case
        var transition = new Transition('package', i, desc)
        if (assets) {
          const assetInfo = assets.find(item => item.id === desc)
          if (assetInfo) {
            transition.displayName = assetInfo.displayName
            transition.displayNamezhCN = assetInfo.displayNameZhCn
          }
        }
        transition.duration = msTransiton.getVideoTransitionDuration()
        transitions.push(transition)
      }
    }
    resolve()
  })
}

function transcodeVideoFxs (videoClip, videoFxs, isRaw, consider2DTransformAsProperty, clipInAnimationList) {
  return new Promise(async function (resolve, reject) {
    let msPropertyVideoFx = videoClip.getPropertyVideoFx()
    if (msPropertyVideoFx && !videoFxs.find(videoFx => videoFx.type === 'property')) {
      let videoFx = new VideoFx('property', videoClip.getFxCount(), '')
      videoFx.isRegional = msPropertyVideoFx.getRegional()
      videoFx.isIgnoreBackground = msPropertyVideoFx.getIgnoreBackground()
      videoFx.isInverseRegion = msPropertyVideoFx.getInverseRegion()
      videoFx.regionalFeatherWidth = msPropertyVideoFx.getRegionalFeatherWidth()
      videoFxs.push(videoFx)
      transcodeFxParams(msPropertyVideoFx, videoFx, clipInAnimationList)
      transcodeKeyFrames(msPropertyVideoFx, videoFx.keyFrames)
    }
    const length = isRaw ? videoClip.getRawFxCount() : videoClip.getFxCount()
    for (let i = 0; i < length; i++) {
      let msVideoFx = isRaw ? videoClip.getRawFxByIndex(i) : videoClip.getFxByIndex(i)
      var type = msVideoFx.getVideoFxType() === NvsVideoFxTypeEnum.Builtin ? 'builtin' : 'package'
      var desc
      if (type === 'builtin') {
        desc = msVideoFx.getBuiltinVideoFxName()
      } else if (type === 'package') {
        desc = msVideoFx.getVideoFxPackageId()
      }

      // Transform 2D 作为property特效处理
      if (consider2DTransformAsProperty && desc === 'Transform 2D') {
        let videoFx = videoFxs.find(videoFx => videoFx.type === 'property')
        if (!videoFx) {
          videoFx = new VideoFx('property', videoClip.getFxCount(), '')
          videoFxs.push(videoFx)
        }
        videoFx.isRegional = msVideoFx.getRegional()
        videoFx.isIgnoreBackground = msVideoFx.getIgnoreBackground()
        videoFx.isInverseRegion = msVideoFx.getInverseRegion()
        videoFx.regionalFeatherWidth = msVideoFx.getRegionalFeatherWidth()
        transcodeFxParams(msVideoFx, videoFx, null , transcodeTransform2DToProperty)
        transcodeKeyFrames(msVideoFx, videoFx.keyFrames)
        continue
      }

      // 有可能添加多个同名的特效，比如2D Tranform, 如果过滤可能会丢失效果，尤其是工程互通的时候
      // if (videoFxs.find(videoFx => videoFx.desc === desc)) continue

      var videoFx = new VideoFx(type, i, desc);
      videoFx.intensity = msVideoFx.getFilterIntensity();
      videoFx.isRegional = msVideoFx.getRegional()
      videoFx.isIgnoreBackground = msVideoFx.getIgnoreBackground()
      videoFx.isInverseRegion = msVideoFx.getInverseRegion()
      videoFx.regionalFeatherWidth = msVideoFx.getRegionalFeatherWidth()
      if (msVideoFx.getRegional()) {
        const vecRegionInfo = msVideoFx.getRegionInfos()
        const regionInfo = vecRegionInfo?.get(0)
        if (regionInfo?.type === 'ellipse') {
          videoFx.shape = 'ellipse'
        }
      }
      videoFx.isRaw = !!isRaw
      videoFxs.push(videoFx)
      transcodeFxParams(msVideoFx, videoFx);
      transcodeKeyFrames(msVideoFx, videoFx.keyFrames);
    }
    // 处理音量特效关键帧
    let msVolumeFx = videoClip.getAudioVolumeFx()
    if (msVolumeFx && (msVolumeFx.hasKeyframeList('Left Gain') || msVolumeFx.hasKeyframeList('Right Gain'))) {
      let videoFx = new VideoFx('volume', videoFxs.length)
      videoFxs.push(videoFx)
      transcodeVolumeKeyFrames(msVolumeFx, videoFx.keyFrames)
    }
    resolve()
  })
}

function transcodeAudioFxs (audioClip, audioFxs, isAudio = true) {
  const count = isAudio ? audioClip.getFxCount() : audioClip.getAudioFxCount()
  for (let i = 0; i < count; i++) {
    let msAudioFx = isAudio ? audioClip.getFxByIndex(i) : audioClip.getAudioFxByIndex(i)
    var audioFx = new AudioFx('builtin', i, msAudioFx.getBuiltinAudioFxName())
    audioFxs.push(audioFx)

    isAudio && transcodeFxParams(msAudioFx, audioFx)
  }
  // 处理音量特效关键帧
  let msVolumeFx = audioClip.getAudioVolumeFx()
  if (msVolumeFx && (msVolumeFx.hasKeyframeList('Left Gain') || msVolumeFx.hasKeyframeList('Right Gain'))) {
    let audioFx = new AudioFx('volume', audioFxs.length)
    audioFxs.push(audioFx)
    transcodeVolumeKeyFrames(msVolumeFx, audioFx.keyFrames)
  }
}

function transcodeFxParams (msFx, fx, clipInAnimationList, transcodeTransform2DToProperty) {
  let fxDescription = msFx.getDescription()
  let fxAllParams = fxDescription.getAllParamsInfo()
  for (let i = 0; i < fxAllParams.size(); i++) {
    let msParam = fxAllParams.get(i)
    var type = msParam.paramType
    var key = msParam.paramName
    var value
    // 避免动画资源路径保存成模板里面的固定路径，造成合成或再次打开云剪工程资源效果丢失
    if (fx.type === 'property' && transcodeTransform2DToProperty && !propertyIncludeKeys.includes(key)) continue
    if (excludeKeys.includes(key)) continue
    if (type === 'float' || type === 'FLOAT') {
      value = msFx.getFloatVal(key)
      fx.params.push(new FxParam('float', key, value))
    } else if (type === 'bool' || type === 'boolean' || type === 'BOOL') {
      value = msFx.getBooleanVal(key)
      fx.params.push(new FxParam('bool', key, value))
    } else if (type === 'color' || type === 'COLOR') {
      value = msFx.getColorVal(key)
      fx.params.push(new FxParam('color', key, Utils.NvsColorToHex(value)))
    } else if (type === 'string' || type === 'STRING') {
      value = msFx.getStringVal(key)
      if (key === 'Background Image') {
        value = value.substring(value.lastIndexOf('/') + 1)
      }
      if (value) {
        if (key === 'Package Id' || key === 'Post Package Id') {
          if (fx && clipInAnimationList) {
            fx.animationType = clipInAnimationList.find(item => item.id === value) ? 'inAnimation' : 'loopAnimation'
            fx.isPostInAnimation = key === 'Post Package Id'
          }
        } else if (key === 'Post Package2 Id' && fx) fx.isPostOutAnimation = true
      }
      fx.params.push(new FxParam('string', key, value))
    } else if (type === 'menu' || type === 'MENU') {
      value = msFx.getMenuVal(key)
      fx.params.push(new FxParam('menu', key, value))
    } else if (type === 'Arbitrary') {
      value = msFx.getArbitraryVal(key)
      if (value && value.size() > 0) {
        let arbitraryVal = value.get(0)
        if (arbitraryVal) {
          fx.shape = arbitraryVal.type
          let valueString = ''
          if (['polygon', 'cubicCurve'].includes(arbitraryVal.type)) {
            if (arbitraryVal.points) {
              for (let j = 0; j < arbitraryVal.points.size(); j++) {
                let point = arbitraryVal.points.get(j)
                valueString += point.x + ',' + point.y + ','
              }
              valueString = valueString.slice(0, valueString.length - 1)
            }
          } else if (arbitraryVal.type === 'ellipse') {
            valueString += arbitraryVal.center.x + ',' + arbitraryVal.center.y + ',' + arbitraryVal.a + ',' + arbitraryVal.b + ',' + arbitraryVal.theta
          }
          fx.params.push(new FxParam('arbitrary', key, valueString))
        }
      }
    }
  }
  if (msFx.getRegional()) {
    let vecRegionInfo = msFx.getRegionInfos()
    if (vecRegionInfo && vecRegionInfo.size() > 0) {
      let regionInfo = vecRegionInfo.get(0)
      if (regionInfo.type === 'polygon') {
        if (regionInfo.points.size() < 4) {
          console.warn('region info points is not enough!')
          return
        }
        var x1Val = regionInfo.points.get(0).x
        var y1Val = regionInfo.points.get(0).y
        var x2Val = regionInfo.points.get(1).x
        var y2Val = regionInfo.points.get(1).y
        var x3Val = regionInfo.points.get(2).x
        var y3Val = regionInfo.points.get(2).y
        var x4Val = regionInfo.points.get(3).x
        var y4Val = regionInfo.points.get(3).y
        value = {
          x1: x1Val,
          y1: y1Val,
          x2: x2Val,
          y2: y2Val,
          x3: x3Val,
          y3: y3Val,
          x4: x4Val,
          y4: y4Val
        }
        fx.params.push(new FxParam('object', 'region', value))
      } else if (regionInfo.type === 'ellipse') {
        var centerXVal = regionInfo.center.x
        var centerYVal = regionInfo.center.y
        var aVal = regionInfo.a
        var bVal = regionInfo.b
        var angleVal = regionInfo.theta
        value = {
          centerX: centerXVal,
          centerY: centerYVal,
          a: aVal,
          b: bVal,
          angle: angleVal
        }
        fx.params.push(new FxParam('object', 'ellipseRegion', value))
      }
    }
  }
}

function transcodeFxParamsWithoutFx (msFx, params) {
  let fxDescription = msFx.getDescription()
  let fxAllParams = fxDescription.getAllParamsInfo()
  for (let i = 0; i < fxAllParams.size(); i++) {
    let msParam = fxAllParams.get(i)
    var type = msParam.paramType
    var key = msParam.paramName
    var value
    if (type === 'float' || type === 'FLOAT') {
      value = msFx.getFloatVal(key)
      params.push(new FxParam('float', key, value))
    } else if (type === 'bool' || type === 'boolean' || type === 'BOOL') {
      value = msFx.getBooleanVal(key)
      params.push(new FxParam('bool', key, value))
    } else if (type === 'color' || type === 'COLOR') {
      value = msFx.getColorVal(key)
      params.push(new FxParam('color', key, Utils.NvsColorToHex(value)))
    } else if (type === 'string' || type === 'STRING') {
      value = msFx.getStringVal(key)
      params.push(new FxParam('string', key, value))
    } else if (type === 'menu' || type === 'MENU') {
      value = msFx.getMenuVal(key)
      params.push(new FxParam('menu', key, value))
    }
  }
}

function transcodeKeyFrames (videoFx, keyFrames) {
  const scaleXKeyFrames = videoFx.getParamKeyframes('Scale X')
  const transXKeyFrames = videoFx.getParamKeyframes('Trans X')
  const transYKeyFrames = videoFx.getParamKeyframes('Trans Y')
  const rotationKeyFrames = videoFx.getParamKeyframes('Rotation')
  const opacityKeyFrames = videoFx.getParamKeyframes('Opacity')
  const regionKeyFrames = videoFx.getParamKeyframes('Region Data')
  // sticker
  const stickerScaleKeyFrames = videoFx.getParamKeyframes(Enum.stickerKFParamType.scale)
  const stickerTransXKeyFrames = videoFx.getParamKeyframes(Enum.stickerKFParamType.transX)
  const stickerTransYKeyFrames = videoFx.getParamKeyframes(Enum.stickerKFParamType.transY)
  const stickerRotZKeyFrames = videoFx.getParamKeyframes(Enum.stickerKFParamType.rotation)
  // caption
  const captionScaleXKeyFrames = videoFx.getParamKeyframes(Enum.captionKFParamType.scaleX)
  const captionTransXKeyFrames = videoFx.getParamKeyframes(Enum.captionKFParamType.transX)
  const captionTransYKeyFrames = videoFx.getParamKeyframes(Enum.captionKFParamType.transY)
  const captionRotZKeyFrames = videoFx.getParamKeyframes(Enum.captionKFParamType.rotation)
  // fx
  const fxIntensityKeyFrames = videoFx.getParamKeyframes(Enum.fxKFParamType.intensity)
  for (let i = 0; i < fxIntensityKeyFrames.size(); i++) {
    const msKeyFrame = fxIntensityKeyFrames.get(i)
    keyFrames.push(new KeyFrame('float', msKeyFrame.time, Enum.fxKFParamType.intensity, msKeyFrame.floatVal, true))
  }
  for (let i = 0; i < captionScaleXKeyFrames.size(); i++) {
    const msKeyFrame = captionScaleXKeyFrames.get(i)
    keyFrames.push(new KeyFrame('float', msKeyFrame.time, Enum.captionKFParamType.scaleX, msKeyFrame.floatVal, true))
    keyFrames.push(new KeyFrame('float', msKeyFrame.time, Enum.captionKFParamType.scaleY, msKeyFrame.floatVal, true))
  }
  for (let i = 0; i < captionTransXKeyFrames.size(); i++) {
    const msKeyFrame = captionTransXKeyFrames.get(i)
    keyFrames.push(new KeyFrame('float', msKeyFrame.time, Enum.captionKFParamType.transX, msKeyFrame.floatVal, true))
  }
  for (let i = 0; i < captionTransYKeyFrames.size(); i++) {
    const msKeyFrame = captionTransYKeyFrames.get(i)
    keyFrames.push(new KeyFrame('float', msKeyFrame.time, Enum.captionKFParamType.transY, msKeyFrame.floatVal, true))
  }
  for (let i = 0; i < captionRotZKeyFrames.size(); i++) {
    const msKeyFrame = captionRotZKeyFrames.get(i)
    keyFrames.push(new KeyFrame('float', msKeyFrame.time, Enum.captionKFParamType.rotation, msKeyFrame.floatVal, true))
  }
  for (let i = 0; i < stickerScaleKeyFrames.size(); i++) {
    const msKeyFrame = stickerScaleKeyFrames.get(i)
    keyFrames.push(new KeyFrame('float', msKeyFrame.time, Enum.stickerKFParamType.rotation, msKeyFrame.floatVal, true))
  }
  for (let i = 0; i < stickerScaleKeyFrames.size(); i++) {
    const msKeyFrame = stickerScaleKeyFrames.get(i)
    keyFrames.push(new KeyFrame('float', msKeyFrame.time, Enum.stickerKFParamType.scale, msKeyFrame.floatVal, true))
  }
  for (let i = 0; i < stickerTransXKeyFrames.size(); i++) {
    const msKeyFrame = stickerTransXKeyFrames.get(i)
    keyFrames.push(new KeyFrame('float', msKeyFrame.time, Enum.stickerKFParamType.transX, msKeyFrame.floatVal, true))
  }
  for (let i = 0; i < stickerTransYKeyFrames.size(); i++) {
    const msKeyFrame = stickerTransYKeyFrames.get(i)
    keyFrames.push(new KeyFrame('float', msKeyFrame.time, Enum.stickerKFParamType.transY, msKeyFrame.floatVal, true))
  }
  for (let i = 0; i < stickerRotZKeyFrames.size(); i++) {
    const msKeyFrame = stickerRotZKeyFrames.get(i)
    keyFrames.push(new KeyFrame('float', msKeyFrame.time, Enum.stickerKFParamType.rotation, msKeyFrame.floatVal, true))
  }
  for (let i = 0; i < scaleXKeyFrames.size(); i++) {
    const msKeyFrame = scaleXKeyFrames.get(i)
    keyFrames.push(new KeyFrame('float', msKeyFrame.time, 'Scale X', msKeyFrame.floatVal, true))
    keyFrames.push(new KeyFrame('float', msKeyFrame.time, 'Scale Y', msKeyFrame.floatVal, true))
  }
  for (let i = 0; i < transXKeyFrames.size(); i++) {
    const msKeyFrame = transXKeyFrames.get(i)
    keyFrames.push(new KeyFrame('float', msKeyFrame.time, 'Trans X', msKeyFrame.floatVal, true))
  }
  for (let i = 0; i < transYKeyFrames.size(); i++) {
    const msKeyFrame = transYKeyFrames.get(i)
    keyFrames.push(new KeyFrame('float', msKeyFrame.time, 'Trans Y', msKeyFrame.floatVal, true))
  }
  for (let i = 0; i < rotationKeyFrames.size(); i++) {
    const msKeyFrame = rotationKeyFrames.get(i)
    keyFrames.push(new KeyFrame('float', msKeyFrame.time, 'Rotation', msKeyFrame.floatVal, true))
  }
  for (let i = 0; i < opacityKeyFrames.size(); i++) {
    const msKeyFrame = opacityKeyFrames.get(i)
    keyFrames.push(new KeyFrame('float', msKeyFrame.time, 'Opacity', msKeyFrame.floatVal, true))
  }
  for (let i = 0; i < regionKeyFrames.size(); i++) {
    const msKeyFrame = regionKeyFrames.get(i)
    const region = getRegionValueFromSdkValue(msKeyFrame.regionInfos)
    keyFrames.push(new KeyFrame('object', msKeyFrame.time, region.type === 'ellipse' ? 'ellipseRegion' : 'region', region, true))
  }
}

function transcodeVolumeKeyFrames (volumeFx, keyFrames) {
  if (!volumeFx) return
  const leftGainKeyFrames = volumeFx.getParamKeyframes('Left Gain')
  const rightGainKeyFrames = volumeFx.getParamKeyframes('Right Gain')
  for (let i = 0; i < leftGainKeyFrames.size(); i++) {
    const msKeyFrame = leftGainKeyFrames.get(i)
    keyFrames.push(new KeyFrame('float', msKeyFrame.time, 'Left Gain', msKeyFrame.floatVal, true))
  }
  for (let i = 0; i < rightGainKeyFrames.size(); i++) {
    const msKeyFrame = rightGainKeyFrames.get(i)
    keyFrames.push(new KeyFrame('float', msKeyFrame.time, 'Right Gain', msKeyFrame.floatVal, true))
  }
}
