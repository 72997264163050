const Task = 'Task'              //通知任务
const MediaTranscode = 'MediaTranscode'  // 资源转码
const MediaUpload = 'MediaUpload'      // 资源上传
const UploadQueue = 'UploadQueue'      // 上传队列
const START = 'start'
const PROGRESS = 'progress'
const SUCCESS = 'success'
const ERROR = 'error'
const AddTask = 'AddTask'
const UpdateTask = 'UpdateTask'
const CheckItemIsExist = 'CheckItemIsExist'
const AudioRecordTranscodeFinish = 'AudioRecordTranscodeFinish' // 录音转码完成

/**
 * 上传下载通知
 */
export default {
  AddTask,
  UpdateTask,
  CheckItemIsExist,
  Task,
  MediaTranscode,
  MediaUpload,
  UploadQueue,
  START,
  PROGRESS,
  SUCCESS,
  ERROR,
  AudioRecordTranscodeFinish
}

/**
 * 上传下载参数
 */
export class upDownParams {
  constructor (type) {
    this.type = type
  }
}

