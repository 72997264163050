import GL from './Global'
import TimelineUtil from './TimelineUtils'
import Utils from './Utils'
export class TimelineData {
  constructor (resWidth, resHeight) {
    this.resWidth = resWidth * 1
    this.resHeight = resHeight * 1
    this.duration = 0
    this.tracks = []
    this.videoSize = '16:9'
    this.controlSpeed = 1
    this.theme = {}
    this.selectTrackIndex = -1
    this.templateId = ''
    this.themeTemplateId = ''
    this.outputType = ''
    this.outputStart = 0
    this.outputEnd = -1
    this.fps = 25
    this.enableRenderOrderByZValue = true
    this.isShowSection = false    // 区间显示否是展示
    this.timeLineSectionWidth = 0 // 区间显示的宽度
    this.timeLineSectionLeft = 0  // 区间显示的左移距离
  }
}
export class WaterMark {
  constructor (params) {
    const { path, width, height, marginX, marginY } = params
    path !== undefined && (this.path = path)
    width !== undefined && (this.width = width * 1)
    height !== undefined && (this.height = height * 1)
    marginX !== undefined && (this.marginX = marginX * 1)
    marginY !== undefined && (this.marginY = marginY * 1)
    this.dirPath = params.dirPath
    this.imageName = params.imageName
    this.imgHeight = params.imgHeight * 1
    this.imgWidth = params.imgWidth * 1
    this.opacity = params.opacity * 1
    this.sceneHeight = params.sceneHeight * 1
    this.sceneWidth = params.sceneWidth * 1
    this.tx = params.tx * 1
    this.ty = params.ty * 1
    this.raw = null
  }
  setRaw (raw) {
    this.raw = raw
  }
  setData () {
    if (!this.raw) {
      console.error('raw is null')
      return
    }
    console.log('设置 水印', this)

    if (this.dirPath) {
      this.raw.addWatermark2(this.sceneWidth, this.sceneHeight, this.imageName, this.imgWidth, this.imgHeight, this.tx, this.ty, this.dirPath, this.opacity)
    } else {
      const index = this.path.lastIndexOf('/')
      const name = this.path.substring(index + 1, this.path.length)
      const path = `${GL.getResourcesFolder()}/${name}`
      this.raw.addWatermark(path, this.width || 0, this.height || 0, this.opacity || 1, this.position || 1, this.marginX || 0, this.marginY || 0)
    }
  }
}
export class StoryboardVideoFx {
  constructor ({ index, type, name, intensity, params }) {
    this.index = 1 * index
    this.type = type
    this.name = name
    this.intensity = intensity
    this.params = params || []
  }
}
export class FaceEffect {
  constructor ({ chinLength, faceSize, foreheadHeight, eyeSize, noseWidth, mouthSize, beautyWhitening, beautyReddening, beautyStrength }) {
    this.chinLength = chinLength
    this.faceSize = faceSize
    this.foreheadHeight = foreheadHeight
    this.eyeSize = eyeSize
    this.noseWidth = noseWidth
    this.mouthSize = mouthSize
    this.beautyWhitening = beautyWhitening
    this.beautyStrength = beautyStrength
    this.beautyReddening = beautyReddening
  }
  setVideoClipRaw (raw) {
    this.videoClipRaw = raw
  }
  setData () {
    // TODO 人脸还需要模型, 并且存的indexDB   noseWidth - 可能没有(1212.xml无)
    let { chinLength, faceSize, forehead, eyeSize, noseWidth, mouthSize, beautyWhitening, beautyReddening, beautyStrength, videoClipRaw } = this
    console.log('添加faceEffect')
    this.arSceneFx = videoClipRaw.appendRawBuiltinFx('AR Scene')
    if (!this.arSceneFx) {
      console.error('人脸特效: 添加AR Scene失败')
      return
    }
    let arSceneManipulate = this.arSceneFx.getARSceneManipulate()
    if (!arSceneManipulate) {
      console.error('人脸特效: ARSceneManipulate is null', this)
      return
    }
    arSceneManipulate.setDetectionMode(0x00000200)
    this.arSceneFx.setBooleanVal('Beauty Effect', true)
    this.arSceneFx.setBooleanVal('Beauty Face Only', true)
    this.arSceneFx.setBooleanVal('Beauty Shape', true)
    if (chinLength !== undefined) this.arSceneFx.setFloatVal('Chin Length Warp Degree', chinLength * 1)
    if (eyeSize !== undefined) this.arSceneFx.setFloatVal('Eye Size Warp Degree', eyeSize * 1)
    if (faceSize !== undefined) this.arSceneFx.setFloatVal('Face Size Warp Degree', faceSize * 1)
    if (forehead !== undefined) this.arSceneFx.setFloatVal('Forehead Height Warp Degree', forehead * 1)
    if (noseWidth !== undefined) this.arSceneFx.setFloatVal('Nose Width Warp Degree', noseWidth * 1)
    if (mouthSize !== undefined) this.arSceneFx.setFloatVal('Mouth Size Warp Degree', mouthSize * 1)
    if (beautyWhitening !== undefined) this.arSceneFx.setFloatVal('Beauty Whitening', beautyWhitening * 1)
    if (beautyReddening !== undefined) this.arSceneFx.setFloatVal('Beauty Reddening', beautyReddening * 1)
    if (beautyStrength !== undefined) this.arSceneFx.setFloatVal('Beauty Strength', beautyStrength * 1)
  }
}
// 时间线/videoClip都有这种AdjustData特效
export class AdjustData {
  constructor ({ amount, blackPoint, brightness, contrast, degree, highlight, saturation, shadow, temperature, tint, inPoint, duration }, raw) {
    this.amount = amount
    this.blackPoint = blackPoint
    this.brightness = brightness
    this.contrast = contrast
    this.degree = degree
    this.highlight = highlight
    this.saturation = saturation
    this.shadow = shadow
    this.temperature = temperature
    this.tint = tint
    this.inPoint = inPoint || 0
    this.duration = duration || 0
    this.raw = raw
  }
  setRaw (raw) {
    this.raw = raw
    if (this.raw.addBuiltinTimelineVideoFx) {
      // 时间线的adjustData
      this.inPoint = this.inPoint || 0
      this.duration = this.duration || this.raw.getDuration()
    } else {
      // video 的adjustData
      delete this.inPoint
      delete this.duration
    }
  }
  setData () {
    console.log('设置 adjustData', this)
    if (!this.raw) {
      console.error('"videoClipRaw" is undefined')
      return
    }
    let setBuiltin = name => {
      if (this.raw.appendRawBuiltinFx) return this.raw.appendRawBuiltinFx(name)
      else if (this.raw.addBuiltinTimelineVideoFx) return this.raw.addBuiltinTimelineVideoFx(this.inPoint, this.duration, name)
      console.error('没有添加builtin的方法')
    }
    this.vignetteFx = setBuiltin('Vignette')
    if (this.vignetteFx) {
      if (this.degree !== undefined && this.degree) this.vignetteFx.setFloatVal('Degree', this.degree * 1)
    } else console.error('特效添加失败, Vignette')

    this.basicImageAdjustFx = setBuiltin('BasicImageAdjust')
    if (this.basicImageAdjustFx) {
      if (this.highlight !== undefined && this.highlight) this.basicImageAdjustFx.setFloatVal('Highlight', this.highlight * 1)
      if (this.shadow !== undefined && this.shadow) this.basicImageAdjustFx.setFloatVal('Shadow', this.shadow * 1)
      if (this.brightness !== undefined && this.brightness) this.basicImageAdjustFx.setFloatVal('Brightness', this.brightness * 1)
      if (this.contrast !== undefined && this.contrast) this.basicImageAdjustFx.setFloatVal('Contrast', this.contrast * 1)
      if (this.blackPoint !== undefined && this.blackPoint) this.basicImageAdjustFx.setFloatVal('Blackpoint', this.blackPoint * 1)
      if (this.saturation !== undefined && this.saturation) this.basicImageAdjustFx.setFloatVal('Saturation', this.saturation * 1)
    } else console.error('特效添加失败, BasicImageAdjust')

    this.tintFx = setBuiltin('Tint')
    if (this.tintFx) {
      if (this.tint !== undefined) this.tintFx.setFloatVal('Tint', this.tint * 1)
      if (this.temperature !== undefined) this.tintFx.setFloatVal('Temperature', this.temperature * 1)
    } else console.error('特效添加失败, Tint')

    this.sharpenFx = setBuiltin('Sharpen')
    if (this.sharpenFx) {
      if (this.amount !== undefined) this.sharpenFx.setFloatVal('Amount', this.amount * 1)
    } else console.error('特效添加失败, Sharpen')
  }
}
export class Fx {
  constructor ({ index, inPoint, duration, type, clipSubType, displayName, displayNamezhCN, id, intensity, isRegional, isIgnoreBackground, isInverseRegion, shape, regionalFeatherWidth, params, name }, raw) {
    this.index = index
    this.inPoint = inPoint
    this.duration = duration
    this.type = type
    this.clipSubType = clipSubType
    this.displayName = displayName ? displayName : ''
    this.displayNamezhCN = displayNamezhCN ? displayNamezhCN : ''
    this.id = id
    this.name = name
    this.intensity = intensity
    this.isRegional = isRegional
    this.isIgnoreBackground = isIgnoreBackground
    this.shape = shape
    this.regionalFeatherWidth = regionalFeatherWidth
    this.isInverseRegion = isInverseRegion
    this.raw = raw
    this.fx = null
    this.params = params || []
  }
  setRaw (raw) {
    this.raw = raw
  }
  async setData () {
    if (!this.raw) {
      console.error('Raw is undefined')
      return
    }
    let duration = this.raw.getDuration()
    if (duration < this.inPoint + this.duration) {
      await TimelineUtil.changeEmptyTrack(this.inPoint + this.duration)
    }
    if (this.type === 'package') {
      this.fx = this.raw.addPackagedTimelineVideoFx(this.inPoint, this.duration, this.id)
    } else {
      this.fx = this.raw.addBuiltinTimelineVideoFx(this.inPoint, this.duration, this.name)
    }
    if (!this.fx) {
      console.error('时间线特效添加失败:', this.inPoint, this.duration, this.id, this.type)
      return
    }
    this.fx.setFilterIntensity(this.intensity * 1)
    this.fx.setRegional(this.isRegional)
    this.fx.setIgnoreBackground(this.isIgnoreBackground)
    this.fx.setInverseRegion(this.isInverseRegion)
    this.fx.setRegionalFeatherWidth(this.regionalFeatherWidth * 1)
    // TODO shape 字段 未使用
    const params = this.fxParams || this.params
    Array.isArray(params) && params.map((item, index) => {
      if (!(item instanceof FxParam)) {
        params[index] = new FxParam(item.type, item.key, item.value)
      }
      params[index].setData(this.fx)
    })
  }
}
export class Raw {
  constructor (raw) {
    this.raw = raw
  }
}
export class Track extends Raw {
  constructor (type, index) {
    super(null)
    this.type = type
    this.index = index
    this.show = true
    this.volume = 1
    this.clips = []
    this.tips = ''
    this.icon = ''
    this.selected = false
    this.uuid = TimelineUtil.uuid()
  }
}
export class VideoTrack extends Track {
  constructor (index) {
    super('videoTrack', index)
    this.transitions = []
  }
}
export class AudioTrack extends Track {
  constructor (index) {
    super('audioTrack', index)
    this.transitions = []
  }
}
export class TimelineVideoFxTrack extends Track {
  constructor (index) {
    super('timelineVideoFxTrack', index)
  }
}

/**
 * captionType String
 */
export class CaptionTrack extends Track {
  constructor (index, captionType) {
    super('captionTrack', index)
    // 废弃
    this.captionType = captionType
  }
}
export class MusicLyricsTrack extends Track {
  constructor (index) {
    super('musicLyricsTrack', index)
    this.font = ''
    this.fontSize = -1
    this.color = ''
    this.align = 'center'
    this.styleDesc = GL.getDefaultSubtitleStyle()
    this.scaleX = 1 // nvsTimelineCaption.getScaleX()
    this.scaleY = 1 // nvsTimelineCaption.getScaleY()
    this.rotation = 0
    this.translationX = 0 // nvsTimelineCaption.getCaptionTranslation().x
    this.translationY = 0 // nvsTimelineCaption.getCaptionTranslation().y
    this.outlineWidth = ''
    this.outlineColor = ''
    this.outline = false
  }
}
export class CompoundCaptionTrack extends Track {
  constructor (index) {
    super('compoundCaptionTrack', index)
  }
}
export class StickerTrack extends Track {
  constructor (index) {
    super('stickerTrack', index)
  }
}
export class Transition extends Raw {
  constructor (clipType, index, desc, displayName, displayNamezhCN) {
    super(null)
    this.index = index
    this.clipType = clipType
    this.desc = desc
    this.selected = false
    this.type = 'transition'
    this.displayName = displayName ? displayName : ''
    this.displayNamezhCN = displayNamezhCN ? displayNamezhCN : ''
    this.duration = 1 * 1000000
    //  记录当前转场附着的clip的uuid 在多选拖拽或者覆盖时作为标识作用
    this.clipUUID = ''
  }
}
export class Clip extends Raw {
  constructor (type, index) {
    super(null)
    this.index = index
    this.type = type
    this.inPoint = -1
    this.outPoint = -1
    this.selected = false
    this.isDragging = false
    this.isInvalid = false  // 片段是否可用
    this.tags = []
    this.combination = false
    this.combinationOrder = 0
  }
}

/**
 * videoType 1 视频 2 音频 3 图片
 */
export class VideoClip extends Clip {
  constructor (index, videoType, m3u8Path, m3u8Url, path, duration) {
    super('video', index)
    this.id = -1
    this.m3u8Path = m3u8Path
    this.alphaM3u8Path = ''
    this.path = path
    this.alphaPath = ''
    this.m3u8Url = m3u8Url
    this.alphaM3u8Url = ''
    this.videoType = videoType
    this.trimIn = 0
    this.trimOut = duration
    this.orgDuration = duration
    this.volume = 1
    this.speed = 1
    this.curveSpeedString = ""
    this.curveSpeedName = ""
    this.bgBlur = false
    this.bgBlurRadius = 50  // 背景模糊强度，和其他端设置的值对齐，修改为50
    this.fadeInDuration = 0
    this.fadeOutDuration = 0
    this.extraRotation = 0 // 旋转角度
    // 倒放信息
    this.reverse = false
    this.reverseStatus = 2 // 未倒放
    this.reverseAlphaM3u8Path = ''
    this.reverseAlphaM3u8Url = ''
    this.reverseAlphaPath = ''
    this.reverseM3u8Path = ''
    this.reverseM3u8Url = ''
    this.reversePath = ''
    this.noAudio = false  // noAudio为true时，对应的volume一定是0
    this.separated = false // 视音频是否分离
    this.videoFxs = []
    this.audioFxs = []
    this.thumbnailBaseUrl = ''
    this.thumbnails = []
    this.thumbnailHost = ''
    this.orgReverseThumbnailHost = '' // 存放倒放后原本的缩略图信息
    this.thumbnailStep = 1000000
    this.thumbnailType = 'jpg'
    this.title = ''
    this.uuid = TimelineUtil.uuid()
    this.leftChannelUrl = ''
    this.rightChannelUrl = ''
    this.width = 0
    this.height = 0
    this.blendingMode = 0
    this.enableClipFreezeFrame = false
    this.freezeFrameTrimPos = 0
    this.fillMode = 1
  }
}
export class AudioClip extends Clip {
  constructor (index, m3u8Path, m3u8Url, path, duration) {
    super('audio', index)
    // left,right
    this.channelType = 'stereo'
    this.id = -1
    this.m3u8Path = m3u8Path
    this.path = path
    this.m3u8Url = m3u8Url
    this.trimIn = 0
    this.trimOut = duration
    this.orgDuration = duration
    this.volume = 1
    this.speed = 1
    this.fadeInDuration = 0
    this.fadeOutDuration = 0
    this.audioFxs = []
    this.title = ''
    this.leftChannelUrl = ''
    this.rightChannelUrl = ''
    this.uuid = TimelineUtil.uuid()
    // 设置多音频流使用的是哪个流
    this.audioStreamIndex = 0
  }
}
export class TimelineVideoFxClip extends Clip {
  constructor (index, clipType, desc) {
    super('timelineVideoFx', index)
    this.clipType = clipType
    this.clipSubType = '' // 区分模糊,马赛克,调整
    this.desc = desc
    this.displayName = ''
    this.displayNamezhCN = ''
    this.intensity = 1
    this.nvTypeId = -1  // 区分滤镜,粒子
    this.nvCategoryId = -1
    this.isRegional = false
    this.isIgnoreBackground = false
    this.isInverseRegion = false
    this.shape = 'rect'
    this.regionalFeatherWidth = 0
    this.params = []
    this.keyFrames = []
    this.descArray = null   // 调整使用
    this.multiRaw = null  // 调整使用
    this.multiParams = null // 调整使用
  }
}
export class CaptionClip extends Clip {
  constructor (index, text, clipSubType, captionType, styleDesc, category) {
    super('caption', index)
    this.text = text
    this.clipSubType = clipSubType // richtext or general or modular
    this.captionType = captionType // materialType
    this.styleDesc = styleDesc // 如果是富文本，存的是贴纸的id，否则存的是字幕的id
    this.category = category  // 判断是花字，气泡，入出，组合动画
    // 模块字幕
    this.contextId = '' // 模块字幕环境样式包ID
    this.rendererId = ''  // 模块字幕渲染样式包ID
    this.animationId = '' // 模块字幕循环动画样式包ID
    this.inAnimationId = '' // 模块字幕入动画样式包ID
    this.outAnimationId = '' // 模块字幕出动画样式包ID
    this.animationPeroid = 0 // 模块字幕循环动画的周期，单位微秒，给底层数据时再除1000
    this.inAnimationDuration = 0 // 模块字幕入动画的时长，单位微秒，给底层数据时再除1000
    this.outAnimationDuration = 0 // 模块字幕出动画的时长，单位微秒，给底层数据时再除1000
    this.scaleX = 1 // nvsTimelineCaption.getScaleX()
    this.scaleY = 1 // nvsTimelineCaption.getScaleY()
    this.rotation = 0
    this.translationX = 0 // nvsTimelineCaption.getCaptionTranslation().x
    this.translationY = 0 // nvsTimelineCaption.getCaptionTranslation().y
    this.font = ''
    this.fontSize = -1
    this.color = ''
    this.bold = ''      // true/false
    this.weight = -1    // use weight to replace bold setting
    this.italic = ''    // true/false
    this.align = ''     // center/left/right/top/bottom/vCenter
    this.shadow = ''    // true/false
    this.shadowColor = ''
    this.shadowOffsetX = 0
    this.shadowOffsetY = 0
    this.shadowFeather = 0
    this.outline = ''   // true/false
    this.outlineColor = ''
    this.outlineWidth = []
    this.bgColor = ''
    this.bgRadius = ''
    this.underline = ''   // true/false
    this.letterSpacingPercentage = 100
    this.letterSpacingAbsolute = 0
    this.letterSpacingType = 0  // 0/1
    this.lineSpacing = 0
    this.keyFrames = []
    this.uuid = '' // 作为字幕唯一标识，可以用来识别富文本字幕对应的贴纸的图片
    this.richHtmlDescription = ''
    this.richCustomStickerImagePath = ''
    this.richCustomStickerImageUrl = ''
    this.params = []
    this.enableSpeed = false
  }
}
export class MusicLyricsClip extends Clip {
  constructor (index) {
    super('musicLyrics', index)
    this.text = ''
    this.inputSelect = false
    this.isLyrics = true
  }
}
export class CompoundCaptionClip extends Clip {
  constructor (index, styleDesc) {
    super('compoundCaption', index)
    this.text = []
    this.styleDesc = styleDesc
    this.scaleX = 1 // nvsTimelineCaption.getScaleX()
    this.scaleY = 1 // nvsTimelineCaption.getScaleY()
    this.rotation = 0
    this.translationX = 0 // nvsTimelineCaption.getCaptionTranslation().x
    this.translationY = 0 // nvsTimelineCaption.getCaptionTranslation().y
    this.font = ''
    this.color = ''
    this.displayName = ''
    this.displayNamezhCN = ''
    this.keyFrames = []
  }
}
export class TemplateClip extends Clip {
  constructor (index, id) {
    super('template', index)
    this.id = id //模板ID
    this.caption = null // new CompoundCaptionClip // 字幕
    this.backgroundImage = null //new VideoClip // 背景图
    this.video = null // 开窗视频
    this.buckle = '' // 扣像
    this.heterochromia = 0 // 异色
  }
}
export class StickerClip extends Clip {
  constructor (index, desc) {
    super('sticker', index)
    this.stickerType = 'general'
    this.desc = desc
    this.scale = 1 // nvsAnimatedSticker.getScale
    this.rotation = 0 // nvsAnimatedSticker.getRotationZ
    this.translationX = 0 // nvsAnimatedSticker.getTranslation().x
    this.translationY = 0 // nvsAnimatedSticker.getTranslation().y
    this.horizontalFlip = false
    this.verticalFlip = false
    this.displayName = ''
    this.displayNamezhCN = ''
    this.keyFrames = []
    this.path = '' // 自定义贴纸的path
    this.animationId = '' // 循环动画样式包ID
    this.inAnimationId = '' // 入动画样式包ID
    this.outAnimationId = '' // 出动画样式包ID
    this.animationPeroid = 0 // 循环动画的周期，单位微秒，给底层数据时再除1000
    this.inAnimationDuration = 0 // 入动画的时长，单位微秒，给底层数据时再除1000
    this.outAnimationDuration = 0 // 出动画的时长，单位微秒，给底层数据时再除1000
    this.uuid = '' // 标识唯一性
    this.volume = 1
  }
}
export class VideoFx extends Raw {
  constructor (type, index, desc) {
    super(null)
    this.type = type // package, builtin, property
    this.desc = desc
    this.index = index
    this.videoFxType = '' // animation, filter, effect
    this.animationType = '' // inAnimation, loopAnimation
    this.isPostInAnimation = false
    this.isPostOutAnimation = false
    this.intensity = 1
    this.isInverseRegion = false
    this.isRegional = false
    this.params = []
    this.keyFrames = []
    this.intensity = 1
    this.isRegional = false
    this.isIgnoreBackground = false
    this.isInverseRegion = false
    this.shape = 'rect'
    this.regionalFeatherWidth = 0
    this.isRaw = false  // 添加特效使用 appendRawBuiltinFx 方法，而不是默认的 appendBuiltinFx
  }
}
export class AudioFx extends Raw {
  constructor (type, index, desc) {
    super(null)
    this.type = type
    this.desc = desc
    this.index = index
    this.params = []
    this.keyFrames = []
  }
}
export class FxParam {
  constructor (type, key, value) {
    this.type = type
    this.key = key
    this.value = value
  }
  setData (raw, fx) {
    if (!raw) {
      console.error('FxParam set error, raw is null')
      return
    }
    if (this.type === 'float') {
      raw.setFloatVal(this.key, this.value)
    } else if (this.type === 'int') {
      raw.setIntVal(this.key, this.value)
    } else if (this.type === 'bool') {
      raw.setBooleanVal(this.key, this.value)
    } else if (this.type === 'string') {
      raw.setStringVal(this.key, this.value)
    } else if (this.type === 'menu') {
      raw.setMenuVal(this.key, this.value)
    } else if (this.type === 'object') {
      if (this.key === 'region') {
        const region = new NvsVectorFloat()
        region.push_back(this.value.x1)
        region.push_back(this.value.y1)
        region.push_back(this.value.x2)
        region.push_back(this.value.y2)
        region.push_back(this.value.x3)
        region.push_back(this.value.y3)
        region.push_back(this.value.x4)
        region.push_back(this.value.y4)
        raw.setRegion(region)
      } else if (this.key === 'ellipseRegion') {
        const center = new NvsPointF(this.value.centerX, this.value.centerY)
        raw.setEllipseRegion(center, this.value.a, this.value.b, this.value.angle)
        fx && raw.setRegionalFeatherWidth(fx.regionalFeatherWidth)
      }
    } else if (this.type === 'color') {
      raw.setColorVal(this.key, Utils.HexToNvsColor(this.value))
      // 以下俩参数 是自动设置的默认值
      raw.setBooleanVal('Spill Removal', true)
      raw.setFloatVal('Softeness Amendment', 0.1)
    }
  }
}
export class KeyFrame {
  constructor (type, time, key, value, visible) {
    this.type = type
    this.time = time
    this.key = key
    this.value = value
    this.visible = visible
  }
}

// 字幕对象, 用于模板
export class Caption {
  constructor (timeline, {text, inPoint, duration, captionStylePackageId}) {
    this.timeline = timeline
    this.text = text
    this.inPoint = inPoint
    this.duration = duration
    this.captionStylePackageId = captionStylePackageId || ''
    this.raw = null
    if (timeline) this.addCaption()
  }
  addCaption () {
    if (!this.timeline) {
      console.error('timeline is null')
      return
    }
    this.raw = this.timeline.addCaption(this.text, this.inPoint, this.duration, this.captionStylePackageId, false)
  }
  setText (text) {
    this.text = text
    this.raw.setText(text)
  }
}
