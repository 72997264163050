import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// 这里组件需要异步加载，不然会造成生产环境css冲突
export default new Router({
  mode: 'history',
  base: `/${process.env.APP_VERSION}/`,
  routes: [
    {
      path: '/',
      redirect: '/Login',
    }, {
      path: '/Login',
      name: 'Login',
      component: () => import('@/components/login/index'),
      hidden: true
    }, {
      path: '/Register',
      name: 'Register',
      component: () => import('@/components/login/index'),
      meta: { title: 'router.login.register' },
      hidden: true
    }, {
      path: '/GetBackPassword',
      name: 'GetBackPassword',
      component: () => import('@/components/login/index'),
      meta: { title: 'router.login.getBackPassword' },
      hidden: true
    }, {
      path: '/Home',
      name: 'Home',
      component: () => import('@/components/home/Home'),
      redirect: '/Home/ProjectList',
      children: [
        {
          path: 'ProjectList',
          component: () => import(/* webpackChunkName: "home" */ '@/components/home/ProjectList'),
          name: 'ProjectList'
        },
        {
          path: 'MyPieces',
          component: () => import(/* webpackChunkName: "home" */ '@/components/home/MyPieces'),
          name: 'MyPieces'
        },
        {
          path: 'TemplateList',
          component: () => import(/* webpackChunkName: "home" */ '@/components/nvsTemplate/TemplateList'),
          name: 'TemplateList'
        }
      ]
    }, {
      path: '/App',
      name: 'App',
      component: () => import('../App')
    }, {
      path: '/TextVideo',
      name: 'TextVideo',
      component: () => import('@/components/textVideo/index')
    }, {
      path: '/LiveApp/:projectId',
      name: 'LiveApp',
      component: () => import('@/components/liveApp/LiveApp')
    }, {
      path: '/Template',
      name: 'Template',
      component: () => import('@/components/nvsTemplate/TemplateApp')
    }, {
      path: '/CoverApp',
      name: 'CoverApp',
      component: () => import('../App')
    }, {
      path: '/Compound',
      name: 'Compound',
      component: () => import('@/components/home/Compound')
    }, {
      path: '/tt',
      name: 'tt',
      component: () => import('../test/TestTimeline.vue')
    }
  ]
})
