var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "b-root" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !this.isEquipmentSupport,
            expression: "!this.isEquipmentSupport"
          }
        ],
        attrs: { id: "b-equipment" }
      },
      [
        _c("img", {
          attrs: { src: "/static/browser/icon_browser_system.png" }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "equipment-title" }, [
          _vm._v(_vm._s(this.$t("browser.equipmentTitle")))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "equipment-sub-title" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                this.$t("browser.equipmentSubTitle") +
                  this.$t("browser.Windows") +
                  "、" +
                  this.$t("browser.Mac") +
                  "、" +
                  this.$t("browser.Linux")
              ) +
              "\n    "
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !this.isBrowserSupport,
            expression: "!this.isBrowserSupport"
          }
        ],
        attrs: { id: "b-n-browser" }
      },
      [
        _c("img", {
          attrs: { src: "/static/browser/icon_browser_browser.png" }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "n-browser-title" }, [
          _vm._v(_vm._s(this.$t("browser.browserTitle")))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "n-browser-desc" },
          _vm._l(_vm.recommendData, function(item, index) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: item.isShow,
                    expression: "item.isShow"
                  }
                ],
                key: index,
                staticClass: "n-browser-sub-show"
              },
              [
                _c("img", { attrs: { src: item.icon } }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "recommend-to",
                    on: {
                      click: function($event) {
                        return _vm.goRecommendUrl(index)
                      }
                    }
                  },
                  [_vm._v(_vm._s(item.message))]
                )
              ]
            )
          }),
          0
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: this.isBrowserSupport,
            expression: "this.isBrowserSupport"
          }
        ],
        attrs: { id: "b-y-browser" }
      },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !this.isBrowserVersionSupport,
                expression: "!this.isBrowserVersionSupport"
              }
            ],
            staticClass: "y-browser-version"
          },
          [
            _c("img", {
              attrs: { src: "/static/browser/icon_browser_update.png" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "update-title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    this.$t("browser.updateTitle") +
                      _vm.isBrowserVersionSubTitle +
                      _vm.$t("browser.browser") +
                      (_vm.is360 ? _vm.$t("browser._360Cut") : "")
                  ) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("img", {
              staticStyle: { "margin-top": "31px" },
              attrs: { src: this.isBrowserVersionIcon }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "update-to",
                on: {
                  click: function($event) {
                    return _vm.toUpdate(_vm.isBrowserVersionUpdateUrl)
                  }
                }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(this.isBrowserVersionTitle) + "\n      "
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  this.isBrowserVersionSupport && !this.isBrowserParamsSupport,
                expression:
                  "this.isBrowserVersionSupport && !this.isBrowserParamsSupport"
              }
            ],
            staticClass: "browser-params"
          },
          [
            _c("div", { staticClass: "params-re-chrome" }, [
              _c("div", { staticClass: "re-params-title" }, [
                _vm._v(_vm._s(this.$t("browser.recommendChrome")))
              ]),
              _vm._v(" "),
              _c("img", {
                staticStyle: { "margin-top": "31px" },
                attrs: { src: "/static/browser/icon_google.png" }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "re-params-to",
                  on: {
                    click: function($event) {
                      return _vm.toUpdate(
                        "http://www.google.cn/chrome/browser/desktop/index.html"
                      )
                    }
                  }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(this.$t("browser.Chrome")) +
                      "\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "re-params-no" }, [
                _vm._v(_vm._s(_vm.$t("browser.recommendNo")))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "y-browser-params" }, [
              _c("div", { staticClass: "params-title" }, [
                _vm._v(_vm._s(this.$t("browser.paramsTitle")))
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "params-icon",
                attrs: { src: _vm.isBrowserParamsUrl }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "params-sub-title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      (this.isSupportSharedArrayBuffer()
                        ? ""
                        : this.$t("browser.noSupport") + "SharedArrayBuffer") +
                        "" +
                        (this.isSupportWebassembly()
                          ? ""
                          : this.$t("browser.noSupport") + "Webassembly")
                    ) +
                    "\n        "
                )
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !this.isBrowserModeSupport,
                expression: "!this.isBrowserModeSupport"
              }
            ],
            staticClass: "y-browser-version"
          },
          [
            _c("img", {
              attrs: { src: "/static/browser/icon_browser_update.png" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "update-title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.isBrowserVersionSubTitle +
                      _vm.$t("browser.browser") +
                      _vm.$t("browser.updateModeTitle")
                  ) +
                  "\n      "
              )
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }