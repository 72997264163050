import Global from './Global'
import NvNetwork from './network/NvNetwork'
import Enum from '@/utils/enum'
import { simd } from 'wasm-feature-detect'
import store from '../store'
import { saveAndInstallPackage } from './AssetsUtils'
import axios from 'axios'
import { buildFormatedThumbnailNumber } from '@/utils'
import i18n from "@/i18n";

export default {
  getMaterialCenterHost,
  timestampToTime,
  timestampToDay,
  formatDateTime,
  secToTime,
  secToTimeDefault,
  convertToSrtTime,
  isMediaSupportFormat,
  getMediaFormatType,
  getNameFromUrl,
  replacePathName,
  getCOSHost,
  isZHLan,
  RGBAToNvsColor,
  RGBAToHex,
  NvsColorToRGBA,
  ARGBHexToRGBA,
  HexToNvsColor,
  HexToRGBA,
  NvsColorToHex,
  signMix,
  myBrowser,
  displayBasedOnCurLanguage,
  generateUUID,
  getUUIDFromStr,
  getVersionFromStr,
  getApiHost,
  isEmpty,
  setVideoClipThumbnails,
  getAssetHost,
  hasFaceEffect,
  isMac,
  isWindows,
  isCtrlKey,
  isShiftKey,
  replaceKeyName,
  range,
  uploadJSON,
  uploadTemplateFile,
  compareSdkVersion,
  getDurationFromM3u8,
  getLiveWsHost,
  getLiveApiHost,
  isAuth,
  getAuthUrl,
  getStorageKeyPath,
  getFileName,
  getDefaultLiveUrl,
  needLowerMemory,
  getWasmModuleName,
  getEffectWasmModuleName,
  getFontFamily,
  getThumbnailUrl,
  getFrameTime,
  getFloorFrameTime,
  download,
  getTrackDOMHeight,
  getDragIconStyle,
  formatFilesize
}
// 获取直播剪辑的默认直播地址，目前是写死的，以后会改成从接口中获取
function getDefaultLiveUrl () {
  const temp = {
    aws: 'https://d3eban5zvz6846.cloudfront.net/m3u8Transform/demo/demo.m3u8',
    aliyun: 'https://alieasset.meishesdk.com/m3u8Transform/demo/demo.m3u8',
    testing: 'https://alieasset.meishesdk.com/m3u8Transform/demo/demo.m3u8',
    debug: 'https://alieasset.meishesdk.com/m3u8Transform/demo/demo.m3u8',
    development: 'https://alieasset.meishesdk.com/m3u8Transform/demo/demo.m3u8'
  }
  return (
    temp[process.env.NODE_ENV] ||
    'https://alieasset.meishesdk.com/m3u8Transform/demo/demo.m3u8'
  )
}
/**
 * 时间戳转换
 * @param time
 * @returns {string}
 */
function timestampToTime (time) {
  var date = new Date(time * 1000) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear() + '-'
  var M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-'
  var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  var h = date.getHours() + ':'
  var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  return Y + M + D + h + m
}

function timestampToDay (time) {
  var date = new Date(time * 1000)
  var Y = date.getFullYear() + '-'
  var M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-'
  var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
  return Y + M + D
}

/**
 * @description                                 格式化日期
 * @param  {Object|String|Number}    time       日期对象或时间戳
 * @param  {String}                  format     格式化模式
 * @return {String}                             格式化后的日期字符串
 */
function formatDateTime (time, format = 'yyyy-MM-dd HH:mm:ss') {
  if (!time) return '-'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        time = parseInt(time)
      } else {
        return '-'
      }
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    M: date.getMonth() + 1,
    d: date.getDate(),
    H: date.getHours(),
    m: date.getMinutes(),
    s: date.getSeconds(),
  }
  const time_str = format.replace(/([yMdHms])+/g, (result, key) => {
    const value = formatObj[key]
    return value.toString().padStart(2, '0')
  })
  return time_str
}

// 传给setFontFamily接口的字体，带中括号
async function getFontFamily (font) {
  const fonts = store.state.material.fonts
  const f = fonts.find(f => {
    if (f.stringValue === font) {
      return true
    }
    // HYQiHei-55S [HYQiHei-HES]
    const temp = f.stringValue.split(' [')
    if (Array.isArray(temp) && temp[0] === font) {
      return true
    }
    return false
  })
  if (f) {
    let familyNameFromStringValue = f.stringValue
    if (!f.hasInstalled) {
      console.warn('字体库有这个字体 但是需要安装 ', familyNameFromStringValue)
      const familyName = await saveAndInstallPackage(f)
      if (familyNameFromStringValue.split(' [')[0] !== familyName) {
        store.commit('material/updateFontNameObj', {
          key: familyNameFromStringValue,
          value: familyName
        })
      }
      store.commit('material/changeFontStatus', familyNameFromStringValue)
      if (store.state.material.fontNameObj.hasOwnProperty(familyNameFromStringValue)) {
        familyNameFromStringValue = store.state.material.fontNameObj[familyNameFromStringValue]
      }
      return familyNameFromStringValue
    } else {
      // console.warn('该字体已安装 ', familyNameFromStringValue)
      if (store.state.material.fontNameObj.hasOwnProperty(familyNameFromStringValue)) {
        familyNameFromStringValue = store.state.material.fontNameObj[familyNameFromStringValue]
      }
      return familyNameFromStringValue
    }
  } else {
    console.warn(`字体库中没有 ${font}, 使用默认字体${Global.getDefaultFontFamily()}`)
    return Global.getDefaultFontFamily()
  }
}

/**
 * Duration 转换
 * @param value
 * @returns {string}
 * @test 3599930/59930
 */
function secToTime (value) {
  let time = value
  if (!value) {
    return '--:--:--'
  }
  if (time < 1000) {
    time = 1000
  }
  let sec = parseInt(time) / 1000
  let min
  let hour = parseInt(sec / 3600)
  if (hour > 0) {
    sec %= 3600
    min = parseInt(sec / 60)
    if (min > 0) {
      sec %= 60
    }
  } else {
    min = parseInt(sec / 60)
    if (min > 0) {
      sec %= 60
    }
  }
  if (sec > 59.5) {
    sec = 0
    min++
    if (min === 60) {
      min = 0
      hour++
    }
  } else if (sec > 59) {
    sec = 59
  } else {
    sec = sec.toFixed(0)
  }
  return unitFormat(hour) + ':' + unitFormat(min) + ':' + unitFormat(sec)
}

/**
 * Duration 转换
 * @param value
 * @returns {string}
 */
function secToTimeDefault (value) {
  let time = value
  let sec = parseInt(time) / 1000
  let min
  let hour = parseInt(sec / 3600)
  if (hour > 0) {
    sec %= 3600
    min = parseInt(sec / 60)
    if (min > 0) {
      sec %= 60
    }
  } else {
    min = parseInt(sec / 60)
    if (min > 0) {
      sec %= 60
    }
  }
  return (
    unitFormat(hour) + ':' + unitFormat(min) + ':' + unitFormat(sec.toFixed(0))
  )
}

function convertToSrtTime (us) {
  const seconds = us / Global.CONVERT
  let sec = parseInt(seconds)
  let ms = Math.round((seconds - sec) * 1000)
  let min
  let hour = parseInt(sec / 3600)
  if (hour > 0) {
    sec %= 3600
    min = parseInt(sec / 60)
    if (min > 0) {
      sec %= 60
    }
  } else {
    min = parseInt(sec / 60)
    if (min > 0) {
      sec %= 60
    }
  }
  return (
    buildNumberString(hour) +
    ':' +
    buildNumberString(min) +
    ':' +
    buildNumberString(sec) +
    ',' +
    buildThreeNumberString(ms)
  )
}

function buildNumberString (number) {
  let numberString = ''
  if (number < 10) {
    numberString += '0'
  }
  return numberString + number.toString()
}

function buildThreeNumberString (number) {
  let numberString = ''
  if (number < 100) {
    numberString += '0'
  }
  if (number < 10) {
    numberString += '0'
  }
  return numberString + number.toString()
}

/**
 * 是否支持上传该视频格式
 * @param name
 * @returns {boolean}
 */
function isMediaSupportFormat (name) {
  let format = name.split('.')
  let suffix = format[format.length - 1].toLowerCase()
  console.log('current format ---> ', suffix)
  return (
    Global.videoTypes.indexOf(suffix) !== -1 ||
    Global.audioTypes.indexOf(suffix) !== -1 ||
    Global.imageTypes.indexOf(suffix) !== -1
  )
}

/**
 * 获取媒体格式类型  1-视频   2-音频  3-图片
 * @param name
 * @returns {number}
 */
function getMediaFormatType (name) {
  if (name === undefined) {
    return 0
  }
  let format = name.split('.').pop()

  if (Global.videoTypes.indexOf(format.toLowerCase()) !== -1) {
    return 1
  }
  if (Global.audioTypes.indexOf(format.toLowerCase()) !== -1) {
    return 2
  }
  if (Global.imageTypes.indexOf(format.toLowerCase()) !== -1) {
    return 3
  }
  return -1
}

function getStorageKeyPath (filename, ext) {
  let type = getMediaFormatType(filename || ext)
  let now = new Date()
  let year = now.getFullYear()
  let month = now.getMonth() + 1
  let date = now.getDate()
  if (type === 1) {
    return 'video/' + year + '/' + month + '/' + date + '/'
  } else if (type === 2) {
    return 'audio/' + year + '/' + month + '/' + date + '/'
  } else if (type === 3) {
    return 'image/' + year + '/' + month + '/' + date + '/'
  } else if (ext === 'xml') {
    return 'xml/' + year + '/' + month + '/' + date + '/'
  } else if (ext === 'json') {
    return 'json/' + year + '/' + month + '/' + date + '/'
  } else if (ext === 'template') {
    return 'template/' + year + '/' + month + '/' + date + '/'
  } else {
    return 'unknown/' + year + '/' + month + '/' + date + '/'
  }
}

/**
 * 判断是否是中文
 * @returns {boolean}
 */
function isZHLan () {
  var language = localStorage.language
  return language.indexOf('zh') > -1
}

function unitFormat (number) {
  let numberString = ''
  if (number < 10) {
    numberString += '0'
  }
  return numberString + number.toString()
}

function getNameFromUrl (url) {
  let name = url.substring(url.lastIndexOf('/') + 1)
  return name
}

function replacePathName (path, replacement) {
  let name = path.substring(path.lastIndexOf('/') + 1)
  return path.replace(name, replacement)
}

function getCOSHost () {
  if (['aliyun', 'aws', 'ksyun'].includes(process.env.NODE_ENV)) {
    return (window.$nvsConfig && window.$nvsConfig.cosHost) || ''
  }
  if (process.env.NODE_ENV === 'huawei') {
    return 'http://yingpu.obs.cn-east-3.myhuaweicloud.com'
  }
  return 'https://alieasset.meishesdk.com'
}

function RGBAToNvsColor (rgbaValue) {
  if (rgbaValue === '' || rgbaValue === undefined || rgbaValue === null) {
    return new NvsColor(1, 1, 1, 0)
  }
  // console.log('RGBAToNvsColor', rgbaValue)
  var rgba = rgbaValue.match(/(\d(\.\d+)?)+/g)
  // console.log('RGBAToNvsColor', rgba)
  return new NvsColor(
    parseInt(rgba[0]) / 255.0,
    parseInt(rgba[1]) / 255.0,
    parseInt(rgba[2]) / 255.0,
    rgba[3] / 1.0
  )
}

function NvsColorToRGBA (color) {
  let r = Math.round(color.r * 255)
  let g = Math.round(color.g * 255)
  let b = Math.round(color.b * 255)
  let a = color.a
  // console.log('NvsColorToRGBA', 'rgb(' + r + ',' + g + ',' + b + ',' + a + ')')
  return 'rgba(' + r + ',' + g + ',' + b + ',' + a + ')'
}

function RGBAToHex (rgbaValue) {
  if (rgbaValue === '' || rgbaValue === null || rgbaValue === undefined) {
    return ''
  }
  var rgba = rgbaValue.match(/(\d(\.\d+)?)+/g)
  var r = parseInt(rgba[0])
  var g = parseInt(rgba[1])
  var b = parseInt(rgba[2])
  var a = parseInt(rgba[3] * 255)
  var hex =
    '#' +
    a.toString(16) +
    ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1) // ARGB
  return hex
}
function NvsColorToHex (nvsColor) {
  const { r, b, g, a = 1 } = nvsColor
  const hex = '#' + ((1 << 8) | (a * 255)).toString(16).slice(1) + ((1 << 8) | (r * 255)).toString(16).slice(1)
  + ((1 << 8) | (g * 255)).toString(16).slice(1) + ((1 << 8) | (b * 255)).toString(16).slice(1)
  return hex
}
function ARGBHexToRGBA (hexValue) {
  if (hexValue === '' || hexValue === null || hexValue === undefined) {
    return ''
  }
  if (!hexValue.includes('#')) {
    return hexValue
  }
  var RGBA =
    'rgba(' +
    parseInt('0x' + hexValue.slice(3, 5)) +
    ',' +
    parseInt('0x' + hexValue.slice(5, 7)) +
    ',' +
    parseInt('0x' + hexValue.slice(7, 9)) +
    ',' +
    parseInt('0x' + hexValue.slice(1, 3)) / 255.0 +
    ')'
  return RGBA
}
function HexToNvsColor (hexColor) {
  if (hexColor === '' || hexColor === null || hexColor === undefined) {
    return new NvsColor(1, 1, 1, 0)
  }
  let r = parseInt('0x' + hexColor.slice(3, 5)) / 255
  let g = parseInt('0x' + hexColor.slice(5, 7)) / 255
  let b = parseInt('0x' + hexColor.slice(7, 9)) / 255
  let a = parseInt('0x' + hexColor.slice(1, 3)) / 255
  return new NvsColor(r, g, b, a)
}
function HexToRGBA (hexColor) {
  if (hexColor.length === 7) {
    const arr = hexColor.split('')
    arr.splice(1, 0, 'ff')
    hexColor = arr.join('')
  }
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
  return result ? `rgba(${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${parseInt(result[4], 16)}, ${parseInt(result[1], 16) / 255})`: 'rgba(255, 255, 255, 1)';
}

/**
 * String 占位符拼接
 * @returns {String}
 */
function signMix (desc, value) {
  if (desc.length === 0) {
    return
  }
  for (var i = 0; i < desc.length; i++) {
    desc = desc.replace(new RegExp('\\{' + i + '\\}', 'g'), value)
  }
  return desc
}

function myBrowser () {
  var userAgent = navigator.userAgent // 取得浏览器的userAgent字符串
  var isOpera = userAgent.indexOf('Opera') > -1 // 判断是否Opera浏览器
  var isIE =
    userAgent.indexOf('compatible') > -1 &&
    userAgent.indexOf('MSIE') > -1 &&
    !isOpera // 判断是否IE浏览器
  var isEdge = userAgent.indexOf('Edge') > -1 // 判断是否IE的Edge浏览器
  var isFF = userAgent.indexOf('Firefox') > -1 // 判断是否Firefox浏览器
  var isSafari =
    userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') == -1 // 判断是否Safari浏览器
  var isChrome =
    userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('Safari') > -1 // 判断Chrome浏览器

  if (isIE) {
    var reIE = new RegExp('MSIE (\\d+\\.\\d+);')
    reIE.test(userAgent)
    var fIEVersion = parseFloat(RegExp['$1'])
    if (fIEVersion == 7) {
      return 'IE7'
    } else if (fIEVersion == 8) {
      return 'IE8'
    } else if (fIEVersion == 9) {
      return 'IE9'
    } else if (fIEVersion == 10) {
      return 'IE10'
    } else if (fIEVersion == 11) {
      return 'IE11'
    } else {
      return '0'
    } // IE版本过低
    return 'IE'
  }
  if (isOpera) {
    return 'Opera'
  }
  if (isEdge) {
    return 'Edge'
  }
  if (isFF) {
    return 'Firefox'
  }
  if (isSafari) {
    return 'Safari'
  }
  if (isChrome) {
    return 'Chrome'
  }
}

function displayBasedOnCurLanguage (data) {
  var language = localStorage.language
  if (language.indexOf('zh') > -1) {
    return data.displayNameZhCn || data.displayNamezhCN
  } else if (language.indexOf('en') > -1) {
    return data.displayName
  } else {
    return data.displayName
  }
}

/**
 * 生成唯一的UUID
 * @returns {string}
 */
function generateUUID () {
  var s = []
  var hexDigits = '0123456789abcdef'
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-'
  var uuid = s.join('')
  return uuid
}
// 从字符串中提取uuid, 如果没有返回 . 之前的字符串
function getUUIDFromStr (str) {
  const reg = /([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?!.*\1)/i
  const res = str.match(reg)
  return res ? res[0] : str.split('.').shift()
}


// 21AC7E3B-BEA1-4781-B77F-AC83615455E2.1.animatedsticker
function getVersionFromStr (str) {
  const arr = str.split('.')
  if (arr.length === 3 && /\d+/.test(arr[1])) {
    return arr[1]
  }
  return -1
}

// 是否鉴权
function isAuth () {
  if (window.$nvsConfig) {
    return window.$nvsConfig.isAuth
  }
  return true
}
function getAuthUrl () {
  const temp = ['testing', 'development', 'localhost', 'debug']
  if (temp.includes(process.env.NODE_ENV)) {
    return NvNetwork.data().webApi_app_auth // 测试环境鉴权地址
  } else {
    return window.$nvsConfig.AuthUrl // 配置文件内的鉴权地址
  }
}
function getApiHost (type) {
  // return '/ueditor/' // 本地测试亚马逊服务时, 解开注释
  const temp = {
    aws: (window.$nvsConfig && window.$nvsConfig.apiHost) || '',
    aliyun: (window.$nvsConfig && window.$nvsConfig.apiHost) || '',
    huawei: (window.$nvsConfig && window.$nvsConfig.apiHost) || '',
    testing: 'https://testeditor.meishesdk.com:8443/',
    ksyun: (window.$nvsConfig && window.$nvsConfig.apiHost) || '',
    // BACKENDALIGN:
    // testing: 'https://editor.meishesdk.com:8888/',
    baidu: 'http://localhost:8088/',
    debug: 'http://localhost:8088/',
    // 测试环境
    // development: 'https://testeapi.meishesdk.com:8443/'
    development: 'https://testeditor.meishesdk.com:8443/'
    // BACKENDALIGN:
    // 正式环境
    // development: 'https://editor.meishesdk.com:8888/'
    // 正式环境的预备环境。接口跟测试环境接口一致
    // development: 'https://editor.meishesdk.com:8888/prev/'
  }
  const host = temp[process.env.NODE_ENV]
  return typeof host === 'string' ? host : host[type] || ''
}
function getMaterialCenterHost () {
  const temp = {
    aws: 'https://creative.meishesdk.com/',
    aliyun: 'https://creative.meishesdk.com/',
    ksyun: 'https://creative.meishesdk.com/',
    testing: 'http://testeditor.meishesdk.com:18080/',
    localhost: 'http://192.168.10.94:10005/',
    baidu: 'wss://localhost:8888/',
    debug: 'wss://localhost:8888/',
    development: 'http://testeditor.meishesdk.com:18080/'
  }
  return temp[process.env.NODE_ENV]
}
function getLiveApiHost () {
  const temp = {
    aws: (window.$nvsConfig && window.$nvsConfig.liveApiHost) || '',
    aliyun: (window.$nvsConfig && window.$nvsConfig.liveApiHost) || '',
    ksyun: (window.$nvsConfig && window.$nvsConfig.liveApiHost) || '',
    testing: 'https://testeditor.meishesdk.com:8888/',
    baidu: 'http://localhost:8888/',
    debug: 'http://localhost:8888/',
    development: 'https://testeditor.meishesdk.com:8888/'
  }
  return temp[process.env.NODE_ENV]
}
// webSocket 地址
function getLiveWsHost () {
  const temp = {
    aws: (window.$nvsConfig && window.$nvsConfig.liveWsHost) || '',
    aliyun: (window.$nvsConfig && window.$nvsConfig.liveWsHost) || '',
    ksyun: (window.$nvsConfig && window.$nvsConfig.liveWsHost) || '',
    testing: 'wss://testeditor.meishesdk.com:8888/',
    localhost: 'http://192.168.10.94:10005/',
    baidu: 'wss://localhost:8888/',
    debug: 'wss://localhost:8888/',
    development: 'wss://testeditor.meishesdk.com:8888/'
  }
  return temp[process.env.NODE_ENV]
}

function getAssetHost () {
  if (process.env.NODE_ENV === 'aws') {
    return 'https://bsedit.s3-us-west-1.amazonaws.com/'
  }
  return 'https://alieasset.meishesdk.com/client/'
}

function isEmpty (string) {
  if (string === '' || string === null || string === undefined) {
    return true
  } else {
    return false
  }
}

/**
 * 老版设置缩略图的方法，使用新版后将废弃
 * @param {*} videoClip
 * @param {*} thumbnails
 * @returns
 */
function setVideoClipThumbnails (videoClip, thumbnails = []) {
  if (thumbnails.length === 0) {
    console.warn('没有缩率图', videoClip.title)
    return
  }
  // 如果视频已倒放，并且有orgThumbnailHost
  // 或者未倒放但有thumbnailHost
  // 说明是新版缩略图策略，此时即使有thumbnailBaseUrl。也不需要再继续了
  if (videoClip.reverse) {
    if (videoClip.orgReverseThumbnailHost && videoClip.thumbnailHost) return
  } else {
    if (videoClip.thumbnailHost) return
  }
  // 如果thumbnailBaseUrl没被赋过值
  if (videoClip.thumbnailBaseUrl !== undefined) {
    videoClip.thumbnailBaseUrl = thumbnails[0].url.replace(
      /(\/(?:video-thumbnail\b|image\b|project\b)\/.*)-[^-]+\./,
      ($1, $2) => $2 + '-#ID#.'
    )
    for (let i = 0; i < thumbnails.length; i++) {
      videoClip.thumbnails[i] = {
        time: thumbnails[i].time,
        // 防止host中包含-.
        url: thumbnails[i].url.match(
          /(\/(?:video-thumbnail\b|image\b|project\b)\/.*)-([^-]+)\./
        )[2]
      }
    }
  } else {
    videoClip.thumbnails = thumbnails
  }
}
/**
 * 被用于获取图片的缩略图和标签相应缩略图
 * @param {*} clip
 * @param {*} index
 * @returns
 */
function getThumbnailUrl (clip, index) {
  const thumbnailBaseUrl = clip.thumbnailBaseUrl
  if (clip.thumbnailHost) {
    if (clip.videoType === Enum.materialType.picture) {
      return `${clip.thumbnailHost}-0000000.${clip.thumbnailType}`
    } else {
      return `${clip.thumbnailHost}-${buildFormatedThumbnailNumber(index * clip.thumbnailStep / 1000)}.${clip.thumbnailType}`
    }
  } else if (thumbnailBaseUrl) {
    const id = clip.thumbnails[index].url
    return thumbnailBaseUrl.replace('#ID#', id)
  } else {
    return clip.thumbnails[index].url
  }
}
let hasFace // 闭包 记录是否有人脸特效
// type: 'seek'/'play'
function hasFaceEffect (tracks, type) {
  let hasFaceEffect
  if (hasFace === undefined) {
    hasFaceEffect = !!tracks.find(track => {
      if (track.type === Global.VIDEOTRACK) {
        let clip = track.clips.find(clip => clip.faceEffect)
        if (!clip) return false
        let { faceEffect } = clip
        for (const key in faceEffect) {
          if (!isNaN(1 * faceEffect[key])) {
            if (faceEffect[key] !== 0) return true
          }
        }
        return false
      }
      return false
    })
    hasFace = hasFaceEffect
    console.log('是否有人脸特效', hasFaceEffect)
  } else hasFaceEffect = hasFace
  if (hasFaceEffect) {
    return type === 'seek'
      ? NvsSeekFlagEnum.BuddyHostVideoFrame
      : NvsPlaybackFlagEnum.BuddyHostVideoFrame
  }
  return 0
}
function isMac () {
  return /macintosh|mac os x/i.test(navigator.userAgent)
}

function isWindows () {
  return /windows|win32/i.test(navigator.userAgent)
}
function isCtrlKey (e) {
  return e && ((isMac() && e.metaKey) || (isWindows() && e.ctrlKey))
}
function isShiftKey (e) {
  return e && (e.shiftKey || e.keyCode === 16)
}
function replaceKeyName (key, uuid) {
  let name = key.slice(key.lastIndexOf('/') + 1, key.lastIndexOf('.'))
  console.log('file name ', key)
  return key.replace(name, uuid)
}
// 将val限制在max, min之间
function range (val, [min, max]) {
  return Math.max(Math.min(val, max), min)
}

/**
 * @param data
 * @param projectId
 * @param uuid // 素材可选。在 uploadModule 为模板，和三端互通时需要
 * @param uploadModule
 * @returns {*}
 */
function uploadJSON (data, projectId, uuid, uploadModule) {
  return new Promise((resolve, reject) => {
    NvNetwork.uploadAliOss({
      projectId,
      keyUUID: generateUUID(),
      file: JSON.stringify(data),
      extension: 'json',
      uuid,
      uploadModule: uploadModule || Enum.uploadModule.bsProject, // 兼容增加 uploadModule 参数前的函数调用
      callback: success => {
        resolve(success.Location)
      },
      errorCb: err => {
        console.error('上传失败', err)
        reject(err)
      }
    })
  })
}

async function uploadTemplateFile(options) {
  const { data, projectId, uuid, uploadModule, progressCallback, extension } = options;
  const file = new Blob([data])
  try {
    return await new Promise((resolve, reject) => {
      NvNetwork.uploadAliOss({
        projectId,
        keyUUID: generateUUID(),
        file,
        extension: extension,
        uploadModule: uploadModule,
        isNeedCallback: 1,
        uuid: uuid,
        callback: data => {
          if (data.uploadFinish) {
            resolve( window.location.protocol + '//' + data.Location)
          } else {  // 如需增加上传进度回调，此处可能要处理错误情况
            typeof progressCallback === 'function' && progressCallback()
          }
        },
        errorCb: reject
      })
    })
  } catch (e) {
    console.error("上传模板错误：", e)
  }
}

function compareSdkVersion(version, minSdkVersion) {
  if (version) {
    let minVersionArray = minSdkVersion.split('.')
    let versionArray = version.split('.')
    if (minVersionArray.length === 3 && versionArray.length === 3) {
      let minVersionArray0 = Number(minVersionArray[0])
      let versionArray0 = Number(versionArray[0])
      if (minVersionArray0 < versionArray0) {
        minSdkVersion = version
      } else if (minVersionArray0 === versionArray0) {
        let minVersionArray1 = Number(minVersionArray[1])
        let versionArray1 = Number(versionArray[1])
        if (minVersionArray1 < versionArray1) {
          minSdkVersion = version
        } else if (minVersionArray1 === versionArray1) {
          let minVersionArray2 = Number(minVersionArray[2])
          let versionArray2 = Number(versionArray[2])
          if (minVersionArray2 < versionArray2) {
            minSdkVersion = version
          }
        }
      }
    }
  }
}

function getDurationFromM3u8 (m3u8String) {
  let duration
  m3u8String.replace(/MEISHE-DURATION:(\d+)/, ($0, $1) => {
    duration = $1
  })
  return duration * 1000
}

function getFileName (url) {
  let parts = url.split('/')
  return parts[parts.length - 1]
}

function needLowerMemory () {
  if (navigator.platform !== 'Win32' && navigator.platform !== 'Windows') { return false }
  var ua = navigator.userAgent
  var uaLowerCase = ua.toLowerCase()
  if (uaLowerCase.indexOf('win64') > -1 || uaLowerCase.indexOf('wow64') > -1) { return false }
  if (
    ua.indexOf('Windows NT 5.0') > -1 ||
    ua.indexOf('Windows 2000') > -1 ||
    ua.indexOf('Windows NT 5.1') > -1 ||
    ua.indexOf('Windows XP') > -1 ||
    ua.indexOf('Windows NT 5.2') > -1 ||
    ua.indexOf('Windows 2003') > -1 ||
    ua.indexOf('Windows NT 6.0') > -1 ||
    ua.indexOf('Windows Vista') > -1 ||
    ua.indexOf('Windows NT 6.1') > -1 ||
    ua.indexOf('Windows 7') > -1
  ) { return true }
  return false
}

// 根据轨道类型获取轨道高度
export function getTrackDOMHeight (trackType) {
  switch (trackType) {

    case Global.VIDEOTRACK:
    case Global.VIDEOCLIP:
      return Global.VIDEO_TRACK_DOM_HEIGHT

    // case Global.AUDIOTRACK:
    // case Global.AUDIOCLIP:
    //   return Global.AUDIO_TRACK_DOM_HEIGHT

    default:
      return Global.MATERIAL_TRACK_DOM_HEIGHT
  }
}

// 根据轨道类型获取拖拽图标大小
export function getDragIconStyle (trackType) {
  console.log(trackType)
  switch (trackType) {
    case Global.VIDEOTRACK || Global.VIDEOCLIP:
      return {}
    default:
      return {
        width: '16px',
        height: '16px',
        'margin-bottom': '7px'
      }
  }
}

async function getWasmModuleName (name) {
  const simdSupported = await simd()
  const is64Bit = () => {
    return navigator.userAgent.toLowerCase().indexOf('win64') > -1 ||
      navigator.userAgent.toLowerCase().indexOf('mac os') > -1
  }
  const path = window.$nvsConfig.SDKPath || '/static/wasm'
  const fileName = name || 'NvWasmPlayer'
  console.warn('needLowerMemory:', needLowerMemory())
  console.warn('simdSupported:', simdSupported)
  console.warn('is 64 bit:', is64Bit())
  if (needLowerMemory()) {
    return `${path}/${fileName}_lower_memory_${window.$nvsConfig.SDKVersion}`
  } else if (is64Bit() && simdSupported) {
    return `${path}/${fileName}_simd_${window.$nvsConfig.SDKVersion}`
  } else {
    return `${path}/${fileName}_${window.$nvsConfig.SDKVersion}`
  }
}
async function getEffectWasmModuleName(fileName) {
  const simdSupported = await simd()
  const path = window.$nvsConfig.SDKPath || '/static/wasm'
  console.warn('simdSupported:', simdSupported)
  if (simdSupported) {
    return `${path}/${fileName}_simd_${window.$nvsConfig.SDKVersion}`
  } else {
    return `${path}/${fileName}_${window.$nvsConfig.SDKVersion}`
  }
}

/**
 * 帧精确，获取相近的帧
 * @param {Number} time 时间 微秒
 * @returns 时间 微秒
 */
 function getFrameTime (time) {
  const s = (time / Global.CONVERT) % 1
  const frame = Math.round(s * Global.SYSTEM)
  const pos =
    parseInt(time / Global.CONVERT) * Global.CONVERT +
    (frame / Global.SYSTEM) * Global.CONVERT
  return pos
}

function getFloorFrameTime(time) {
  return time - time % (Global.CONVERT / Global.SYSTEM)
}

// 下载.xml/.json等工程描述文件时，自动在url后拼接参数 t=时间戳 。解决某些特殊情况下，保存-刷新 拿不到最新的文件
function download (url, options = {}) {
  if (/(.xml|.json)$/.test(url)) {
    options = {
      ...options,
      params: { ...options.params, t: Date.now() }
    }
  }
  return axios.get(url, options).then(res => res.data)
}

export function escapeXmlValue (textValue) {
  return textValue
    .replaceAll('&', '&amp;')
    .replaceAll('<', '&lt;')
    .replaceAll('>', '&gt;')
    .replaceAll('"', '&quot;')
    .replaceAll("'", '&apos;')
    .replaceAll('\n', '&#10;')
    .replaceAll('\r', '&#13;')
}

export function unescapeXmlValue (textValue) {
  return textValue
    .replaceAll('&amp;', '&')
    .replaceAll('&lt;', '<')
    .replaceAll('&gt;', '>')
    .replaceAll('&quot;', '"')
    .replaceAll('&apos;', "'")
    .replaceAll('&#10;', '\n')
    .replaceAll('&#13;', '\r')
}

// 计算文件大小函数(保留两位小数),Size为字节大小 // size：初始文件大小
function formatFilesize(size) {
  if (!size)
    return i18n.t('labels.unknown');
  let num = 1024.00; //byte
  if (size < num)
    return size + "B";
  if (size < Math.pow(num, 2))
    return (size / num).toFixed(2) + "K"; //kb
  if (size < Math.pow(num, 3))
    return (size / Math.pow(num, 2)).toFixed(2) + "M"; //M
  if (size < Math.pow(num, 4))
    return (size / Math.pow(num, 3)).toFixed(2) + "G"; //G
  return (size / Math.pow(num, 4)).toFixed(2) + "T";  //T
}
