import {TimelineData, VideoTrack, AudioTrack, MusicLyricsTrack, VideoFx, FxParam, AdjustData, FaceEffect, WaterMark, Fx} from './ProjectData'
import EventBus from '../EventBus'
import EventBusKey from './EventBusKey'
import GL from './Global'
import Utils from './Utils'
import axios from 'axios'
import {checkAndInstallAssetFromIndexDB, saveAssetToIndexDB} from './AssetsUtils'
import {getTrackLastIndexInItems, deepCopy} from './TrackCalculatorUtils'
import vm from '@/main'
import api from '@/http'
import { getKey, isFrameTimeEqual, getSelectedClip, isLoadingDuplicate, getKeyFramesByClip, refreshKeyFrameWhenMouseUp, refreshVideoPropertyFxWhenMouseUp } from '@/utils'
import store from '@/store'
import { Loading } from 'element-ui'
import { alignFrame } from '@/utils/index'
import transcodeTimelineToProjectData from '@/utils/TimelineToXml'
const KEYFRAME_AUDIO = ["Left Gain", "Right Gain"];
let mTimeline
let mStreamingContext
let timelineData
let timelineDataArray = []
let clipTimeline
let disablePlaybackPos = true
let templateAlphaTasks = [] // 生成带通道模板使用
let backgroundImageArray = []

// 片段监视器
function buildClipTimeline (clip, isVideo) {
  mStreamingContext.streamingEngineReadyForTimelineModification().then(() => {
    let videoTrack, audioTrack
    if (clipTimeline === undefined) {
      clipTimeline = mStreamingContext.createTimeline(
        new NvsVideoResolution(GL.RES_16_9_WIDTH, GL.RES_16_9_HEIGHT),
        new NvsRational(25, 1),
        new NvsAudioResolution(44100, 2))
      videoTrack = clipTimeline.appendVideoTrack()
      audioTrack = clipTimeline.appendAudioTrack()
    } else {
      videoTrack = clipTimeline.getVideoTrackByIndex(0)
      videoTrack.removeAllClips()
      audioTrack = clipTimeline.getAudioTrackByIndex(0)
      audioTrack.removeAllClips()
    }
    if (isVideo) {
      let videoClip = videoTrack.appendClip(clip.m3u8LocalPath)
      if (videoClip.getVideoType() === 1) {
        videoClip.setImageMotionAnimationEnabled(false)
        videoClip.setImageMotionMode(0)
      }
    } else {
      let avFileInfo = mStreamingContext.getAVFileInfo(clip.m3u8LocalPath, 0)
      videoTrack.appendClip2(':/footage/default_black.png', 0, avFileInfo.duration)
      audioTrack.appendClip(clip.m3u8LocalPath)
    }
    EventBus.$emit(EventBusKey.monitorTypeUpdate, {
      type: GL.MONITOR_TYPE_1,
      target: clipTimeline,
      clipInfo: clip
    })
  })
}

async function installDefaultSubtitleStyle () {
  if (!GL.getDefaultSubtitleStyle()) {
    try {
      const mDatas = await api.material.material_list({
        type: Enum.mType.captionstyle,
        page: 0,
        pageSize: 20,
        category: Enum.captionType.libretto
      })
      for (let i = 0; i < mDatas.materialList.length; i++) {
        let url = mDatas.materialList[i].packageUrl
        let packageName = url.substring(url.lastIndexOf('/') + 1)
        checkAndInstallAssetFromIndexDB(packageName).then((filePath) => {
          if (i === 0) {
            GL.setDefaultSubtitleStyle(filePath.substring(0, filePath.indexOf('.')))
          }

          EventBus.$emit(EventBusKey.defaultFontHasInstalled)
        }).catch(e => {
          axios.get(url, {responseType: 'arraybuffer'})
            .then(function (response) {
              console.log('download caption finish ')
              saveAssetToIndexDB(packageName, new Uint8Array(response.data)).then(() => {
                checkAndInstallAssetFromIndexDB(packageName).then((filePath) => {
                  console.log('finish install asset package ', filePath)
                  if (i === 0) {
                    GL.setDefaultSubtitleStyle(filePath.substring(0, filePath.indexOf('.')))
                  }
                  EventBus.$emit(EventBusKey.defaultFontHasInstalled)
                }).catch(e => {
                  EventBus.$emit(EventBusKey.defaultFontHasInstalled)
                  console.error(e)
                })
              }).catch(e => {
                EventBus.$emit(EventBusKey.defaultFontHasInstalled)
                console.error(e)
              })
            })
            .catch(function (error) {
              EventBus.$emit(EventBusKey.defaultFontHasInstalled)
              console.log(error)
            })
        })
      }
    } catch (error) {
      EventBus.$emit(EventBusKey.defaultFontHasInstalled)
      console.log(error)
    }
  }
}

var queueUUID = ''
/**
 * 创建时间线
 * @param {string} templateId 模板ID, 创建模板时间线时需要
 * @param {boolean} loadProject 是否为加载工程
 */
function createTimeline (defaultTimelineData, loadProject) {
  const templateId = defaultTimelineData && defaultTimelineData.templateId
  if (mTimeline === undefined) {
    const resolution = sessionStorage.nvsResolution || '16:9'
    let width, height
    if (!defaultTimelineData) {
      [ width, height ] = getResolution(resolution)
    } else {
      width = defaultTimelineData.resWidth
      height = defaultTimelineData.resHeight
    }
    timelineData = new TimelineData(width, height)
    timelineData.videoSize = resolution
    if (!loadProject) {
      // 确保画幅的唯一性
      addTimelineData(timelineData)
    }
    // mStreamingContext.setUseChineseLanguageOnly(true)
    if (!templateId) {
      mTimeline = mStreamingContext.createTimeline(
        new NvsVideoResolution(width, height),
        new NvsRational(25, 1),
        new NvsAudioResolution(44100, 2))
    } else {
      // 通过这个方法创建跟模板相关的时间线，可以不校验模板里面素材的license
      timelineData.templateId = templateId
      mTimeline = mStreamingContext.createEmptyTemplateTimeline(
        new NvsVideoResolution(width, height),
        new NvsRational(25, 1),
        new NvsAudioResolution(44100, 2),
        timelineData.templateId)
    }
    // 设置一个视频最多解析的音频流数量，目前最大值为8
    mTimeline.setMaxAudioStreamSupportedOnVideoTrack(8)
    // 开启zvalue
    timelineData.enableRenderOrderByZValue && mTimeline.enableRenderOrderByZValue(true)
    mStreamingContext.enableAudioVUMeter(true)
    checkUndefind(mTimeline, timelineData)
  }

  queueUUID = Utils.generateUUID()
  insertCurrentQueueToIndexDB('queueMap', 3)
  saveBaseProjectDataToIndexDB(queueUUID + '_queue', 3)
}
function removeTimeline () {
  if (!mStreamingContext) {
    console.error('StreamingContext is null')
    return
  }
  mStreamingContext.removeTimeline(mTimeline)
}
/**
 * 计算时间线的宽高
 * @param {string} resolution 画幅比 16:9等
 * @return {array} [width, height]
 */
function getResolution (resolution) {
  const resolvingPower = (sessionStorage.resolvingPower || 540) * 1
  const [w, h] = resolution.split(':')
  const power = Math.min(w, h) / resolvingPower
  const maxLen = Math.max(w, h) / power
  console.log('宽高', w * 1 > h * 1 ? [maxLen, resolvingPower * 1] : [resolvingPower * 1, maxLen])
  return w * 1 > h * 1 ? [maxLen, resolvingPower * 1] : [resolvingPower * 1, maxLen]
}
function addTimelineFx () {
  return new Promise((resolve, reject) => {
    mStreamingContext.streamingEngineReadyForTimelineModification().then(async () => {
      let { adjustData, timelineFilterFx, timelineVideoFxs, waterMark } = timelineData
      if (waterMark) {
        console.log('设置waterMark', waterMark)
        // 下载资源
        try {
          if (waterMark.path) {
            await installOtherFile({ url: waterMark.path })
          }
          console.log('水印资源下载完成', timelineData)
          if (!(waterMark instanceof WaterMark)) {
            timelineData.waterMark = new WaterMark(waterMark)
          }
          timelineData.waterMark.setRaw(mTimeline)
          timelineData.waterMark.setData()
        } catch (error) {
          console.error('waterMark资源下载失败', error)
        }
      }
      if (adjustData) {
        // console.log('设置adjustData - timeline', adjustData)
        if (!(adjustData instanceof AdjustData)) {
          timelineData.adjustData = new AdjustData(adjustData)
        }
        timelineData.adjustData.setRaw(mTimeline)
        timelineData.adjustData.setData(mTimeline.getDuration())
      }
      if (timelineFilterFx) {
        // console.log('设置timelineFilterFx', timelineFilterFx)
        if (!(timelineFilterFx instanceof Fx)) {
          timelineData.timelineFilterFx = new Fx(timelineFilterFx)
        }
        timelineData.timelineFilterFx.setRaw(mTimeline)
        timelineData.timelineFilterFx.setData()
      }
      if (Array.isArray(timelineVideoFxs)) {
        timelineVideoFxs.map((fx, index) => {
          if (!(fx instanceof Fx)) {
            timelineVideoFxs[index] = new Fx(fx)
          }
          timelineVideoFxs[index].setRaw(mTimeline)
          timelineVideoFxs[index].setData()
        })
      }
      resolve()
    })
  })
}
/**
 * 下载水印资源，自定义贴纸资源等
 * @param {*} url http地址
 */
function installOtherFile (params = {}) {
  let { url, packageName, assetType = 'resources', projectId } = params
  if (!packageName) {
    packageName = url.substring(url.lastIndexOf('/') + 1, url.length)
  }
  let checkLic = true
  if (assetType === 'projectresource') checkLic = false
  return new Promise((resolve, reject) => {
    checkAndInstallAssetFromIndexDB(packageName, true, assetType, checkLic, projectId).then(() => {
      resolve()
    }).catch(() => {
      axios.get(url, { responseType: 'arraybuffer' }).then(res => {
        saveAssetToIndexDB(packageName, new Uint8Array(res.data), assetType).then(() => {
          checkAndInstallAssetFromIndexDB(packageName, true, assetType, checkLic, projectId).then(() => {
            resolve()
          }).catch(reject)
        })
      }).catch(reject)
    })
  })
}
function deleteFx () {
  mStreamingContext.closeHumanDetection()
  return mStreamingContext.streamingEngineReadyForTimelineModification().then(() => {
    mTimeline.deleteWatermark()
  })
}
function initContext () {
  if (mStreamingContext === undefined) {
    mStreamingContext = nvsGetStreamingContextInstance()
  }
  mStreamingContext.onWebRequestWaitStatusChange = (isVideo, waiting) => {
    EventBus.$emit(EventBusKey.onWebRequestWaitStatusChange, isVideo, waiting)
  }

  mStreamingContext.onStreamingEngineStateChanged = (state) => {

  }

  mStreamingContext.onPlaybackTimelinePosition = (timeline, position) => {
    if (timeline === mTimeline && disablePlaybackPos) return
    EventBus.$emit(EventBusKey.onPlaybackTimelinePosition, timeline, position)
  }

  mStreamingContext.onPlaybackStopped = (timeline) => {
    EventBus.$emit(EventBusKey.onPlaybackStopped, timeline)
  }

  mStreamingContext.onPlaybackEOF = (timeline) => {
    EventBus.$emit(EventBusKey.onPlaybackEOF, timeline)
  }

  mStreamingContext.onAudioVUMeter = (timeline, leftVUValue, rightVUValue, timeStamp) => {
    EventBus.$emit(EventBusKey.onAudioVUMeter, timeline, leftVUValue, rightVUValue, timeStamp)
  }

  mStreamingContext.getAssetPackageManager().onFinishAssetPackageInstallation = (assetPackageId, assetPackageFilePath, assetPackageType, error) => {
    EventBus.$emit(EventBusKey.onFinishAssetPackageInstallation + assetPackageFilePath, assetPackageId, assetPackageFilePath, assetPackageType, error)
  }

  mStreamingContext.onImageGrabbedArrived = (imageData, time) => {
    EventBus.$emit(EventBusKey.grabImage, imageData, time)
  }
}
function changeTimelineResolution () {
  const {resWidth: width, resHeight: height} = timelineData
  // const [width, height] = getResolution(timelineData.videoSize)
  if (mTimeline.getVideoRes().width === width && mTimeline.getVideoRes().height === height) return null
  return new Promise((resolve, reject) => {
    mStreamingContext.streamingEngineReadyForTimelineModification().then(() => {
      mTimeline.changeVideoSize2(width, height)
      resolve()
    }).catch(e => reject)
  })
}
function removeCurrentTheme () {
  const themeId = mTimeline.getCurrentThemeId()
  if (themeId) {
    return new Promise((resolve, reject) => {
      mStreamingContext.streamingEngineReadyForTimelineModification().then(() => {
        mTimeline.removeCurrentTheme()
        resolve()
      }).catch(e => reject)
    })
  } else {
    return null
  }
}
function setTimelineDataArray (arr) {
  timelineDataArray = arr;
}
function newTimelineDataByRatio (ratio) {
  const [ width, height ] = getResolution(ratio)
  let data = new TimelineData(width, height)
  data.videoSize = ratio
  initTimelineData(data)
  // 确保画幅的唯一性
  addTimelineData(data)
  return data
}
function addTimelineData (data) {
  const index = timelineDataArray.findIndex(tl => tl.videoSize === data.videoSize)
  if (index > -1) { // 如果时间线有该画幅的数据, 直接替换
    timelineDataArray[index] = data
  } else {
    timelineDataArray.push(data)
  }
}
function getTimelineDataByRatio (ratio) {
  if (!ratio) {
    if (timelineDataArray.length > 0) return timelineDataArray[0];
    return null;
  }
  for (let i = 0; i < timelineDataArray.length; i++) {
    if (timelineDataArray[i].videoSize === ratio) return timelineDataArray[i];
  }
  return null;
}
function setTimelineDataByRatio (ratio, data) {
  for (let i = 0; i < timelineDataArray.length; i++) {
    if (timelineDataArray[i].videoSize === ratio) {
      timelineDataArray[i] = data
      break
    }
  }
}
// 保证timelineDataArray内 存储的timelineData中的视频inPoint/outPoint都是不算主题片头的值
function removeThemeTitleOffset() {
  const { theme } = timelineData
  if (theme && theme.id && theme.titleDuration) {
    const { VIDEOTRACK } = GL
    timelineData.tracks.map(({ type, clips }) => {
      if ([VIDEOTRACK].includes(type)) {
        clips.map(clip => {
          clip.inPoint -= theme.titleDuration
          clip.outPoint -= theme.titleDuration
        })
      }
    })
  }
}
async function setTimelineData (options = {}) {
  const {
    removeThemeTitleOffsetFirst = false,
    isBuildTemplate = false,
    templateAlphaTasks: _templateAlphaTasks = []
  } = options

  // 生成模板用，模板需要的alpha通道为mp4文件
  templateAlphaTasks = _templateAlphaTasks

  if (mTimeline === null) {
    console.error('mTimeline is null')
    return
  }
  try {
    let start = Date.now()
    // 清理轨道前先移除主题
    if (removeThemeTitleOffsetFirst) {
      removeThemeTitleOffset()
    }
    await changeTimelineResolution()
    await removeCurrentTheme()
    await deleteFx()
    await clearAllTracks()
    await buildVideoTrack()
    changeEmptyTrack(findMaxPoint())
    await buildAudioTrack()
    await clearCaptions(mTimeline.getFirstCaption())
    await clearCompoundCaptions(mTimeline.getFirstCompoundCaption())
    await clearStickers(mTimeline.getFirstAnimatedSticker())
    await clearVideoFxs(mTimeline.getFirstTimelineVideoFx())
    await addTimelineFx()
    await buildCaptionByItem()
    await buildSubtitleCaptionByItem()
    await buildStickerByItem()
    await buildVideoFXByItem()
    await buildCompoundCaptionByItem()
    let time = 0
    let seeker = document.getElementById('tl_seeker')
    let trackContainer = document.getElementById('trackContainer')
    if (seeker !== undefined && trackContainer !== undefined) {
      let transform = seeker.style.transform
      if (transform !== '') {
        let transformPX = parseInt(transform.substring(transform.indexOf('(') + 1, transform.indexOf(')') - 2)) - 4
        time = GL.getNowTimeByPx(transformPX + GL.leftStrackContainerWidth, trackContainer.scrollLeft)
      }
    }
    EventBus.$emit(EventBusKey.monitorBuild, true) // true表示 这里的方法不进行seek
    await refreshAfterApplyTheme()
    for (let i = 0; i < timelineData.tracks.length; i++) {
      const track = timelineData.tracks[i]
      // 更新轨道index
      track.index = i
      if (track.type === GL.VIDEOTRACK) {
        await mStreamingContext.streamingEngineReadyForTimelineModification()
        buildTransitionForVideoClip(track, track.raw)
      }
    }
    EventBus.$emit(EventBusKey.applyThemeEdit, true)
    !templateAlphaTasks.length && seekTimeline()
    if (!isBuildTemplate) { // 构建模版时。不过多触发计算缩略图 [isBuildTemplate: true 时不触发]
      EventBus.$emit(EventBusKey.refreshTransitionOnBuild)
    }
    EventBus.$emit(EventBusKey.setTrackIndexAndClipIndexAfterBuild)
    EventBus.$emit(EventBusKey.shouldUpdataMusicLyricsData, true)
    console.log(`build timeline: ${Date.now() - start}ms`)
  } catch (error) {
    console.error('timeline setting error:', error)
  }
}
function clearAllTracks () {
  return new Promise((resolve, reject) => {
    clearAllVideoTrack().then(() => {
      clearAllAudioTrack().then(() => {
        console.log('清理所有轨道')
        resolve()
      })
    }).catch(e => {
      reject(new Error('轨道清理错误', e))
    })
  })
}
async function clearAllVideoTrack () {
  // 如果 引擎停止放到外部, 撤销时可能引发引擎未停止, 导致轨道删除失败, 死循环
  while (mTimeline.videoTrackCount() !== 0) {
    await mStreamingContext.streamingEngineReadyForTimelineModification()
    mTimeline.removeVideoTrack(0)
  }
}
async function clearAllAudioTrack () {
  while (mTimeline.audioTrackCount() !== 0) {
    await mStreamingContext.streamingEngineReadyForTimelineModification()
    mTimeline.removeAudioTrack(0)
  }
}
function getTimelineData () {
  return timelineData
}
function getTimelineDataArray () {
  return timelineDataArray
}
/**
 * 重建轨道和回复轨道可见时都需要 区别在于item的传参
 * @param item 重建轨道时不传或者undefined 恢复轨道要传track
 * @returns {Promise<T>}
 */
async function buildCaptionByItem (item) {
  return mStreamingContext.streamingEngineReadyForTimelineModification().then(async () => {
    if (item !== undefined && item.show) {
      await addCaptions(item.clips, timelineData.tracks.length - item.index)
    } else {
      for (let i = 0, length = timelineData.tracks.length; i < length; i++) {
        let item = timelineData.tracks[i]
        if (item.type === GL.CAPTIONTRACK) {
          if (item.show) {
            await addCaptions(item.clips, length - i)
          }
        }
      }
    }
  })
}

async function buildSubtitleCaptionByItem (item) {
  return mStreamingContext.streamingEngineReadyForTimelineModification().then(async () => {
    if (item !== undefined && item.show) {
      await addSubtitleCaptions(item.clips, 100)
    } else {
      for (let i = 0, length = timelineData.tracks.length; i < length; i++) {
        let item = timelineData.tracks[i]
        if (item.type === GL.MUSICLYRICSTRACK) {
          if (item.show) {
            await addSubtitleCaptions(item.clips, 100)
          }
        }
      }
    }
  })
}

function buildCompoundCaptionByItem (item) {
  return mStreamingContext.streamingEngineReadyForTimelineModification().then(async () => {
    if (item !== undefined && item.show) {
      await addCompoundCaptions(item.clips, timelineData.tracks.length - item.index)
    } else {
      for (let i = 0, length = timelineData.tracks.length; i < length; i++) {
        let item = timelineData.tracks[i]
        if (item.type === GL.COMPOUNDCAPTIONTRACK && item.show) {
          await addCompoundCaptions(item.clips, length - i)
        }
      }
    }
  })
}
function refreshAfterApplyTheme () {
  return new Promise((resolve, reject) => {
    EventBus.$emit(EventBusKey.refreshAfterApplyTheme, () => {
      resolve()
    })
  })
}
function buildStickerByItem (item) {
  return mStreamingContext.streamingEngineReadyForTimelineModification().then(async () => {
    if (item !== undefined && item.show) {
      await addStickers(item.clips, timelineData.tracks.length - item.index)
    } else {
      for (let i = 0, length = timelineData.tracks.length; i < length; i++) {
        let item = timelineData.tracks[i]
        if (item.type === GL.STICKERTRACK && item.show) {
          await addStickers(item.clips, length - i)
        }
      }
    }
  })
}
async function buildVideoFXByItem (item) {
  if (item && item.show) {
    await addFilters(item.clips, timelineData.tracks.length - item.index)
  } else {
    for (let i = 0, length = timelineData.tracks.length; i < length; i++) {
      let item = timelineData.tracks[i]
      if (item.type === GL.TIMELINEVIDEOFXTRACK && item.show) {
        await addFilters(item.clips, length - i)
      }
    }
  }
}
function getTracksInTimelineDataByType (type) {
  let videoTrackS = []
  for (let i = 0; i < timelineData.tracks.length; i++) {
    if (timelineData.tracks[i].type === type) {
      videoTrackS.push(timelineData.tracks[i])
    }
  }
  return videoTrackS
}

function getMusicLyricsTrack () {
  let index = -1
  for (let i = 0; i < timelineData.tracks.length; i++) {
    if (timelineData.tracks[i].type === GL.MUSICLYRICSCLIP) {
      index = i
    }
  }
  return index === -1 ? undefined : timelineData.tracks[index]
}
function buildVideoTrack () {
  return new Promise((resolve, reject) => {
    if (mTimeline == null) {
      console.error('mTimeline is null')
      reject(new Error('mTimeline is null'))
    }
    mStreamingContext.streamingEngineReadyForTimelineModification().then(async () => {
      await clearVideoTrack()

      let emptyTrack = mTimeline.appendVideoTrack()
      emptyTrack.setAvailableInTheme(false)
      // emptyTrack.appendClip(':/footage/default_black.png')
      console.log('记录的时间线长度', timelineData.duration)
      await changeEmptyTrack(timelineData.duration)
      let tracks = getTracksInTimelineDataByType(GL.VIDEOTRACK)
      for (let i = tracks.length - 1; i >= 0; i--) {
        let trackData = tracks[i]
        let videoTrack = mTimeline.appendVideoTrack()
        checkUndefind(videoTrack, trackData)
        trackData.raw = videoTrack
        if (videoTrack == null) {
          console.error('buildVideoTrack', 'failed to append video track')
          reject(new Error('buildVideoTrack: failed to append video track'))
        }
        if (trackData.show) {
          await buildVideoTrackByItem(trackData)
        }
        let videoVolume = trackData.volume
        videoTrack.setVolumeGain(videoVolume, videoVolume)
      }
      // seekTimeline(0)
      // console.log('tracks', tracks)
      console.log('创建视频轨道成功')
      resolve()
    })
  })
}
function buildTransitionForVideoClip (trackData, videoTrack) {
  for (let j = 0; j < trackData.transitions.length; j++) {
    let transition = trackData.transitions[j]
    if (trackData.show) { // 显示的轨道才添加转场
      if (transition.clipType === GL.TIMELINEVIDEOFXTYPEBUILDIN) {
        transition.raw = videoTrack.setBuiltinTransition(transition.index + hasTitle(trackData), transition.desc)
      } else {
        transition.raw = videoTrack.setPackagedTransition(transition.index + hasTitle(trackData), transition.desc)
      }
      if (transition.raw) {
        transition.raw.setVideoTransitionDuration(transition.duration, 1)
      }
    }
    transition.clipUUID = trackData.clips[transition.index].uuid
    if (!transition.raw && trackData.show) {
      console.error('添加转场失败', transition)
    }
  }
}
// 当前轨道是否有片头
function hasTitle (track) {
  if (track.type === GL.VIDEOTRACK) {
    if (!track.raw) return 0
    const clip = track.raw.getClipByIndex(0)
    if (clip && clip.getRoleInTheme() === NvsClipRoleInThemeEnum.Title) return 1
  }
  return 0
}
function changeEmptyTrack (outPoint) {
  EventBus.$emit("refreshTimeLineSection")
  return mStreamingContext.streamingEngineReadyForTimelineModification().then(() => {
    let track = mTimeline.getVideoTrackByIndex(0)
    let blackClip = track.getClipByIndex(0)
    // let videoDuration = 0
    // for (let i = 0; i < timelineData.tracks.length; i++) {
    //   if (timelineData.tracks[i].type === 'videoTrack') {
    //     timelineData.tracks[i].clips.map(clip => {
    //       videoDuration = Math.max(videoDuration, clip.outPoint)
    //     })
    //   }
    // }
    // if (outPoint > videoDuration) {
    //   if (blackClip) track.changeOutPoint(0, outPoint)
    //   else track.addClip2(':/footage/default_black.png', videoDuration, 0, outPoint - videoDuration)
    // } else {
    //   track.removeAllClips()
    // }
    // 因为时间线特效的渲染需要基于视频，如果没有视频就会渲染不出来，所以还是需要空轨的黑图片，但是黑图片需要是透明的
    if (blackClip) track.changeOutPoint(0, outPoint)
    else track.appendClip2(':/footage/default_black.png', 0, outPoint)
    vm.$set(timelineData, 'duration', outPoint)
    timelineData.duration = outPoint
  })
}
function findMaxPoint () {
  let tracks = timelineData.tracks
  let maxPoint = 0
  tracks.map(track => {
    track.clips.map(clip => {
      maxPoint = Math.max(maxPoint, clip.outPoint)
    })
  })
  return maxPoint
}
async function clearVideoTrack () {
  return mStreamingContext.streamingEngineReadyForTimelineModification().then(() => {
    if (mTimeline !== undefined) {
      let count = mTimeline.videoTrackCount()
      for (let i = 0; i < count; i++) {
        let videoTrack = mTimeline.getVideoTrackByIndex(i)
        videoTrack.removeAllClips()
      }
    }
  })
}
async function clearAudioTrack () {
  return mStreamingContext.streamingEngineReadyForTimelineModification().then(() => {
    if (mTimeline !== undefined) {
      let count = mTimeline.audioTrackCount()
      for (let i = 0; i < count; i++) {
        let audioTrack = mTimeline.getAudioTrackByIndex(i)
        audioTrack.removeAllClips()
      }
    }
  })
}

async function addVideoClip (videoTrack, clipInfo) {
  if (videoTrack == null || clipInfo == null) {
    return
  }
  let filePath = clipInfo.reverse ? clipInfo.reverseM3u8Path : clipInfo.m3u8Path
  let videoClip = videoTrack.addClipWithSpeed2(filePath, clipInfo.inPoint, clipInfo.trimIn, clipInfo.trimOut, clipInfo.speed || 1, true)
  // console.log('添加视频剪辑， ', videoTrack, videoClip, clipInfo)
  !videoClip && console.error('clip添加失败', filePath, clipInfo.inPoint, clipInfo.trimIn, clipInfo.trimOut)
  await setVideoClipProperty(videoClip, clipInfo)
  refreshVideoPropertyFxWhenMouseUp(clipInfo)
  return videoClip
}

async function setVideoClipProperty (videoClip, clipInfo) {
  if (videoClip == null) {
    console.error('addVideoClip', 'failed to append video clip', clipInfo)
    // EventBus.$emit('debug')
    return
  }
  videoClip.enablePropertyVideoFx(true) // 为了让模板能正确处理一些特效比如motion
  clipInfo.raw = videoClip
  //
  if (clipInfo.uuid === '') {
    clipInfo.uuid = uuid()
  }
  if (clipInfo.videoType === 3) {
    videoClip.setImageMotionAnimationEnabled(false)
    videoClip.setImageMotionMode(0)
  }
  if (clipInfo.reverse) {
    if (clipInfo.reverseAlphaM3u8Path) {
      if (templateAlphaTasks.length) {
        templateAlphaTasks.forEach(alphaTask => {
          if(alphaTask.uuid === clipInfo.uuid + '_alpha' || getKey(clipInfo) + '_alpha' === alphaTask.name) {
            let videoRawFx = videoClip.appendRawBuiltinFx('Set Alpha')
            videoRawFx.setStringVal('Alpha File', alphaTask.uuid + '.mp4')
            videoRawFx.setBooleanVal('Clip Trim Used', true)
          }
        })
      } else {
        let videoRawFx = videoClip.appendRawBuiltinFx('Set Alpha')
        videoRawFx.setStringVal('Alpha File', clipInfo.reverseAlphaM3u8Path)
        videoRawFx.setBooleanVal('Clip Trim Used', true)
      }
    }
  } else {
    if (clipInfo.alphaM3u8Path) {
      if (templateAlphaTasks.length) {
        templateAlphaTasks.forEach(alphaTask => {
          if(alphaTask.uuid === clipInfo.uuid + '_alpha' || getKey(clipInfo) + '_alpha' === alphaTask.name) {
            let videoRawFx = videoClip.appendRawBuiltinFx('Set Alpha')
            videoRawFx.setStringVal('Alpha File', alphaTask.uuid + '.mp4')
            videoRawFx.setBooleanVal('Clip Trim Used', true)
          }
        })
      } else {
        let videoRawFx = videoClip.appendRawBuiltinFx('Set Alpha')
        videoRawFx.setStringVal('Alpha File', clipInfo.alphaM3u8Path)
        videoRawFx.setBooleanVal('Clip Trim Used', true)
      }
    }
  }
  videoClip.setExtraVideoRotation(clipInfo.extraRotation)
  let volumeGain = clipInfo.volume
  videoClip.setVolumeGain(volumeGain, volumeGain)

  await mStreamingContext.streamingEngineReadyForTimelineModification()
  if (clipInfo.curveSpeedName) {
    videoClip.changeCurvesVariableSpeed(clipInfo.curveSpeedString, true)
  } else {
    videoClip.changeSpeed(clipInfo.speed, true)
  }
  if (GL.getIsLoadingProject() === false) {  // xml读取完成后的的所有操作都是需要对齐的
    // 此时底层数据是正确的，只是需要改变ui的长度
    clipInfo.outPoint = videoClip.getOutPoint()
  }
  if (clipInfo.separated === true) {
    videoClip.setVolumeGain(0, 0)
  }

  videoClip.setFadeInDuration(clipInfo.fadeInDuration)
  videoClip.setFadeOutDuration(clipInfo.fadeOutDuration)

  // 兼容老的工程背景模糊数据，然后保存新工程时废弃掉原有的bgBlur和bgBlurRadius
  if (clipInfo.bgBlur) {
    let propertyFx = clipInfo.videoFxs.find(item => item.type === 'property')
    if (propertyFx) {
      const param = propertyFx.params.find(item => item.key === 'Background Mode')
      if (!param) {
        propertyFx.params.push(new FxParam('menu', 'Background Mode', 'Blur'))
      } else {
        param.value = 'Blur'
      }
      const param2 = propertyFx.params.find(item => item.key === 'Background Blur Radius')
      if (!param2) {
        propertyFx.params.push(new FxParam('float', 'Background Blur Radius', clipInfo.bgBlurRadius))
      } else {
        param2.value = clipInfo.bgBlurRadius
      }
    } else {
      propertyFx = new VideoFx('property', clipInfo.videoFxs.length, '')
      propertyFx.params.push(new FxParam('menu', 'Background Mode', 'Blur'))
      propertyFx.params.push(new FxParam('float', 'Background Blur Radius', clipInfo.bgBlurRadius))
      clipInfo.videoFxs.push(propertyFx)
    }
    clipInfo.bgBlur = false
    clipInfo.bgBlurRadius = 0
  }

  const videoFx = clipInfo.videoFxs.find(item => item.type === 'property')
  if (videoFx) {
    videoFx.raw = videoClip.getPropertyVideoFx()
    let valueParam = null;
    let param = videoFx.params.find(item => item.key === 'Background Mode')
    if (!param) {
      param = new FxParam('menu', 'Background Mode', 'Color Solid')
      valueParam = new FxParam('color', 'Background Color', '#00000000')
      videoFx.params.push(param, valueParam);
    }
    switch (param.value) {
      case 'Color Solid':
        const bgColorParam = valueParam || videoFx.params.find(item => item.key === 'Background Color')
        videoFx.raw.setMenuVal('Background Mode', 'Color Solid')
        videoFx.raw.setColorVal('Background Color', Utils.HexToNvsColor(bgColorParam.value))
        break
      case 'Image File':
        try {
          if (backgroundImageArray.length <= 0) {
            const ret = await api.material.material_list({
              type: Enum.mType.backgroundImage,
              page: 0,
              pageSize: 40
            })
            backgroundImageArray = ret.materialList
          }
          const bgImageParam = videoFx.params.find(item => item.key === 'Background Image')
          const imageAsset = backgroundImageArray.find(item => item.packageUrl.indexOf(bgImageParam.value) >= 0)
          let imageUrl = bgImageParam.value
          if (imageAsset && imageAsset.packageUrl) {
            imageUrl = imageAsset.packageUrl
          }
          await installOtherFile({ url: imageUrl, assetType: 'backgroundimage' })
          const filePath = GL.getBackgroundImageFolder() + '/' + bgImageParam.value
          videoFx.raw.setMenuVal('Background Mode', 'Image File')
          videoFx.raw.setStringVal('Background Image', filePath)
        } catch (error) {
          console.error('获取背景图片失败：' + error);
        }
        break
      case 'Blur':
        const bgBlurRadiusParam = videoFx.params.find(item => item.key === 'Background Blur Radius')
        videoFx.raw.setMenuVal('Background Mode', 'Blur')
        videoFx.raw.setFloatVal('Background Blur Radius', bgBlurRadiusParam.value)
        break
    }
    const scanParam = videoFx.params.find(item => item.key === 'Scan Value')
    if (scanParam?.value) {
      videoFx.raw.setMenuVal('Fill Mode', 'PanAndScan')
      videoFx.raw.setFloatVal('Scan Value', scanParam.value)
    }
  }
  if(clipInfo.fillMode === 1) { // 变化模式为默认裁剪的情况下
    const videoFxRaw = videoClip.getPropertyVideoFx()
    videoFxRaw.setFloatVal('Scan Value', 1, )
    videoFxRaw.setMenuVal('Fill Mode', 'PanAndScan')
  }
  // 设置混合模式
  if (clipInfo.blendingMode !== undefined) videoClip.setBlendingMode(clipInfo.blendingMode)

  // 定格视频
  if (clipInfo.enableClipFreezeFrame) {
    clipInfo.raw.enableClipFreezeFrame(true)
    clipInfo.raw.setClipFreezeFrameTrimPosition(clipInfo.freezeFrameTrimPos)
  }

  // console.warn('-----添加其他特效', clipInfo)
  addVideoFxs(videoClip, clipInfo)
  addAudioFxs(videoClip, clipInfo)
  addStoryboardVideoFxs(videoClip, clipInfo)
  addAdjustData(videoClip, clipInfo)
  addFaceEffect(videoClip, clipInfo)
}
async function addFaceEffect (videoClip, clipInfo) {
  if (!clipInfo.faceEffect || !Utils.hasFaceEffect(timelineData.tracks)) return
  await installFaceModel()
  // 应用模型
  let filePath = '/model/ms_face_v1_0_2.model'
  mStreamingContext.initHumanDetection(filePath, '', 0x00000703)
  if (!(clipInfo.faceEffect instanceof FaceEffect)) {
    clipInfo.faceEffect = new FaceEffect(clipInfo.faceEffect)
  }
  clipInfo.faceEffect.setVideoClipRaw(videoClip)
  clipInfo.faceEffect.setData()
}
// 下载人脸模型
function installFaceModel () {
  const url = 'https://alieasset.meishesdk.com/model/ms_face_v1.0.2.model'
  let packageName = 'ms_face_v1_0_2.model'
  return new Promise((resolve, reject) => {
    checkAndInstallAssetFromIndexDB(packageName).then(() => {
      resolve()
    }).catch(() => {
      axios.get(url, { responseType: 'arraybuffer' }).then(res => {
        saveAssetToIndexDB(packageName, new Uint8Array(res.data)).then(() => {
          checkAndInstallAssetFromIndexDB(packageName).then(() => {
            resolve()
          }).catch(reject)
        })
      }).catch(reject)
    })
  })
}
function addStoryboardVideoFxs (videoClip, clipInfo) {
  if (!Array.isArray(clipInfo.storyboardVideoFxs)) return
  clipInfo.storyboardVideoFxs.map(item => {
    item.raw = videoClip.appendRawBuiltinFx(item.name)
    if (item.raw) addParams(item)
    else console.error('Storyboard特效添加失败', item.name, item)
  })
}
function addAdjustData (videoClip, clipInfo) {
  if (!clipInfo.adjustData) return
  if (!(clipInfo.adjustData instanceof AdjustData)) {
    clipInfo.adjustData = new AdjustData(clipInfo.adjustData)
  }
  clipInfo.adjustData.setRaw(videoClip)
  clipInfo.adjustData.setData()
}
async function insertVideoClip (videoTrack, clipInfo, index) {
  if (videoTrack == null || clipInfo == null) {
    return
  }
  let filePath = clipInfo.reverse ? clipInfo.reverseM3u8Path : clipInfo.m3u8Path
  // console.log('insertVideoClip', filePath, videoTrack, index, clipInfo)
  let videoClip = videoTrack.insertClip2(filePath, clipInfo.trimIn, clipInfo.trimOut, index)
  await setVideoClipProperty(videoClip, clipInfo)
}

function addVideoFxs (videoClip, clipInfo) {
  checkUndefind(videoClip, clipInfo)
  for (let i = 0; i < clipInfo.videoFxs.length; i++) {
    var videoFx = clipInfo.videoFxs[i]
    var nvsVideoFx
    if (videoFx.type === 'builtin') {
      nvsVideoFx = videoFx.isRaw ? videoClip.appendRawBuiltinFx(videoFx.desc) : videoClip.appendBuiltinFx(videoFx.desc)
    } else if (videoFx.type === 'package' && videoFx.videoFxType !== 'animation') {
      // 片段上的动画滤镜，都是属性特技
      nvsVideoFx = videoClip.appendPackagedFx(videoFx.desc)
    } else {
      videoClip.enablePropertyVideoFx(true)
      nvsVideoFx = videoClip.getPropertyVideoFx()
    }
    checkUndefind(nvsVideoFx, videoFx)
    if (nvsVideoFx) {
      nvsVideoFx.setFilterIntensity(videoFx.intensity)
    } else {
      console.error('特效添加失败', videoClip)
      return
    }
    videoFx.raw = nvsVideoFx
    if (videoFx.isInverseRegion) nvsVideoFx.setInverseRegion(videoFx.isInverseRegion)
    if (videoFx.isRegional) nvsVideoFx.setRegional(videoFx.isRegional)

    addParams(videoFx)
    addKeyFrames(videoFx)
    if (videoFx.desc === 'Mosaic' || videoFx.desc === 'Gaussian Blur')  {
      nvsVideoFx.setRegional(videoFx.isRegional)
      nvsVideoFx.setIgnoreBackground(videoFx.isIgnoreBackground)
      nvsVideoFx.setInverseRegion(videoFx.isInverseRegion)
      nvsVideoFx.setRegionalFeatherWidth(videoFx.regionalFeatherWidth)
    }
  }
}

function removeVideoOrAudioTrackClip (item) {
  return mStreamingContext.streamingEngineReadyForTimelineModification().then(() => {
    item.raw.removeAllClips()
    for (let i = 0; i < item.clips.length; i++) {
      item.clips[i].raw = null
    }
  })
}
function buildVideoTrackByItem (item) {
  return mStreamingContext.streamingEngineReadyForTimelineModification().then(async () => {
    let track = item.raw
    for (let i = 0; i < item.clips.length; i++) {
      let clip = item.clips[i]
      // 构建时间线的时候，不需要重新设置视频片段出点
      await addVideoClip(track, clip)
    }
    // buildTransitionForVideoClip(item, item.raw)
  })
}
function buildAudioTrack () {
  return new Promise((resolve, reject) => {
    mStreamingContext.streamingEngineReadyForTimelineModification().then(async () => {
      await clearAudioTrack()
      let tracks = getTracksInTimelineDataByType(GL.AUDIOTRACK)
      for (let i = tracks.length - 1; i >= 0; i--) {
        let trackData = tracks[i]
        let audioTrack = mTimeline.appendAudioTrack()
        trackData.raw = audioTrack
        if (audioTrack === undefined) {
          console.error('buildAudioTrack', 'failed to append Audio track')
          reject(new Error('buildAudioTrack: failed to append Audio track'))
        }
        if (trackData.show) {
          await buildAudioTrackByItem(trackData)
        }
        for (let j = 0; j < trackData.transitions.length; j++) {
          let transition = trackData.transitions[j]
          if (transition.clipType === 'package') {
            transition.raw = audioTrack.setPackagedTransition(transition.index, transition.desc)
          } else if (transition.clipType === 'builtin') {
            transition.raw = audioTrack.setBuiltinTransition(transition.index, transition.desc)
          }
          transition.raw.setVideoTransitionDuration(transition.duration, 1)
          transition.clipUUID = trackData.clips[transition.index].uuid
        }
        let videoVolume = trackData.volume
        audioTrack.setVolumeGain(videoVolume, videoVolume)
      }
      console.log('音频轨 铺设完成')
      resolve()
    }).catch(e => {
      reject(e)
    })
  })
}
function buildAudioTrackByItem (item) {
  return mStreamingContext.streamingEngineReadyForTimelineModification().then(() => {
    let track = item.raw
    for (let i = 0; i < item.clips.length; i++) {
      let clip = item.clips[i]
      // 构建时间线的时候，不需要重新设置音频片段出点
      addAudioClip(track, clip)
    }
  })
}
async function addAudioClip (track, clip) {
  let path = clip.reverse ? clip.reverseM3u8Path : clip.m3u8Path
  let audioClip = track.addClip2(path, clip.inPoint, clip.trimIn, clip.trimOut)
  audioClip.changeSpeed(clip.speed, true)
  // console.log('addAudioClip', clip, clip.volume, audioClip)
  await setAudioProperty(audioClip, clip)
}
function insertAudioClip (audioTrack, clipInfo, index) {
  if (audioTrack == null || clipInfo == null) {
    return
  }
  let filePath = clipInfo.m3u8Path
  let audioClip = audioTrack.insertClip2(filePath, clipInfo.trimIn, clipInfo.trimOut, index)
  setAudioProperty(audioClip, clipInfo)
}
async function setAudioProperty (audioClip, clip) {
  if (audioClip === undefined) {
    console.error('addAudioClip is failed: ', clip)
    return
  }
  if (clip.uuid === '') {
    clip.uuid = uuid()
  }
  // 判断是否在音频clip上添加了关键帧
  clip.isAddAudioKeyFrames = false
  const audioFx = clip.audioFxs.find(
        param => param.type === "volume"
  );
  if (audioFx !== undefined) {
    if (audioFx.keyFrames.length > 0) {
      clip.isAddAudioKeyFrames = true
    }
  }

  if(!clip.isAddAudioKeyFrames){
    if (clip.channelType === 'stereo') {
      audioClip.setVolumeGain(clip.volume, clip.volume)
    } else if (clip.channelType === 'left') {
      audioClip.setVolumeGain(clip.volume, 0)
    } else if (clip.channelType === 'right') {
      audioClip.setVolumeGain(0, clip.volume)
    }
  }

  await mStreamingContext.streamingEngineReadyForTimelineModification()
  if (clip.curveSpeedString) {
    audioClip.changeCurvesVariableSpeed(clip.curveSpeedString, true)
  } else {
    audioClip.changeSpeed(clip.speed, true)
  }
  if (GL.getIsLoadingProject() === false) {    // 此时底层数据是正确的，只是需要改变ui的长度
    clip.outPoint = audioClip.getOutPoint()
  }
  if (clip.audioStreamIndex) {
    audioClip.setAudioStreamIndex(clip.audioStreamIndex)
  }
  audioClip.setFadeInDuration(clip.fadeInDuration)
  audioClip.setFadeOutDuration(clip.fadeOutDuration)
  addAudioFxs(audioClip, clip)
  clip.raw = audioClip
}
// function addAudioFxs (audioClip, clipInfo) {
//   for (let i = 0; i < clipInfo.audioFxs.length; i++) {
//     let audioFx = clipInfo.audioFxs[i]
//     clipInfo.type === 'audio' ? audioClip.appendFx(audioFx.desc) : audioClip.appendAudioFx(audioFx.desc)
//   }
// }
function addAudioFxs (audioClip, clipInfo) {
  for (let i = 0; i < clipInfo.audioFxs.length; i++) {
    let audioFx = clipInfo.audioFxs[i]
      if(audioFx.type === 'builtin') {
        audioFx.raw = audioClip.appendFx(audioFx.desc)
      } else if(audioFx.type === 'volume') {
        audioFx.raw = audioClip.getAudioVolumeFx()
          if (audioFx.keyFrames.length > 0) {
            audioFx.keyFrames.forEach(ele=>{
              ele.time = Number(ele.time)
              ele.value = Number(ele.value)
              ele.visible =  ele.visible === 'true' || ele.visible ? true : false
              audioFx.raw.setFloatValAtTime(ele.key,ele.value,ele.time)
            })
          }

      }else {
        audioFx.raw = audioClip.appendAudioFx(audioFx.desc)
      }
  }
}
function clearCaptions (caption) {
  function clear (caption, deleteAllCallBack) {
    return mStreamingContext.streamingEngineReadyForTimelineModification().then(() => {
      if (caption) {
        clear(mTimeline.removeCaption(caption), deleteAllCallBack)
      } else {
        if (deleteAllCallBack && typeof deleteAllCallBack === 'function') {
          deleteAllCallBack()
        }
      }
    })
  }
  return new Promise((resolve, reject) => {
    clear(caption, () => { resolve() })
  })
}
function clearCompoundCaptions (caption) {
  function clearCompoundCaption (caption, deleteAllCallBack) {
    return mStreamingContext.streamingEngineReadyForTimelineModification().then(() => {
      if (caption) {
        clearCompoundCaption(mTimeline.removeCompoundCaption(caption), deleteAllCallBack)
      } else {
        if (deleteAllCallBack && typeof deleteAllCallBack === 'function') {
          deleteAllCallBack()
        }
      }
    })
  }
  return new Promise((resolve, reject) => {
    clearCompoundCaption(caption, () => { resolve() })
  })
}

function clearStickers (sticker) {
  function clearSticker (sticker, deleteAllCallBack) {
    return mStreamingContext.streamingEngineReadyForTimelineModification().then(() => {
      if (sticker) {
        clearSticker(mTimeline.removeAnimatedSticker(sticker), deleteAllCallBack)
      } else {
        if (deleteAllCallBack && typeof deleteAllCallBack === 'function') {
          deleteAllCallBack()
        }
      }
    })
  }
  return new Promise((resolve, reject) => {
    clearSticker(sticker, () => { resolve() })
  })
}

function clearVideoFxs (videoFx) {
  function clearVideoFx (videoFx, deleteAllCallBack) {
    return mStreamingContext.streamingEngineReadyForTimelineModification().then(() => {
      if (videoFx) {
        clearVideoFx(mTimeline.removeTimelineVideoFx(videoFx), deleteAllCallBack)
      } else {
        if (deleteAllCallBack && typeof deleteAllCallBack === 'function') {
          mTimeline.deleteWatermark()
          deleteAllCallBack()
        }
      }
    })
  }
  return new Promise((resolve, reject) => {
    clearVideoFx(videoFx, () => { resolve() })
  })
}

function changeMusicCaptionsStyle (newStyleDesc) {
  let musicClips = []
  for (let i = 0; i < timelineData.tracks.length; i++) {
    if (timelineData.tracks[i].type === 'musicLyricsTrack') {
      musicClips = timelineData.tracks[i].clips
    }
  }
  for (let i = 0; i < musicClips.length; i++) {
    musicClips[i].styleDesc = newStyleDesc
  }
}

async function deleteCaptions (captionClips) {
  // console.log('deleteCaptions', captionClips)
  for (let i = 0; i < captionClips.length; i++) {
    if (captionClips[i].raw) {
      const clip = captionClips[i]
      await mStreamingContext.streamingEngineReadyForTimelineModification()
      if (clip.clipSubType === 'richtext') {
        await mTimeline.removeAnimatedSticker(clip.raw)
      } else {
        await mTimeline.removeCaption(clip.raw)
      }
      captionClips[i].raw = null
    }
  }
}

function deleteCaption (captionClip) {
  return mStreamingContext.streamingEngineReadyForTimelineModification().then(async () => {
    if (captionClip.raw) {
      if (captionClip.clipSubType === 'richtext') {
        await mTimeline.removeAnimatedSticker(captionClip.raw)
      } else {
        await mTimeline.removeCaption(captionClip.raw)
      }
      captionClip.raw = null
    }
  })
}

function deleteCompoundCaptions (compoundCaptionClips) {
  return mStreamingContext.streamingEngineReadyForTimelineModification().then(() => {
    // console.log('deleteCompoundCaptions', compoundCaptionClips)
    for (let i = 0; i < compoundCaptionClips.length; i++) {
      if (compoundCaptionClips[i].raw) {
        // console.log('删除了一个字幕： ', i, compoundCaptionClips[i].raw)
        mTimeline.removeCompoundCaption(compoundCaptionClips[i].raw)
        compoundCaptionClips[i].raw = null
      }
    }
  })
}

async function addCompoundCaptions (captionClips, z) {
  return mStreamingContext.streamingEngineReadyForTimelineModification().then(async () => {
    // console.log('addCompoundCaptions', captionClips, mTimeline.getDuration())
    for (let i = 0, length = captionClips.length; i < length; i++) {
      await addCompoundCaption(captionClips[i], z)
    }
  })
}
function addCompoundCaption (captionClip, z) {
  return mStreamingContext.streamingEngineReadyForTimelineModification().then(async () => {
    let caption = mTimeline.addCompoundCaption(captionClip.inPoint, captionClip.outPoint - captionClip.inPoint, captionClip.styleDesc)
    if (!caption) {
      console.error('组合字幕添加失败', captionClip)
      return
    }
    captionClip.isInvalid = store.state.material.invalidAssetIds.includes(captionClip.styleDesc)
    // 如果上层结构里没有，需要添加
    if (captionClip.text.length === 0) {
      for (let i = 0; i < caption.getCaptionCount(); i++) {
        const fontFromCaption = await Utils.getFontFamily(caption.getFontFamily(i))
        caption.setFontFamily(i, fontFromCaption)
        const fontForView = Object.keys(store.state.material.fontNameObj).find(item => store.state.material.fontNameObj[item] === fontFromCaption)
        captionClip.text.push({
          text: caption.getText(i),
          font: fontForView || fontFromCaption,
          color: caption.getTextColor(i) ? Utils.NvsColorToRGBA(caption.getTextColor(i)) : ''
        })
      }
    } else {
      captionClip.text.map(async (item, index) => {
        if (typeof item === 'string') {
          caption.setText(index, item)
          const fontFromCaption = caption.getFontFamily(index)
          const fontForView = Object.keys(store.state.material.fontNameObj).find(item => store.state.material.fontNameObj[item] === fontFromCaption)
          captionClip.text[index] = {
            text: item,
            font: fontForView || fontFromCaption,
            color: caption.getTextColor(index) ? Utils.NvsColorToRGBA(caption.getTextColor(index)) : ''}
        } else {
          caption.setText(index, item.text)
          let fontFromCaption = item.font || captionClip.font
          if (store.state.material.fontNameObj.hasOwnProperty(fontFromCaption)) {
            fontFromCaption = store.state.material.fontNameObj[fontFromCaption]
          }
          caption.setFontFamily(index, fontFromCaption)
          if (item.color || captionClip.color) {
            caption.setTextColor(index, Utils.RGBAToNvsColor(item.color || captionClip.color))
          }
        }
      })
    }
    caption.setScaleX(captionClip.scaleX)
    caption.setScaleY(captionClip.scaleY)
    caption.setRotationZ(captionClip.rotation)
    const offsetPointF = new NvsPointF(captionClip.translationX, captionClip.translationY)
    caption.setCaptionTranslation(offsetPointF)
    if (z !== undefined) {
      caption.setZValue(z)
    }
    captionClip.raw = caption
  })
}
async function addCaptions (captionClips, z) {
  return mStreamingContext.streamingEngineReadyForTimelineModification().then(async () => {
    for (let i = 0, length = captionClips.length; i < length; i++) {
      await addCaption(captionClips[i], z)
    }
  })
}
async function addCaption (captionClip, z) {
  await mStreamingContext.streamingEngineReadyForTimelineModification()
  let styleDesc = captionClip.styleDesc
  if (captionClip.text === '') {
    styleDesc = ''
  }
  // 富文本字幕
  if (captionClip.clipSubType === 'richtext') {
    let sticker
    try {
      let name
      name = captionClip.uuid + '.png'
      const filePath = (GL.getResourcesFolder() + '/' + name).replace(/^\//, '')
      await installOtherFile({ url: captionClip.richCustomStickerImageUrl, packageName: name })
      await mStreamingContext.streamingEngineReadyForTimelineModification()
      sticker = mTimeline.addAnimatedSticker(
        captionClip.inPoint,
        captionClip.outPoint - captionClip.inPoint,
        captionClip.styleDesc + '',
        false,
        true,
        filePath
      )
    } catch (e) {
      console.error('自定义贴纸资源安装失败', e)
    }
    if (!sticker) {
      console.error('自定义贴纸添加失败', captionClip)
      return
    }
    captionClip.isInvalid = store.state.material.invalidAssetIds.includes(captionClip.styleDesc)
    sticker.setScale(captionClip.scaleX)
    sticker.setRotationZ(captionClip.rotation)
    const offsetPointF = new NvsPointF(captionClip.translationX, captionClip.translationY)
    sticker.setTranslation(offsetPointF)
    // sticker.setHorizontalFlip(captionClip.horizontalFlip)
    // sticker.setVerticalFlip(captionClip.verticalFlip)
    if (z !== undefined) {
      sticker.setZValue(z)
    }
    captionClip.raw = sticker
    return
  }
  let caption
  // 模块字幕
  if (captionClip.clipSubType === 'modular') {
    caption = mTimeline.addModularCaption(
      captionClip.text,
      captionClip.inPoint,
      captionClip.outPoint - captionClip.inPoint
    )
    const contextId = captionClip.contextId
    const rendererId = captionClip.rendererId
    const animationId = captionClip.animationId
    const inAnimationId = captionClip.inAnimationId
    const outAnimationId = captionClip.outAnimationId
    contextId && caption.applyModularCaptionContext(contextId)
    rendererId && caption.applyModularCaptionRenderer(rendererId)
    if (animationId) {
      caption.applyModularCaptionAnimation(animationId)
      caption.setModularCaptionAnimationPeroid(captionClip.animationPeroid / 1000)
    } else {
      if (inAnimationId) {
        caption.applyModularCaptionInAnimation(inAnimationId)
        // 出动画duration会默认设为500，此处重置为0
        caption.setModularCaptionOutAnimationDuration(0)
        caption.setModularCaptionInAnimationDuration(captionClip.inAnimationDuration / 1000)
      }
      if (outAnimationId) {
        caption.applyModularCaptionOutAnimation(outAnimationId)
        caption.setModularCaptionOutAnimationDuration(captionClip.outAnimationDuration / 1000)
      }
    }
    const getInterFn = (a, b) => a.filter(v => b.includes(v))
    const interArr = getInterFn(
      store.state.material.invalidAssetIds,
      [contextId, rendererId, animationId, inAnimationId, outAnimationId]
    )
    captionClip.isInvalid = interArr.length > 0
  } else {
    caption = mTimeline.addCaption(
      captionClip.text,
      captionClip.inPoint,
      captionClip.outPoint - captionClip.inPoint,
      styleDesc,
      false
    )
  }
  if (!caption) {
    console.error('字幕添加失败', captionClip)
    return
  }
  captionClip.isInvalid = store.state.material.invalidAssetIds.includes(styleDesc)
  // 需要用getFontFamily做转换才能给setFontFamily
  const fontFromCaption = await Utils.getFontFamily(captionClip.font || caption.getFontFamily())
  caption.setFontFamily(fontFromCaption)
  const fontForView = Object.keys(store.state.material.fontNameObj).find(item => store.state.material.fontNameObj[item] === fontFromCaption)
  captionClip.font = fontForView || fontFromCaption
  if (captionClip.type !== GL.MUSICLYRICSCLIP && captionClip.type !== GL.BLANKLINE) {
    if (typeof captionClip.scaleX !== 'number') {
      captionClip.scaleX = caption.getScaleX()
    } else {
      caption.setScaleX(captionClip.scaleX)
    }

    if (typeof captionClip.scaleY !== 'number') {
      captionClip.scaleY = caption.getScaleY()
    } else {
      caption.setScaleY(captionClip.scaleY)
    }

    if (typeof captionClip.rotation !== 'number') {
      captionClip.rotation = caption.getRotationZ()
    } else {
      caption.setRotationZ(captionClip.rotation)
    }

    if (typeof captionClip.translationX !== 'number' || typeof captionClip.translationY !== 'number') {
      const {x, y} = caption.getCaptionTranslation()
      captionClip.translationX = x
      captionClip.translationY = y
    } else {
      const offsetPointF = new NvsPointF(captionClip.translationX, captionClip.translationY)
      caption.setCaptionTranslation(offsetPointF)
    }

    if (captionClip.weight <= 0) {
      captionClip.weight = caption.getWeight()
      captionClip.bold = captionClip.weight > 500
    } else {
      caption.setWeight(captionClip.weight)
    }

    if (typeof captionClip.italic !== 'boolean') {
      captionClip.italic = caption.getItalic()
    } else {
      caption.setItalic(captionClip.italic)
    }

    if (typeof captionClip.underline !== 'boolean') {
      captionClip.underline = caption.getUnderline()
    } else {
      caption.setUnderline(captionClip.underline)
    }

    if (typeof captionClip.shadow !== 'boolean') {
      captionClip.shadow = caption.getDrawShadow()
      const {x, y} = caption.getShadowOffset()
      captionClip.shadowOffsetX = x
      captionClip.shadowOffsetY = y
    } else {
      caption.setDrawShadow(captionClip.shadow)
      caption.setShadowOffset(new NvsPointF(captionClip.shadowOffsetX, captionClip.shadowOffsetY))
    }

    if (captionClip.shadowColor) {
      caption.setShadowColor(Utils.RGBAToNvsColor(captionClip.shadowColor))
    }

    caption.setShadowFeather(captionClip.shadowFeather)

    if (typeof captionClip.outline !== 'boolean') {
      captionClip.outline = caption.getDrawOutline()
    } else {
      caption.setDrawOutline(captionClip.outline)
    }

    if (captionClip.outlineColor) {
      caption.setOutlineColor(Utils.RGBAToNvsColor(captionClip.outlineColor))
    }

    if (typeof captionClip.outlineWidth !== 'number') {
      captionClip.outlineWidth = caption.getOutlineWidth()
    } else {
      caption.setOutlineWidth(captionClip.outlineWidth)
    }

    if (captionClip.bgColor) {
      caption.setBackgroundColor(Utils.RGBAToNvsColor(captionClip.bgColor))
    } else {
      caption.setBackgroundColor(Utils.RGBAToNvsColor('rgba(0, 0, 0, 0)'))
    }
    if (typeof captionClip.bgRadius === 'number') {
      caption.setBackgroundRadius(captionClip.bgRadius)
    } else {
      captionClip.bgRadius = caption.getBackgroundRadius()
    }
  }
  if (z !== undefined) {
    caption.setZValue(z)
  }

  const aligns = ['left', 'center', 'right','top',  'vCenter','bottom']
  // 这是文字对其方式 垂直对齐是 设置 setVerticalLayout(true) 再对应得设置 setTextAlignment
  if (!captionClip.align) {
    let index = caption.getVerticalLayout() ? caption.getTextAlignment() + 3 : caption.getTextAlignment()
    captionClip.align = aligns[index]
  } else {
    let index = aligns.indexOf(captionClip.align)
    caption.setVerticalLayout(index > 2)
    caption.setTextAlignment(index > 2 ? index - 3 : index)
  }

  if (captionClip.fontSize > 0) {
    caption.setFontSize(GL.computerFontSizeInResolvingPower(captionClip.fontSize, false ))
  } else {
    captionClip.fontSize = GL.computerFontSizeInResolvingPower(caption.getFontSize())
  }

  if (captionClip.color) {
    caption.setTextColor(Utils.RGBAToNvsColor(captionClip.color))
  }
  if (captionClip.letterSpacingType !== undefined) {
    caption.setLetterSpacingType(captionClip.letterSpacingType)
    if (captionClip.letterSpacingType === NvsCaptionLetterSpacingTypeEnum.Percentage) {
      caption.setLetterSpacing(captionClip.letterSpacingPercentage)
    } else {
      caption.setLetterSpacing(captionClip.letterSpacingAbsolute)
    }
  } else {
    captionClip.letterSpacingType = caption.getLetterSpacingType()
  }
  if (captionClip.lineSpacing) {
    caption.setLineSpacing(captionClip.lineSpacing)
  }
  captionClip.raw = caption
  if (Array.isArray(captionClip.params)) {
    addParams(captionClip)
  }
  if (Array.isArray(captionClip.keyFrames)) {
    addKeyFrames(captionClip)
  }
  if (captionClip.enableSpeed) {
    captionClip.raw.changeOutPoint(captionClip.inPoint + 5000000)
    captionClip.raw.enableSpeed(true)
    captionClip.raw.changeOutPoint(captionClip.outPoint)
  }
}

async function applyCaptionStyleForMusicLyrics (caption, style, isUseDefaultAssetParam) {
  let tracks = getTracksInTimelineDataByType(GL.MUSICLYRICSTRACK)
  if (tracks.length !== 1) {
    console.log('addSubtitleCaption', 'musicLyrics track length is too large')
    return
  }
  await mStreamingContext.streamingEngineReadyForTimelineModification()
  if (caption !== undefined && caption !== null && caption instanceof NvsTimelineCaption) {
    caption.applyCaptionStyle(style, isUseDefaultAssetParam)
    let tracks = timelineData.tracks[getTrackLastIndexInItems(TimelineUtils.timelineData.tracks, GL.MUSICLYRICSTRACK)]
    if (tracks !== undefined) {
      if (tracks.fontSize !== undefined) {
        caption.setFontSize(GL.computerFontSizeInResolvingPower(tracks.fontSize, false))
      }
      if (tracks.color !== undefined && tracks.color !== '') {
        caption.setTextColor(Utils.RGBAToNvsColor(tracks.color))
      }
      if (tracks.font) {
        const font = await Utils.getFontFamily(tracks.font)
        caption.setFontFamily(font)
      }
      if (tracks.outline) {
        caption.setDrawOutline(tracks.outline)
        tracks.outlineColor && caption.setOutlineColor(Utils.RGBAToNvsColor(tracks.outlineColor))
        tracks.outlineWidth && caption.setOutlineWidth(tracks.outlineWidth)
      }
      if (tracks.scaleX !== undefined && tracks.scaleY !== undefined && tracks.rotation !== undefined &&
      tracks.translationX !== undefined && tracks.translationY !== undefined && style !== '') {
        caption.setScaleX(tracks.scaleX)
        caption.setScaleY(tracks.scaleY)
        caption.setRotationZ(tracks.rotation)
        const offsetPointF = new NvsPointF(tracks.translationX, tracks.translationY)
        caption.setCaptionTranslation(offsetPointF)
      }
    }
  }
}

function applyCaptionsStyleForMusicLyrics (captions, style, isUseDefaultAssetParam) {
  return mStreamingContext.streamingEngineReadyForTimelineModification().then(async () => {
    for (let i = 0, length = captions.length; i < length; i++) {
      await applyCaptionStyleForMusicLyrics(captions[i].raw, style, isUseDefaultAssetParam)
    }
  })
}

async function addSubtitleCaptions (captionClips, z, callback) {
  return mStreamingContext.streamingEngineReadyForTimelineModification().then(async () => {
    for (let i = 0, length = captionClips.length; i < length; i++) {
      await addSubtitleCaption(captionClips[i], z)
    }
    if (callback && typeof callback === 'function') {
      callback()
    }
  })
}

async function addSubtitleCaption (captionClip, z) {
  let styleDesc = ''
  let tracks = timelineData.tracks[getTrackLastIndexInItems(TimelineUtils.timelineData.tracks, GL.MUSICLYRICSTRACK)]
  if (tracks !== undefined) {
    if (tracks.styleDesc !== undefined) {
      styleDesc = tracks.styleDesc
    } else {
      if (captionClip.styleDesc !== undefined) {
        styleDesc = captionClip.styleDesc
      }
    }
    if (captionClip.text === '') {
      styleDesc = ''
    }
    const _isDuplicate = isLoadingDuplicate({ message: vm.$t('loading.loading') })
    const loadingInstance = !_isDuplicate && Loading.service({
      target: '#app',
      text: vm.$t('loading.loading'),
      background: 'rgba(0, 0, 0, 0)',
      lock: true
    })
    let caption = mTimeline.addCaption(captionClip.text, captionClip.inPoint,captionClip.outPoint - captionClip.inPoint, styleDesc, false)
    if (!caption) {
      console.error('唱词字幕添加失败', captionClip, styleDesc)
      !_isDuplicate && loadingInstance.close()
      return
    }
    captionClip.isInvalid = store.state.material.invalidAssetIds.includes(styleDesc)
    !_isDuplicate && loadingInstance.close()
    let type = 1
    if (tracks.align !== undefined) {
      if (tracks.align === 'left') {
        type = 0
      } else if (tracks.align === 'right') {
        type = 2
      }
    } else {
      if (captionClip.align !== undefined) {
        if (captionClip.align === 'left') {
          type = 0
        } else if (captionClip.align === 'right') {
          type = 2
        }
      }
    }
    caption.setTextAlignment(type)
    caption.setIsLyrics(true)
    if (tracks.fontSize !== undefined) {
      if (tracks.fontSize > 0) {
        caption.setFontSize(GL.computerFontSizeInResolvingPower(tracks.fontSize, false))
      } else {
        tracks.fontSize = parseFloat(caption.getFontSize().toFixed(0))
      }
    } else {
      if (captionClip.fontSize !== undefined) {
        if (captionClip.fontSize > 0) {
          caption.setFontSize(captionClip.fontSize)
        } else {
          captionClip.fontSize = parseFloat(caption.getFontSize().toFixed(0))
        }
      }
    }
    if (tracks.outline) {
      caption.setDrawOutline(tracks.outline)
      if (tracks.outlineColor) { caption.setOutlineColor(Utils.RGBAToNvsColor(tracks.outlineColor)) }
      if (typeof tracks.outlineWidth === 'number') caption.setOutlineWidth(tracks.outlineWidth)
    } else {
      tracks.outline = caption.getDrawOutline()
      if (!tracks.outlineColor) { tracks.outlineColor = Utils.NvsColorToRGBA(caption.getOutlineColor()) }
      if (typeof tracks.outlineWidth !== 'number') tracks.outlineWidth = caption.getOutlineWidth()
    }
    if (tracks.color !== undefined) {
      if (tracks.color !== '') {
        caption.setTextColor(Utils.RGBAToNvsColor(tracks.color))
      }
    } else {
      if (captionClip.color !== undefined) {
        if (captionClip.color !== '') {
          caption.setTextColor(Utils.RGBAToNvsColor(captionClip.color))
        }
      }
    }
    let fontFromCaption
    if (tracks.font) {
      fontFromCaption = await Utils.getFontFamily(tracks.font)
      caption.setFontFamily(fontFromCaption)
    } else {
      fontFromCaption = await Utils.getFontFamily(captionClip.font || caption.getFontFamily())
      caption.setFontFamily(fontFromCaption)
      const fontForView = Object.keys(store.state.material.fontNameObj).find(item => store.state.material.fontNameObj[item] === fontFromCaption)
      tracks.font = fontForView || fontFromCaption
    }
    if (styleDesc !== '') {
      if (tracks.scaleX !== undefined && tracks.scaleY !== undefined && tracks.rotation !== undefined &&
        tracks.translationX !== undefined && tracks.translationY !== undefined) {
        if (tracks.scaleX === 1 && tracks.scaleY === 1 && tracks.rotation === 0 && tracks.translationX === 0 && tracks.translationY === 0) {
          tracks.scaleX = caption.getScaleX()
          tracks.scaleY = caption.getScaleY()
          tracks.rotation = caption.getRotationZ()
          let pointF = caption.getCaptionTranslation()
          tracks.translationX = pointF.x
          tracks.translationY = pointF.y
        }
        if (captionClip.text !== '') {
          caption.setScaleX(tracks.scaleX)
          caption.setScaleY(tracks.scaleY)
          caption.setRotationZ(tracks.rotation)
          const offsetPointF = new NvsPointF(tracks.translationX, tracks.translationY)
          caption.setCaptionTranslation(offsetPointF)
        }
      }
    }
    if (z !== undefined) {
      caption.setZValue(z)
    }
    captionClip.raw = caption
  }
}

function playBackTimeline () {
  if (mTimeline !== null && mTimeline !== undefined && mStreamingContext !== undefined) {
    let nowTime = getCurrentTimelinePosition()
    if (nowTime === mTimeline.getDuration()) {
      nowTime = 0
    }
    let tar = Utils.hasFaceEffect(timelineData.tracks)
    mStreamingContext.playbackTimeline(mTimeline, nowTime, -1, NvsVideoPreviewSizeModeEnum.LiveWindowSize, true, 0 | tar)
  }
}

function stop () {
  if (mStreamingContext !== null && mStreamingContext !== undefined) {
    mStreamingContext.stop()
  }
}

function getCurrentTimelinePosition () {
  if (mTimeline && mStreamingContext) {
    return mStreamingContext.getTimelineCurrentPosition(mTimeline)
  }
  return 0
}
async function resurfaceCaptions (captionClips, z, callback) {
  await addCaptions(captionClips, z)
  if (callback && typeof callback === 'function') {
    callback()
  }
}
async function resurfaceCompoundCaptions (captionClips, z, callback) {
  await addCompoundCaptions(captionClips, z)
  if (callback && typeof callback === 'function') {
    callback()
  }
}
function logStickers (firstAnimatedSticker) {
  if (firstAnimatedSticker !== undefined) {
    // console.log('logStickers firstAnimatedSticker', firstAnimatedSticker.getInPoint(), firstAnimatedSticker.getOutPoint())
    // console.log('logStickers firstAnimatedSticker', firstAnimatedSticker.getInPoint(), firstAnimatedSticker.getOutPoint(), firstAnimatedSticker.getAnimatedStickerPackageId())
    let nextSticker = mTimeline.getNextAnimatedSticker(firstAnimatedSticker)
    if (nextSticker !== undefined) {
      // console.log('logStickers', nextSticker.getInPoint(), nextSticker.getOutPoint())
      // console.log('logStickers', nextSticker.getInPoint(), nextSticker.getOutPoint(), nextSticker.getAnimatedStickerPackageId())
      logStickers(nextSticker)
    }
  }
}
async function deleteStickers (stickerClips) {
  // console.log('deleteStickers', stickerClips)
  for (let i = 0; i < stickerClips.length; i++) {
    if (stickerClips[i].raw) {
      await mStreamingContext.streamingEngineReadyForTimelineModification()
      // console.log('删除了一个贴纸： ', i)
      mTimeline.removeAnimatedSticker(stickerClips[i].raw)
    }
  }
}
async function resurfaceStickers (stickerClips, z, callback) {
  await addStickers(stickerClips, z)
  if (callback && typeof callback === 'function') {
    callback()
  }
}
async function addStickers (stickerClips, z) {
  // return mStreamingContext.streamingEngineReadyForTimelineModification().then(async () => {
  for (let i = 0, length = stickerClips.length; i < length; i++) {
    await addSticker(stickerClips[i], z)
  }
  // })
}
async function addSticker (stickerClip, z) {
  await mStreamingContext.streamingEngineReadyForTimelineModification()
  let sticker
  if (stickerClip.stickerType === 'custom') {
    let index = stickerClip.path.lastIndexOf('/')
    let name = stickerClip.path.substring(index + 1, stickerClip.path.length)
    try {
      await installOtherFile({ url: stickerClip.path })
      sticker = mTimeline.addAnimatedSticker(stickerClip.inPoint, stickerClip.outPoint - stickerClip.inPoint, stickerClip.desc + '', false, true, `${GL.getResourcesFolder()}/${name}`)
    } catch (e) {
      console.error('自定义贴纸资源安装失败', e)
    }
  } else {
    sticker = mTimeline.addAnimatedSticker(stickerClip.inPoint, stickerClip.outPoint - stickerClip.inPoint, stickerClip.desc + '', false, false, '')
  }
  if (!sticker) {
    console.error(stickerClip.stickerType === 'custom' ? '自定义' : '', '贴纸添加失败', stickerClip)
    return
  }
  const animationId = stickerClip.animationId
  const inAnimationId = stickerClip.inAnimationId
  const outAnimationId = stickerClip.outAnimationId
  if (animationId) {
    sticker.applyAnimatedStickerPeriodAnimation(animationId)
    sticker.setAnimatedStickerAnimationPeriod(stickerClip.animationPeroid / 1000)
  } else {
    if (inAnimationId) {
      sticker.applyAnimatedStickerInAnimation(inAnimationId)
      // 出动画duration会默认设为500，此处重置为0
      sticker.setAnimatedStickerOutAnimationDuration(0)
      sticker.setAnimatedStickerInAnimationDuration(stickerClip.inAnimationDuration / 1000)
    }
    if (outAnimationId) {
      sticker.applyAnimatedStickerOutAnimation(outAnimationId)
      sticker.setAnimatedStickerOutAnimationDuration(stickerClip.outAnimationDuration / 1000)
    }
  }
  const getInterFn = (a, b) => a.filter(v => b.includes(v))
  const interArr = getInterFn(
    store.state.material.invalidAssetIds,
    [stickerClip.desc, animationId, inAnimationId, outAnimationId]
  )
  stickerClip.isInvalid = interArr.length > 0
  sticker.setScale(stickerClip.scale)
  sticker.setRotationZ(stickerClip.rotation)
  const offsetPointF = new NvsPointF(stickerClip.translationX, stickerClip.translationY)
  sticker.setTranslation(offsetPointF)
  sticker.setHorizontalFlip(stickerClip.horizontalFlip)
  sticker.setVerticalFlip(stickerClip.verticalFlip)
  if (z !== undefined) {
    sticker.setZValue(z)
  }
  sticker.setVolumeGain(stickerClip.volume, stickerClip.volume)
  stickerClip.raw = sticker
  if (Array.isArray(stickerClip.keyFrames)) {
    addKeyFrames(stickerClip)
  }
}
async function addFilters (filterClips, z) {
  await mStreamingContext.streamingEngineReadyForTimelineModification()
  for (let i = 0; i < filterClips.length; i++) {
    var isParticleFX
    if (filterClips[i].clipType === GL.TIMELINEVIDEOFXTYPPACKAGE) {
      isParticleFX = mStreamingContext.getAssetPackageManager().isParticleFX(filterClips[i].desc)
      filterClips[i].raw = mTimeline.addPackagedTimelineVideoFx(filterClips[i].inPoint, filterClips[i].outPoint - filterClips[i].inPoint, filterClips[i].desc)
      if (!filterClips[i].raw) {
        console.error('时间线滤镜添加失败', filterClips[i]);
        return
      }
      filterClips[i].raw.setFilterIntensity(filterClips[i].intensity)
      filterClips[i].raw.setRegional(filterClips[i].isRegional)
      filterClips[i].raw.setIgnoreBackground(filterClips[i].isIgnoreBackground)
      filterClips[i].raw.setInverseRegion(filterClips[i].isInverseRegion)
      filterClips[i].raw.setRegionalFeatherWidth(filterClips[i].regionalFeatherWidth)
      filterClips[i].isInvalid = store.state.material.invalidAssetIds.includes(filterClips[i].desc)
      if (z !== undefined) {
        filterClips[i].raw.setZValue(z)
      }
      // console.log('添加滤镜：', filterClips[i].inPoint, filterClips[i].outPoint - filterClips[i].inPoint, filterClips[i].desc, filterClips[i].raw)
    } else {
      isParticleFX = false
      if(filterClips[i].desc){
        if (Array.isArray(filterClips[i].descArray)) {
          // await mStreamingContext.streamingEngineReadyForTimelineModification()
          filterClips[i].descArray.forEach(desc => {
            let raw = mTimeline.addBuiltinTimelineVideoFx(
              filterClips[i].inPoint,
              filterClips[i].outPoint - filterClips[i].inPoint,
              desc
            )
            checkUndefind(raw, filterClips[i])
            raw.setFilterIntensity(filterClips[i].intensity)
            raw.setRegional(filterClips[i].isRegional)
            raw.setIgnoreBackground(filterClips[i].isIgnoreBackground)
            raw.setInverseRegion(filterClips[i].isInverseRegion)
            raw.setRegionalFeatherWidth(filterClips[i].regionalFeatherWidth)
            if (z !== undefined) {
              raw.setZValue(z)
            }
            if (filterClips[i].multiRaw === null) {
              filterClips[i].multiRaw = {}
            }
            filterClips[i].multiRaw[desc] = raw
          })
        } else {
          filterClips[i].raw = mTimeline.addBuiltinTimelineVideoFx(filterClips[i].inPoint, filterClips[i].outPoint - filterClips[i].inPoint, filterClips[i].desc)
          checkUndefind(filterClips[i].raw, filterClips[i])
          filterClips[i].raw && filterClips[i].raw.setFilterIntensity(filterClips[i].intensity)
          filterClips[i].raw && filterClips[i].raw.setRegional(filterClips[i].isRegional)
          filterClips[i].raw && filterClips[i].raw.setIgnoreBackground(filterClips[i].isIgnoreBackground)
          filterClips[i].raw && filterClips[i].raw.setInverseRegion(filterClips[i].isInverseRegion)
          filterClips[i].raw && filterClips[i].raw.setRegionalFeatherWidth(filterClips[i].regionalFeatherWidth)
          if (z !== undefined) {
            filterClips[i].raw.setZValue(z)
          }
        }
      }
      // console.log('添加特效：', filterClips[i].inPoint, filterClips[i].outPoint - filterClips[i].inPoint, filterClips[i].desc, filterClips[i].raw)
    }

    if (isParticleFX) {
      var desc = mStreamingContext.getAssetPackageManager().getVideoFxAssetPackageDescription(filterClips[i].desc)
      var descParser = new NvsAssetPackageParticleDescParser(desc)
      var emitterList = descParser.getParticlePartitionEmitter(0)
      addParticleParams(filterClips[i], emitterList)
    } else {
      if (filterClips[i].multiRaw) {
        filterClips[i].descArray.forEach(desc => {
          addParams({
            "raw": filterClips[i].multiRaw[desc],
            "params": filterClips[i].multiParams[desc],
            "shape": filterClips[i].shape
          })
        })
      } else {
        addParams(filterClips[i])
      }

      if (filterClips[i].keyFrames.length > 0) {
        addKeyFrames(filterClips[i])
      }
    }
  }
}
function addParams (fx) {
  var videoFx = fx.raw
  for (let i = 0; i < fx.params.length; i++) {
    let param = fx.params[i]
    if (param.type === 'float') {
      videoFx.setFloatVal(param.key, Number(param.value))
    } else if (param.type === 'int') {
      videoFx.setIntVal(param.key, Number(param.value))
    } else if (param.type === 'bool') {
      videoFx.setBooleanVal(param.key, param.value)
    } else if (param.type === 'string') {
      if (param.key !== 'Background Image') {
        videoFx.setStringVal(param.key, param.value)
      }
    } else if (param.type === 'menu') {
      videoFx.setMenuVal(param.key, param.value)
    } else if (param.type === 'arbitrary') {
      if (param.value) {
        let regionInfo = new NvsRegionInfo()
        let arr = param.value.split(',')
        regionInfo.type = fx.shape
        if (regionInfo.type === 'polygon' || regionInfo.type === 'cubicCurve') {
          regionInfo.points = new NvsVectorVal()
          for (let i = 0; i < arr.length; i += 2) {
            regionInfo.points.push_back(new NvsPointF(Number(arr[i]), Number(arr[i+1])))
          }
        } else if (regionInfo.type === 'ellipse') {
          if (arr.length >= 5) {
            regionInfo.center = new NvsPointF(Number(arr[0]), Number(arr[1]))
            regionInfo.a = Number(arr[2])
            regionInfo.b = Number(arr[3])
            regionInfo.theta = Number(arr[4])
          }
        }
        videoFx.setArbitraryVal(param.key, regionInfo)
      }
    } else if (param.type === 'object') {
      if (param.key === 'region' && fx.shape === 'rect') {
        const region = new NvsVectorFloat()
        region.push_back(param.value.x1)
        region.push_back(param.value.y1)
        region.push_back(param.value.x2)
        region.push_back(param.value.y2)
        region.push_back(param.value.x3)
        region.push_back(param.value.y3)
        region.push_back(param.value.x4)
        region.push_back(param.value.y4)
        videoFx.setRegion(region)
      } else if (param.key === 'ellipseRegion' && fx.shape === 'ellipse') {
        const center = new NvsPointF(param.value.centerX, param.value.centerY)
        videoFx.setEllipseRegion(center, param.value.a, param.value.b, param.value.angle)
        videoFx.setRegionalFeatherWidth(fx.regionalFeatherWidth)
      }
    } else if (param.type === 'color') {
      videoFx.setColorVal(param.key, Utils.HexToNvsColor(param.value))
    }
  }
}
function addKeyFrames (fx) {
  var videoFx = fx.raw
  for (let i = 0; i < fx.keyFrames.length; i++) {
    const keyFrame = fx.keyFrames[i]
    if(KEYFRAME_AUDIO.includes(keyFrame.key)) continue;
    if (keyFrame.type === 'float') {
      videoFx.setFloatValAtTime(keyFrame.key, keyFrame.value, Number(keyFrame.time))
    } else if (keyFrame.type === 'int') {
      videoFx.setIntValAtTime(keyFrame.key, keyFrame.value, Number(keyFrame.time))
    } else if (keyFrame.type === 'bool') {
      videoFx.setBooleanValAtTime(keyFrame.key, keyFrame.value, Number(keyFrame.time))
    } else if (keyFrame.type === 'string') {
      videoFx.setStringValAtTime(keyFrame.key, keyFrame.value, Number(keyFrame.time))
    } else if (keyFrame.type === 'menu') {
      videoFx.setMenuValAtTime(keyFrame.key, keyFrame.value, Number(keyFrame.time))
    } else if (keyFrame.type === 'object') {
      if (keyFrame.key === 'region') {
        const region = new NvsVectorFloat()
        region.push_back(keyFrame.value.x1)
        region.push_back(keyFrame.value.y1)
        region.push_back(keyFrame.value.x2)
        region.push_back(keyFrame.value.y2)
        region.push_back(keyFrame.value.x3)
        region.push_back(keyFrame.value.y3)
        region.push_back(keyFrame.value.x4)
        region.push_back(keyFrame.value.y4)
        videoFx.setRegionAtTime(region, Number(keyFrame.time))
      } else if (keyFrame.key === 'ellipseRegion') {
        const center = new NvsPointF(keyFrame.value.centerX, keyFrame.value.centerY)
        videoFx.setEllipseRegionAtTime(center, keyFrame.value.a, keyFrame.value.b, keyFrame.value.angle, parseFloat(keyFrame.time))
        videoFx.setRegionalFeatherWidth(fx.regionalFeatherWidth)
      }
    } else if (keyFrame.type === 'color') {
      // videoFx.setColorValAtTime(param.key, Utils.HexToNvsColor(param.value))
    }
  }
}
function addParticleParams (fx, emitterList) {
  var videoFx = fx.raw
  var particleCtx = videoFx.getParticleSystemContext()
  for (let i = 0; i < fx.params.length; i++) {
    let param = fx.params[i]
    if (param.type === 'float') {
      if (param.key === 'rateGain') {
        for (let j = 0; j < emitterList.length; j++) {
          particleCtx.setEmitterRateGain(emitterList[j], param.value)
        }
      } else if (param.key === 'sizeGain') {
        for (let j = 0; j < emitterList.length; j++) {
          particleCtx.setEmitterParticleSizeGain(emitterList[j], param.value)
        }
      }
    } else if (param.type === 'object') {
      if (param.key == 'curvePoint') {
        for (let j = 0; j < emitterList.length; j++) {
          particleCtx.appendPositionToEmitterPositionCurve(emitterList[j], param.value.timeSec, param.value.x, param.value.y)
        }
      }
    }
  }
}
async function resurfaceFilters (stickerClips, callback) {
  let z;
  timelineData.tracks.forEach(item=>{
      if(item.clips === stickerClips) {
        z = timelineData.tracks.length - item.index
      }
  })
  await addFilters(stickerClips, z)
  if (callback && typeof callback === 'function') {
    callback()
  }
}

function logFilters (firstTimelineVideoFx) {
  // if (firstTimelineVideoFx !== undefined) {
  //   console.log('logFilters firstTimelineVideoFx', firstTimelineVideoFx.getInPoint(), firstTimelineVideoFx.getOutPoint())
  //   // console.log('logStickers firstAnimatedSticker', firstAnimatedSticker.getInPoint(), firstAnimatedSticker.getOutPoint(), firstAnimatedSticker.getAnimatedStickerPackageId())
  //   let nextSticker = mTimeline.getNextTimelineVideoFx(firstTimelineVideoFx)
  //   if (nextSticker !== undefined) {
  //     console.log('logFilters', nextSticker.getInPoint(), nextSticker.getOutPoint())
  //     // console.log('logStickers', nextSticker.getInPoint(), nextSticker.getOutPoint(), nextSticker.getAnimatedStickerPackageId())
  //     logFilters(nextSticker)
  //   }
  // }
}
async function deleteFilters (filterClips) {
  // console.log('deleteFilters', filterClips)
  for (let i = 0; i < filterClips.length; i++) {
    if (filterClips[i].raw) {
      await mStreamingContext.streamingEngineReadyForTimelineModification()
      // console.log('删除了一个贴纸： ', i, filterClips[i].raw)
      mTimeline.removeTimelineVideoFx(filterClips[i].raw)
    }

    if (filterClips[i].multiRaw) {
      await mStreamingContext.streamingEngineReadyForTimelineModification()
      Object.values(filterClips[i].multiRaw).forEach(raw => {
        mTimeline.removeTimelineVideoFx(raw)
      })
    }
  }
}

function nvStreamingContext () {
  return mStreamingContext
}

/**
 * seek定位
 * @param nowTime 可以不穿参数 会定位到现在的播放位置
 */
function seekTimeline (nowTime, prefetch) {
  if (!mStreamingContext || !mTimeline) {
    console.error('timeline is null')
    return
  }
  nowTime = nowTime ?? getCurrentTimelinePosition()
  nowTime = Utils.getFrameTime(nowTime)
  let tar = Utils.hasFaceEffect(timelineData.tracks, 'seek')
  let flags = tar
  if (prefetch) flags |= NvsSeekFlagEnum.WebReaderPrefetchForSeek
  mStreamingContext.seekTimeline(mTimeline, nowTime, NvsVideoPreviewSizeModeEnum.LiveWindowSize, flags)
  if(store.state.currentPanelType === GL.MediaPanel) {
    toggleKeyFramesSelected()
    toggleAudioKeyFramesSelected()
  }else if(store.state.currentPanelType === GL.MusicPanel) {
    toggleAudioKeyFramesSelected()
  }
  EventBus.$emit(EventBusKey.onPlaybackSeek, nowTime)
  EventBus.$emit(EventBusKey.isMoveSeekBar, nowTime)
}

/**
 * @description: 处理关键帧的选中状态
 * @param {*}
 * @return {*}
 */
function toggleKeyFramesSelected() {
  const selectedClip = getSelectedClip()
  if(!selectedClip) return
  const keyFrames = getKeyFramesByClip(selectedClip)
  store.commit('keyFrame/resetVideoKeyFrameSelected')
  keyFrames.map(frame => {
    if (isFrameTimeEqual(Number(frame.time) + selectedClip.inPoint)) {
      store.commit('keyFrame/updateSelectedTime', Number(frame.time))
      switch (frame.key) {
        case 'Scale X':
        case 'Scale Y':
          store.commit('keyFrame/toggleVideoScaleSelected', true)
          break;
        case 'Trans X':
        case 'Trans Y':
          store.commit('keyFrame/toggleVideoTransSelected', true)
          break;
        case 'Opacity':
          store.commit('keyFrame/toggleVideoOpacitySelected', true)
          break;
        case 'Rotation':
          store.commit('keyFrame/toggleVideoRotationSelected', true)
          break;
        case 'region':
        case 'ellipseRegion':
          store.commit('keyFrame/toggleVideoMaskSelected', true)
          break;
        default:
          break;
      }
    }
  })
}
function toggleAudioKeyFramesSelected() {
  const selectedClip = getSelectedClip()
  if(!selectedClip) return
  const keyFrames = getKeyFramesByClip(selectedClip)
  store.commit('keyFrame/resetAudioKeyFrameSelected')
    keyFrames.map(frame => {
      if (isFrameTimeEqual(Number(frame.time) + selectedClip.inPoint)) {
        store.commit('keyFrame/updateSelectedTime', Number(frame.time))
        switch (frame.key) {
          case 'Left Gain':
          case 'Right Gain':
            store.commit('keyFrame/toggleAudioVolumeSelected', true)
            break;
          default:
            break;
        }
      }
    })


}
// 获取当前引擎状态
function isPlaying () {
  if (!mStreamingContext) {
    console.error('mStreamingContext is null')
    return false
  }
  return mStreamingContext.getStreamingEngineState() === NvsStreamingEngineStateEnum.StreamingEngineStatePlayback
}

function timeline () {
  return mTimeline
}
function getMaxTrackLength () {
  let length = 0
  // videoTrack
  for (let i = 1; i < mTimeline.videoTrackCount(); i++) {
    let videoTrack = mTimeline.getVideoTrackByIndex(i)
    let lastClip = videoTrack.getClipByIndex(videoTrack.getClipCount() - 1)
    if (lastClip !== undefined) {
      length = lastClip.getOutPoint() > length ? lastClip.getOutPoint() : length
      // console.log('videoTrackCount', length, lastClip.getOutPoint())
    }
  }
  // audioTrack
  for (let i = 0; i < mTimeline.audioTrackCount(); i++) {
    let audioTrack = mTimeline.getAudioTrackByIndex(i)
    let lastClip = audioTrack.getClipByIndex(audioTrack.getClipCount() - 1)
    if (lastClip !== undefined) {
      length = lastClip.getOutPoint() > length ? lastClip.getOutPoint() : length
    }
  }
  // 字幕
  let lastCaption = mTimeline.getLastCaption()
  if (lastCaption !== undefined) {
    length = lastCaption.getOutPoint() > length ? lastCaption.getOutPoint() : length
  }
  // 贴纸
  let lastSticker = mTimeline.getLastAnimatedSticker()
  if (lastSticker !== undefined) {
    length = lastSticker.getOutPoint() > length ? lastSticker.getOutPoint() : length
  }
  // 复合字幕
  let lastCompoundCaption = mTimeline.getLastCompoundCaption()
  if (lastCompoundCaption !== undefined) {
    length = lastCompoundCaption.getOutPoint() > length ? lastCompoundCaption.getOutPoint() : length
  }
  // 特效
  let lastVideoFx = mTimeline.getLastTimelineVideoFx()
  if (lastVideoFx !== undefined) {
    length = lastVideoFx.getOutPoint() > length ? lastVideoFx.getOutPoint() : length
  }
  return length
}
// 获取轨道数据中的最大outPoint
function getMaxDataLength () {
  let max = 0
  let tracks = timelineData.tracks
  for (let i = 0; i < tracks.length; i++) {
    let clips = tracks[i].clips
    for (let j = 0; j < clips.length; j++) {
      let clip = clips[j]
      if (clip.outPoint > max) {
        max = clip.outPoint
      }
    }
  }
  return max
}
function getAudioClipByPathName (path) {
  let tracks = timelineData.tracks
  for (let i = 0; i < tracks.length; i++) {
    let tracksKey = tracks[i]
    if (tracksKey.type !== GL.AUDIOTRACK) {
      continue
    }
    let clips = tracksKey.clips
    for (let j = 0; j < clips.length; j++) {
      let clip = clips[j]
      // console.log('getAudioClipByPathName', path, clip)
      if (clip.m3u8Path === path && clip.m3u8Url === path && clip.path === path) {
        return clip
      }
    }
  }
  return undefined
}

function uuid () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

/**
 * 检查被addclip2分割的视频，他们的共同点是uuid重复。只适用于音视频 （确定只适用于音频？？）
 * @param trackInItems 在数据里的track
 * @param videoTrackInTimeline 在底层的track
 */
async function checkSplitClipAndAdd (trackInItems) {
  let o = {}
  for (let i = 0; i < trackInItems.clips.length; i++) {
    let item = trackInItems.clips[i]
    if (o[item.uuid] && item.uuid !== '') {
      o[item.uuid].a++
      o[item.uuid].b.push(item.index)
    } else {
      o[item.uuid] = {a: 1, b: [item.index]}
    }
  }
  // console.log('checkSplitClipAndAdd', o, trackInItems.clips)
  let videoTrackInTimeline = trackInItems.raw
  for (let ddd in o) {
    if (o[ddd].a > 1) {
      // 后面clip要重新添加特效等等
      for (let j = 1; j < o[ddd].a; j++) {
        const clip = trackInItems.clips[o[ddd].b[j]]
        const prevClip = trackInItems.clips[o[ddd].b[j - 1]]
        clip.uuid = uuid()
        if (!trackInItems.show) {
          continue
        }
        const clipRaw = videoTrackInTimeline.getClipByTimelinePosition(clip.inPoint)
        // console.log('重复的clip，', o[ddd].b[j])
        // 这里不可以 删除后面的clip 再重新添加！ 对于有变速的音视频，删除再添加会影响后面的视频。
        // videoTrackInTimeline.removeClip(clipRawIndex, true)
        const frames = getKeyFramesByClip(clip)
        const diff = clip.inPoint - prevClip.inPoint
        frames.forEach(frame => {
          frame.time = Number(frame.time) - diff
        })
        if (clip.videoFxs) {
          let propertyFx = clip.videoFxs.find(fx => fx.type === 'property')
          if (propertyFx) {
            let package2Id = propertyFx.isPostOutAnimation ? 'Post Package2 Id' : 'Package2 Id'
            if (propertyFx.animationType) {
              for (let i = 0; i < propertyFx.params.length; i++) {
                let param = propertyFx.params[i]
                if (param.key === 'Package Effect In' || param.key === 'Package Effect Out') param.value -= diff
              }
            } else {
              const paramPackage2Id = propertyFx.params.find(item => item.key === package2Id)
              if (paramPackage2Id && paramPackage2Id.value) {
                for (let i = 0; i < propertyFx.params.length; i++) {
                  let param = propertyFx.params[i]
                  if (param.key === 'Package2 Effect In' || param.key === 'Package2 Effect Out') param.value -= diff
                }
              }
            }
          }
          refreshVideoPropertyFxWhenMouseUp(clip)
        }
        if (trackInItems.type === GL.VIDEOTRACK) {
          // addVideoClip(videoTrackInTimeline, clip, prevClip)
          await setVideoClipProperty(clipRaw, clip)
        } else if (trackInItems.type === GL.AUDIOTRACK) {
          // addAudioClip(videoTrackInTimeline, clip)
          setAudioProperty(clipRaw, clip)
        }
        refreshKeyFrameWhenMouseUp(clip)
      }
    } else {
      const clip = trackInItems.clips[o[ddd].b[0]]
      refreshKeyFrameWhenMouseUp(clip)
    }
  }
}

var cmdPos = -1
var cmdMaxPos = cmdPos
var db
var objectStoreName = 'operations'

function canUndo () {
  return cmdPos > 0
}

function canRedo () {
  return cmdPos < cmdMaxPos
}

function saveBaseProjectDataToIndexDB (name, version) {
  var request = window.indexedDB.open(name, version)
  request.onerror = function (e) {
    console.error('open index db error!')
  }
  request.onsuccess = function (e) {
    db = e.target.result
    // console.log('index db version:' + db.version)
    if (db.objectStoreNames.contains(objectStoreName)) {
      var transaction = db.transaction(objectStoreName, 'readwrite')
      var store = transaction.objectStore(objectStoreName)
      store.clear()
      saveProjectDataToIndexDB(true)
    }
  }
  request.onupgradeneeded = function (e) {
    var ret = e.target.result
    if (!ret.objectStoreNames.contains(objectStoreName)) {
      ret.createObjectStore(objectStoreName, {keyPath: 'id'})
    } else {
      var transaction = db.transaction(objectStoreName, 'readwrite')
      var store = transaction.objectStore(objectStoreName)
      store.clear()
      saveProjectDataToIndexDB()
    }
    console.log('DB version changed to ' + version)
  }
}

function saveProjectDataToIndexDB (first) {
  var transaction = db.transaction(objectStoreName, 'readwrite')
  var store = transaction.objectStore(objectStoreName)
  for (var i = cmdPos + 1; i <= cmdMaxPos; i++) {
    store.delete(i)
  }
  let tData = deepCopy(TimelineUtils.timelineData)
  let { titleDuration } = TimelineUtils.timelineData.theme
  let diff = first ? 0 : (titleDuration || 0)
  tData.tracks.map(track => {
    if (track.type === GL.VIDEOTRACK) {
      track.clips.map(clip => {
        clip.inPoint -= diff
        clip.outPoint -= diff
      })
    }
  })

  // 删除带主题的时间线多出来的视频空轨道
  const videoTrackIndexArr = []
  tData.tracks.forEach((item, index) => {
    if (item.type === GL.VIDEOTRACK && item.clips.length === 0) {
      videoTrackIndexArr.push(index)
    }
  })
  for (let i = videoTrackIndexArr - 1; i > 0; i--) {
    tData.tracks.splice(videoTrackIndexArr[i], 1)
  }

  var data = {
    'id': ++cmdPos,
    'data': JSON.stringify(tData, replacer)
  }
  // console.log('记录数据：', data.data)
  store.put(data)
  cmdMaxPos = cmdPos
  changeUIStatus('revocation', !canUndo())
  changeUIStatus('recover', !canRedo())
  window.onbeforeunload = e => {
    nvsGetStreamingContextInstance().stop()
    return '关闭可能不会保存更改'
  }
}

function undoProjectDataFromIndexDB () {
  if (cmdPos <= 0) {
    console.log('No operation to undo!')
    return
  }
  --cmdPos
  loadProjectDataFromIndexDB(cmdPos)
  changeUIStatus('revocation', !canUndo())
  changeUIStatus('recover', !canRedo())
}

function redoProjectDataFromIndexDB () {
  if (cmdPos >= cmdMaxPos) {
    console.log('No operation to redo!')
    return
  }
  ++cmdPos
  loadProjectDataFromIndexDB(cmdPos)
  changeUIStatus('revocation', !canUndo())
  changeUIStatus('recover', !canRedo())
}
// 撤销重做后，修改icon的状态
function changeUIStatus (id, isDisabled) {
  const dom = document.getElementById(id)
  if (dom) {
    if (isDisabled) dom.classList.add('disable')
    else dom.classList.remove('disable')
  }
}
function resetProjectDataInIndexDB () {
  var transaction = db.transaction(objectStoreName, 'readwrite')
  var store = transaction.objectStore(objectStoreName)
  store.clear()
  cmdPos = -1
  cmdMaxPos = cmdPos
  saveProjectDataToIndexDB()
}

function loadProjectDataFromIndexDB (value) {
  var transaction = db.transaction(objectStoreName, 'readwrite')
  var store = transaction.objectStore(objectStoreName)
  var request = store.get(value)
  const loadingInstance = Loading.service({target: '#app', background: 'rgba(0, 0, 0, 0.7)', lock: true})
  request.onsuccess = async function (e) {
    var data = e.target.result
    const timelineData = JSON.parse(data.data, (key, value) => {
      if(key === 'tracks') {
        value.forEach(track => {
          track.clips.forEach(clip => clip.selected = false)
        })
      }
      if (key === 'raw' && value === 'undefined') {
        return null
      }
      return value
     })
    TimelineUtils.timelineData = timelineData
    await setTimelineData()
    addTimelineData(TimelineUtils.timelineData) // 数据更新到timelineDataArray
    let theme = TimelineUtils.timelineData.theme
    if (!(theme && theme.id)) EventBus.$emit(EventBusKey.refreshTimelineUI)
    loadingInstance.close()
    // EventBus.$emit(EventBusKey.monitorTimelineAppend)
    EventBus.$emit(EventBusKey.revocationHandle)
  }
  request.onerror = () => {
    loadingInstance.close()
  }
}

function replacer (key, value) {
  if (key === 'raw') {
    return 'undefined'
  }
  return value
}

// function saveProjectDataToFS () {
//   var timeline = TimelineUtils.timelineData
//   FS.writeFile('/mydata/projectData.json', JSON.stringify(timeline, replacer))
//   console.log('read project json data!')
//   console.log(FS.readFile('/mydata/projectData.json', {encoding: 'utf8'}))
// }
//
// function loadProjectDataFromFS () {
//   var timeline = JSON.parse(FS.readFile('/mydata/projectData.json', {encoding: 'utf8'}))
//   console.log(timeline)
// }

var queueMapDB
var queueMapObjectStoreName = 'queues'
var expireDuration = 7*24*3600*1000

function insertCurrentQueueToIndexDB (name, version) {
  var nowTime = new Date().getTime()
  var request = window.indexedDB.open(name, version)
  request.onerror = function (e) {
    console.error('open index db error!')
  }
  request.onsuccess = function (e) {
    queueMapDB = e.target.result
    // console.log('queue map indexedDB version:' + queueMapDB.version)
    if (queueMapDB.objectStoreNames.contains(queueMapObjectStoreName)) {
      var transaction = queueMapDB.transaction(queueMapObjectStoreName, 'readwrite')
      var store = transaction.objectStore(queueMapObjectStoreName)
      var data = {
        'id': queueUUID,
        'date': nowTime
      }
      store.put(data)
      var requestGetAllKeys = store.getAllKeys()
      requestGetAllKeys.onsuccess = function (e) {
        var resultGetAllKeys = e.target.result
        for (let i = 0; i < resultGetAllKeys.length; i++) {
          let requestGetOne = store.get(resultGetAllKeys[i])
          requestGetOne.onsuccess = function (e) {
            let resultGetOne = e.target.result
            if (nowTime - resultGetOne.date > expireDuration) {
              window.indexedDB.deleteDatabase(resultGetOne.id + '_queue')
              store.delete(resultGetOne.id)
            }
          }
        }
      }
    }
  }
  request.onupgradeneeded = function (e) {
    var ret = e.target.result
    if (!ret.objectStoreNames.contains(queueMapObjectStoreName)) {
      ret.createObjectStore(queueMapObjectStoreName, {keyPath: 'id'})
    } else {
      var transaction = queueMapDB.transaction(queueMapObjectStoreName, 'readwrite')
      var store = transaction.objectStore(queueMapObjectStoreName)
      var data = {
        'id': queueUUID,
        'date': new Date().getTime()
      }
      store.put(data)
      var requestGetAllKeys = store.getAllKeys()
      requestGetAllKeys.onsuccess = function (e) {
        var resultGetAllKeys = e.target.result
        for (let i = 0; i < resultGetAllKeys.length; i++) {
          let requestGetOne = store.get(resultGetAllKeys[i])
          requestGetOne.onsuccess = function (e) {
            let resultGetOne = e.target.result
            if (nowTime - resultGetOne.date > expireDuration) {
              store.delete(resultGetAllKeys[i])
            }
          }
        }
      }
    }
    // console.log('Queue map indexedDB version changed to ' + version)
  }
}

function updateCurrentQueueTimeToIndexDB() {
  var transaction = queueMapDB.transaction(queueMapObjectStoreName, 'readwrite')
  var store = transaction.objectStore(queueMapObjectStoreName)
  var data = {
    'id': queueUUID,
    'date': new Date().getTime()
  }
  store.put(data)
}

var videoVolArray = []
var audioVolArray = []
var themeVol

function muteAudioOutput () {
  if (mTimeline === undefined) { return }
  let videoTrackCount = mTimeline.videoTrackCount()
  for (let i = 0; i < videoTrackCount; i++) {
    let track = mTimeline.getVideoTrackByIndex(i)
    videoVolArray.push(track.getVolumeGain())
    track.setVolumeGain(0, 0)
  }
  let audioTrackCount = mTimeline.audioTrackCount()
  for (let i = 0; i < audioTrackCount; i++) {
    let track = mTimeline.getAudioTrackByIndex(i)
    audioVolArray.push(track.getVolumeGain())
    track.setVolumeGain(0, 0)
  }
  let { theme } = timelineData
  if (theme && theme.id) {
    themeVol = mTimeline.getThemeMusicVolumeGain()
    mTimeline.setThemeMusicVolumeGain(0, 0)
  }
}

function resumeAudioOutput () {
  if (mTimeline === undefined) { return }
  for (let i = 0; i < videoVolArray.length; i++) {
    let track = mTimeline.getVideoTrackByIndex(i)
    if (track !== undefined) {
      let volume = videoVolArray[i]
      track.setVolumeGain(volume.leftVolume, volume.rightVolume)
    }
  }
  for (let i = 0; i < audioVolArray.length; i++) {
    let track = mTimeline.getAudioTrackByIndex(i)
    if (track !== undefined) {
      let volume = audioVolArray[i]
      track.setVolumeGain(volume.leftVolume, volume.rightVolume)
    }
  }
  videoVolArray.length = 0
  audioVolArray.length = 0
  let { theme } = timelineData
  if (theme && theme.id) {
    themeVol = themeVol === undefined ? 1 : themeVol
    mTimeline.setThemeMusicVolumeGain(themeVol, themeVol)
  }
}

function disablePlaybackPositionCallback (disable) {
  disablePlaybackPos = disable
}

/**
 * 检查底层对象是否添加正确
 * @param obj 底层对象
 * @param data 对象附加的本地属性  可以为空
 */
function checkUndefind (obj, data) {
  if (obj === undefined) {
    console.error(obj, ' is undefind! ', data)
    // EventBus.$emit('debug')
  }
}
function getClipFromTrackData (trackData, inPoint) {
  let clipLength = trackData.clips.length
  let index = -1
  for (let i = 0; i < clipLength; i++) {
    let clip = trackData.clips[i]
    if (clip.inPoint === inPoint) {
      return i
    }
  }
  return index
}
function setTheme (theme) {
  if (!timelineData) {
    console.error('timelineDate is undefined')
    return
  }
  timelineData.theme = theme
}
function updataTheme (key, value) {
  if (key) timelineData.theme[key] = value
}
function deleteTheme () {
  if (!timelineData) {
    console.error('timelineDate is undefined')
    return
  }
  timelineData.theme = {}
}
function initTimelineData (data) {
  data.tracks.push(new VideoTrack(0))
  data.tracks.push(new AudioTrack(data.tracks.length))
  if (GL.isSupportCaption() && GL.isSupportCaptionStyleSetting())
    data.tracks.push(new MusicLyricsTrack(data.tracks.length))
}

// 音视频或单声道分离时，分离的音频可能是同个视频多音频流中的一个，
// 根据新字段audioStreamIndex，从leftChannelUrl或rightChannelUrl中找到
function getAudioClipWaveUrl(clip) {
  const index = clip.audioStreamIndex
  let url
  if(clip.channelType === 'right') {
    url = clip.rightChannelUrl
  } else {
    url = clip.leftChannelUrl
  }
  if (index && (index > 0)) {
    let urlArray= url.split(";")
    url = urlArray[index]
  }
  return url
}
// 对齐上下层数据的trim和曲线变速参数
function alignTrimAndGetCurveSpeedString(...args) {
  for(let clip of args) {
    const { raw } = clip
    if (!raw) break
    clip.curveSpeedString = raw.getClipVariableSpeedCurvesString()
    if (!clip.curveSpeedString) break
    clip.speed = raw.getSpeed()
    clip.trimIn = raw.getTrimIn()
    clip.trimOut = raw.getTrimOut()
    clip.inPoint = raw.getInPoint()
    clip.outPoint = clip.inPoint + (clip.trimOut - clip.trimIn) / clip.speed
    alignFrame(clip)
  }
}

async function applyThemeTemplate (templateId) {
  await mStreamingContext.streamingEngineReadyForTimelineModification()
  // mTimeline.addCaption('caption1', 0, 5000000, '', false)
  let footages = []
  for (let i = 0; i < timelineData.tracks.length; i++) {
    let track = timelineData.tracks[i]
    if (track.type === GL.VIDEOTRACK || track.type === GL.AUDIOTRACK) {
      if (track.clips.length > 0) {
        footages = footages.concat(track.clips)
      }
    }
  }
  try {
    mTimeline.applyThemeTemplate(templateId)
    TimelineUtils.timelineData = await transcodeTimelineToProjectData(mTimeline, footages, templateId)
    setTimelineData({ isBuildTemplate: true })
    saveProjectDataToIndexDB()
  } catch (error) {
    console.error(error);
  }
}
const TimelineUtils = {
  alignTrimAndGetCurveSpeedString,
  getResolution,
  setTimelineData,
  buildClipTimeline,
  timeline,
  createTimeline,
  nvStreamingContext,
  buildVideoTrack,
  buildAudioTrack,
  buildCaptionByItem,
  buildSubtitleCaptionByItem,
  buildStickerByItem,
  addVideoClip,
  seekTimeline,
  isPlaying,
  initContext,
  changeEmptyTrack,
  refreshAfterApplyTheme,
  deleteCaptions,
  deleteCaption,
  setVideoClipProperty,
  addCaptions,
  addCaption,
  addSubtitleCaption,
  addSubtitleCaptions,
  addParams,
  addVideoFxs,
  resurfaceCaptions,
  resurfaceCompoundCaptions,
  saveProjectDataToIndexDB,
  undoProjectDataFromIndexDB,
  redoProjectDataFromIndexDB,
  resetProjectDataInIndexDB,
  buildTransitionForVideoClip,
  canUndo,
  canRedo,
  updateCurrentQueueTimeToIndexDB,
  deleteStickers,
  addStickers,
  addSticker,
  logStickers,
  resurfaceStickers,
  playBackTimeline,
  getCurrentTimelinePosition,
  removeVideoOrAudioTrackClip,
  buildVideoTrackByItem,
  buildAudioTrackByItem,
  getMaxTrackLength,
  addFilters,
  resurfaceFilters,
  logFilters,
  deleteFilters,
  addAudioClip,
  addAudioFxs,
  buildCompoundCaptionByItem,
  buildVideoFXByItem,
  addCompoundCaptions,
  addCompoundCaption,
  deleteCompoundCaptions,
  stop,
  muteAudioOutput,
  resumeAudioOutput,
  disablePlaybackPositionCallback,
  getAudioClipByPathName,
  getTimelineData,
  getTimelineDataArray,
  setTimelineDataArray,
  newTimelineDataByRatio,
  getTimelineDataByRatio,
  changeMusicCaptionsStyle,
  applyCaptionStyleForMusicLyrics,
  applyCaptionsStyleForMusicLyrics,
  getMaxDataLength,
  uuid,
  checkSplitClipAndAdd,
  insertVideoClip,
  insertAudioClip,
  installDefaultSubtitleStyle,
  getClipFromTrackData,
  getTracksInTimelineDataByType,
  getMusicLyricsTrack,
  setTheme,
  deleteTheme,
  updataTheme,
  initTimelineData,
  addTimelineData,
  getAudioClipWaveUrl,
  installOtherFile,
  removeTimeline,
  applyThemeTemplate
}
Object.defineProperty(TimelineUtils, 'timelineData', {
  get () {
    return timelineData
  },
  set (val) {
    timelineData = val
    if (mTimeline == null) {
      console.error('mTimeline is null')
      return
    }
    EventBus.$emit(EventBusKey.refreshTimelineUI)
  }
})

export default TimelineUtils
