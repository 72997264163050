import { get, post } from '@/utils/request'
import NvNetwork from '@/api/network/NvNetwork'

export default {

  tag_all: ({ ...props }) =>
    get({
      url: NvNetwork.data().tag_all,
      headers: {
        params: { ...props }
      }
    }),
  
  tag_fuzzySearch: ({ ...props }) =>
    get({
      url: NvNetwork.data().tag_fuzzySearch,
      headers: {
        params: { ...props }
      }
    }),
  
  tag_create: (data, { ...props }) =>
    post({
      url: NvNetwork.data().tag_create,
      data,
      headers: {
        params: { ...props }
      }
    }),
}
