export default {
  namespaced: true,
  state: {
    toggleVU: false,
    togglePopRichtext: false,
    loadingInstance: null
  },
  mutations: {
    updateLoadingInstance (state, paylod) {
      state.loadingInstance = paylod
    },
    updateToggleVU (state) {
      state.toggleVU = !state.toggleVU
    },
    openPopRichtext(state) {
      state.togglePopRichtext = true
    },
    updatePopRichtext(state) {
      state.togglePopRichtext = !state.togglePopRichtext
    }
  }
}
